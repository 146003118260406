.multiple-bookings-contaner {
  padding-top: 60px;
  .PageHead {
    display: flex;
    justify-content: space-between;

    .cashback-bar-wrapper {
      margin: 0;
    }

    .cashback-item {
      justify-content: end;
      margin-right: 50px;
    }

    .cashback-item {
      justify-content: end;
      margin-right: 50px;
    }
  }

  .Booking-Multiple-Actions {
    z-index: 12;
  }

  .MultipleBookingLayout-Collapse {
    z-index: 8;
  }

  .MultipleBookingLayout-Collapse-Icon {
    z-index: 24;
  }
}

.multiple-bookings-contaner,
.Batch {
  .cashback-info-section {
    position: fixed;
    bottom: 57px;
    right: 0;
    left: auto;
    cursor: pointer;
    width: 390px;
    z-index: 23;
    transform: translateX(390px);
    transition: 400ms ease-in-out 0s;

    &.Active {
      transform: translateX(0);
    }

    [data-tooltip-position="top"] {
      &:before {
        transform: translate(-75%, -5px);
      }

      &:after {
        transform: translateY(-5px);
      }
    }
  }

  .partial-popup {
    width: 390px;
    right: 0;
    left: auto;
    z-index: 12;
  }

  .credit-section {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 10px 20px;

    .block-item-custom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:before {
        content: '';
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        z-index: -1;
        background-repeat: repeat-x;
        height: 2px;
        background-size: 5px;
        background-position: 0px 0;
      }

      &.hide-divide {
        justify-content: flex-start;

        &:before {
          display: none
        }

        label {
          margin-right: 5px;
        }
      }

      label,
      span {
        background-color: var(--color-white);
      }
    }

    .Overlay-Checkbox {
      margin-right: 10px;
    }
  }

  .credit-tooltip {
    &[data-tooltip-position="bottom"] {
      &:before {
        transform: translate(-50%, 4px);
      }

      &:after {
        transform: translateY(4px);
      }
    }
  }
}

.ez-multiple-chat-hotline .Normal-Booking {
  width: auto;
  max-width: 340px;
  min-width: 340px;
}

.MultipleBookingLayout-Collapse {
  padding: 15px 0 0;
}

.SmartPlanner .Booking-BatchEZ {
  .cashback-explain {
    z-index: 12;

    .popup-inner {
      width: 375px;
      background-color: unset;
      overflow: visible;
    }

    .btn-wrapper {
      padding: 0;
      margin: 10px 0 0;
    }
  }
}

.multiple-bookings-contaner .DynamicPopupWrapper .Popup {
  max-width: 340px;
  margin: auto;
}
.multiple-bookings-contaner .DynamicPopupWrapper .Popup .Popup-Booking-Custom {
  min-width: 310px;
  max-width: 310px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .multiple-bookings-contaner {
    .partial-popup,
    .cashback-info-section {
      zoom: 0.8;
    }
  }

  .Batch {
    .partial-popup,
    .cashback-info-section {
      zoom: 0.85;
    }

    .cashback-info-section {
      bottom: 62px;
    }
  }

  .SmartPlanner .cashback-info-section {
    bottom: 67px;
  }
}

.z-index-8-max {
  z-index: 8 !important;
}