.address-input {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  &.with-auto {
    width: auto;
  }
  &.Input {
    align-items: flex-start;
    height: auto;
  }
  textarea {
    resize: none;
    border: none;
    font-size: var(--font-size);
    height: 38px;
    line-height: 18px;
    padding: 10px;
    flex: 1;
    box-sizing: border-box;
    background-color: unset;
    &:focus-visible {
      outline: none;
    }
  }
  &:has(textarea:focus):not(.error, .dlvr-error-input-border) {
    border: 1px solid var(--color-green);
  }

  &:hover {
    .right-action {
      display: flex;
      background: #fff;
    }
  }
  &:hover {
    .input-action-group {
      display: flex;
    }
  }
  .input-action-group {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--color-white);
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    display: none;
    &.expanded {
      display: flex;
    }
  }
  &.Light-Gray-bg .input-action-group {
    background-color: #efefef;
  }
  &.dlvr-error-input-border .input-action-group {
    background-color: #feedeb;
  }
}
.dropdown-location {
  position: fixed;
  padding: 0;
  margin: 0;
  background-color: white;
  z-index: 111111;
  min-height: 36px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.30);
  .dropdown-item {
    color: #444;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    padding: 0 10px;
    &:last-child .dropdown-item-line {
      border-bottom: none;
    }
    .dropdown-item-line {
      padding: 10px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-bottom: 1px solid #DBDBDB;
    }
    &:hover {
      background-color: #fafafa;
    }
    &.active {
      background-color: #ebf2fe;
    }
    &.no-results {
      cursor: unset;
    }

    .Place-Icon {
      height: 14px;
      margin: 0 7px 0px 0;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .dropdown-location {
    zoom: 0.8;
  }
}

.show-more-autocomplete {
  padding: 10px;
  margin-top: 10px;
  >div:first-child {
    color: #282828;
    font-size: 14px;
    font-weight: 500;
  }
  >div:last-child {
    color: rgba(40, 40, 40, 0.80);
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
    &:hover {
      color: black;
    }
    >img {
      margin-right: 5px;
    }
  }
}
.show-contact-autocomplete {
  padding: 10px;
  color: #282828;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  .icon-contact-cs {
    margin-right: 10px;
  }
}
.action-autocomplete {
  color: var(--color-green);
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: var(--color-dark-green)
  }
}
