:root {
  --font-family-base: "Roboto", "Helvetica", sans-serif;
  --max-content-width: 1000px;
  --screen-xs-min: 480px;
  --screen-sm-min: 768px;
  --screen-md-min: 992px;
  --screen-lg-min: 1200px;
  --color-white: #fff;
  --color-green: #3fae29;
  --color-dark-green: #0e730f;
  --color-blue: #00b9e6;
  --color-red: #f44336;
  --color-light-red: #f04433;
  --color-dark-red: #d91a0c;
  --color-yellow: #ffdb00;
  --color-gray-xxxl: #f9f9f9;
  --color-gray-xxl: #efefef;
  --color-gray-xl: #cecece;
  --color-gray-l: #aaa;
  --color-gray: #949494;
  --color-gray-d: #818181;
  --color-gray-xd: #6d6d6d;
  --color-gray-xxd: #4b4b4b;
  --color-gray-button: #a1a1a1;
  --color-dark-gray: var(--color-gray-d);
  --color-black: #333;
  --color-primary: var(--color-green);
  --color-secondary: var(--color-yellow);
  --color-disabled-text: var(--color-gray-xl);
  --color-disabled-bg: var(--color-gray-xxl);
  --color-note-bg: rgba(255, 255, 165, 0.3);
  --length-xxxl: 128px;
  --length-xxl: 96px;
  --length-xl: 60px;
  --length-l: 48px;
  --length: 36px;
  --length-s: 24px;
  --length-xs: 18px;
  --length-xxs: 15px;
  --length-xxxs: 12px;
  --height-xxxt: var(--length-xxxl);
  --height-xxt: var(--length-xxl);
  --height-xt: var(--length-xl);
  --height-t: var(--length-l);
  --height: var(--length);
  --height-s: var(--length-s);
  --height-xs: var(--length-xs);
  --height-xxs: var(--length-xxs);
  --height-xxxs: var(--length-xxxs);
  --width-xxxt: var(--length-xxxl);
  --width-xxt: var(--length-xxl);
  --width-xt: var(--length-xl);
  --width-t: var(--length-l);
  --width: var(--length);
  --width-s: var(--length-s);
  --width-xs: var(--length-xs);
  --width-xxs: var(--length-xxs);
  --width-xxxs: var(--length-xxxs);
  --font-size-xxxb: 48px;
  --font-size-xxb: 34px;
  --font-size-xb: 24px;
  --font-size-b: 18px;
  --font-size: 15px;
  --font-size-s: 13px;
  --font-size-xs: 9px;
  --font-size-xxs: 6px;
  --radius-xc: 12px;
  --radius-c: 9px;
  --radius: 6px;
  --radius-s: 3px;
  --radius-xs: 1px;
  --box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  --text-color: var(--color-gray-xxd);
  --text-color-l: var(--color-gray-xxl);
  --menu-height: 60px;
  --color-gray-1: #282828;
}

.Icon {
  font-size: var(--font-size);
}

.Icon.xs {
  font-size: var(--font-size-xs);
}

.Icon.s {
  font-size: var(--font-size-s);
}

.Icon.b {
  font-size: var(--font-size-b);
}

.Icon.xb {
  font-size: var(--font-size-xb);
}

.Icon.xxb {
  font-size: var(--font-size-xxb);
}

.Icon.xxxb {
  font-size: var(--font-size-xxxb);
}

.Icon.inherit {
  font-size: inherit;
}

.Icon.green,
.Input .Icon.green {
  color: var(--color-green);
}

.Icon.red,
.Input .Icon.red {
  color: var(--color-red);
}

.Icon.blue,
.Input .Icon.blue {
  color: var(--color-blue);
}

.Icon.yellow,
.Input .Icon.yellow {
  color: var(--color-yellow);
}

.Icon.gray,
.Input .Icon.gray {
  color: var(--color-gray);
}

.Avatar {
  height: var(--length);
  width: var(--length);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.Avatar,
.Avatar-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Avatar-icon {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  color: var(--color-green);
  display: none;
}

.Avatar:hover .Avatar-icon {
  display: flex;
  cursor: pointer;
}

.Avatar .Avatar-icon img {
  width: 24px;
}

.Avatar img {
  height: auto;
  width: 100%;
}

.Avatar.circle {
  border-radius: 1000px;
  overflow: hidden;
}

.Avatar.rounded {
  overflow: hidden;
}

.Avatar.rounded,
.Avatar.rounded img {
  border-radius: var(--radius);
}

.Avatar.b {
  height: var(--length-l);
  width: var(--length-l);
}

.Avatar.xb {
  height: var(--length-xl);
  width: var(--length-xl);
}

.Avatar.xxb {
  height: var(--length-xxl);
  width: var(--length-xxl);
}

.TitleSubtitle {
  font-size: inherit;
}

.TitleSubtitle-title {
  font-size: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.TitleSubtitle-title .step-2-eye {
  margin-left: 4px;
  margin-top: 1px;
}

.step-2-eye {
  cursor: pointer;
}

.step-2-eye:hover {
  color: #3fae29;
}

.TitleSubtitle-subtitle {
  font-size: small;
}

.dlvr-color-white {
  color: var(--color-white) !important;
}

.dlvr-font-13 {
  font-size: 13px;
}

.dlvr-hide {
  display: none !important;
}

.Dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Dropdown:hover {
  cursor: pointer;
}

.Dropdown-Head {
  position: relative;
  height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Dropdown-Head,
.Dropdown-Head-Caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Dropdown-Head-Caret {
  line-height: 100%;
}

.Dropdown-Head-Arrow {
  width: 12px;
  height: 12px;
  left: 50%;
  margin: -3px 0 0 -6px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 10001;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
}

.Dropdown-Head-Arrow,
.Dropdown-Menu {
  display: none;
  background: #fff;
  position: absolute;
  top: 100%;
}

.Dropdown-Menu {
  box-shadow: var(--box-shadow);
  border-bottom-left-radius: var(--radius-s);
  border-bottom-right-radius: var(--radius-s);
  left: 0;
  margin: 0 auto;
  width: auto;
  white-space: nowrap;
  z-index: 10000;
  min-width: 120px;
}

.Dropdown-Menu.right {
  left: auto;
  right: 0;
}

.Dropdown-Menu.bottom {
  top: auto;
  bottom: 100%;
}

.Dropdown-Menu-Item {
  font-size: 18px;
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  color: #000;
  display: block;
  text-decoration: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.07);
}

.Dropdown-Menu-Item:first-child {
  border: none;
}

.Dropdown-Menu-Item.selected {
  color: var(--color-green);
}

.Dropdown-Close {
  display: none;
  background: hsla(0, 0%, 100%, 0);
  content: "";
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.Dropdown.disabled {
  background: var(--color-gray-xxl);
}

.Dropdown.open .Dropdown-Head-Arrow,
.Dropdown.open .Dropdown-Menu {
  display: block;
}

.Dropdown.open .Dropdown-Head-Arrow.bottom {
  bottom: 100%;
  top: auto;
  margin-bottom: -5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.Dropdown.open .Dropdown-Close {
  display: block;
}

.Dropdown.dark-green .Dropdown-Menu-Item {
  color: #fff;
}

.Dropdown-Menu-Item:hover {
  color: var(--color-primary);
}

.Dropdown.dark-green .Dropdown-Menu-Item:hover {
  color: var(--color-yellow);
}

.Dropdown.dark-green .Dropdown-Head-Arrow,
.Dropdown.dark-green .Dropdown-Menu {
  background: var(--color-dark-green);
}

.Dropdown.green .Dropdown-Menu-Item {
  color: #fff;
}

.Dropdown.green .Dropdown-Menu-Item:hover {
  color: var(--color-secondary);
}

.Devina-Screen .Dropdown {
  padding: 0 10px 0 15px;
  background-color: var(--color-yellow);
}

.Devina-Screen .Dropdown .Dropdown-Head-Caret i,
.Devina-Screen .TitleSubtitle {
  color: var(--color-black);
}

.Devina-Screen .TitleSubtitle {
  padding: 0 0 0 10px;
}

.Devina-Screen .Dropdown-Menu {
  background-color: var(--color-yellow);
}

.Devina-Screen .Dropdown-Menu-Item {
  border-top-color: rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
}

.Devina-Screen .Dropdown-Menu img {
  width: 40px;
}

.Dropdown-Devina-Time b {
  display: block;
  color: var(--color-primary);
  font-size: 28px;
  line-height: 24px;
}

.Devina-Screen .Dropdown-Menu .Dropdown-Devina-Time img {
  width: 42px;
  margin: auto;
  display: block;
}

.Dropdown-Devina-Time span {
  color: var(--color-primary);
  font-size: 13px;
}

.Logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: inherit;
  padding: 0 20px;
}

.Logo img {
  height: 40px;
}

.Logo-business-badge {
  position: absolute;
  top: -5px;
  left: -65px;
  width: 120px;
  padding: 15px 0 5px;
  color: var(--color-dark-green);
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  text-transform: uppercase;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.Logo-business-badge + img,
.Logo-business-badge ~ img {
  margin-left: 35px;
}

.Button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: var(--height);
  font-size: var(--font-size);
  padding: 0 9px;
  border: 1px solid var(--color-gray-xxl);
  background: none;
  border-radius: var(--radius);
  outline: none;
}

.Button:hover {
  cursor: pointer;
}

.Button.active,
.Button:hover {
  background: var(--color-gray-xxl);
}

.Button.active {
  border: none;
}

.Button.xs {
  height: var(--height-xs);
  padding: 0 3px;
  font-size: var(--font-size-xs);
  border-radius: var(--radius-xs);
}

.Button.s {
  height: var(--height-s);
  padding: 0 5px;
  font-size: var(--font-size-s);
  border-radius: var(--radius-s);
}

.Button.b {
  height: var(--height-t);
  padding: 0 13px;
  font-size: var(--font-size-b);
}

.Button.green {
  background: var(--color-green);
}

.Button.green,
.Button.green:hover {
  border: none;
  color: var(--color-white);
}

.Button.green:hover {
  background: var(--color-dark-green);
}

.Button.dark-green,
.Button.green.active {
  border: none;
  background: var(--color-dark-green);
}

.Button.dark-green {
  color: var(--color-white);
}

.Button.dark-green:hover {
  color: var(--color-white);
}

.Button.dark-green.active,
.Button.dark-green:hover {
  border: none;
  background: var(--color-dark-green);
}

.Button.red {
  background: var(--color-red);
}

.Button.red,
.Button.red:hover {
  border: none;
  color: var(--color-white) !important;
}

.Button.red.active,
.Button.red:hover {
  background: var(--color-dark-red);
}

.Button.red.active {
  border: none;
}

.Button.gray {
  border: none;
  background: var(--color-gray-xl);
}

.Button.white {
  border: none;
  background: var(--color-white);
}

.Button.gray:hover {
  color: var(--color-white);
}

.Button.gray.active,
.Button.gray:hover {
  border: none;
  background: var(--color-gray);
}

.Button.green-text {
  color: var(--color-green);
}

.Button.red-text {
  color: var(--color-red);
}

.Button.white-text {
  color: var(--color-white);
}

.Button.white-text:hover {
  color: var(--color-gray);
}

.Button.green-border {
  border-color: var(--color-green);
}

.Button.red-border {
  border-color: var(--color-red);
}

.Button.white-border {
  border-color: var(--color-white);
}

.Button.disabled {
  color: rgba(0, 0, 0, 0.1);
  background: var(--color-gray-xxl);
}

.Button.disabled:hover {
  color: rgba(0, 0, 0, 0.1) !important;
  background: var(--color-gray-xxl) !important;
}

.Button.with-icon .Icon {
  margin-right: 5px;
}

.Button.with-right-icon .Icon {
  margin-left: 5px;
}

.Button.xs .Icon {
  margin-right: 2px;
}

.Button.s .Icon {
  margin-right: 3px;
}

.Button.icon-only .Icon {
  margin: 0;
}

.Box {
  position: relative;
  width: 100%;
}

.Box,
.Box-Icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Box-Icon {
  background: var(--color-white);
  height: 64px;
  border-radius: 64px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 50%;
  margin: -32px 0 0 -32px;
}

.Box-Icon,
.Box-Icon-Plate {
  width: 64px;
  position: absolute;
}

.Box-Icon-Plate {
  background: var(--color-dark-green);
  color: var(--color-green);
  height: auto;
  bottom: -10px;
  font-size: 11px;
  padding: 2px 3px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.Box-Icon img {
  width: auto;
  height: 40px;
}

.Box-Icon.Logo-Custom img {
  height: 40px;
}

.Box-Icon.Custom img {
  height: 46px;
}

.Box-Head {
  width: 100%;
  min-height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;
}

.Box-Content {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Box-Content-Note {
  text-align: center;
  width: 100%;
  font-size: var(--font-size);
}

.Box-Content-SplitView {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Box-Content .Form {
  padding: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Box-Content .Form .FormGroup {
  margin: 10px 0;
  width: 100%;
}

.Box-Head-Close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Box-Head-Close :hover {
  cursor: pointer;
  color: var(--color-dark-green);
}

.Box-Head-back {
  position: absolute;
  top: 18px;
  left: 10px;
  color: var(--color-white);
}

.Box-Head-back:hover {
  cursor: pointer;
  color: var(--color-dark-green);
}

.dark-green.Button {
  color: var(--color-green);
  padding: 0 5px;
}

.Box-List-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--color-gray-xxl);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Box-List-Item:last-child {
  border-bottom: none;
}

.Box-List-Item.title {
  background: var(--color-gray-xxl);
  text-transform: uppercase;
  font-size: var(--font-size-s);
  font-weight: 700;
  color: var(--color-gray);
  padding: 5px 10px;
}

.Box-List-Item .TitleSubtitle,
.Box-List-Item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Box-Actions {
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.Box-Actions,
.Box-Actions .Button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Box-Actions .Button {
  margin: 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Radio {
  cursor: pointer;
  height: 24px;
}

.Radio.disabled {
  pointer-events: none;
}

.Radio.disabled i {
  color: var(--color-gray-xl);
}

.Checkbox {
  cursor: pointer;
  height: 24px;
}

.Checkbox i {
  line-height: 1;
}

.Checkbox.disabled {
  pointer-events: none;
}

.Checkbox.disabled i {
  color: var(--color-gray-xl);
}

.FormGroup-label {
  color: var(--color-gray);
  display: block;
  margin-bottom: 5px;
}

.FormGroup-explanation {
  color: var(--color-gray);
  display: block;
  margin-top: 5px;
  font-size: var(--font-size-s);
}

.FormGroup .FormGroup-label button {
  float: right;
  display: inline-block;
  color: var(--color-green);
  font-size: 15px;
  border: none;
  background: none;
}

.FormGroup .checkbox-item,
.FormGroup .checkbox-item .Checkbox,
.FormGroup .checkbox-item .Radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.FormGroup .checkbox-item label {
  margin: 3px 0 0 5px;
  color: var(--color-gray);
}

.FormGroup.error .FormGroup-explanation {
  color: var(--color-red);
}

.FormGroup-Datetime {
  position: relative;
}

.FormGroup-Datetime .rdt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--height);
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background: var(--color-white);
}

.FormGroup-Datetime .rdt input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: none;
  outline: none;
  padding: 0 10px;
  font-size: var(--font-size);
  border-radius: 0;
  border: 0 none;
  height: 100%;
  text-align: left;
}

.FormGroup-Datetime .Icon {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -9px;
  z-index: 1;
  left: auto;
}

.FormGroup-Datetime .rdtPicker {
  top: 100%;
  margin-top: 10px;
  height: 240px;
}

.FormGroup-Datetime .rdtPicker:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--color-green);
  position: absolute;
  top: -6px;
  right: 10px;
}

.Input {
  height: var(--height);
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  position: relative;
  background: var(--color-white);
}

.Input,
.Input > div.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Input > div.header {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Input.note {
  background: var(--color-note-bg);
}

.Input input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: inherit;
  border: none;
  background: none;
  outline: none;
  padding: 0 10px;
  font-size: var(--font-size);
  border-radius: var(--radius);
}

.Input.error,
.error-border {
  border-color: var(--color-red);
}

.Input.disabled {
  color: var(--color-disabled-text);
  background: var(--color-disabled-bg);
}

.Input input::-webkit-input-placeholder {
  color: var(--color-gray-xl);
}

.Input input:-ms-input-placeholder {
  color: var(--color-gray-xl);
}

.Input input::placeholder {
  color: var(--color-gray-xl);
}

.Input-Icon {
  width: var(--length);
  position: relative;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Input-Icon,
.Input .Icon {
  color: var(--color-gray);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Input .Input-Icon + input {
  padding-left: 0;
}

.Input .activated .Icon,
.Input .Icon:hover {
  color: var(--color-green);
}

.Input .Icon:hover {
  cursor: pointer;
}

.RightMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 380px;
  background: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 11111;
}

.RightMenu-close-pane {
  content: "";
  position: fixed;
  top: 0;
  right: 380px;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.RightMenu-avatar-block,
.RightMenu.visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.RightMenu-avatar-block {
  color: var(--color-white);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.RightMenu .Box {
  z-index: 10001;
  position: relative;
  background: var(--color-white);
}

.RightMenu .Box-Head {
  background: var(--color-green);
}

.RightMenu .Box-Head h3 {
  color: var(--color-white);
  margin: 0;
  display: block;
  text-align: center;
  width: 100%;
}

.RightMenu-avatar-block .TitleSubtitle {
  text-align: center;
  margin: 5px 0 0;
}

.RightMenu-avatar-block .TitleSubtitle-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.RightMenu .Box-Actions {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.RightMenu .Box-List-Item {
  padding: 10px 20px 10px 10px;
}

.RightMenu .Box-List-Item .TitleSubtitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}

.RightMenu .Box-List-Item:hover {
  cursor: pointer;
  background: var(--color-gray-xxxl);
}

.Menu {
  background: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: var(--menu-height);
  z-index: 3;
  position: relative;
}

.Menu a {
  text-decoration: none;
}

.Menu-Nav {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Menu-Nav.left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Menu-Nav-Item {
  padding: 0 10px;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}

.Menu-Nav-Item:hover {
  color: var(--color-secondary) !important;
  cursor: pointer;
}

.Menu-Nav-Item.selected {
  color: var(--color-secondary);
}

.Home {
  background: #fff;
}

.SingleBookingLayout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Driving-count {
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  margin: auto;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
}

.Driving-count ul {
  margin: 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 7px 10px -6px #a9a9a9;
  opacity: 0.9;
  background-color: #f5d60f;
  border: 3px solid var(--color-white);
  width: auto;
  overflow: hidden;
  margin-top: -100px;
}

.Driving-count li,
.Driving-count ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Driving-count li {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: var(--font-size-xxb);
  font-weight: 700;
  padding: 5px;
  margin: 0;
  line-height: 1;
}

.Driving-count li span {
  font-size: var(--font-size-s);
  font-weight: 400;
}

.Driving-count li:first-child {
  padding-left: 10px;
}

.Driving-count li:nth-child(2) {
  padding-left: 0;
  padding-right: 10px;
}

.Driving-count li:nth-child(3) {
  background: rgba(0, 0, 0, 0.05);
  padding-left: 10px;
}

.Driving-count li:nth-child(4) {
  background: rgba(0, 0, 0, 0.05);
  padding-right: 10px;
}

.Map,
.Map-Overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  z-index: 2;
}

.BookingWizard {
  width: 420px;
  background: var(--color-gray-xxxl);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9000;
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.BookingWizard .Head {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.BookingWizard .Content {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 10px;
  position: relative;
}

.BookingWizard .Content::-webkit-scrollbar-track {
  background-color: #ddd;
}

.BookingWizard .Content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: none;
  border-radius: 0;
}

.BookingWizard .Content h3 {
  padding: 10px;
  margin: 0;
  font-size: var(--font-size);
  color: var(--color-gray);
  text-transform: uppercase;
}

.BookingWizard .Actions {
  width: 100%;
  background: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10000;
}

.BookingWizard .Actions .Button {
  width: 100%;
  margin: 5px;
}

.BookingWizard .Content .block {
  background: var(--color-white);
  margin: 0 10px;
  border-radius: var(--radius);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.BookingWizard .Content .block-item,
.BookingWizard .Content .block-sub-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  font-size: var(--font-size-s);
}

.BookingWizard .Content .block-item .Pin {
  margin-right: 10px;
}

.BookingWizard .Content .block-item .Input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BookingWizard .Content .block-item .sub-account-tooltip {
  display: flex;
  align-items: end;
}

.FullDay.block {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  background: none !important;
  box-shadow: none !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  padding: 60px !important;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-gray);
}

.FullDay.block .Icon {
  font-size: 32px;
}

.FullDay.block p {
  padding: 0;
  margin: 10px 0 0;
  font-size: var(--font-size-s);
}

.BookingWizard .Modal.visible {
  position: absolute;
}

.BookingWizard .Normal-Booking-Custom {
  bottom: 56px !important;
}

.BookingWizard .Content .Locations.block .Input-Icon:first-child,
.modal-batchUploadValidate .Locations.block .Input-Icon:first-child {
}

.BookingWizard .Content .Locations.block .Input-Icon:first-child i,
.modal-batchUploadValidate .Locations.block .Input-Icon:first-child i {
  cursor: move;
}

.Steps {
  font-size: var(--font-size);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  overflow: hidden;
}

.Steps.evenly .Step {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Steps .Step {
  background: var(--color-white);
  color: var(--color-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Steps .Step:after {
  content: "";
  background: var(--color-white);
  display: block;
  height: var(--height);
  width: var(--height);
  -webkit-transform: rotate(45deg) skew(15deg, 15deg);
  -ms-transform: rotate(45deg) skew(15deg, 15deg);
  transform: rotate(45deg) skew(15deg, 15deg);
  position: absolute;
  right: -12px;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.15);
}

.Steps .Step.selected {
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Steps .Step.selected,
.Steps .Step.selected:after {
  background: var(--color-primary);
}

.Steps .Step:nth-child(10) {
  z-index: 1;
}

.Steps .Step:nth-child(9) {
  z-index: 2;
}

.Steps .Step:nth-child(8) {
  z-index: 3;
}

.Steps .Step:nth-child(7) {
  z-index: 4;
}

.Steps .Step:nth-child(6) {
  z-index: 5;
}

.Steps .Step:nth-child(5) {
  z-index: 6;
}

.Steps .Step:nth-child(4) {
  z-index: 7;
}

.Steps .Step:nth-child(3) {
  z-index: 8;
}

.Steps .Step:nth-child(2) {
  z-index: 9;
}

.Steps .Step:first-child {
  z-index: 10;
}

.Modal {
  display: none;
}

.Modal.visible,
.dlvr-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100011;
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Modal.center-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100011;
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Modal-NoBg {
  background: transparent !important;
}

.Modal-Basic .Box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 606px;
  background: var(--color-white);
  margin: auto;
  max-height: 80%;
  border-radius: 6px;
}

.Modal-Basic .Box .Box-Head,
.Modal-Basic .Modal-Head {
  border-radius: 6px 6px 0 0;
}

.Modal-Basic .Modal-Head {
  background: var(--color-green);
  color: var(--color-white);
  font-size: var(--font-size-xb);
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.Modal-Basic .Modal-Head i {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  cursor: pointer;
}

.Modal-Basic .Modal-Content {
  overflow-y: auto;
}

.Modal-Basic .Modal-SplitView {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.Modal-Basic .Modal-SplitView.Custom {
  padding: 0;
}

.Modal-FromGroup {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Modal-FromGroup span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Modal-FromGroup select {
  height: 20px;
}

.Modal-Basic-List p {
  margin: 0 0 20px;
}

.Modal-Basic-List table span {
  display: inline-block;
  padding-right: 15px;
  color: gray;
}

.Modal-Basic .Modal-Actions {
  padding: 5px 15px;
}

.Modal-Basic .Modal-Actions,
.Modal-Basic .Modal-Actions > div {
  width: 320px !important;
  -ms-flex-item-align: end;
  align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Modal-Basic .Modal-Actions > div {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Modal-Basic .Box-Actions .Button,
.Modal-Basic .Box-Actions button,
.Modal-Basic .Modal-Actions .Button,
.Modal-Basic .Modal-Actions button {
  width: 120px;
  max-width: 120px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.Modal-Error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Modal-Error .Modal-Error-Icon {
  margin-right: 15px;
}

.Modal-Error .Modal-Error-Icon i {
  color: var(--color-yellow);
  font-size: 45px;
}

.Modal-Error .Modal-Error-Group p {
  margin: 0;
  padding: 0;
}

#root .Modal-Actions-Full {
  width: 100% !important;
}

#root .Modal-Actions-Full .Modal-Actions-Left,
#root .Modal-Actions-Full .Modal-Actions-Right {
  width: auto !important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#root .Modal-Actions-Full .Modal-Actions-Right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#root .Modal-Actions-Full .Modal-Actions-Left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#root .Modal-Actions-Full .Modal-Actions-Left > div,
#root .Modal-Actions-Full .Modal-Actions-Right > div {
  width: auto !important;
  max-width: auto !important;
  -ms-flex-item-align: end;
  align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Pin {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: var(--length-s);
  height: var(--length-s);
  border-radius: 100px;
  text-align: center;
}

.Pin.s {
  width: var(--length-xs);
  height: var(--length-xs);
  font-size: 8px;
}

.Pin.b {
  width: 32px;
  height: 32px;
  font-size: 13px;
}

.Pin.blue {
  background: var(--color-blue);
  color: var(--color-white);
}

.Pin.yellow {
  background: var(--color-yellow);
  color: var(--color-black);
}

.Pin.green {
  background: var(--color-green);
}

.Pin.green,
.Pin.red {
  color: var(--color-white);
}

.Pin.red {
  background: var(--color-red);
}

.DriverBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DriverBlock .dlvr-driver-avatar img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.dlvr-driver-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DriverBlock .DriverInfo.Custom {
  padding: 0 10px;
}

.DriverBlock .DriverInfo h3 {
  font-size: 17px;
  padding: 0;
  margin: 0;
  text-align: left !important;
}

.DriverBlock .DriverInfo-Plate,
.DriverBlock .DriverInfo-Vehicle {
  font-size: var(--font-size-s);
}

.DriverInfo-Button {
  margin: 5px -2.5px 0;
}

.Overlay {
  display: none;
}

.Overlay.visible {
  position: fixed;
  z-index: 100009;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
}

.Overlay.visible,
.Overlay.visible .Overlay-Image {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Overlay.visible .Overlay-Image {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Overlay.visible .Overlay-Image img {
  width: auto !important;
  height: auto !important;
  max-height: 80%;
  max-width: 80%;
}

.Loader {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Loader .Overlay {
  background: hsla(0, 0%, 100%, 0.2);
}

.Loader-Icon {
  background: var(--color-green);
  width: 116px;
  height: 116px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;
}

.Loader-Icon img {
  width: auto;
  height: 68px;
}

.Loader-Icon:after {
  content: "";
  width: 94px;
  height: 94px;
  border: 1px dashed var(--color-white);
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100px;
  -webkit-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}

.locating-first .Loader-Icon:before {
  -webkit-animation: sonarWave 2s linear infinite;
  animation: sonarWave 2s linear infinite;
}

.locating-first .Loader-Icon:before,
.locating-more .Loader-Icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px;
  background-color: var(--color-green);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.locating-more .Loader-Icon:before {
  -webkit-animation: sonarWave2 2s linear infinite;
  animation: sonarWave2 2s linear infinite;
}

.Loader-Text {
  width: auto;
  text-align: center;
  margin-top: 10px;
  text-shadow: 0 1px #000;
}

@-webkit-keyframes rotating {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotating {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes sonarWave {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes sonarWave {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@-webkit-keyframes sonarWave2 {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(6);
    transform: scale(6);
    opacity: 0;
  }
}

@keyframes sonarWave2 {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(6);
    transform: scale(6);
    opacity: 0;
  }
}

@-webkit-keyframes sonarWave3 {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(9);
    transform: scale(9);
    opacity: 0;
  }
}

@keyframes sonarWave3 {
  0% {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(9);
    transform: scale(9);
    opacity: 0;
  }
}

.Popup.devina-style .Box {
  margin-top: 60px;
}

.Popup.normal-driver-found .Box {
  width: auto;
  margin-top: 0;
}

.Popup.normal-driver-found .title {
  color: var(--color-yellow);
  border-bottom: 1px solid var(--color-white);
  text-align: left;
  padding: 0 0 10px;
  font-size: var(--font-size-b);
  margin-bottom: 20px;
}

.Popup.devina-style.no-action .Box {
  margin-top: 0 !important;
}

.Popup.with-icon .Box-Content {
  padding-top: 30px !important;
}

.Popup.top-align {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
  margin: 60px 0;
}

.Popup .Box-Content {
  background: var(--color-white);
  color: var(--color-black);
  margin: 5px 10px;
  box-sizing: border-box;
  width: auto;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  min-height: 100px;
}

.normal-driver .Box .Box-Content,
.Popup.devina-style .Box .Box-Content {
  background: var(--color-green);
  color: var(--color-white);
}

.Normal {
  width: 100%;
  box-sizing: border-box;
}

.Normal h3,
.Normal p {
  font-size: var(--font-size);
  margin: 0;
  padding: 5px 0;
  text-align: center;
}

.Devina,
.Devina-Image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Devina-Image {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 20px;
}

.Devina-Image img {
  height: 180px;
  width: auto;
}

.Devina-Content {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
  font-size: var(--font-size);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 20px 0 0;
}

.Devina-Content h3,
.Devina-Content p {
  font-size: var(--font-size);
  margin: 0;
  padding: 5px 0;
  text-align: center;
}

.Devina-Content .countdown {
  font-size: var(--font-size-xb);
  font-weight: 700;
}

.Devina-Content-Noted {
  margin: -10px 10px 5px;
  background: var(--color-dark-green);
  text-align: center;
  font-size: var(--font-size);
  color: var(--color-white);
  border-radius: 0 0 6px 6px;
  padding: 5px;
}

.Devina-Content .DriverBlock {
  margin-top: 5px;
}

.Devina-Content .DriverBlock .DriverInfo {
  padding: 0 !important;
  margin-left: 10px;
}

.Devina-Content .DriverBlock .DriverInfo h3 {
  font-size: var(--font-size) !important;
  text-align: left;
}

.Devina-Content .DriverBlock .DriverInfo i {
  font-size: var(--font-size) !important;
}

.Popup .Cancel-Reasons .Button {
  width: 100%;
  margin-top: 10px;
}

.Popup .AddressItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.Popup .AddressItem .AddressPin {
  margin-right: 10px;
}

.Popup .AddressItem .AddressContact-Address {
  font-size: var(--font-size);
}

.Popup .AddressItem .AddressContact-Contact {
  font-size: var(--font-size-s);
  color: var(--color-yellow);
}

.Popup .payor-icon {
  width: 22px;
  height: auto;
}

.Popup .AddressItem .AddressPin .payor-icon {
  position: absolute;
  margin: -5px 0 0 6px;
}

.Popup .Box-Content-Note {
  margin-top: 20px;
}

.Popup .Pricing {
  margin-top: 15px;
  width: 300px;
}

.Popup .Pricing .block-item {
  border-top: 1px solid var(--color-gray-xxl);
  padding: 10px 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: var(--font-size-s);
}

.Popup .Pricing .block-item,
.Popup .Pricing .block-item.noted {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Popup .Pricing .block-item.noted {
  width: 100%;
  display: block;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Popup .Pricing .block-item.noted b {
  display: block;
  color: var(--color-yellow);
  font-size: 16px;
  margin-bottom: 10px;
}

.Popup .Pricing .block-item.noted p {
  text-align: justify;
}

.Popup .Pricing .block-item:first-child {
  border-top: none;
}

.Popup .Pricing .block-item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 10px;
}

.Popup .Pricing .block-item.total label {
  font-size: var(--font-size-b);
  text-transform: uppercase;
}

.Popup .Pricing .block-item.total span {
  font-size: var(--font-size-xxb);
  color: var(--color-yellow);
}

.Popup .Pricing .block-sub-item {
  padding: 0 0 10px 15px !important;
  font-size: var(--font-size-s);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.Popup .Pricing .block-sub-item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.locating-drivers {
  margin: auto;
}

.normal-driver-found .Box-Content,
.normal-driver .Box-Content {
  padding: 20px;
}

.Popup-Booking-Custom {
  min-width: 400px;
  max-width: 400px;
  white-space: pre-line;
}

.Popup-Booking-Normal p {
  margin: 0;
}

.Popup-Booking-Attachments {
  margin-top: 15px;
}

.Popup-Booking-Attachments ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Popup-Booking-Attachments li {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Popup-Booking-Attachments a {
  width: 100px;
  height: 100px;
  float: left;
  position: relative;
  border: 1px solid var(--color-white);
  background: var(--color-white);
}

.Popup-Booking-Attachments img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.Popup-Booking-Attachments p {
  padding-left: 15px;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  word-break: break-word;
}

.Popup-Booking-Signatures {
  margin-top: 15px;
}

.Popup-Booking-Signatures ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Popup-Booking-Signatures li {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Signatures-Pin div {
  width: 40px;
  height: 40px;
  font-size: 14px;
}

.Signatures-Info {
  margin: 0 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Signatures-Info b {
  font-weight: 400;
  font-size: 15px;
}

.Signatures-Info span {
  display: block;
  color: var(--color-yellow);
}

.Signatures-Image {
  width: 80px;
  height: 60px;
  float: left;
  position: relative;
  border: 1px solid #fff;
  background-color: #fff;
}

.Signatures-Image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.Normal-Booking {
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80%;
}

.Normal-Booking-prefix {
  width: 288px !important;
}

.Normal-Booking-prefix .Box {
  border-radius: 16px !important;
  height: 182px !important;
}

.Normal-Booking-prefix .Normal-Booking-Title {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.Normal-Booking-prefix .center {
  font-size: 18px;
  line-height: 1.11;
  margin: 0 5px;
}

.Normal-Booking-prefix .Box-Icon-Plate {
  width: 63px !important;
  height: 63px !important;
}

.Normal-Booking-prefix .Normal-Booking-Actions.Custom a:first-child {
  height: 40px;
  width: 140px;
  margin: 0 8px 0 5px;
}

.Normal-Booking-prefix .Normal-Booking-Actions.Custom a:last-child {
  height: 40px;
  width: 140px;
  margin: 0 5px 0 0;
}

.Normal-Booking-prefix .dlvr-button-prefix {
  cursor: pointer;
  font-size: 18px !important;
  line-height: 43px !important;
  font-weight: 500 !important;
}

.devina-style {
  max-width: 420px;
}

.Normal-Booking .Box-Icon {
  box-shadow: 1px 1px 6px var(--color-dark-green);
}

.Normal-Booking .Box-Icon-Image {
  height: 26px;
  max-width: 100%;
}

.Normal-Booking .Box {
  background: var(--color-green);
  color: var(--color-white);
  border-radius: 16px;
}

.Normal-Booking .Box-Time-Type {
  background: var(--color-green);
  color: var(--color-white);
  border-radius: 6px;
}

.Normal-Booking .Box-ez {
  width: 354px;
  height: 132px !important;
  background: var(--color-green) !important;
  border-radius: 16px;
  color: var(--color-white);
  text-align: center;
}

.dlvr-normal-booking .Box {
  background: var(--color-green);
  color: var(--color-white);
  border-radius: 6px;
}

.Normal-Booking .Box-Content {
  margin-top: 47px;
  padding: 0 15px 15px;
  box-sizing: border-box;
}

.Normal-Booking .Box-Content-Ez {
  margin-top: 52px;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.dlvr-normal-booking .Box-Content {
  padding: 0 15px 15px;
  box-sizing: border-box;
}

.Normal-Booking-Title {
  color: var(--color-yellow);
  margin: 0 0 15px;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}

.dlvr-booking-title {
  color: var(--color-white);
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0;
}

.Normal-Booking-Title.Custom {
  color: var(--color-white);
  font-weight: 700;
}

.Normal-Booking-Sub-Title {
  display: block;
  text-align: center;
  margin: -15px 0 10px;
  font-size: 12px;
  font-style: italic;
}

.Normal-Booking p {
  padding: 0;
  text-align: left;
  line-height: 1.11 !important;
  font-size: 18px !important;
}

.Normal-Booking p.center {
  text-align: center;
}

.Normal-Booking p.custom-color {
  color: var(--color-yellow);
}

.Normal-Booking-Devide {
  height: 1px;
  background-color: var(--color-white);
  margin: 10px auto;
  max-width: 100%;
}

.Normal-Booking-Image {
  margin: 0 -15px;
}

.Normal-Booking-Image:before {
  content: "";
  background-color: #349a1a;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  z-index: 0;
}

.Normal-Booking-Image > div {
  display: inline-block;
  width: 100%;
  height: 180px;
  background: url(https://www.transportify.com.ph/wp-content/uploads/2016/10/scale-2.png) no-repeat;
  background-size: 100%;
  background-position: 50%;
  position: relative;
  margin: 10px auto auto;
}

.Normal-Booking-Image p {
  margin: 44px 0 0 70px;
  font-size: 14px;
  max-width: 124px;
  height: 92px;
}

.Normal-Booking-Image b,
.Normal-Booking-Image p {
  color: var(--color-black);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Normal-Booking-Image b {
  position: absolute;
  font-size: 15px;
  top: 20px;
  right: 30px;
  width: 90px;
  text-align: center;
  height: 45px;
  line-height: 16px;
}

.Normal-Booking-Actions {
  margin-top: 10px;
}

.Normal-Booking-Actions.Custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px -5px 0;
}

.Normal-Booking-Actions.Custom a,
.Normal-Booking-Actions.Custom button {
  text-transform: capitalize;
  font-weight: 400;
  font-size: var(--font-size);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
}

.Normal-Booking-Actions a,
.Normal-Booking-Actions button {
  line-height: 36px;
  display: block;
  text-align: center;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  width: 100%;
  border: 0 none;
}

.Normal-Booking-Actions a:last-child,
.Normal-Booking-Actions button:last-child {
  margin-bottom: 0;
}

.Normal-Booking-Actions a.White,
.Normal-Booking-Actions button.White {
  color: var(--color-green);
  background-color: var(--color-white);
}

.Normal-Booking-Actions a.Gray,
.Normal-Booking-Actions button.Gray {
  color: var(--color-gray-xxd);
  background-color: var(--color-gray-xl);
}

.First-Time-Discount {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.First-Time-Discount * {
  box-sizing: border-box;
}

.Discount-Message {
  background-color: #00bbe6;
  border-radius: 50%;
  padding: 10px;
  border: 10px solid var(--color-white);
  position: absolute;
  box-shadow: -1px 0 21px 3px hsla(300, 2%, 51%, 0.69);
}

.Discount-Content {
  border-radius: 50%;
  border: 2px dashed var(--color-white);
  padding: 20px;
}

.Discount-Row {
  width: 172px;
  padding-bottom: 100%;
  position: relative;
}

.Discount-Text {
  margin: auto;
  text-align: center;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 25px;
}

.Discount-Text-Custom span {
  font-size: 1.5em !important;
}

.Discount-Text p {
  line-height: 28px;
}

.Discount-Text p:first-child {
  margin-top: 0;
  margin-bottom: 0;
}

.Discount-Text sup {
  font-size: 14px;
  padding-right: 4px;
}

.Discount-Text sub {
  position: absolute;
  right: -20px;
  bottom: 12px;
  font-size: 25px;
}

.Discount-Text span {
  color: #ff0;
  font-size: 3em;
  position: relative;
  margin-left: -18px;
}

.Discount-Footer {
  position: absolute;
  bottom: 18px;
  color: var(--color-white);
  left: -16px;
}

.Discount-Footer > div {
  float: left;
}

.Discount-Footer-Left {
  -webkit-transform: rotate(180deg) scaleY(-1);
  -ms-transform: rotate(180deg) scaleY(-1);
  transform: rotate(180deg) scaleY(-1);
}

.Discount-Footer-Mid {
  background-color: #028cab;
  width: 214px;
  padding: 10px;
  margin-top: -16px;
  z-index: 34;
  position: relative;
  margin-left: -15px;
  text-align: center;
  font-weight: 700;
}

.Discount-Footer-Right {
  position: absolute;
  left: 240px;
}

.close-modal-common {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal-Common,
.modal-Common .Box,
.modal-ParkingTolls,
.modal-ParkingTolls .Box,
.modal-RatingDriver,
.modal-RatingDriver .Box {
  align-self: center;
}

.modal-RatingDriver .Box-Content {
  margin: 0;
  width: 400px;
}

.RatingDriver-Block {
  padding-top: 20px;
}

.RatingDriver-Block span {
  display: block;
  text-align: center;
  font-size: var(--font-size-s);
  clear: both;
}

.RatingDriver-Block .Avatar {
  width: 80px;
  height: 80px;
  margin: 20px auto auto;
}

.RatingDriver-Block h5 {
  margin: 15px 0 5px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}

.RatingDriver-Block .showReason {
  margin-bottom: 15px;
}

.RatingDriver-Block .RatingStars {
  text-align: center;
  margin-bottom: 5px;
}

.RatingDriver-Block .RatingStars i {
  color: #fff;
  font-size: 28px;
  margin: 0 2px;
  cursor: pointer;
}

.RatingDriver-Block .RatingStars i.active,
.RatingDriver-Block .RatingStars i.yellow {
  color: var(--color-yellow);
}

.RatingDriver-Rating {
  display: none;
  margin-bottom: 15px;
  overflow: hidden;
}

.RatingDriver-Rating.visible {
  display: block;
}

.RatingDriver-Rating > div {
  margin-top: 15px;
  padding: 10px 6px 4px;
  border-radius: 4px;
  background: var(--color-dark-green);
  position: relative;
  float: left;
}

.RatingDriver-Rating > div:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-dark-green);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -10px;
}

.RatingDriver-Rating a,
.RatingDriver-Rating button {
  width: 48%;
  margin: 0 1% 2%;
  float: left;
  background-color: var(--color-white);
  line-height: 24px;
  color: var(--color-green);
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  outline: 0;
  padding: 0;
  border: 0 none;
}

.RatingDriver-Rating a.active,
.RatingDriver-Rating a:hover,
.RatingDriver-Rating button.active,
.RatingDriver-Rating button:hover {
  background-color: var(--color-green);
  color: var(--color-white);
}

.RatingDriver-Block textarea {
  height: 60px;
  width: 100%;
  margin-top: 10px;
  border: 0 none;
  resize: none;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}

.modal-RatingDriver .Normal-Booking-Actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px -5px 0;
  width: 410px;
}

.modal-RatingDriver .Normal-Booking-Actions a,
.modal-RatingDriver .Normal-Booking-Actions button {
  text-transform: capitalize;
  font-weight: 400;
  font-size: var(--font-size);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
}

.modal-RatingDriver .Normal-Booking-Actions button:disabled {
  color: #4a4a4a;
  background: #a5a5a5;
}

.modal-ParkingTolls .Box-Content {
  margin: 0;
  width: 924px;
  padding-bottom: 0;
}

.ParkingTolls-Block {
  margin: 15px -20px 0;
}

.ParkingTolls-Block table {
  width: 100%;
  border: 0 none;
  border-collapse: collapse;
}

.ParkingTolls-Block thead tr th {
  border-top: 1px solid #359222;
  padding: 5px 0;
  font-weight: 400;
  font-size: var(--font-size-s);
  text-align: left;
  padding-left: 20px;
  white-space: nowrap;
}

.ParkingTolls-Block thead tr th:last-child {
  padding-right: 20px;
}

.ParkingTolls-Block tbody td {
  border-top: 1px solid #359222;
  padding: 12px 12px 12px 20px;
}

.ParkingTolls-Group-Pin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ParkingTolls-Group-Pin .Pin {
  margin-right: 10px;
}

.ParkingTolls-Group-Pin label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ParkingTolls-Group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ParkingTolls-Group > div:first-of-type {
  width: 44px;
  height: 44px;
  position: relative;
  margin-right: 15px;
}

.ParkingTolls-Group > div:first-of-type img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.ParkingTolls-Group-Fee {
  height: 54px;
}

.ParkingTolls-Group-Fee p {
  text-align: left;
  font-size: var(--font-size-s);
  line-height: 22px;
  padding: 0;
}

.ParkingTolls-Block tbody td span {
  font-size: var(--font-size-s);
  color: var(--color-yellow);
  display: block;
  margin-bottom: 12px;
}

.modal-ParkingTolls .Normal-Booking-Actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 10px -5px 0;
  width: 934px;
}

.modal-ParkingTolls .Normal-Booking-Actions a,
.modal-ParkingTolls .Normal-Booking-Actions button {
  text-transform: capitalize;
  font-weight: 400;
  font-size: var(--font-size);
  width: 182px;
  margin: 0 5px;
}

.modal-ParkingTolls .Normal-Booking-Actions button {
  outline: 0;
  border: 0 none;
}

.modal-Completed .Box-Content,
.modal-Completed .Normal-Booking-Actions {
  width: 340px;
  margin: 0;
}

.modal-Completed .Box-Content img {
  display: block;
  margin: auto;
}

.modal-Completed .Box-Content p {
  margin: 10px 0 0;
  text-align: center;
}

.modal-Completed .Normal-Booking-Actions {
  margin: 10px -5px 0;
  width: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-Completed .Normal-Booking-Actions * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
  text-transform: capitalize;
  font-weight: 400;
}

.ParkingTolls-Price {
  border-top: 1px solid #359222;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ParkingTolls-Price span {
  color: var(--color-yellow);
  margin-right: 20px;
}

.ParkingTolls-Price b {
  font-size: var(--font-size-xxb);
  color: var(--color-yellow);
}

.modal-NoContent .Popup-Booking-Normal h5 {
  margin-top: 10px;
}

.modal-NoContent .Popup-Booking-Custom {
  min-width: 320px;
  max-width: 320px;
}

.Share-Location {
  position: relative;
}

.Share-Location .Input {
  margin-right: 100px;
  border-radius: 6px 0 0 6px;
}

.Share-Location .Share-Location-Submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 101px;
  background-color: #3fae29;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 0 none;
  padding: 0;
  outline: 0;
}

.Share-Location .Share-Location-Submit:hover {
  background-color: #349621;
}

.Share-Location .Share-Location-Submit span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
  text-align: center;
}

.Share-Location .Share-Location-Submit div {
  background-color: #349621;
  width: 32px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Share-Location .Share-Location-Submit i {
  color: #fff;
}

.modal-multipleSavedBookings .Box {
  max-width: 1076px;
  background: var(--color-white);
  max-height: 90%;
}

.modal-multipleSavedBookings .Box .Table .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.modal-multipleSavedBookings .Box .Table tbody td {
  padding: 10px 5px;
}

.modal-multipleSavedBookings .Box .Table thead td {
  padding: 5px;
}

.modal-multipleSavedBookings .Box .Table tbody td:last-child {
  text-align: center;
}

.modal-multipleSavedBookings .Box .Table tbody td:last-child > div {
  display: inline-block;
}

.modal-multipleSavedBookings .Box .Table .cell:last-child {
  margin-bottom: 0;
}

.modal-multipleSavedBookings .Box .Table .cell .Pin {
  margin-right: 10px;
}

.modal-multipleSavedBookings .Box .Table .cell span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-multipleSavedBookings .Pagination ul {
  padding: 0;
}

.TextArea {
  height: auto;
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.TextArea,
.TextArea textarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.TextArea textarea {
  min-height: 50px;
  min-width: 200px;
  border: none;
  background: none;
  outline: none;
  padding: 10px;
  font-size: var(--font-size);
  width: 100%;
}

.TextArea.note {
  background: var(--color-note-bg);
}

.TextArea.error {
  border-color: var(--color-red);
}

.TextArea.disabled {
  color: var(--color-disabled-text);
  background: var(--color-disabled-bg);
}

.TextArea textarea::-webkit-input-placeholder {
  color: var(--color-gray-xl);
}

.TextArea textarea:-ms-input-placeholder {
  color: var(--color-gray-xl);
}

.TextArea textarea::placeholder {
  color: var(--color-gray-xl);
}

.Popover {
  position: absolute;
  top: 0;
  left: 100%;
  background: var(--color-white);
  width: 300px;
  display: none;
  padding: 15px 10px;
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.Popover h3 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
  font-size: var(--font-size);
}

.Popover-Arrow,
.Popover.visible {
  display: block !important;
}

.Popover-Arrow {
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: var(--color-white);
  width: 10px;
  height: 10px;
  top: 24px;
  left: -5px;
}

.Popover-List {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Popover-Item {
  padding: 5px;
  position: relative;
}

.Popover-Item.actions {
  padding: 10px 0 5px !important;
}

.Popover-Item.actions,
.Popover-Item.actions .Button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Popover-Item.actions .Button {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  margin: 0 5px;
  height: var(--height);
}

.Popover-contact-list {
  border: 1px solid var(--color-green);
  background: var(--color-gray-xxxl);
  border-radius: var(--radius-s);
  max-height: 240px;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.Popover-contact-list-arrow:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--color-green);
  position: absolute;
  top: 0;
  left: 40px;
}

.Popover-contact-list-arrow.custom:before {
  right: 40px;
  left: auto;
}

.Popover-contact-list::-webkit-scrollbar {
  width: 5px;
}

.Popover-contact-list::-webkit-scrollbar-track {
  background-color: #ddd;
}

.Popover-contact-list::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: none;
  border-radius: 0;
}

.Popover-contact-list-item {
  padding: 5px 10px;
  border-bottom: 1px solid var(--color-gray-xl);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Popover-contact-list-item:last-child {
  border-bottom: none;
}

.Popover-contact-list-item .TitleSubtitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: var(--font-size);
}

.Popover-contact-list-item .TitleSubtitle-subtitle {
  color: var(--color-gray);
}

.Popover-contact-list-item:hover {
  cursor: pointer;
}

.Popover-contact-list-item:hover .TitleSubtitle-title {
  color: var(--color-green);
}

.Popover-contact-list-item .Icon,
.Popover-contact-list-item:hover .Icon {
  color: var(--color-green);
  font-size: 17px;
}

.Selectbox-Contact {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  display: none;
}

.Selectbox-Contact-Custom {
  padding: 1px 0 0;
  left: -1px;
  right: -1px;
}

.Selectbox-Contact-Custom .Popover-contact-list {
  border-color: #c1c1c1;
  max-height: 175px;
}

.Selectbox-Contact.active {
  display: block;
}

.Locations.block .Button {
  padding-right: 5px;
}

.Locations.block .block-item.actions .optimize-route {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: var(--color-gray);
  font-size: var(--font-size-s);
}

.Locations.block .block-item.actions .optimize-route .Checkbox {
  margin-right: 10px;
  margin-top: -4px;
}

.Services.block {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}

.Services.block,
.Services.block .block-item {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 !important;
}

.Services.block .block-item {
  border-right: 1px solid var(--color-gray-xxl);
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 70px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.Services.block .block-item.selected {
  color: var(--color-green);
  background: var(--color-gray-xxl);
}

.Services.block .block-item:last-child {
  border: none;
  overflow: hidden;
}

.Services.block .block-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 11px;
  color: var(--color-gray);
  margin-bottom: 5px;
  text-align: center;
  height: 26px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Services.block .block-item .Overlay-RadioBox label,
.Services.block .Radio {
  display: none;
}

.Services.block .Services-Icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Services.block .Services-Icon img {
  max-width: 100%;
  width: auto;
  height: 24px;
}

.Services.block.list-view .Services-Icon img {
  height: auto;
}

.Services-PopupEye {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

.Services-PopupEye .Icon {
  color: var(--color-gray);
}

.Services.block .block-item.selected label,
.Services.block .selected .Services-PopupEye .Icon {
  color: var(--color-green);
}

.Services.block .block-item:hover {
  cursor: pointer;
}

.Services-PopupEye .Icon:hover {
  cursor: pointer;
  color: var(--color-green);
}

.Services.block label:hover {
  cursor: pointer;
}

.Services.block.list-view {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Services.block.list-view .block-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 15px 10px !important;
  min-height: auto;
  border: none;
  border-bottom: 1px solid var(--color-gray-xxl);
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Services.block.list-view .block-item .Radio {
  display: block;
}

.Services.block.list-view .block-item .Services-Icon {
  height: 100%;
  margin: 0 10px 0 15px !important;
  width: 40px;
}

.Services.block.list-view .block-item label {
  font-size: var(--font-size);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0;
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: auto;
}

.Services.block.list-view .block-item .Services-PopupEye {
  position: relative;
  top: auto;
  right: auto;
}

.Services.block.list-view .block-item .Overlay-RadioBox label {
  display: block;
}

.Vehicles.block {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}

.Vehicles.block,
.Vehicles.block .block-item {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 !important;
}

.Vehicles.block .block-item {
  border-right: 1px solid var(--color-gray-xxl);
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-white);
  margin: 0;
  border-width: 1px;
}

.Vehicles.block .block-item.selected {
  color: var(--color-green);
  background: var(--color-gray-xxl);
}

.Vehicles.block .block-item:last-child {
  border-right: none;
  overflow: hidden;
}

.Vehicles.block .block-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--color-gray);
  height: 26px;
  text-align: center;
}

.Vehicles.block.list-view .block-item label {
  text-align: left;
  height: auto;
}

.Vehicles.block .block-item-image {
  min-height: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px;
}

.Vehicles.block .block-item-image img {
  height: 24px;
  max-height: 30%;
  width: auto;
}

.Vehicles.block .block-item.selected label {
  color: var(--color-green);
}

.Vehicles.block .block-item:hover,
.Vehicles.block .block-item label:hover {
  cursor: pointer;
}

.Vehicles-PopupEye .Icon:hover {
  cursor: pointer;
  color: var(--color-green);
}

.Vehicles.block.list-view {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Vehicles.block.list-view .block-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Vehicles.block .block-item .Radio,
.Vehicles.block.list-view .Radio {
  display: block;
  margin: 13px 15px 7px 10px;
}

.Vehicles.block .block-item .Overlay-RadioBox,
.Vehicles.block.list-view .Overlay-RadioBox {
  display: block;
  margin: 10px 15px 10px 10px;
}

.Vehicles.block .block-item .Radio {
  height: 14px;
}

.Vehicles.block.list-view .block-item .Radio {
  height: 24px;
}

.Vehicles.block.list-view label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.PickupTime.block {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 !important;
  overflow: hidden;
}

.PickupTime.block,
.PickupTime.block .block-item {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.PickupTime.block .block-item {
  border-right: 1px solid var(--color-gray-xxl);
  padding: 10px 0 !important;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.PickupTime.block .block-item.selected {
  color: var(--color-green) !important;
  background: var(--color-gray-xxl);
}

.PickupTime.block .block-item:last-child {
  border-right: none;
  overflow: hidden;
}

.PickupTime.block .PickupTime-Name {
  font-size: 14px;
}

.PickupTime.block .PickupTime-Image {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.PickupTime.block .block-item .PickupTime-Image i {
  font-size: 32px;
  color: var(--color-gray);
}

.PickupTime.block .block-item.selected .PickupTime-Image i {
  font-size: 32px;
  color: var(--color-green);
}

.PickupTime.block .PickupTime-Explanation {
  font-size: 11px;
  color: var(--color-gray);
  height: 14px;
  padding: 0 5px;
  text-align: center;
  line-height: 12px;
}

.PickupTime.block .block-item.selected .PickupTime-Explanation {
  color: var(--color-green);
}

.PickupTime-DatePicker {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 420px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  z-index: 100001;
}

.PickupTime-DatePicker,
.PickupTime-DatePicker .DatePicker-Calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.PickupTime-DatePicker .DatePicker-Calendar {
  z-index: 1;
  background: var(--color-green);
  border-radius: var(--radius);
  padding-top: 40px;
  position: relative;
}

.PickupTime-DatePicker .DatePicker-Calendar:before {
  content: "";
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  top: -32px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.DatePicker-Calendar-img {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.DatePicker-Calendar-img > div {
  padding: 0;
}

.PickupTime-DatePicker .DatePicker-Actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 270px;
}

.PickupTime-DatePicker .DatePicker-Actions .Button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 5px;
}

.PickupTime-DatePicker .DatePicker-Actions .Button.white {
  border: 1px solid var(--color-green);
  color: var(--color-green);
  background: var(--color-white);
}

.PickupTime.block .block-item:hover {
  cursor: pointer;
  background: var(--color-gray-xxl);
}

.PickupTime-DatePicker .DatePicker-Actions .Button.gray:hover {
  background-color: #a4a4a4;
  color: inherit;
}

.PickupTime-DatePicker .DatePicker-Actions .Button.white:hover {
  background: #ccc;
  border-color: #ccc;
}

.rdt table {
  border-collapse: collapse;
}

.rdtPicker {
  background: var(--color-green);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.rdtPicker .rdtTimeToggle:hover,
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  background: #eee;
}

// .rdtPicker td.rdtNew,
// .rdtPicker td.rdtOld {
//   color: #999;
// }

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--color-yellow);
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--color-white);
  color: var(--color-green);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-s);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  color: var(--color-dark-green);
}

.rdtPicker th {
  border-bottom: none;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eee;
}

.rdt {
  position: relative;
}

.rdt input {
  height: 24px;
  border-radius: 3px;
  border: 1px solid #3fae2a;
  text-align: center;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #3fae2a;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  bottom: 40px;
  right: 0;
  color: #fff;
  border-radius: 3px;
  font-size: 13px;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  font-size: 28px;
  margin: 10px 0;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker .rdtTimeToggle:hover,
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  background: #027400;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
}

// .rdtPicker td.rdtNew,
// .rdtPicker td.rdtOld {
//   color: #027400;
// }

.rdtPicker td.rdtToday {
  position: relative;
  color: #ffdb00;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background: #ffdb00;
  color: #027400;
  border-radius: 6px;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #027400;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtNext span,
.rdtPrev span {
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #027400;
}

.rdtPicker tfoot {
  border-top: none;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background-color: var(--color-dark-green);
  color: var(--color-white);
  cursor: pointer;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtDays tfoot,
.rdtTime thead {
  display: none;
}

.CodPod.Popover {
  top: 0;
  bottom: 0;
  border-radius: 0 !important;
  padding: 0 !important;
}

.CodPod.Popover .Popover-List {
  padding: 10px !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.CodPod.Popover .Popover-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.CodPod.Popover .Popover-Item .Pin {
  margin-right: 10px;
}

.CodPod.Popover .Popover-Item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.CodPod.Popover .Popover-Item label,
.CodPod.Popover .Popover-SubItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: var(--font-size-s);
}

.CodPod.Popover .Popover-SubItem {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 36px;
  margin-bottom: 5px;
  padding-right: 10px;
}

.CodPod.Popover .Popover-SubItem .Input {
  margin-left: 30px;
  height: 28px;
  font-size: 13px;
  border-radius: var(--radius-s);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.CodPod.Popover .Popover-SubItem .Input input {
  font-size: 13px;
}

.CodPod.Popover .Popover-SubItem.disabled {
  color: var(--color-gray-xl);
}

.CodPod.Popover .Popover-SubItem.hidden {
  display: none;
}

.CodPod.Popover .Popover-SubItem .Checkbox {
  margin-right: 5px;
}

.ExtraServices.block {
  padding: 0 !important;
}

.ExtraServices.block .block-item {
  border-bottom: 1px solid var(--color-gray-xxl);
  padding: 10px !important;
}

.ExtraServices.block .block-item.selected {
  color: var(--color-green);
}

.ExtraServices.block .block-item:last-child {
  border-bottom: none;
}

.ExtraServices.block .block-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ExtraServices.block .block-item .Dropdown {
  border: 1px solid var(--color-gray-xxl);
  border-radius: var(--radius-s);
}

.ExtraServices.block .block-item .Dropdown-Head {
  padding: 3px 5px !important;
  width: 40px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.ExtraServices.block .block-item .TitleSubtitle-subtitle {
  color: var(--color-green);
}

.DiscountCode.block {
  padding: 0 !important;
}

.Note.block {
  padding: 0 !important;
}

.Options.block {
  padding: 0 !important;
}

.Options.block label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Options.block .TitleSubtitle-subtitle {
  color: var(--color-gray);
}

.Options.block .block-item {
  border-bottom: 1px solid var(--color-gray-xxl);
  padding: 10px !important;
}

.Options.block .block-item:last-child {
  border-bottom: none;
}

.ImageViewer {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.ImageViewer .ImageViewer-Image {
  border-radius: var(--radius-s);
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.ImageViewer .ImageViewer-Image > img {
  width: 100%;
  height: auto;
}

.ImageViewer .ImageViewer-Image > img:hover {
  cursor: pointer;
}

.ImageViewer-Delete {
  position: absolute;
  top: -5px;
  right: -6px;
  background: var(--color-red);
  z-index: 10000;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100px;
  text-align: center;
  color: var(--color-white);
}

.ImageViewer-Delete:hover {
  cursor: pointer;
}

.Attachments.block,
.Attachments.block .block-item {
  padding: 0 !important;
}

.Attachments.block .block-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 120px;
  border-right: 1px solid var(--color-gray-xxl);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.Attachments.block .block-item:last-child {
  border-right: none;
}

.Attachments.block .block-item .Dropzone {
  margin: 10px;
  border: 1px dashed var(--color-gray-xl);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-gray-xl);
  border-radius: var(--radius-s);
}

.Attachments.block .block-item .Dropzone:hover {
  cursor: pointer;
  color: var(--color-green);
  border-color: var(--color-green);
}

.Attachments.block .block-item .ImageView {
  position: relative;
  margin: 10px;
  color: var(--color-gray-xl);
}

.Attachments.block .block-item .FileView,
.Attachments.block .block-item .ImageView {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: var(--radius-s);
}

.Attachments.block .block-item .FileView {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--color-gray-xxl);
  color: var(--var-gray);
  text-decoration: none;
  height: 100%;
}

.Attachments.block .block-item .FileView img {
  width: auto;
  max-height: 48px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Attachments.block .block-item .FileView label {
  width: 100%;
  max-height: 24px;
  font-size: 11px;
  text-align: center;
  padding: 2px 5px;
  display: block;
}

.JobOrder.block {
  padding: 0 !important;
}

.Badges.block {
  padding: 0 !important;
}

.Badges.block .block-item {
  border-bottom: 1px solid var(--color-gray-xxl);
  padding: 10px !important;
}

.Badges.block .block-item.selected {
  color: var(--color-green);
}

.Badges.block .block-item:last-child {
  border-bottom: none;
}

.Badges.block .block-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Badges.block .block-item .Dropdown {
  border: 1px solid var(--color-gray-xxl);
  border-radius: var(--radius-s);
}

.Badges.block .block-item .Dropdown-head {
  padding: 3px 5px !important;
  width: 40px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Badges.block .block-item .TitleSubtitle-subtitle {
  color: var(--color-green);
}

.Badges.block .block-item-popup-eye .Icon {
  color: var(--color-gray);
  margin-top: 2px;
}

.Badges.block .block-item-popup-eye .Icon:hover {
  cursor: pointer;
  color: var(--color-green);
}

.Reimbursements.block {
  padding: 0 !important;
}

.Reimbursements.block .block-item {
  border-bottom: 1px solid var(--color-gray-xxl);
  padding: 10px !important;
}

.Reimbursements.block .block-item.selected {
  color: var(--color-green);
}

.Reimbursements.block .block-item:last-child {
  border-bottom: none;
}

.Reimbursements.block .block-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Reimbursements.block .block-item .Dropdown {
  border: 1px solid var(--color-gray-xxl);
  border-radius: var(--radius-s);
}

.Reimbursements.block .block-item .Dropdown-head {
  padding: 3px 5px !important;
  width: 40px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Reimbursements.block .block-item .TitleSubtitle-subtitle {
  color: var(--color-green);
}

.Reimbursements.block .block-item-popup-eye .Icon {
  color: var(--color-gray);
  margin-top: 2px;
}

.Reimbursements.block .block-item-popup-eye .Icon:hover {
  cursor: pointer;
  color: var(--color-green);
}

.CodPod.block {
  padding: 0 !important;
}

.CodPod.block .block-item {
  padding: 10px !important;
}

.CodPod.block .block-item .TitleSubtitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.CodPod.block .block-item .TitleSubtitle-subtitle {
  color: var(--color-green);
}

.CodPod.block .block-item:hover {
  cursor: pointer;
}

.Pricing.block {
  padding: 0 10px !important;
}

.booking-detail-pricing.block {
  padding: 0 !important;
}

.Pricing.block .block-item {
  border-top: 1px solid var(--color-gray-xxl);
  padding: 10px 0 !important;
  display: flex;
  align-items: end;
}

.Pricing.block .block-item:first-child {
  border-top: none;
}

.Pricing.block .block-item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Pricing.block .block-item.fare span {
  color: var(--color-green);
}

.Pricing.block .block-item.total label {
  font-size: var(--font-size-b);
  text-transform: uppercase;
}

.Pricing.block .block-item.total span {
  font-size: var(--font-size-xxb);
  color: var(--color-green);
}

.Pricing.block .block-item.subtotal span {
  color: var(--color-green);
}

.Pricing.block .block-sub-item {
  padding: 0 0 10px 15px !important;
  font-size: var(--font-size-s);
}

.Pricing.block .block-sub-item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.PricingNote.block {
  background: none !important;
  box-shadow: none !important;
  font-size: var(--font-size-s);
  color: var(--color-gray);
}

.LocationContacts .block-item label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: var(--font-size-s);
}

.LocationContacts .block-sub-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 36px;
  font-size: var(--font-size-s);
  margin-bottom: 5px;
}

.LocationContacts .block-sub-item .Icon {
  margin-left: 30px;
  margin-right: 10px;
}

.LocationContacts .block-sub-item .Radio {
  margin-left: -2px;
  margin-right: -3px;
}

.LocationContacts .block-sub-item .Input input {
  font-size: var(--font-size-s);
}

.BookingInfo {
  background: var(--color-green);
  padding: 0;
  color: var(--color-white);
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 10;
}

.BookingInfo table {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.BookingInfo table thead td {
  color: hsla(0, 0%, 100%, 0.8);
  font-size: var(--font-size);
  padding: 0 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.BookingInfo table tbody td {
  padding: 10px;
  vertical-align: top;
}

.BookingInfo .BookingDetail,
.BookingInfo .ContactInfo,
.BookingInfo .PickupDetail {
  font-size: var(--font-size);
  line-height: 1.5;
}

.BookingInfo .BookingDetail b,
.BookingInfo .ContactInfo b,
.BookingInfo .PickupDetail b {
  font-weight: 700;
  color: var(--color-yellow);
}

.BookingInfo .AddressItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  font-size: var(--font-size);
}

.BookingInfo .AddressItem .Pin {
  margin-right: 10px;
}

.BookingInfo .AddressItem .Button {
  margin-left: 10px;
}

.BookingInfo-Actions {
  background: var(--color-dark-green);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  height: 56px;
  box-sizing: border-box;
}

.BookingInfo-Actions-Left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BookingInfo-Actions-Left .Button {
  margin-right: 10px;
}

.BookingInfo-Actions-Right .Button {
  margin-left: 10px;
}

.Locating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.Locating.Locating-status-bar {
  top: 110px;
}

.Locating .Map-Overlay {
  height: calc(100% - 60px);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

.Locating .Map-Overlay .Modal {
  position: absolute;
}

.Locating .Overlay {
  position: absolute;
  background: none;
}

.Locating .Loader-Text {
  color: var(--color-white);
}

.Locating .Popup {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.Locating .Popup.No-Seft {
  -ms-flex-item-align: inherit !important;
  -ms-grid-row-align: inherit !important;
  align-self: inherit !important;
}

.Locating .Modal-Basic .DriverBlock .DriverInfo h3 {
  text-align: left;
  font-size: var(--font-size);
  min-width: 120px;
}

.Locating .Modal-Basic .Box {
  max-width: 460px;
}

.PageHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: var(--height-t);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--color-gray-xxl);
}

.PageHead-title {
  margin: 0;
  padding: 0 20px;
  color: var(--color-gray-xd);
  font-size: var(--font-size-xb);
  font-weight: 700;
}

.PageHead-tabs {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: inherit;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.PageHead-tabs .item {
  padding: 8px 20px;
}

.PageHead-tabs .item:hover {
  cursor: pointer;
  color: var(--color-green);
}

.PageHead-tabs .selected.item {
  background: var(--color-white);
  color: var(--color-green);
  border-top-left-radius: var(--radius-s);
  border-top-right-radius: var(--radius-s);
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
}

.PageHead-actions {
  margin-right: 10px;
  width: auto;
}

.PageHead-actions .Button {
  margin-left: 10px;
  padding: 0 8px;
}

.Table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-gray-xl);
}

.Table::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 6px;
}

.Table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
}

.Table td {
  text-align: left;
  border-bottom: 1px solid var(--color-gray-xl);
  border-left: 1px solid var(--color-gray-xl);
}

.Table td:first-child {
  border-left: none;
}

.Table tbody tr:last-child td {
  border-bottom: none;
}

.Table thead td {
  padding: 5px 20px;
  color: var(--color-gray-l);
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font-size-s);
}

.Table tbody td {
  padding: 15px 20px;
}

.Table tbody tr:nth-child(2n + 2) td {
  background: var(--color-gray-xxl);
}

.Table tbody tr:nth-child(2n + 2) td.actions .Button {
  background-color: var(--color-white);
}

.Table td.align-left {
  text-align: left;
}

.Table td.align-center {
  text-align: center;
}

.Table td.align-right {
  text-align: right;
}

.Table td.selector {
  text-align: center;
  max-width: 40px;
  padding: 0 5px;
}

.Table td.company,
.Table td.role,
.Table td.status {
  text-align: center;
}

.Table td.add,
.Table td.balance,
.Table td.date,
.Table td.preference,
.Table td.subtract {
  text-align: center;
  width: 100px;
}

.Table td.actions {
  text-align: center;
  width: auto;
  width: 100px;
}

.Table td.actions-custom {
  width: 180px;
}

.Table td.actions-custom .Button {
  margin-bottom: 5px !important;
}

.Table td.actions .Button {
  margin: 0 5px;
}

.Table td.actions .Button.delete:hover i {
  color: var(--color-red);
}

.Table td.actions .Button.add:hover i {
  color: var(--color-green);
}

.Table td.centered {
  text-align: center;
}

.Table td.driver {
  width: 300px;
}

.Table.Table-Custom tbody tr:nth-child(2n + 2) td {
  background: transparent;
}

.Table-Custom .Td-Bg-Gray,
.Table-Custom .Tr-Bg-Gray {
  background: #efefef;
}

.BatchUploadLayout {
  padding-top: 60px;
}

.BatchUploadLayout a {
}

.BatchUploadLayout .Steps {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.BatchUploadLayout section {
  padding: 20px 20px 0;
}

.BatchUploadLayout section.actions {
  padding: 20px;
  text-align: right;
}

.BatchUploadLayout section.actions .Button {
  margin-left: 10px;
  min-width: 120px;
}

.BatchUploadLayout .FormGroup {
  margin: 10px 0;
}

.BatchUploadLayout .List-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 0;
}

.BatchUploadLayout .List-Item .Button {
  color: var(--color-green);
  margin-left: 10px;
}

.BatchUploadLayout .List-Item .Pin {
  margin-right: 10px;
  margin-top: 2px;
}

.BatchUploadIntro {
  width: 100%;
}

.BatchUploadIntro,
.BatchUploadIntro .SplitView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.BatchUploadIntro .SplitView {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}

.BatchUploadIntro .SplitView:first-child:after {
  content: "";
  position: absolute;
  top: 30%;
  bottom: 30%;
  right: -1px;
  left: 100%;
  background: var(--color-gray-xl);
}

.BatchUploadIntro h2 {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-xxb);
  text-align: center;
}

.BatchUploadIntro h5 {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-xb);
  font-weight: 400;
  text-align: center;
}

.BatchUploadIntro-Image {
  height: 160px;
  margin: 20px 0 10px;
}

.BatchUploadIntro-Image img {
  height: inherit;
  width: auto;
}

.BatchUploadIntro p {
  width: 400px;
  font-size: var(--font-size-b);
  color: var(--color-gray);
  line-height: 1.8;
  text-align: center;
  padding: 0;
  margin-bottom: 20px;
}

.BatchUploadIntro .Button {
  width: 150px;
}

.Tabs,
.Tabs-Head {
  position: relative;
}

.Tabs-Head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.Tabs-Head-Item {
  padding: 10px 20px;
  border-top-right-radius: var(--radius);
  border-top-left-radius: var(--radius);
}

.Tabs-Head-Item.selected {
  color: var(--color-green);
  background: var(--color-gray-xxxl);
  border: 1px solid var(--color-gray-xxl);
  border-bottom: none;
  margin-bottom: -1px;
}

.Tabs-Head-Item:hover {
  cursor: pointer;
}

.Tabs-Content {
  padding: 10px 20px;
  background: var(--color-gray-xxxl);
  border: 1px solid var(--color-gray-xxl);
  position: relative;
}

.Tabs-Content-Item {
  display: none;
}

.Tabs-Content-Item.visible {
  display: block;
}

.BatchUploadUpload {
  width: 100%;
}

.BatchUploadUpload .TextArea {
  max-width: 350px;
}

.BatchUploadUpload .Dropzone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 160px;
  width: 350px;
  border: 1px dashed var(--color-green);
  background: var(--color-white);
  border-radius: var(--radius);
}

.BatchUploadUpload .Dropzone .Icon {
  color: var(--color-green);
}

.BatchUploadUpload .Dropzone p {
  margin: 0;
  padding: 10px 0;
  color: var(--color-green);
}

.BatchUploadLayout .FormGroup .Input {
  max-width: 350px;
}

.BatchUploadAssign {
  width: 100%;
}

.BatchUploadAssign .BatchUploadAssign-Box {
  background: var(--color-gray-xxxl);
  border: 1px solid var(--color-gray-xxl);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  padding: 10px 20px;
}

.BatchUploadAssign .FormGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  padding: 0;
  margin: 0;
  margin: 10px 0;
}

.BatchUploadAssign .FormGroup label {
  width: 150px;
  text-align: right;
  padding-right: 20px;
}

.BatchUploadAssign .FormGroup select {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 36px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-xl);
}

.BatchUploadAssign .FormGroup-explanation {
  padding-left: 20px;
  box-sizing: border-box;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.BatchUploadOptions {
  width: 100%;
}

.BatchUploadOptions .BatchUploadOptions-Box {
  background: var(--color-gray-xxxl);
  border: 1px solid var(--color-gray-xxl);
  padding: 10px 20px;
}

.BatchUploadOptions .BatchUploadOptions-Box .FormGroup-Option-Actions {
  float: left;
  margin-right: 15px;
}

.BatchUploadOptions .FormGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0;
  margin: 0;
  margin: 10px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.BatchUploadOptions .FormGroup .locations-error {
  margin-left: 0;
  margin-top: 5px;
}

.BatchUploadOptions .FormGroup-Title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.BatchUploadOptions .FormGroup-Title h3 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-b);
}

.BatchUploadOptions .FormGroup-Title-Actions input {
  margin: 0 5px 0 15px;
}

.BatchUploadOptions .FormGroup-Option {
  width: 100%;
  display: none;
}

.BatchUploadOptions .visible.FormGroup-Option {
  display: block;
}

.BatchUploadOptions .ContactInfo {
  width: 400px;
  padding: 20px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-xxl);
  margin: 10px 0;
  line-height: 1.5;
  border-radius: var(--radius);
  box-sizing: border-box;
}

.BatchUploadOptions .FormGroup-Option .Input {
  width: 200px;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  margin: 10px 10px 10px 0;
}

.BatchUploadOptions .List {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.BatchUploadOptions .List-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--color-white);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid var(--color-gray-xxl);
  min-width: 120px;
  margin-left: -1px;
}

.BatchUploadOptions .List-Item select {
  border-radius: var(--radius);
}

.BatchUploadOptions .List-Item-Image img {
  height: 24px;
  width: auto;
  margin: 5px 0;
}

.BatchUploadOptions .List-Item label {
  font-size: var(--font-size-s);
}

.BatchUploadOptions .Modal .Box {
  max-width: 720px;
  background: var(--color-white);
}

.BatchUploadOptions .Box-Content,
.BatchUploadOptions.Modal-Basic .Box-Content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.BatchUploadOptions .Box-Content-SplitView {
  padding: 0 5px;
}

.BatchUploadOptions .Box-Content-SplitView.No-Padding {
  padding-left: 0;
}

.BatchUploadOptions .Box-Content-SplitView ul {
  width: 100%;
  height: 200px;
  outline: none;
  padding: 0;
  background: var(--color-white);
  border: 1px solid #a9a9a9;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
}

.BatchUploadOptions .Box-Content-SplitView li {
  padding: 10px;
  font-size: var(--font-size);
}

.BatchUploadOptions .Box-Content-SplitView li.active {
  background: var(--color-green);
  color: var(--color-white);
}

.BatchUploadOptions .Box-Content-SplitView .ContactInfo {
  width: 100% !important;
  margin: 0;
  background: #f5f6f7;
}

.BatchUploadOptions .Modal .Box-Actions {
  width: 320px !important;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.BatchUploadOptions .Modal .Box-Actions .Button {
  width: 120px !important;
  -webkit-box-flex: 1 !important;
  -ms-flex: auto !important;
  flex: auto !important;
}

.BatchUploadOptions.Add-Location .Box,
.BatchUploadOptions .Add-Location .Box {
  max-width: 1024px;
}

.Modal-Addlocation-Info {
  width: 40%;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

.Modal-Addlocation-Map {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 400px;
  background-color: #ddd;
}

.Modal-Addlocation-Info-Right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 15px;
}

.BatchUploadOptions .Modal-FormGroup-Addlocation {
  margin-top: 0;
  position: relative;
}

.BatchUploadOptions .Modal-FormGroup-Addlocation .TextArea {
  padding-right: 45px;
}

.BatchUploadOptions .Modal-FormGroup-Addlocation textarea {
  resize: none;
  padding-right: 0;
}

.BatchUploadOptions .Modal-FormGroup-Note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
  position: relative;
  padding-bottom: 5px;
}

.BatchUploadOptions .Modal-FormGroup-Note a,
.BatchUploadOptions .Modal-FormGroup-Note button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
}

.BatchUploadValidate {
  width: 100%;
}

.modal-batchUploadValidate .Box {
  max-width: 1076px;
  background: var(--color-white);
  max-height: 90%;
}

.modal-batchUploadValidate .Box-Head {
  background: var(--color-green);
  color: var(--color-white);
  font-size: var(--font-size-xb);
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-batchUploadValidate .Box-Content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-batchUploadValidate .Box-Content .Locations {
  width: 380px;
  padding: 10px;
  box-sizing: border-box;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  overflow-y: auto;
  background: var(--color-gray-xxxl);
  z-index: 3;
}

.modal-batchUploadValidate .Box-Content .Locations h5 {
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 15px;
  color: var(--color-gray);
}

.modal-batchUploadValidate .Box-Content .Locations .block-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: var(--color-white);
}

.modal-batchUploadValidate .Box-Content .Locations .block-item.actions input {
  margin: 0 14px 0 6px;
}

.modal-batchUploadValidate .Box-Content .Locations .block-item .Pin {
  margin-right: 10px;
}

.modal-batchUploadValidate .Box-Content .Locations .block-item .Input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

.modal-batchUploadValidate .Box-Content .Locations .block-item.actions {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.modal-batchUploadValidate .Box-Content .Map {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-batchUploadValidate .Box-Actions {
  width: 100% !important;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-batchUploadValidate .Box-Actions .Button {
  width: 120px !important;
  -webkit-box-flex: 0 !important;
  -ms-flex: none !important;
  flex: none !important;
}

.modal-batchUploadValidate .Box-Content .Locations .block-item .Input .Popover {
  margin-left: 20px;
}

.modal-batchUploadValidate .Box-Content .Locations .block-item .Input .Popover .Popover-Arrow {
  top: 15px;
}

.Popover-Close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}

.BatchUploadBook {
  width: 100%;
}

.BatchUploadBook .Bookings-Table {
  margin-top: 15px;
}

.BatchUploadBook .Table td {
  padding: 5px;
}

.BatchUploadBook table * {
  font-size: 12px;
}

.BatchUploadBook td.locations-address .Pin,
.BatchUploadBook td.locations_address .Pin {
  margin-right: 10px;
}

.BatchUploadBook td.locations-address .Button,
.BatchUploadBook td.locations_address .Button {
  background: #fff;
}

.BatchUploadBook td.locations-address span,
.BatchUploadBook td.locations_address span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BatchUploadBook .Bookings-Table .cell {
  border: 0 none;
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

.BatchUploadBook .locations-distance,
.BatchUploadBook .locations-price {
  text-align: center;
}

.BatchUploadBook-actions .row {
  margin-bottom: 10px;
}

.BatchUploadBook-actions-Left,
.BatchUploadBook-actions .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BatchUploadBook-actions-Left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BatchUploadBook-actions-Left .Button,
.BatchUploadBook-actions-Left a {
  margin: 0 10px 0 0;
}

.BatchUploadBook-actions-Right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BatchUploadBook-actions-Left .Button.disabled,
.BatchUploadBook-actions-Right .Button.disabled {
  background-color: var(--color-gray);
  color: var(--color-black);
  pointer-events: none;
}

.BatchUploadBook-actions-Left .Button.disabled:hover,
.BatchUploadBook-actions-Right .Button.disabled:hover {
  background-color: var(--color-gray-xd) !important;
  color: var(--color-black) !important;
}

.BatchUploadProgress {
  width: 100%;
}

.BatchUploadProgress .PageHead-actions .Button:hover {
  color: var(--color-white);
}

.BatchUploadProgress .Table td {
  padding-left: 5px;
  padding-right: 5px;
}

.BatchUploadProgress table * {
  font-size: 12px;
}

.BatchUploadProgress td.locations-address,
.BatchUploadProgress td.locations_address {
  padding: 5px;
}

.BatchUploadProgress td.locations-address .Pin,
.BatchUploadProgress td.locations_address .Pin {
  margin-right: 10px;
}

.BatchUploadProgress td.locations-address .Button,
.BatchUploadProgress td.locations_address .Button {
  background: #fff;
}

.BatchUploadProgress td.locations-address span,
.BatchUploadProgress td.locations_address span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BatchUploadProgress .Table .cell {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BatchUploadProgress .Table thead td {
  white-space: nowrap;
}

.BatchUploadProgress .batch-name a {
  color: var(--color-green);
}

.BatchUploadProgress .booking-id,
.BatchUploadProgress .bookings,
.BatchUploadProgress .canceled,
.BatchUploadProgress .completed,
.BatchUploadProgress .confirmed,
.BatchUploadProgress .fee,
.BatchUploadProgress .in-progress,
.BatchUploadProgress .locating-driver,
.BatchUploadProgress .saved-as-draft,
.BatchUploadProgress .status,
.BatchUploadProgress .vehicle-types,
.BatchUploadProgress .vehicle_type {
  text-align: center;
}

.BatchUploadProgress .Table thead td.actions {
  min-width: 210px;
  width: 210px;
}

.Detail-Batch {
  padding-top: 15px;
}

.BatchUploadProgress-Batch {
  background: var(--color-gray-xxl);
  padding: 0 20px;
}

.BatchUploadProgress-Batch a {
  border-radius: 26px;
  line-height: 40px;
  display: inline-block;
  padding: 0 15px;
  margin: 0 10px 10px 0;
  font-size: 13px;
}

.BatchUploadProgress-Batch a.active {
  background-color: var(--color-green);
  color: var(--color-white);
}

.BatchUploadHistory {
  width: 100%;
}

.BatchUploadHistory .PageHead-actions .Button:hover {
  color: var(--color-white);
}

.BatchUploadHistory .Table td {
  padding-left: 5px;
  padding-right: 5px;
}

.BatchUploadHistory table * {
  font-size: 12px;
}

.BatchUploadHistory td.locations-address .Button,
.BatchUploadHistory td.locations_address .Button {
  background: #fff;
}

.BatchUploadHistory .Table thead td {
  white-space: nowrap;
}

.BatchUploadHistory .batch-name a {
  color: var(--color-green);
}

.BatchUploadHistory .bookings,
.BatchUploadHistory .canceled,
.BatchUploadHistory .completed,
.BatchUploadHistory .confirmed,
.BatchUploadHistory .in-progress,
.BatchUploadHistory .locating-driver,
.BatchUploadHistory .saved-as-draft {
  text-align: center;
}

.BatchUploadHistory .Table thead td.actions {
  min-width: 160px;
  width: 160px;
}

.BatchUploadHistoryDetail {
  width: 100%;
}

.BatchUploadHistoryDetail .PageHead-actions .Button:hover {
  color: var(--color-white);
}

.BatchUploadHistoryDetail .Table td {
  padding-left: 5px;
  padding-right: 5px;
}

.BatchUploadHistoryDetail table * {
  font-size: 12px;
}

.BatchUploadHistoryDetail td.locations-address,
.BatchUploadHistoryDetail td.locations_address {
  padding: 5px;
}

.BatchUploadHistoryDetail td.locations-address .Pin,
.BatchUploadHistoryDetail td.locations_address .Pin {
  margin-right: 10px;
}

.BatchUploadHistoryDetail td.locations-address .Button,
.BatchUploadHistoryDetail td.locations_address .Button {
  background: #fff;
}

.BatchUploadHistoryDetail td.locations-address span,
.BatchUploadHistoryDetail td.locations_address span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.BatchUploadHistoryDetail .Table .cell {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BatchUploadHistoryDetail .Table thead td {
  white-space: nowrap;
}

.BatchUploadHistoryDetail .batch-name a {
  color: var(--color-green);
}

.BatchUploadHistoryDetail .booking-id,
.BatchUploadHistoryDetail .fee,
.BatchUploadHistoryDetail .status,
.BatchUploadHistoryDetail .vehicle-types,
.BatchUploadHistoryDetail .vehicle_type {
  text-align: center;
}

.BatchUploadHistoryDetail .Table thead td.actions {
  min-width: 210px;
  width: 210px;
}

.Batch .BatchUpload-Title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 20px 0;
}

.Batch .BatchUpload-Title.Custom {
  padding: 10px 20px 0 0;
  margin-bottom: -48px;
}

.Batch .BatchUpload-Title h3 {
  padding: 0;
  margin: 0;
  color: var(--color-green);
  font-size: var(--font-size-xb);
}

.Batch .BatchUpload-Title .Button {
}

.APIDashboardLayout a {
  text-decoration: none;
}

.APIDashboardLayout .Steps {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.APIDashboardLayout section {
  padding: 20px 20px 0;
}

.APIDashboardLayout section.actions {
  padding: 20px;
  text-align: right;
}

.APIDashboardLayout section.actions .Button {
  margin-left: 10px;
  min-width: 120px;
}

.APIDashboardLayout .FormGroup {
  margin: 10px 0;
}

.APIDashboardLayout .List-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 0;
}

.APIDashboardLayout .List-Item .Button {
  color: var(--color-green);
  margin-left: 10px;
}

.APIDashboardLayout .List-Item .Pin {
  margin-right: 10px;
  margin-top: 2px;
}

.APIDashboardIntro {
  width: 100%;
}

.Tabs-Api {
  border-bottom: 3px solid var(--color-primary);
  padding: 10px 20px 0;
  overflow: hidden;
}

.Tabs-Api ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.Tabs-Api li {
  float: left;
}

.Tabs-Api a {
  width: 133px;
  line-height: 35px;
  border-top: 1px solid var(--color-gray-l);
  border-left: 1px solid var(--color-gray-l);
  border-right: 1px solid var(--color-gray-l);
  background: var(--color-gray-xxl);
  color: var(--color-gray-xxd);
  font-size: var(--font-size-s);
  display: block;
  text-align: center;
}

.Tabs-Api .active a {
  border-top: 1px solid var(--color-primary);
  border-left: 1px solid var(--color-primary);
  border-right: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--color-white);
}

.APIDashboardIntro .FormGroup {
  margin: 0 0 30px;
  padding-bottom: 1px;
  overflow: hidden;
}

.APIDashboardIntro .FormGroup * {
  box-sizing: border-box;
}

.APIDashboardIntro .FormGroup .FormGroup-label {
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  color: #323232;
}

.APIDashboardIntro .FormGroup .Input {
  width: 50%;
  float: left;
}

.APIDashboardIntro .FormGroup .APIDashboardIntro-Link {
  width: 15%;
  float: left;
  text-align: left;
  line-height: 32px;
  padding: 0 5px 0 25px;
  font-size: var(--font-size-s);
  color: var(--color-primary);
  cursor: pointer;
}

.APIDashboardIntro .FormGroup .APIDashboardIntro-Link:hover {
  text-decoration: underline;
}

.APIDashboardIntro .FormGroup .FormGroup-explanation {
  margin-top: 0;
  width: 35%;
  float: left;
  font-size: var(--font-size-s);
}

.APIDashboardIntro .FormGroup select {
  width: 500px;
  margin-right: 15px;
}

.APIDashboardIntro-Box {
  margin-bottom: 30px;
  display: none;
}

.APIDashboardIntro-Box.active {
  display: block;
}

.APIDashboardIntro-Box .Input {
  float: left;
  margin-right: 15px;
  width: 500px;
}

.APIDashboardIntro-Group button {
  margin-right: 10px;
}

.APIDashboardIntro-Alert {
  width: 100%;
  max-width: 310px;
  padding: 15px;
  background-color: #fffbe6;
  box-shadow: inset 0 0 0 1px #d9caab, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.2857rem 0.2857rem;
}

.APIDashboardIntro-Alert p {
  margin-top: 0;
  font-size: var(--font-size-s);
  color: #876a38;
}

.APIDashboardIntro-tag {
  margin-bottom: 50px;
}

.SplitView > ul {
  list-style-type: decimal;
}

.SplitView > p,
.SplitView > ul {
  font-size: 14px;
}

.APIDashboardIntro-Alert .Button,
.APIDashboardIntro .Button.green {
  min-width: 120px;
}

.Status {
  display: block;
  position: relative;
  height: inherit;
  padding: 0 5px;
  font-size: var(--font-size-s);
  text-align: center;
}

.Status i {
  margin: auto;
  display: block;
}

.Status.red {
  color: var(--color-red);
}

.Status.green {
  color: var(--color-green);
}

.Status.blue {
  color: var(--color-blue);
}

.Status.white {
  color: var(--color-white);
}

.dropoff-items {
  margin: 0 -5px !important;
}

.dropoff-items:first-child {
  margin-top: -5px !important;
}

.APIDashboardBooking {
  width: 100%;
}

.APIDashboardBooking table tr td:last-child button {
  font-size: 11px;
  width: 120px;
}

.APIDashboardBooking .Table thead tr td.dropoff,
.APIDashboardBooking .Table thead tr td.pickup {
  min-width: 200px;
}

.APIDashboardBooking .Table td {
  padding: 5px;
  font-size: 12px;
}

.APIDashboardBooking .action,
.APIDashboardBooking .amount,
.APIDashboardBooking .booking-code,
.APIDashboardBooking .booking-status,
.APIDashboardBooking .company-id,
.APIDashboardBooking .contact-name,
.APIDashboardBooking .created-at,
.APIDashboardBooking .customer-id,
.APIDashboardBooking .customer-name,
.APIDashboardBooking .driver-id,
.APIDashboardBooking .driver-name,
.APIDashboardBooking .note,
.APIDashboardBooking .number-location,
.APIDashboardBooking .time-type,
.APIDashboardBooking .total-distance,
.APIDashboardBooking .Vehicle-type {
  text-align: center;
}

.APIDashboardBooking table tr td:last-child .Button.green {
  background-color: var(--color-green) !important;
}

.APIDashboardBooking .booking-status span {
  white-space: nowrap;
  display: inline-block;
  line-height: 22px;
  padding: 0 8px;
}

.status-locating-driver-timeout {
  background-color: #f44336 !important;
  color: #fff;
}

.APIDashboardBooking .actions {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  background-color: transparent !important;
  color: #3fae29;
  border: 0 none;
  width: 100px;
  margin: 0 !important;
  text-decoration: underline;
  height: auto !important;
  cursor: pointer;
}

.APIDashboard .SplitView ul a,
.FormGroup-explanation a {
  color: var(--color-green);
  text-decoration: underline;
}

.APIDashboard .SplitView ul a:hover,
.FormGroup-explanation a:hover {
  text-decoration: underline;
}

.ItemAddingBlock {
  border: 1px solid var(--color-gray-xxl);
  padding: 10px;
  margin: 10px;
  border-radius: var(--radius-s);
  display: none;
}

.ItemAddingBlock.visible {
  display: block;
}

.ItemAddingBlock .block-item {
  padding: 10px;
  border-bottom: 1px solid var(--color-gray-xxl);
}

.ItemAddingBlock .block-item .Button {
  margin-right: 10px;
}

.ItemAddingBlock .block-item:last-child {
  border-bottom: none;
}

.ItemAddingBlock h3 {
  padding: 0;
  margin: 0;
}

.ItemAddingBlock p {
  padding: 0;
  margin: 0;
  color: var(--color-dark-gray);
}

.ItemAddingBlock .Form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 10px;
}

.ItemAddingBlock .FormGroup {
  margin-right: 10px;
}

.ItemAddingBlock .Button {
  min-width: 120px;
}

.ItemAddingBlock .Dropzone {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 160px;
  width: 350px;
  border: 1px dashed var(--color-green);
  background: var(--color-white);
  border-radius: var(--radius);
}

.ItemAddingBlock .Dropzone .Icon {
  color: var(--color-green);
}

.ItemAddingBlock .Dropzone p {
  margin: 0;
  padding: 10px 0;
  color: var(--color-green);
}

.Filter {
  padding: 0 10px;
}

.Filter,
.Filter .FormGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Filter .FormGroup {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Filter .FormGroup label {
  font-size: var(--font-size-s);
  margin-right: 15px;
}

.Filter .FormGroup select {
  height: var(--height);
  padding: 0 10px;
  font-size: var(--font-size);
  border-width: 1px;
  border-color: var(--color-gray-xl);
  border-radius: var(--radius);
  background: var(--color-white);
}
.FormGroup-DropDown li {
  border-bottom: 1px solid var(--color-gray-xl);
}
.FormGroup-DropDown li:last-child {
  border-bottom: none;
}

.Box-List-Item a,
.Box-List-Item button {
  margin-left: 10px;
}

.MyDrivers .Modal-Basic .DriverBlock .DriverInfo h3 {
  text-align: left;
  font-size: var(--font-size);
  min-width: 120px;
}

.MyDrivers .Modal-Basic .Box {
  max-width: 460px;
}

.Pagination ul {
  display: block;
  padding: 10px 5px;
  margin: 0;
}

.Pagination ul li {
  display: inline-block;
}

.Pagination ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid var(--color-gray-xxl);
  margin: 0 5px;
  color: var(--color-gray);
  border-radius: var(--radius);
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Pagination ul li a.active,
.Pagination ul li a:hover {
  background: var(--color-green);
  border: 1px solid var(--color-green);
  color: var(--color-white);
  border-radius: var(--radius-s);
}

.Toast {
  display: inline-block;
  cursor: pointer;
}

.toast {
  opacity: 1 !important;
  top: 80px;
}

.toast-success {
  background-color: var(--color-green);
}

.toast-info {
  background-color: var(--color-blue);
}

.toast-waring {
  background-color: var(--color-secondary);
}

.toast-error {
  background-color: var(--color-red);
}

.Contacts,
.Employee,
.MyDrivers {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 60px;
}

.Contacts-search {
  padding: 10px;
  height: auto;
}

.Contacts-search,
.Contacts-search .col,
.Contacts-search .col-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Contacts-search .col {
  margin-right: 10px;
}

.Contacts-search .col-custom {
  margin-right: 40px;
}

.Contacts-search label {
  margin-right: 20px;
  font-size: var(--length-xxxs);
  color: var(--color-gray-d);
}

.Contacts .address input,
.Contacts .email input,
.Contacts .note input {
  width: 356px;
}

.Contacts .ItemAddingBlock .Dropzone {
  margin-bottom: 15px;
}

.Contacts .ItemAddingBlock .block-item-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 362px;
}

.Contacts .ItemAddingBlock .block-item-button * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Closure .Popup {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.Closure .Popup.top-align {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.Closure-Head .PageHead-actions {
  border-radius: var(--radius);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Closure-Head .PageHead-actions a,
.Closure-Head .PageHead-actions button {
  margin: 0;
  border-radius: 0;
}

.Closure-Head .PageHead-actions a.active,
.Closure-Head .PageHead-actions button.active {
  background-color: var(--color-green);
  color: #fff;
}

.Closure-Head-Export,
.Closure-Head .PageHead-actions .active i {
  color: var(--color-white);
}

.Closure-Head-Export {
  margin-right: 5px;
  background-color: var(--color-green);
  display: block;
  line-height: 36px;
  padding: 0 15px;
  border-radius: var(--radius);
}

.Closure-Head-Export:hover {
  background-color: var(--color-dark-green) !important;
  color: var(--color-white) !important;
}

.Closure-Tabs {
  display: none;
}

.Closure-Tabs.active {
  display: block;
  height: 100%;
}

.Closure .Filter .FormGroup:nth-of-type(3) input {
  width: 330px;
}

.Table .Closure-Center {
  text-align: center;
}

.Closure-BookingID,
.Closure-Fee {
  width: 80px;
  white-space: nowrap;
}

.Closure-BookingID a,
.Closure-BookingID a:hover {
  color: var(--color-green);
  text-decoration: underline;
}

.Closure-Status {
  width: 120px;
}

.Closure-Locating-Group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  margin-bottom: 5px;
}

.Closure-Locating-Group:last-child {
  margin-bottom: 0;
}

.Closure-Locating-Text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 10px;
}

.Closure-Group {
  padding: 0 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Closure-List {
  width: 25%;
  margin-bottom: 10px;
  position: relative;
}

.Closure-List.chat.expand {
  min-height: 752px;
}

.Closure-List .Row {
  margin: 0 5px;
  background-color: var(--color-green);
  border-radius: 4px;
}

.Closure-List-Devide {
  clear: both;
  height: 1px;
  background-color: hsla(0, 0%, 100%, 0.3);
}

.Closure-List-Title {
  padding: 10px 15px;
  overflow: hidden;
}

.Closure-List-TitleInfo,
.Closure-List-TitleInfo a:hover {
  color: var(--color-yellow);
}

.Closure-List-Content,
.Closure-List-Info {
  padding: 15px 15px 10px;
  color: var(--color-white);
}

.Closure-List-Sub {
  font-size: 16px;
  color: var(--color-yellow);
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
}

.Closure-List-Sub a {
  text-align: right;
  float: right;
  text-decoration: underline;
  font-weight: 400;
}

.Closure-List-Sub a:hover {
  color: var(--color-yellow) !important;
}

.Closure-List-Table {
  width: 100%;
  margin: 0 0 10px;
}

.Closure-List-Table-Row {
  overflow: hidden;
}

.Closure-List-Table-Row .Left {
  width: 45%;
  float: left;
}

.Closure-List-Table-Row .Right {
  width: 55%;
  float: left;
  text-align: right;
}

.Closure-List-Table-Row p {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  line-height: 24px;
}

.Closure-List-Table-Row .Left p {
  padding-right: 5px;
}

.Closure-List-Table-Row .Right p {
  padding-left: 5px;
}

.Closure-List-Button {
  margin: 0 -2.5px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Closure-List-Button-No {
  height: 32px;
}

.Closure-List-Info .Closure-List-Button {
  margin: 0 -2.5px 5px;
}

.Closure-List-Button .Half-Button {
  width: 50%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 2.5px;
}

.Closure-List-Button .Full-Button {
  width: 100%;
  margin: 0 2.5px;
}

.Closure-List-Button a,
.Closure-List-Button button {
  background-color: var(--color-white);
  color: var(--color-green);
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  display: block;
  text-transform: capitalize;
  width: 100%;
  border: 0 none;
  outline: 0;
  cursor: pointer;
}

.Closure-List-Button a:hover,
.Closure-List-Button button:hover {
  background-color: var(--color-dark-green);
  color: var(--color-white) !important;
}

.Closure-List-Info .DriverBlock {
  margin-bottom: 10px;
}

.Closure .Table td.actions .Button {
  margin: 0 3px;
}

.Closure-List-TitleImage {
  height: 25px;
  float: left;
  margin-right: 10px;
  margin-top: 1px;
}

.Closure-List-TitleImage img {
  display: block;
  max-width: 100%;
  height: 100%;
}

.Closure-Fullday .Row,
.Closure-Scheduled .Row {
  background-color: #00b9e8;
}

.Closure-Fullday .Closure-List-Button a,
.Closure-Fullday .Closure-List-Button button,
.Closure-Scheduled .Closure-List-Button a,
.Closure-Scheduled .Closure-List-Button button {
  color: #00b9e8;
}

.Closure-Fullday .Closure-List-Button a:hover,
.Closure-Fullday .Closure-List-Button button:hover,
.Closure-Scheduled .Closure-List-Button a:hover,
.Closure-Scheduled .Closure-List-Button button:hover {
  background-color: #0588a9;
  color: var(--color-white);
}

.Closure-List-Info {
  min-height: 260px;
  position: relative;
}

.NoDriverFound {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.NoDriverFound i {
  color: #307b21 !important;
}

.NoDriverFound p {
  color: #307b21;
}

.Closure-Tabs-Table .Closure-DriverInfo .NoDriverFound {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 96px;
  width: 100%;
}

.Closure-Tabs-Table .Closure-DriverInfo .NoDriverFound p {
  color: rgba(0, 0, 0, 0.4);
}

.Closure-Fullday .NoDriverFound i,
.Closure-Scheduled .NoDriverFound i {
  color: #0588a9 !important;
}

.Closure-Fullday .NoDriverFound p,
.Closure-Scheduled .NoDriverFound p {
  color: #0588a9;
}

.Closure-List-Button.With-Icon {
  background-color: var(--color-white);
  border-radius: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  min-height: 75px;
  margin: 0;
}

.Closure-List-Button.With-Icon .Half-Button:first-of-type {
  border-right: 1px solid #ddd;
}

.Closure-List-Button.With-Icon > i {
  display: block;
  margin-bottom: 10px;
}

.Closure-List-Button.With-Icon a,
.Closure-List-Button.With-Icon button {
  color: var(--color-gray-xxd);
  line-height: 16px;
  font-weight: 400;
  max-width: 140px;
  margin: auto;
}

.Closure-List-Button.With-Icon b {
  display: block;
}

.Ban-Icon {
  position: relative;
}

.Ban-Icon i.close {
  position: absolute;
  top: 6px;
  left: 14px;
  right: 0;
  margin: auto;
  background-color: #fff;
  border: 1px solid var(--color-gray);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 10px;
}

.Closure-List-Button.With-Icon a:hover {
  background-color: var(--color-white) !important;
  color: var(--color-gray-xxd) !important;
  opacity: 0.8;
}

td.Closure-DriverInfo > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DriverBlock-Button {
  max-width: 240px;
}

.Closure-Tabs-Table .DriverInfo-Button .Closure-List-Button a,
.DriverBlock-Button .Closure-List-Button.With-Icon {
  border: 1px solid #ddd;
}

.Closure-Tabs-List .DriverInfo-Chat,
.Closure-Tabs-Table .DriverBlock {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Closure-Tabs-List .DriverInfo-Chat {
  position: relative;
}

.Closure-Tabs-Table .DriverInfo-Chat {
  position: relative;
  width: 200px;
}

.DriverInfo-Chat-Group {
  position: absolute;
  top: 3px;
  right: 0;
}

.Closure-Tabs-Table .DriverInfo-Chat-Group {
  right: 20px;
}

.DriverInfo-Chat-Group span {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: var(--color-yellow);
  font-size: 10px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  color: var(--color-black);
}

.Ban-Icon-active b,
.Ban-Icon-active i,
.Ban-Icon-active {
  color: var(--color-red) !important;
}

.Ban-Icon-active i.close {
  border-color: var(--color-red) !important;
}

.Favorite-Icon-active b,
.Favorite-Icon-active i,
.Favorite-Icon-active {
  color: var(--color-green) !important;
}

.DriverInfo-Button .Closure-List-Button .Half-Button {
  padding: 0;
  width: auto;
}

.DriverInfo-Button a {
  white-space: nowrap;
  padding: 0 5px;
  min-width: 94px;
  box-sizing: border-box;
}

.Closure-DriverInfo .DriverBlock-Button {
  min-width: 240px;
}

.Table .Closure-Center {
  padding: 15px 5px;
}

.Closure-List .DriverBlock .DriverInfo h3 {
  white-space: nowrap;
}

.Closure-List-Tab-List.vertical-scroll::-webkit-scrollbar {
  width: 4px;
}

.Closure-List-Tab-List.vertical-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.Closure-List-Tab-List.vertical-scroll::-webkit-scrollbar-thumb {
  background-color: var(--color-white);
}

.Closure-List-Tabs {
  border: 0 none !important;
  background: transparent;
  border-radius: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.Closure-List-Tabs .Closure-List-Button a:hover,
.Closure-List-Tabs .Closure-List-Button button:hover {
  background-color: var(--color-green);
}

.Closure-Fullday .Closure-List-Tabs .Closure-List-Button a:hover,
.Closure-Fullday .Closure-List-Tabs .Closure-List-Button button:hover,
.Closure-Scheduled .Closure-List-Tabs .Closure-List-Button a:hover,
.Closure-Scheduled .Closure-List-Tabs .Closure-List-Button button:hover {
  background-color: #00b9e8;
}

.Closure-List-Tabs-Actions {
  background: transparent;
  border: 0 none !important;
  padding: 0 15px !important;
  margin-bottom: -1px !important;
}

.Closure-List-Tabs-Actions li {
  margin: 0 !important;
  width: 50%;
  border: 0 none !important;
  background: transparent !important;
}

.Closure-List-Tabs-Actions a {
  outline: 0;
  display: block;
  margin: 0 2.5px;
  float: none !important;
  cursor: pointer !important;
  color: var(--color-yellow) !important;
  text-align: center;
  position: relative;
  border-radius: var(--radius) var(--radius) 0 0;
}

.Closure-List-Tabs-Actions a span {
  min-width: 12px;
  min-height: 12px;
  font-size: 9px;
  display: inline-block;
  background-color: var(--color-yellow);
  border-radius: 50%;
  color: #333;
  line-height: 14px;
  top: 5px;
  position: absolute;
  margin-left: 5px;
  padding: 0 1px;
}

.Closure-Fullday .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Scheduled .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: #077490 !important;
  border-radius: var(--radius) var(--radius) 0 0;
}

.Closure-List-Tabs-Content {
  padding: 0 !important;
  height: 250px;
  min-height: 250px;
  position: relative;
}

.Closure-List-Tab-Block {
  padding: 10px 17px;
  height: 54px;
  box-sizing: border-box;
  display: flex;
}

.Closure-List-Tab-Block .DriverBlock .DriverInfo {
  padding: 0 10px;
}

.Closure-List.chat .Avatar {
  width: 86px;
  height: 86px;
}

.Closure-List-Tab-Block .DriverBlock .Avatar {
  width: 32px;
  height: 32px;
}

.Closure-List-Tab-Block .DriverInfo h3 {
  margin: 0;
  color: var(--color-white);
  font-size: 14px;
}

.Closure-List.chat .DriverInfo i.gray,
.Closure-Tabs-List .DriverInfo i.gray {
  color: var(--color-white);
}

.Closure-List-Tab-Block .DriverInfo .RatingStars {
  height: 18px;
}

.Closure-List-Tab-List {
  padding: 0 9px 0 14px;
  position: absolute;
  top: 54px;
  left: 3px;
  right: 3px;
  bottom: 40px;
}

.Closure-List-Tab-List ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Closure-List-Tab-List li {
  margin-bottom: 10px;
  overflow: hidden;
}

.Closure-List-Tab-List li > div {
  background-color: #e8e8e8;
  border-radius: 4px;
  position: relative;
  padding: 8px;
  font-size: 12px;
  margin: 0 8px;
  width: 60%;
}

.Closure-List-Tab-List li > div:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #e8e8e8;
}

.Closure-List-Tab-List li.client > div:before {
  left: -8px;
}

.Closure-List-Tab-List li.me > div {
  background-color: #deefd8;
  float: right;
}

.Closure-List-Tab-List li.me > div:before {
  right: -8px;
  border-top: 10px solid #deefd8;
}

.Closure-List-Tab-List li > div span {
  display: inline-block;
  word-break: break-word;
}

.Closure-List-Tab-List li > div span.full {
  padding-bottom: 15px;
}

.Closure-List-Tab-List li > div sup {
  position: absolute;
  right: 8px;
  bottom: 8px;
  color: #999;
}

.Closure-List-Tab-List li.me > div sup {
  right: 25px;
}

.Closure-List-Tab-List li.me > div i {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 12px;
  height: 12px;
  font-size: 13px;
}

.Closure-List-Tab-Bottom {
  position: absolute;
  bottom: 3px;
  left: 3px;
  right: 3px;
}

.Closure-List-Tab-Bottom textarea {
  width: 100%;
  resize: none;
  display: block;
  height: 32px;
  line-height: 28px;
  border: 0 none;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 5px;
  font-size: 12px;
  padding-right: 32px;
  outline: none;
  overflow-y: hidden;
}

.Closure-List-Tab-Bottom i {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  color: silver;
  cursor: pointer;
  z-index: 2;
}

.Closure-List.expand .Closure-List-Tabs {
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  z-index: 2;
}

.Closure-List.expand .Closure-List-Tabs-Content {
  max-height: 484px;
}

.Closure-List-Tabs-Bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
}

.Closure-List.expand .Closure-List-Tabs-Actions a {
  background-color: #cecece;
  color: #5a5a5a !important;
}

.Closure-List.expand .Row {
  height: 100%;
}

.Closure-List-Tab-List-Loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
}

.Closure-List-Tab-List-Loader .Loader-Icon {
  width: 80px;
  height: 80px;
}

.Closure-List-Tab-List-Loader .Loader-Icon:after {
  width: 58px;
  height: 58px;
}

.Closure-List-Tab-List-Loader .Loader-Icon img {
  height: 48px;
}

.Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: var(--color-dark-green) !important;
  color: var(--color-white) !important;
}

@media (min-width: 1336px) {
  .Closure-List-TitleImage {
    margin-right: 10px;
  }
  .Closure-List-TitleInfo {
    font-size: 18px;
  }
}

@media (min-width: 1191px) and (max-width: 1335px) {
  .Closure-List-TitleImage {
    margin-right: 5px;
  }
  .Closure-List-TitleInfo {
    font-size: 17px;
  }
}

@media (max-width: 1192px) {
  .Closure-List {
    width: 33.3333%;
  }
  .Closure-List-TitleImage {
    margin-right: 5px;
  }
  .Closure-List-TitleInfo {
    font-size: 16px;
  }
}

.BannerStatus {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10000;
}

.BannerStatus .Status:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  top: -10px;
}

.BannerStatus.top.left {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -45px;
  top: -10px;
}

.BannerStatus.top.left .Status {
  padding: 16px 28px 15px;
}

.BannerStatus.top.left .Status:before {
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 70px solid var(--color-dark-gray);
}

.BannerStatus.top.right .Status:before {
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-top: 70px solid var(--color-dark-gray);
}

.BannerStatus.bottom.left .Status:before {
  border-top: 70px solid transparent;
  border-bottom: 70px solid transparent;
  border-right: 70px solid var(--color-dark-gray);
}

.BannerStatus.bottom.right .Status:before {
  border-top: 70px solid transparent;
  border-bottom: 70px solid transparent;
  border-left: 70px solid var(--color-dark-gray);
}

.BannerStatus > .Status {
  color: var(--color-white);
  width: 83px;
  height: 40px;
  font-size: 12px;
  line-height: 12px;
}

.BannerStatus.top.left > .Satus:before {
  border-bottom-color: var(--color-dark-gray);
}

.BannerStatus.top.right > .Satus:before {
  border-top-color: var(--color-dark-gray);
}

.BannerStatus.bottom.left > .Satus:before {
  border-right-color: var(--color-dark-gray);
}

.BannerStatus.bottom.right > .Satus:before {
  border-left-color: var(--color-dark-gray);
}

.BannerStatus.top.left > .green:before {
  border-bottom-color: var(--color-green);
}

.BannerStatus.top.right > .green:before {
  border-top-color: var(--color-green);
}

.BannerStatus.bottom.left > .green:before {
  border-right-color: var(--color-green);
}

.BannerStatus.bottom.right > .green:before {
  border-left-color: var(--color-green);
}

.BannerStatus.top.left > .red:before {
  border-bottom-color: var(--color-red);
}

.BannerStatus.top.right > .red:before {
  border-top-color: var(--color-red);
}

.BannerStatus.bottom.left > .red:before {
  border-right-color: var(--color-red);
}

.BannerStatus.bottom.right > .red:before {
  border-left-color: var(--color-red);
}

.BannerStatus.top.left > .blue:before {
  border-bottom-color: var(--color-blue);
}

.BannerStatus.top.right > .blue:before {
  border-top-color: var(--color-blue);
}

.BannerStatus.bottom.left > .blue:before {
  border-right-color: var(--color-blue);
}

.BannerStatus.bottom.right > .blue:before {
  border-left-color: var(--color-blue);
}

.DetailBooking-Section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
}

.DetailBooking-Box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 20px;
  box-sizing: border-box;
}

.DetailBooking-Box.Right {
  -webkit-box-flex: inherit;
  -ms-flex: inherit;
  flex: inherit;
  width: 370px;
  padding: 0 0 0 20px;
}

.DetailBooking-Attachments > h5,
.DetailBooking-Group > h5,
.DetailBooking-List > h5 {
  margin: 0;
  font-size: var(--font-size-s);
  color: var(--color-gray);
  text-transform: uppercase;
}

.DetailBooking-FormGroup {
  padding: 10px;
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  margin: 10px 0 20px;
}

.DetailBooking-FormGroup p {
  margin: 0;
}

.DetailBooking-FormGroup .sub-account-tag {
  margin: 15px 0px 0px;
}

.DetailBooking-FormGroup .sub-account-tag {
  margin: 15px 0px 0px;
}

.DetailBooking-FormGroup .sub-account-tag:first-child {
  margin-top: 0px;
}

.DetailBooking-FormGroup .DriverBlock .DriverInfo h3 {
  font-size: var(--font-size);
}

.DetailBooking-FormGroup .RatingStars {
  height: 18px;
  margin-top: -2px;
}

.DetailBooking-FormGroup table {
  width: 100%;
}

.DetailBooking-FormGroup table td {
  padding: 5px 0;
}

.DetailBooking-FormGroup table td:last-child {
  text-align: right;
}

.DetailBooking-FormGroup-Custom {
  padding-bottom: 0;
}

.DetailBooking-FormGroup-Custom-Button {
  max-width: 100% !important;
  margin: 0 0 10px !important;
  padding: 0 !important;
}

.DetailBooking-FormGroup-Custom > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DetailBooking-FormGroup-Custom > div .DriverBlock {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 10px;
}

.DetailBooking-FormGroup-Custom > div .DetailBooking-FormGroup-Button {
  -webkit-box-flex: inherit;
  -ms-flex: inherit;
  flex: inherit;
  width: 100px;
}

.DetailBooking-FormGroup-Button .Button {
  width: 100px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  height: auto;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
}

.DetailBooking-FormGroup-Button .Button:last-child {
  margin-bottom: 0;
}

.DetailBooking-FormGroup-Row {
  margin: 0 -10px;
  padding: 10px;
}

.DetailBooking-FormGroup-Row > div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: var(--font-size);
}

.DetailBooking-Price ul {
  list-style: none;
  padding: 0 0 0 10px;
  margin: 10px 0;
}

.DetailBooking-Price li {
  margin-bottom: 10px;
}

.DetailBooking-Price > div label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.DetailBooking-Price label {
  text-transform: capitalize;
}

.DetailBooking-Price-Noted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}

.DetailBooking-Price-Noted p {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: var(--font-size);
  padding-right: 15px;
}

.DetailBooking-Price-Noted a {
  width: 100px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  height: auto;
  border-radius: 4px;
  font-size: 12px;
}

.DetailBooking-Price-Total {
  margin: 10px 0;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DetailBooking-Price-Total,
.line-booking {
  border-top: 1px solid var(--color-gray-xl);
}

.line-booking {
  height: 1px;
}

.DetailBooking-Price-Total + label {
  margin-bottom: 10px;
  display: block;
}

.DetailBooking-Price-Total h3 {
  margin: 0;
  font-size: var(--font-size-xxb);
  color: var(--color-green);
}

.DetailBooking-Map {
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.DetailBooking-Map-Imgage img {
  margin: auto;
  display: block;
  width: 100%;
}

.DetailBooking-Attachments > h5,
.DetailBooking-List > h5 {
  margin: 15px 0 5px;
}

.DetailBooking-List .Table td {
  padding: 10px;
}

.DetailBooking--Locations > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DetailBooking--Locations-Group {
  margin-left: 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.DetailBooking--Distance,
.DetailBooking--Parking,
.DetailBooking--Photos,
.DetailBooking--Signatures,
.DetailBooking--Time,
.DetailBooking--Tolls {
  text-align: center !important;
  box-sizing: border-box;
}

.DetailBooking--Time label {
  white-space: nowrap;
}

.DetailBooking-List thead .DetailBooking--Signatures {
  padding: 0;
}

.DetailBooking-FormGroup-Bg {
  background-color: #fffada;
}

.DetailBooking-FormGroup-Bg.DetailBooking-FormGroup-Row {
  border-radius: 0 0 var(--radius) var(--radius);
}

.DetailBooking--ImageViewer img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.DetailBooking--ImageViewer .detail-attachment img {
  max-height: unset;
  object-fit: cover;
}

.DetailBooking--Photos-List {
  width: 86px;
  margin: 0 -9px;
}

.DetailBooking--Photos-List .DetailBooking--ImageViewer {
  width: 24px;
  margin: 0 2px 2px;
  height: 20px !important;
  float: left;
}
.DetailBooking--ImageViewer {
  img.img-cover {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.DetailBooking--ImageViewer {
  width: 100%;
  height: 46px;
  position: relative;
}

.DetailBooking-ImageView {
  padding-top: 10px;
}

.DetailBooking-ImageView .DetailBooking--ImageViewer {
  float: left;
  width: 180px;
  height: 100px;
  border-radius: var(--radius);
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.DetailBooking .PageHead-actions .Button {
  width: 140px;
}

.DetailBooking-Map-Share {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10000;
}

.DetailBooking-Map-Share a {
  width: 130px;
}

.Employee {
  background: #fff;
  padding-bottom: 30px;
}

.Employee .ItemAddingBlock {
  display: block;
}

.Employee-search {
  padding: 10px;
  height: auto;
}

.Employee-search,
.Employee-search .col,
.Employee-search .col-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Employee-search .col {
  margin-right: 10px;
}

.Employee-search .col-custom {
  margin-right: 40px;
}

.Employee-search label {
  margin-right: 20px;
  font-size: var(--length-xxxs);
  color: var(--color-gray-d);
}

.Employee .address input,
.Employee .email input,
.Employee .note input {
  width: 356px;
}

.Employee .Table tbody td.actions {
  text-align: right;
  width: 150px;
}

.Wallet {
  background: #fff;
}

.Wallet-search {
  padding: 10px;
  height: auto;
}

.Wallet-search,
.Wallet-search .col,
.Wallet-search .col-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Wallet-search .col {
  margin-right: 10px;
}

.Wallet-search .col-custom {
  margin-right: 40px;
}

.Wallet-search label {
  margin-right: 20px;
  font-size: var(--length-xxxs);
  color: var(--color-gray-d);
}

.Wallet .address input,
.Wallet .email input,
.Wallet .note input {
  width: 356px;
}

.Wallet .Filter .FormGroup label {
  text-transform: uppercase;
}

.Dashboard {
  padding: 20px 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Dashboard-Item {
  width: auto;
  padding: 20px;
  background: var(--color-green);
  color: var(--color-white);
  border-radius: var(--radius);
  margin-right: 20px;
}

.Dashboard-Item .Number {
  font-size: var(--font-size-xxb);
}

.LoginBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.LoginBox .Box {
  width: 420px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 80px 0 40px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.LoginBox .Box-Head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-green);
}

.LoginBox .Box-Head h3 {
  padding: 10px 0;
  margin: 0;
  text-align: center;
}

.LoginBox .Box-Head .Logo img {
  width: 48px;
  height: auto;
}

.LoginBox .Box .Form {
  padding: 0 20px;
}

.LoginBox .Box .FormGroup {
  padding: 0;
  margin: 0 0 10px;
}

.LoginBox .Box-Actions {
  padding: 5px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.LoginBox .Box-Actions.copyright {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.LoginBox .Box-Actions.copyright h5 {
  color: var(--color-gray);
  font-weight: 400;
  margin: 5px 0 10px;
}

.LoginBox .Box-Actions.copyright a {
  text-decoration: underline;
  color: var(--color-green);
}

.LoginBox .Box-Actions.copyright .Logo img {
  width: auto;
  height: 24px;
  margin: 10px 0 0;
}

.LoginBox .Box-Actions .Button {
  margin: 0 10px;
}

.SelectAccountBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.SelectAccountBox .Box {
  width: 420px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.SelectAccountBox .Box-List-Item {
  padding: 10px 20px 10px 10px;
}

.SelectAccountBox .Box-List-Item.title {
  padding: 5px 10px;
  background: var(--color-gray-xxl);
}

.SelectAccountBox .Box-List-Item:hover {
  cursor: pointer;
  background: var(--color-gray-xxxl);
}

.SelectAccountBox .Box-List-Item .TitleSubtitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}

.SelectAccountBox .Box-Head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-green);
}

.SelectAccountBox .Box-Head h3 {
  padding: 0 0 10px;
  margin: 0;
  text-align: center;
}

.SelectAccountBox .Box-Head p {
  padding: 0;
  margin: 20px 0 0;
  color: var(--color-dark-gray);
}

.SelectAccountBox .Box-Head .Logo img {
  width: 48px;
  height: auto;
}

.Login {
  background-size: cover;
  background-position: bottom;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.Login.fullscreen {
  top: 0;
  z-index: 100002;
}

.Login .LoginBox,
.Login .SelectAccountBox {
  background: rgba(0, 0, 0, 0.6);
}

.Login .FormGroup .checkbox-item .Checkbox input {
  margin-left: 0;
}

.Login .FormGroup .checkbox-item label {
  margin-top: 0 !important;
}

.SignupBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.SignupBox .Box {
  width: 632px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 80px 0 40px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.SignupBox .Box-Head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-green);
}

.SignupBox .Box-Head a {
  color: var(--color-green);
  text-decoration: underline;
}

.SignupBox .Box-Head h3 {
  padding: 10px 0;
  margin: 0;
  text-align: center;
}

.SignupBox .Box-Head p {
  color: var(--color-gray);
  padding: 0;
  margin: 0 0 5px;
  font-size: var(--font-size-s);
}

.SignupBox .Box-Head .Logo img {
  width: 48px;
  height: auto;
}

.SignupBox .Box .Form {
  padding: 0 20px;
}

.SignupBox .Box .FormGroup {
  padding: 0;
  margin: 0 0 10px;
}

.SignupBox .Box-Actions {
  padding: 5px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.SignupBox .Box-Actions.copyright {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.SignupBox .Box-Actions.copyright h5 {
  color: var(--color-gray);
  font-weight: 400;
  margin: 5px 0 10px;
}

.SignupBox .Box-Actions.copyright a {
  color: var(--color-green);
}

.SignupBox .Box-Actions.copyright .Logo img {
  width: auto;
  height: 24px;
  margin: 10px 0 0;
}

.SignupBox .Box-Actions .Button {
  margin: 0 10px;
}

.SignupBox .Box-Actions p {
  margin: 0 10px;
  color: var(--color-gray);
  font-size: var(--font-size-s);
  text-align: center;
}

.Signup {
  background-size: cover;
  background-position: bottom;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.Signup.fullscreen {
  top: 0;
  z-index: 100002;
}

.Signup .SelectAccountBox,
.Signup .SignupBox {
  background: rgba(0, 0, 0, 0.6);
}

.SelectCityBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.SelectCityBox .Box {
  width: 420px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.SelectCityBox .Box-List-Item {
  padding: 10px 20px 10px 10px;
}

.SelectCityBox .Box-List-Item.title {
  padding: 5px 10px;
  background: var(--color-gray-xxl);
}

.SelectCityBox .Box-List-Item:hover {
  cursor: pointer;
  background: var(--color-gray-xxxl);
}

.SelectCityBox .Box-List-Item .TitleSubtitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}

.SelectCityBox .Box-Head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-green);
}

.SelectCityBox .Box-Head h3 {
  padding: 0 0 10px;
  margin: 0;
  text-align: center;
}

.SelectCityBox .Box-Head p {
  padding: 0;
  margin: 20px 0 0;
  color: var(--color-dark-gray);
}

.SelectCity {
  background-size: cover;
  background-position: bottom;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
}

.SelectCity.fullscreen {
  top: 0;
  z-index: 100002;
}

.SelectCity .SelectCityBox {
  background: rgba(0, 0, 0, 0.6);
}

.SelectCity .SelectCityBox .Avatar {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.nav-links,
.StyleGuideLayout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav-links {
  width: 200px;
}

.page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Social {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: var(--height);
  font-size: var(--font-size);
  padding: 0 15px;
  border: 0 none;
  border-radius: var(--radius);
  color: #fff;
  outline: none;
}

.Social:hover {
  cursor: pointer;
}

.Social.s {
  height: var(--height-xs);
  padding: 0 5px;
  font-size: var(--font-size-xs);
  border-radius: var(--radius-xs);
}

.Social.s img {
  height: 10px;
  margin-right: 5px;
}

.Social.b {
  height: var(--height-s);
  padding: 0 10px;
  font-size: var(--font-size-s);
  border-radius: var(--radius-s);
}

.Social.b img {
  height: 10px;
  margin-right: 10px;
}

.Social.xb {
  height: var(--height-t);
  padding: 0 20px;
  font-size: var(--font-size-b);
}

.Social.xb img {
  height: 20px;
  margin-right: 20px;
}

.Social.facebook {
  background-color: #39579a;
}

.Social.facebook:hover {
  background-color: #304a84;
}

.Social.google {
  background-color: #e3481f;
}

.Social.google:hover {
  background-color: #ca421e;
}

.Social img {
  height: 15px;
  margin-right: 15px;
}

.ButtonGroup {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 10px;
}

.ButtonGroup > .Button:nth-child(n) {
  margin: 0;
  margin-left: -1px;
}

.ButtonGroup > .Button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ButtonGroup > .Button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ButtonGroup > .Button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.Tooltip-content {
  visibility: hidden;
  position: fixed;
  display: block;
  font-size: 0.75rem;
  line-height: 1.4;
}

.Tooltip:nth-child(n) {
  margin: 0;
}

.Tooltip-top {
  margin-top: -0.1875rem;
  padding: 0.3125rem 0;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.Tooltip-bottom {
  margin-top: 0.1875rem;
  padding: 0.3125rem 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.Tooltip-left {
  margin-left: -0.1875rem;
  padding: 0 0.3125rem;
  -webkit-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.Tooltip-right {
  margin-left: 0.1875rem;
  padding: 0 0.3125rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.Tooltip-top .Tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 5px 5px 0;
}

.Tooltip-top.Tooltip-gray .Tooltip-arrow {
  border-top-color: var(--color-gray);
}

.Tooltip-top.Tooltip-blue .Tooltip-arrow {
  border-top-color: var(--color-blue);
}

.Tooltip-top.Tooltip-green .Tooltip-arrow {
  border-top-color: var(--color-green);
}

.Tooltip-top.Tooltip-yellow .Tooltip-arrow {
  border-top-color: var(--color-yellow);
}

.Tooltip-top.Tooltip-red .Tooltip-arrow {
  border-top-color: var(--color-red);
}

.Tooltip-bottom .Tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0 5px 5px;
}

.Tooltip-bottom.Tooltip-gray .Tooltip-arrow {
  border-bottom-color: var(--color-gray);
}

.Tooltip-bottom.Tooltip-blue .Tooltip-arrow {
  border-bottom-color: var(--color-blue);
}

.Tooltip-bottom.Tooltip-green .Tooltip-arrow {
  border-bottom-color: var(--color-green);
}

.Tooltip-bottom.Tooltip-yellow .Tooltip-arrow {
  border-bottom-color: var(--color-yellow);
}

.Tooltip-bottom.Tooltip-red .Tooltip-arrow {
  border-bottom-color: var(--color-red);
}

.Tooltip-left .Tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.3125rem;
  border-width: 5px 0 5px 5px;
}

.Tooltip-left.Tooltip-gray .Tooltip-arrow {
  border-left-color: var(--color-gray);
}

.Tooltip-left.Tooltip-blue .Tooltip-arrow {
  border-left-color: var(--color-blue);
}

.Tooltip-left.Tooltip-green .Tooltip-arrow {
  border-left-color: var(--color-green);
}

.Tooltip-left.Tooltip-yellow .Tooltip-arrow {
  border-left-color: var(--color-yellow);
}

.Tooltip-left.Tooltip-red .Tooltip-arrow {
  border-left-color: var(--color-red);
}

.Tooltip-right .Tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.3125rem;
  border-width: 5px 5px 5px 0;
}

.Tooltip-right.Tooltip-black .Tooltip-arrow {
  border-right-color: #000;
}

.Tooltip-right.Tooltip-gray .Tooltip-arrow {
  border-right-color: var(--color-gray);
}

.Tooltip-right.Tooltip-blue .Tooltip-arrow {
  border-right-color: var(--color-blue);
}

.Tooltip-right.Tooltip-green .Tooltip-arrow {
  border-right-color: var(--color-green);
}

.Tooltip-right.Tooltip-yellow .Tooltip-arrow {
  border-right-color: var(--color-yellow);
}

.Tooltip-right.Tooltip-red .Tooltip-arrow {
  border-right-color: var(--color-red);
}

.Tooltip-inner {
  max-width: 12.5rem;
  padding: 0.1875rem 0.5rem;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 0.25rem;
}

.Tooltip-black .Tooltip-inner {
  background-color: #000;
}

.Tooltip-gray .Tooltip-inner {
  background-color: var(--color-gray);
}

.Tooltip-blue .Tooltip-inner {
  background-color: var(--color-blue);
}

.Tooltip-green .Tooltip-inner {
  background-color: var(--color-green);
}

.Tooltip-yellow .Tooltip-inner {
  background-color: var(--color-yellow);
}

.Tooltip-red .Tooltip-inner {
  background-color: var(--color-red);
}

.styleguide {
  padding: 0 20px;
  width: 100%;
}

.styleguide h1 {
  margin: 30px 0 10px;
  font-size: 36px;
}

.styleguide h1,
.styleguide h2 {
  padding: 0;
  text-transform: uppercase;
}

.styleguide h2 {
  margin: 50px 0 0;
  font-size: 24px;
}

.styleguide h3 {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 30px;
  color: #ddd;
  font-weight: 400;
  text-transform: uppercase;
}

.styleguide section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 30px 0 40px;
}

.styleguide section + section {
  padding: 0 0 40px;
}

.ButtonStyle .Button,
.Social {
  margin: 10px 20px 10px 0;
}

.Select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--height);
  border: 1px solid var(--color-gray-xl);
  border-radius: var(--radius);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.Select .Dropdown {
  padding: 0 10px 0 15px;
}

.FormStyle {
  padding: 30px;
}

.FormStyle .ButtonStyle .Button {
  margin: 10px 20px 10px 0;
}

.FormStyle .Input {
  width: 400px;
}

.FormStyle .TextArea textarea {
  min-width: 400px;
}

.FormStyle .checkbox-radio-item {
  width: 100%;
}

.FormStyle .checkbox-radio-item,
.FormStyle .checkbox-radio-item label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.FormStyle .checkbox-radio-item label {
  padding: 0 5px;
  line-height: 1;
}

.FormStyle .Select {
  margin-right: 20px;
}

.FormStyle .address-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 400px;
}

.FormStyle .address-input .Pin {
  margin-right: 10px;
}

.FormStyle .address-input .Input {
  width: 300px;
}

.BookingStyle .Pin,
.BookingStyle .Status {
  margin-right: 20px;
}

.BookingStyle {
  padding: 30px;
}

body {
  font-family: var(--font-family-base);
  padding: 0;
  margin: 0;
  overflow: auto;
}

button {
  cursor: pointer;
  padding: 0;
  outline: 0;
}

button:disabled {
  color: #4a4a4a;
  background: #a5a5a5;
}

a {
  color: inherit;
  text-decoration: none;
}

a:not(.no-hover):hover {
  color: var(--color-green);
}

select {
  height: var(--height);
  padding: 0 10px;
  background: var(--color-white);
  border: 1px solid #a9a9a9;
}

input,
textarea {
  font-family: var(--font-family-base);
}

.vertical-scroll {
  overflow-y: auto;
}

.vertical-scroll::-webkit-scrollbar {
  width: 8px;
}

.vertical-scroll::-webkit-scrollbar-track {
  background-color: #ddd;
}

.vertical-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: none;
  border-radius: 0;
}

.horizontal-scroll {
  overflow-x: auto;
}

.horizontal-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background-color: #ddd;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: none;
  border-radius: 0;
}

.dropoff-items {
  margin: 0 -20px;
  padding: 15px 10px;
  border-bottom: 1px solid #cecece;
}

.dropoff-items:first-child {
  margin-top: -15px;
  padding-bottom: 15px;
}

.dropoff-items:last-child {
  margin-bottom: 0;
  border-bottom: 0 none;
}

.dropoff-custom {
  padding-bottom: 0 !important;
}

.ui-datepicker {
  width: auto;
}

.ui-datepicker-calendar {
  padding: 0 4px 4px;
  margin-top: -4px;
  display: block;
  box-sizing: border-box;
}

.ui-datepicker.ui-widget.ui-widget-content:not(.datepicker-common):not(.datepicker-batch) {
  border: 1px solid var(--color-green);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: var(--radius);
  font-size: 13px;
  background: var(--color-green);
  margin-top: 10px;
}

.ui-datepicker.ui-widget.ui-widget-content:not(.datepicker-common):not(.datepicker-batch):before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--color-green);
  position: absolute;
  top: -6px;
  left: 10px;
  display: block;
}

.ui-datepicker .ui-widget-header {
  border: 0 none;
  background: transparent;
  font-weight: 400;
  color: #fff;
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  width: 36px;
  height: 30px;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 4px;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-prev:hover {
  border: 0 none !important;
}

.ui-datepicker .ui-datepicker-prev {
  left: 4px;
}

.ui-datepicker .ui-datepicker-next {
  right: 4px;
}

.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
  margin: 0;
  top: 0;
  left: 0;
  background: none !important;
  text-indent: 0 !important;
  color: #fff;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  width: auto;
  height: auto;
}

.ui-datepicker .ui-datepicker-title {
  height: 30px;
  font-size: 13px;
  margin: 1px 40px 0;
  line-height: 30px;
  font-weight: 700;
}

.ui-datepicker .ui-datepicker-title:hover {
  background-color: var(--color-dark-green);
}

.ui-datepicker .ui-datepicker-calendar thead th {
  font-size: 13px;
  font-weight: 700;
}

.ui-datepicker .ui-button:hover,
.ui-datepicker .ui-state-focus,
.ui-datepicker .ui-state-hover {
  border: 1px solid var(--color-dark-green);
  background: var(--color-dark-green);
  font-weight: 400;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: 400;
  border: 0;
  color: #fff;
}

.ui-datepicker .ui-button,
.ui-datepicker .ui-button.ui-state-disabled:active,
.ui-datepicker .ui-button.ui-state-disabled:hover,
.ui-datepicker .ui-state-default {
  border: 0 none !important;
  background: transparent !important;
  font-weight: 400 !important;
  font-size: 12px;
  color: #fff !important;
  padding: 8px 10px !important;
  border-radius: var(--radius);
  text-align: center !important;
}

.ui-datepicker.ui-widget-content .ui-state-default.ui-state-hover {
  background: #0e730f !important;
}

#ui-datepicker-div .ui-state-active {
  background: #ffdb00 !important;
  color: #027400 !important;
}

#ui-datepicker-div .ui-state-active:before {
  border-bottom-color: #fff;
}

.ui-datepicker .ui-state-highlight {
  color: #ffdb00 !important;
  position: relative;
}

.ui-datepicker .ui-state-highlight:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ffdb00;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.ui-datepicker .ui-priority-secondary {
  color: var(--color-dark-green) !important;
  opacity: 1;
}

.ui-datepicker .ui-priority-secondary:hover {
  color: var(--color-white) !important;
  background-color: var(--color-dark-green) !important;
}

#ui-datepicker-div .ui-state-active.ui-priority-secondary {
  color: var(--color-white) !important;
}

.datepicker-batch-bg,
.datepicker-common-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 100000;
  display: none;
}

.ui-widget-content.datepicker-batch,
.ui-widget-content.datepicker-common {
  z-index: 100001 !important;
  left: 0 !important;
  top: 0 !important;
  right: 0;
  bottom: 0;
  max-width: 260px;
  max-height: 400px;
  margin: auto;
  box-shadow: none;
  background-color: transparent;
  border: 0 none;
}

.datepicker-batch .ui-widget-header,
.datepicker-common .ui-widget-header {
  border-radius: var(--radius) var(--radius) 0 0;
}

.ui-widget-content.datepicker-batch .ui-datepicker-calendar,
.ui-widget-content.datepicker-batch .ui-datepicker-header,
.ui-widget-content.datepicker-batch .ui-timepicker-div,
.ui-widget-content.datepicker-common .ui-datepicker-calendar,
.ui-widget-content.datepicker-common .ui-datepicker-header,
.ui-widget-content.datepicker-common .ui-timepicker-div {
  background-color: #3fae29;
}

.ui-widget-content.datepicker-batch .ui-datepicker-calendar,
.ui-widget-content.datepicker-batch .ui-timepicker-div dl,
.ui-widget-content.datepicker-common .ui-datepicker-calendar,
.ui-widget-content.datepicker-common .ui-timepicker-div dl {
  margin-top: 0;
  margin-bottom: 0;
}

.ui-widget-content.datepicker-batch .ui-datepicker-buttonpane,
.ui-widget-content.datepicker-common .ui-datepicker-buttonpane {
  overflow: hidden;
  border: 0 none;
  background: transparent;
  margin: 6px -3px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  clear: both;
}

.ui-widget-content.datepicker-batch .ui-datepicker-buttonpane button,
.ui-widget-content.datepicker-common .ui-datepicker-buttonpane button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  float: none;
  margin: 0 3px;
  background-color: #cecece !important;
  color: inherit !important;
  outline: 0;
  height: 24px;
  border-radius: var(--radius-s);
  line-height: 22px;
  padding: 0 !important;
}

.ui-widget-content.datepicker-batch .ui-datepicker-buttonpane button.ui-datepicker-close,
.ui-widget-content.datepicker-common .ui-datepicker-buttonpane button.ui-datepicker-close {
  border: 1px solid #3fae29 !important;
  color: #3fae29 !important;
  background: #fff !important;
  opacity: 1;
}

.ui-widget-content.datepicker-batch .ui-datepicker-buttonpane button:hover,
.ui-widget-content.datepicker-common .ui-datepicker-buttonpane button:hover {
  background-color: #a4a4a4 !important;
  color: inherit !important;
}

.ui-widget-content.datepicker-common .ui-datepicker-buttonpane button.ui-datepicker-close:hover {
  background: #ccc !important;
  border-color: #ccc !important;
}

.ui-widget-content.datepicker-batch:before,
.ui-widget-content.datepicker-common:before {
  display: none;
}

.datepicker-batch .ui_tpicker_hour_label,
.datepicker-batch .ui_tpicker_minute_label,
.datepicker-batch .ui_tpicker_time,
.datepicker-batch .ui_tpicker_time_label,
.datepicker-batch .ui_tpicker_unit_hide,
.datepicker-common .ui_tpicker_hour_label,
.datepicker-common .ui_tpicker_minute_label,
.datepicker-common .ui_tpicker_time,
.datepicker-common .ui_tpicker_time_label,
.datepicker-common .ui_tpicker_unit_hide {
  display: none !important;
}

.datepicker-batch .ui-timepicker-div,
.datepicker-common .ui-timepicker-div {
  overflow: hidden;
  padding: 50px 0;
  border-radius: 0 0 var(--radius) var(--radius);
  text-align: center;
}

.datepicker-batch .ui_tpicker_hour,
.datepicker-batch .ui_tpicker_minute,
.datepicker-common .ui_tpicker_hour,
.datepicker-common .ui_tpicker_minute {
  display: inline-block;
  width: 40px;
  margin: 0 5px;
  box-sizing: border-box;
}

.datepicker-batch .ui_tpicker_hour,
.datepicker-common .ui_tpicker_hour {
  position: relative;
}

.datepicker-batch .ui_tpicker_hour:before,
.datepicker-common .ui_tpicker_hour:before {
  content: ":";
  position: absolute;
  color: #fff;
  z-index: 2;
  top: 50%;
  height: 20px;
  margin-top: -10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  width: 6px;
  right: -8px;
}

.datepicker-batch .ui-widget.ui-widget-content,
.datepicker-common .ui-widget.ui-widget-content {
  box-shadow: none;
  border: 0 none;
  margin: 0;
  overflow: inherit;
}

.datepicker-batch .ui-widget.ui-widget-content input,
.datepicker-common .ui-widget.ui-widget-content input {
  margin: 0;
  padding: 0;
  height: 32px;
  text-align: center;
  font-size: 20px;
  outline: 0;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-button,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-button {
  position: absolute;
  padding: 0 !important;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-button span span,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-button span span {
  color: #fff;
  background: none;
  text-indent: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  padding: 5px 0;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-button:hover,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-button:hover {
  background-color: var(--color-dark-green) !important;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-button:hover span span,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-button:hover span span {
  color: var(--color-white) !important;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-up,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-up {
  top: -37px;
}

.datepicker-batch .ui-widget.ui-widget-content .ui-spinner-down,
.datepicker-common .ui-widget.ui-widget-content .ui-spinner-down {
  bottom: -35px;
}

.datepicker-batch .ui-timepicker-div .ui-widget-content,
.datepicker-common .ui-timepicker-div .ui-widget-content {
  background: transparent;
  color: var(--color-white);
}

.mytooltip {
  padding: 5px 10px;
  border-radius: var(--radius);
  font-size: 12px;
  box-shadow: none;
  border: 1px solid #ddd;
}

.mytooltip:before {
  bottom: -6px;
  border-top: 6px solid #fff;
  z-index: 2;
}

.mytooltip:after,
.mytooltip:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  width: 0;
  height: 0;
}

.mytooltip:after {
  bottom: -7px;
  border-top: 6px solid #ddd;
  z-index: 1;
}

.default-color-noted {
  color: inherit !important;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.block-txt {
  display: block;
}

.red {
  color: var(--color-red) !important;
}

.green {
  color: var(--color-green);
}

.yellow {
  color: var(--color-yellow);
}

.blue {
  color: var(--color-blue);
}

.gray {
  color: var(--color-gray);
}

.purple {
  color: purple;
}

.orange {
  color: orange;
}

.black {
  color: var(--color-black);
}

.olive {
  color: olive;
}

.italic {
  font-style: italic;
}

.hover-underline:hover,
.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 700;
}

.pre-line {
  white-space: pre-line !important;
}

.center,
.pre-line {
  text-align: center !important;
}

.flex-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.w24 {
  width: 24px !important;
}

.w32 {
  width: 32px !important;
}

.w40 {
  width: 40px !important;
}

.wauto {
  width: auto !important;
}

.Full {
  width: 100% !important;
}

.Shorted-Text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.Pagination {
  text-align: center;
  overflow: hidden;
}

.right {
  float: right;
}

.CommonLayout {
  position: fixed;
  top: var(--menu-height);
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.CommonLayout > div {
  width: 100%;
}

.mfp-bg {
  z-index: 100000 !important;
}

.mfp-wrap {
  z-index: 100001 !important;
}

.Empty-List {
  min-height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Empty-Box {
  color: #949494;
}

.Empty-Box img {
  max-width: 140px;
  margin: auto;
  display: block;
}

.Empty-Box > span {
  display: block;
  text-align: center;
  margin: 15px 0;
  font-size: 25px;
}

.Empty-Box a,
.Empty-Box button {
  max-width: 180px;
  display: block;
  margin: auto;
  text-align: center;
  line-height: 36px;
  padding: 0;
}

.Empty-Box button {
  width: 100%;
}

.Empty-Box a:hover,
.Empty-Box button:hover {
  color: #fff;
  background-color: #0e730f;
}

.modal-Reset {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
}

.modal-Reset-Custom {
  position: relative;
}

.modal-Reset-Custom .Box {
  width: auto;
}

.modal-Reset-Custom .Box-Content {
  width: 100%;
  min-width: 370px;
  margin: 5px;
  max-width: 460px;
}

.modal-Reset-Custom .Normal-Booking-Actions {
  min-width: 380px;
  width: calc(100% + 10px);
  margin: 5px 0 0;
  box-sizing: border-box;
  max-width: 470px;
}

.step-2 .Actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.step-2:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  width: 420px;
  left: 0;
  z-index: 100011;
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ChangeAccountBox .Box {
  border-radius: 0;
  padding: 20px;
}

.ChangeAccountBox .Box p {
  text-align: center;
  margin: 0;
}

.ChangeAccountBox .Box .Button {
  margin-top: 20px;
}

.LoginBoxCustom {
  top: 0 !important;
}

#root .block-item-custom {
  border-top: 0 none !important;
  padding-top: 0 !important;
}

#root .block-sub-item-custom {
  padding: 0 0 10px 15px !important;
}

.line-booking.white {
  border-color: var(--color-white);
}

.line-booking.custom-margin {
  margin-bottom: 10px;
}

.Pricing-bock-custom {
  background: transparent !important;
  box-shadow: none !important;
  margin-top: 20px !important;
  z-index: 0;
}

.full-day-beta {
  position: absolute;
  top: 6px;
  right: -13px;
  background-color: #ffdb00;
  color: #333;
  font-size: 10px;
  padding: 1px 14px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fullday a {
  text-decoration: underline;
}

.fullday a,
.fullday a:hover {
  color: #5ffb40;
}

.fullday-custom a {
  color: #ffdb00;
}

.fullday-custom a:hover {
  color: #ffdb00;
  text-decoration: underline;
}

.Overlay-Checkbox,
.ParkingTolls-Checkbox {
  position: relative;
  height: 20px;
}

.Sub-Account-Tag-Checkbox,
.multiple-select-checkbox {
  position: relative;
  height: 18px;
  width: 18px;
}

.Overlay-Checkbox input,
.ParkingTolls-Checkbox input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  z-index: 2;
  cursor: pointer;
}
.Sub-Account-Tag-Checkbox input,
.multiple-select-checkbox input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  z-index: 2;
  cursor: pointer;
}

.Sub-Account-Tag-Checkbox label {
  padding-left: 28px;
  line-height: 18px;
  font-size: var(--font-size);
  display: block;
  white-space: nowrap;
  color: var(--color-white);
}

.multiple-select-checkbox label {
  padding-right: 10px;
  line-height: 18px;
  font-size: var(--font-size);
  display: inline-block;
  white-space: nowrap;
  color: var(--color-white);
}

.Overlay-Checkbox label,
.ParkingTolls-Checkbox label {
  padding-left: 28px;
  line-height: 20px;
  font-size: var(--font-size);
  display: block;
  white-space: nowrap;
  color: var(--color-white);
}

.Overlay-Checkbox.Green-Checkbox label,
.Sub-Account-Tag-Checkbox.Light-Green-Checkbox label,
.multiple-select-checkbox.Light-Green-Checkbox label,
.ParkingTolls-Checkbox.Green-Checkbox label {
  color: var(--color-black);
}

.Overlay-Checkbox label:before,
.ParkingTolls-Checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-white);
  border-radius: 3px;
}

.Sub-Account-Tag-Checkbox label:before,
.multiple-select-checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  border: 2px solid var(--color-white);
  border-radius: 3px;
}

.Overlay-Checkbox i,
.ParkingTolls-Checkbox i {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-green);
  line-height: 20px;
  text-align: center;
  width: 20px;
}

.Sub-Account-Tag-Checkbox i,
.multiple-select-checkbox i {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-green);
  line-height: 18px;
  text-align: center;
  width: 18px;
  height: 18px;
}

.Overlay-Checkbox input[type="checkbox"]:checked + label:before,
.Sub-Account-Tag-Checkbox input[type="checkbox"]:checked + label:before,
.multiple-select-checkbox input[type="checkbox"]:checked + label:before,
.ParkingTolls-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--color-white);
}

.Overlay-Checkbox input[type="checkbox"]:checked + label i,
.Sub-Account-Tag-Checkbox input[type="checkbox"]:checked + label i,
.multiple-select-checkbox input[type="checkbox"]:checked + label i,
.ParkingTolls-Checkbox input[type="checkbox"]:checked + label i {
  display: block;
}

.Overlay-Checkbox.With-Icon label,
.Sub-Account-Tag-Checkbox.With-Icon label,
.multiple-select-checkbox.With-Icon label,
.ParkingTolls-Checkbox.With-Icon label {
  padding-left: 20px;
}

.Overlay-Checkbox.Green-Checkbox label:before,
.Sub-Account-Tag-Checkbox.Light-Green-Checkbox label:before,
.multiple-select-checkbox.Light-Green-Checkbox label:before,
.ParkingTolls-Checkbox.Green-Checkbox label:before {
  border-color: #a9a9a9;
}

.Overlay-Checkbox.Green-Checkbox label i,
.Sub-Account-Tag-Checkbox.Light-Green-Checkbox label i,
.multiple-select-checkbox.Light-Green-Checkbox label i,
.ParkingTolls-Checkbox.Green-Checkbox label i {
  color: var(--color-white);
}

.Overlay-Checkbox.Green-Checkbox input[type="checkbox"]:checked + label:before,
.ParkingTolls-Checkbox.Green-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--color-green);
  border-color: var(--color-green);
}

.Sub-Account-Tag-Checkbox.Light-Green-Checkbox input[type="checkbox"]:checked + label:before,
.multiple-select-checkbox.Light-Green-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: #6ba85b;
  border-color: #6ba85b;
}

.Overlay-Checkbox.Disable input,
.Sub-Account-Tag-Checkbox.Disable input,
.multiple-select-checkbox.Disable input,
.ParkingTolls-Checkbox.Disable input {
  pointer-events: none;
}

.Overlay-Checkbox.Disable label,
.ParkingTolls-Checkbox.Disable label {
  color: var(--color-dark-green);
}

.Overlay-Checkbox.Green-Checkbox.Disable label,
.Overlay-Checkbox.Light-Green-Checkbox.Disable label,
.ParkingTolls-Checkbox.Green-Checkbox.Disable label {
  color: #ddd;
}

.Overlay-Checkbox.Disable label:before,
.ParkingTolls-Checkbox.Disable label:before {
  border-color: var(--color-dark-green);
}

.Overlay-Checkbox.Disable input[type="checkbox"]:checked + label:before,
.ParkingTolls-Checkbox.Disable input[type="checkbox"]:checked + label:before {
  background-color: var(--color-dark-green);
  border-color: var(--color-dark-green);
}

.Overlay-Checkbox.Green-Checkbox.Disable label:before,
.Sub-Account-Tag-Checkbox.Light-Green-Checkbox.Disable label:before,
.multiple-select-checkbox.Light-Green-Checkbox.Disable label:before,
.ParkingTolls-Checkbox.Green-Checkbox.Disable label:before {
  border-color: #ddd;
}

.Overlay-Checkbox.Green-Checkbox.Disable input[type="checkbox"]:checked + label:before,
.Sub-Account-Tag-Checkbox.Light-Green-Checkbox.Disable input[type="checkbox"]:checked + label:before,
.multiple-select-checkbox.Light-Green-Checkbox.Disable input[type="checkbox"]:checked + label:before,
.ParkingTolls-Checkbox.Green-Checkbox.Disable input[type="checkbox"]:checked + label:before {
  background-color: #ddd;
  border-color: #ddd;
}

select:disabled {
  background: #dbdbdb;
  border-color: #dbdbdb;
}

.Overlay-RadioBox {
  position: relative;
  height: 20px;
}

.Overlay-RadioBox input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
  cursor: pointer;
}

.Overlay-RadioBox label {
  padding-left: 28px;
  line-height: 20px;
  font-size: var(--font-size-s);
  display: block;
  white-space: nowrap;
  color: var(--color-white);
}

.Overlay-RadioBox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #a9a9a9;
  border-radius: 50%;
}

.Overlay-RadioBox i {
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
  color: var(--color-green);
  line-height: 12px;
  text-align: center;
  width: 12px;
  font-size: 0 !important;
  border-radius: 50%;
}

.Overlay-RadioBox input[type="radio"]:checked + label:before {
  background-color: var(--color-white);
  border-color: var(--color-green);
}

.Overlay-RadioBox input[type="radio"]:checked + label i {
  display: block;
  color: var(--color-green);
  background-color: var(--color-green);
}

.Overlay-RadioBox.With-Icon label {
  padding-left: 20px;
}

.Overlay-RadioBox.Green-RadioBox label:before {
  border-color: #a9a9a9;
}

.Overlay-RadioBox.Green-RadioBox label i {
  color: var(--color-white);
}

.Overlay-RadioBox.Green-RadioBox input[type="radio"]:checked + label:before {
  border-color: var(--color-green);
}

.Overlay-RadioBox.Disable input {
  pointer-events: none;
}

.Overlay-RadioBox.Disable label {
  color: var(--color-dark-green);
}

.Overlay-RadioBox.Green-RadioBox.Disable label {
  color: #ddd;
}

.Overlay-RadioBox.Disable label:before {
  border-color: #ddd;
}

.Overlay-RadioBox.Disable input[type="radio"]:checked + label i {
  color: #ddd;
  background-color: #ddd;
}

.Overlay-RadioBox.Green-RadioBox.Disable input[type="radio"]:checked + label:before,
.Overlay-RadioBox.Green-RadioBox.Disable label:before {
  border-color: #ddd;
}

#toast-container > .toast {
  background-image: none !important;
  text-align: center;
  padding-left: 15px !important;
}

#toast-container > .toast .toast-message {
  line-height: 24px;
  min-height: 24px;
  padding-left: 35px;
  position: relative;
  display: inline-block;
}

#toast-container > .toast .toast-message:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}

#toast-container > .toast-warning {
  background-color: var(--color-blue);
  color: var(--color-white);
}

#toast-container > .toast-error .toast-message:before,
#toast-container > .toast-warning .toast-message:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM4NDlBNUM4ODdFRjExRTc5MTY3RTQyMTExNDg1MDE1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM4NDlBNUM5ODdFRjExRTc5MTY3RTQyMTExNDg1MDE1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Mzg0OUE1QzY4N0VGMTFFNzkxNjdFNDIxMTE0ODUwMTUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Mzg0OUE1Qzc4N0VGMTFFNzkxNjdFNDIxMTE0ODUwMTUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6ZJJy3AAABHUlEQVR42tSWPQrCQBCFs0HETizTKnoAr6AQrLyBCF7Bq+QYxtLKAwieICDaxDpYiEVwfMKIa8yuY0wKH3wkZH5emAxsFBE5Vcp1Kta3BgEj131EQnxwZnxpnbR5A2zoqQ0/K81gTO8al2mwzTHYlmUQkFnBrwY9kFgMEs4x9rCtqQJz0LTkNDlHFVnTaeZtY9Bm4kxs+u2I6iDKNDlo8UMmFnGNeEQT0M0ZmY6uLteIRtQBu5wPutdy9jnxHddaR+SChWFjTlreyZCz4B5Ggz5IqbhS7mE0COl3hSaDGbh8KH7k2nThXi8GLXAUvJ3EgLhXS1/TGfAkxwfzSR73dBRchriuQK3k0zIFo7vBEjcDcK3gOF6rv/+ruAkwAMlcklg+TGYvAAAAAElFTkSuQmCC) !important;
}

#toast-container > .toast-info {
  background-color: var(--color-yellow);
  color: var(--color-black);
}

#toast-container > .toast-info .toast-message:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODg1RDkyNjI4N0YyMTFFNzgzQkRGMDIxNzYyNURDMTQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODg1RDkyNjE4N0YyMTFFNzgzQkRGMDIxNzYyNURDMTQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUZDNDg5NzA4N0VFMTFFNzgxOTVCMkI5MzhERDQ1RDgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUZDNDg5NzE4N0VFMTFFNzgxOTVCMkI5MzhERDQ1RDgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5t1WK1AAABiklEQVR42ryWMUvDQBiGL6lLLHata6FOYq2LOLq7OxQEK86dCgWFbv4NsY6Oikt/QXFz6CChznZwSAsOVRvfg/fgOJPLVWI+eOCuvTzvXUK/1BP22gVHYB9sgSo/n4IQPIHHOI6fxYrVBDcgAnEGEdc2ESRMvAT5GbgCm5zPwQjcGeuOwQHY4PwNXEJ6bdt5F3xxZ6+gDyrAT1jr87s+18a8tqufQK82+NTkNZd7SUlNC5GOthnQAO9cELrKVYAWEtIhXQ0VII860HZet/gi8iuAsrp2koG6tTtgBr5BL2vDJDGAIT26ZnSLC160AEFGwDmxBQR0xXSLe06GDgGpz8AIGNIp3WLMSSvDVQYnpJwWwJAWneM17ee/nhGwDW45fmGbSCvlqvor3I1lythaPhuXrA+RXynXVAZMODn9y0M2y/O8gC5ZE5+NTNZhjidQrpEMeGDHLIGObXMpY7M6dM3pdm4Ve3y4S46dW4VrsyuxPVc4dm52hbTrf3vhFP7KLOSln+vflh8BBgCCVjHCadBT2QAAAABJRU5ErkJggg==) !important;
}

#toast-container > .toast-success .toast-message:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}

.Popup-Event .Overlay-Lightbox-Image img {
  max-width: 1024px;
  height: auto;
  margin: auto;
  max-height: 80%;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Popup-Event-Block {
  top: 60px !important;
  z-index: 10009 !important;
}

.locations-error {
  margin-left: 38px;
  margin-bottom: 5px;
}

.LoginBoxCustom-Close {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--color-green);
  color: #fff;
  cursor: pointer;
}

.LoginBoxCustom-Close:hover {
  background-color: var(--color-green);
}

.LoginBoxCustom-Close i {
  display: block;
  line-height: 24px;
  font-size: 15px !important;
  color: var(--color-green);
}

.LoginBoxCustom-Close:hover i {
  color: var(--color-white);
}

#root + #d_hotline_frame,
#root ~ #d_hotline_frame {
  bottom: 40px !important;
}

.MultipleBookingLayout-Scroll {
  margin-right: 390px;
}

.MultipleBookingLayout .BookingWizard {
  margin-right: 10px;
  border-radius: var(--radius);
  z-index: inherit;
  min-width: 410px;
  width: 410px;
}

.MultipleBookingLayout .BookingWizard-Actions button,
.MultipleBookingLayout .BookingWizard .Actions button,
.MultipleBookingLayout .BookingWizard .Header button {
  border: 0 none;
  background-color: transparent;
  outline: 0;
}

.MultipleBookingLayout .BookingWizard .Header {
  background-color: var(--color-white);
  border-radius: var(--radius) var(--radius) 0 0;
  padding: 0 10px;
}

.MultipleBookingLayout .BookingWizard .Header .Left {
  margin-right: 25px;
}

.MultipleBookingLayout .BookingWizard .Header .Right {
  width: 20px;
  float: right;
  margin-top: -33px;
}

.MultipleBookingLayout .BookingWizard .Header .Left input {
  margin: 8px 0;
  height: 28px;
  width: 100%;
  outline: 0;
  border: 0 none;
  font-size: 18px;
  font-weight: 700;
}

.MultipleBookingLayout .BookingWizard .view-map {
  float: right;
}

.MultipleBookingLayout .BookingWizard .Popover {
  z-index: 100;
}

.MultipleBookingLayout .BookingWizard .Actions {
  z-index: inherit;
  height: 46px;
  padding: 0 10px;
  position: relative;
  border-radius: 0 0 var(--radius) var(--radius);
  box-shadow: none;
}

.MultipleBookingLayout .BookingWizard .Actions .Left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
  margin-left: 0;
  color: var(--color-red);
}

.MultipleBookingLayout .BookingWizard .Actions .Left i {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: var(--color-white);
  border: 1px solid #efefef;
  color: var(--color-green);
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
}

.MultipleBookingLayout .BookingWizard .Actions .Right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0;
  margin-right: 0;
  color: var(--color-green);
}

.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles {
  display: none;
  position: absolute;
  bottom: 47px;
  left: 10px;
  z-index: 23;
  width: 156px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #efefef;
  overflow: hidden;
}

.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles.Active {
  display: block;
}

.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles button {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #444;
  padding: 0 12px;
  margin: 0;
  height: 32px;
  border-radius: 0;
}

.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles button:hover {
  background: #ececec;
}

.MultipleBookingLayout .BookingWizard-Actions {
  margin-left: 10px;
  border: 2px dashed var(--color-green);
  border-radius: 6px;
  max-width: 202px;
  min-width: 202px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.MultipleBookingLayout .BookingWizard-Actions button {
  height: 50%;
}

.MultipleBookingLayout .BookingWizard-Actions-Add,
.MultipleBookingLayout .BookingWizard-Actions-Saved {
  color: var(--color-green);
}

.MultipleBookingLayout .BookingWizard-Actions button > div {
  margin-bottom: 10px;
}

.MultipleBookingLayout .BookingWizard-Actions .icon {
  position: relative;
  display: inline-block;
}

.BookingWizard-Actions-Add img,
.BookingWizard-Actions-Saved img {
  margin: auto;
  display: block;
}

.MultipleBookingLayout-Collapse {
  position: fixed;
  right: 0;
  top: 148px;
  bottom: 80px;
  z-index: 1;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  background-color: var(--color-white);
  box-shadow: 0 -6px 6px #313131;
}

.MultipleBookingLayout-Summary-Title {
  text-align: center;
  margin-top: 10px;
}

.MultipleBookingLayout-Summary-Title h2 {
  margin: 0 0 20px;
}

.MultipleBookingLayout-Summary-Group {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.MultipleBookingLayout-Summary-Group-Note {
  min-height: 130px;
}

.MultipleBookingLayout-Summary-Group .Left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.MultipleBookingLayout-Summary-Group .Right h1,
.MultipleBookingLayout-Summary-Group .Right h2,
.MultipleBookingLayout-Summary-Group .Right h3,
.MultipleBookingLayout-Summary-Group .Right h4,
.MultipleBookingLayout-Summary-Group .Right h5,
.MultipleBookingLayout-Summary-Group .Right h6 {
  margin: 0;
}

.MultipleBookingLayout-Noted {
  padding: 0 20px;
  margin-top: 10px;
}

.MultipleBookingLayout-Noted p {
  margin: 5px 0;
}

.MultipleBookingLayout-Line {
  border-top: 1px solid var(--color-gray-xxl);
}

.Booking-Multiple-Actions {
  position: fixed;
  bottom: 0;
  height: 80px;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  padding: 0 30px;
}

.Booking-Multiple-Actions > div {
  float: left;
  padding: 17px 0;
}

.Booking-Multiple-Actions .Left {
  padding: 0;
}

.Booking-Multiple-Actions .Right {
  text-align: right;
  width: 100%;
}

.Booking-Multiple-Actions button {
  min-width: 180px;
  height: 46px;
}

.Booking-Multiple-Actions .Right button {
  margin: 0 0 0 5px;
}

.Booking-Multiple-Actions .Left button {
  margin: 0 5px 0 0;
}

.pac-container {
  z-index: 99999999 !important;
}

.BookingWizard .Head,
.Menu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.23) !important;
}

.BookingWizard .CodPod.Popover .Popover-Item {
  color: var(--color-white);
  margin-bottom: 5px;
}

.BookingWizard .CodPod.Popover .Popover-List {
  margin-bottom: 56px;
}

.BookingWizard select {
  padding: 0 5px;
  width: 40px;
}

.Custom-Step {
  position: relative;
  z-index: 0;
}

.BookingWizard,
.BookingWizard .Actions,
.BookingWizard .Popover,
.BookingWizard .Popover-Arrow,
.BookingWizard .Steps .Step,
.BookingWizard .Steps .Step:after,
.Custom-Step .Steps .Step,
.Custom-Step .Steps .Step:after {
  background-color: var(--color-green);
}

.BookingWizard .Steps .Step:after {
  box-shadow: none;
  border-top: 1px solid var(--color-dark-green);
  border-right: 1px solid var(--color-dark-green);
}

.BookingWizard .Steps .Step.selected:after {
  border: 0 none;
}

.BookingWizard .Actions .Button,
.BookingWizard .PickupTime.block .block-item.selected,
.BookingWizard .Services.block .block-item.selected,
.BookingWizard .Steps .Step.selected,
.BookingWizard .Steps .Step.selected:after,
.BookingWizard .Vehicles.block .block-item.selected,
.Custom-Step .Steps .Step.selected,
.Custom-Step .Steps .Step.selected:after {
  background-color: var(--color-dark-green);
}

.BookingWizard .PickupTime.block .block-item.selected,
.BookingWizard .Services.block .block-item.selected,
.BookingWizard .Vehicles.block .block-item.selected {
  border-color: transparent !important;
}

.BookingWizard .CodPod.Popover .Popover-List::-webkit-scrollbar-thumb,
.BookingWizard .Content::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-green);
}

.BookingWizard .CodPod.Popover .Popover-List::-webkit-scrollbar-track,
.BookingWizard .Content::-webkit-scrollbar-track {
  background-color: #3c8a33;
}

.BookingWizard .Actions .Button.gray {
  background: transparent;
  border: 1px solid var(--color-white);
}

.BookingWizard .Actions .Button,
.BookingWizard .FormGroup-label,
.BookingWizard .FullDay *,
.BookingWizard .PickupTime.block .block-item.selected,
.BookingWizard .PickupTime.block .block-item.selected *,
.BookingWizard .PickupTime.block .block-item.selected .PickupTime-Image i,
.BookingWizard .Popover h3,
.BookingWizard .Pricing-bock-custom b,
.BookingWizard .PricingNote.block,
.BookingWizard .Services.block .block-item.selected,
.BookingWizard .Services.block .block-item.selected *,
.BookingWizard .Steps .Step,
.BookingWizard .Vehicles.block .block-item.selected,
.BookingWizard .Vehicles.block .block-item.selected *,
.Custom-Step .Steps .Step {
  color: var(--color-white);
}

.BookingWizard .Steps .Step.selected,
.Custom-Step .Steps .Step.selected {
  font-weight: 700;
}

.BookingWizard .TextArea textarea {
  background-color: var(--color-white);
}

.BookingWizard .Popover-contact-list {
  border: 1px solid var(--color-white);
}

.BookingWizard .Popover-contact-list-arrow:before {
  border-bottom-color: var(--color-white);
}

.BookingWizard .Actions {
  box-shadow: none;
}

.BookingWizard .Content #Step-02,
.BookingWizard .Content #Step-03 {
  padding-top: 20px;
}

.BookingWizard .Content #Step-01 {
  padding-top: 0;
}

.BookingWizard .Content h3 {
  display: none;
}

.BookingWizard .Content h3.Custom {
  display: block !important;
  color: var(--color-white);
  padding: 0 10px;
  margin: -2px 0px -12px 0;
}

.BookingWizard .Content .block {
  margin: 20px 10px;
}

.BookingWizard .Content .block.PricingNote {
  margin: 0 10px;
  padding-bottom: 15px;
}

.BookingWizard .Content .block.Pricing {
  margin-bottom: 0;
}

.BookingWizard .Services.block .block-item .Overlay-RadioBox,
.BookingWizard .Services.block .block-item .Radio,
.BookingWizard .Vehicles.block .block-item .Overlay-RadioBox,
.BookingWizard .Vehicles.block .block-item .Radio {
  display: none;
}

#root .BookingWizard .Services.block .Services-Icon {
  margin-left: 0 !important;
}

.BookingWizard .Vehicles.block .block-item label {
  margin-left: 10px;
  height: auto;
}

.MultipleBookingLayout .BookingWizard .Actions .Left,
.MultipleBookingLayout .BookingWizard .Actions .Right {
  color: var(--color-white);
}

.MultipleBookingLayout .BookingWizard .Header,
.MultipleBookingLayout .BookingWizard .Header .Left input {
  background-color: var(--color-green);
}

.MultipleBookingLayout .BookingWizard .Header .Left h3,
.MultipleBookingLayout .BookingWizard .Header .Left input,
.MultipleBookingLayout .BookingWizard .Header .Right *,
.MultipleBookingLayout .BookingWizard .Header .Right i {
  color: var(--color-white);
}

.MultipleBookingLayout .BookingWizard .view-map {
  text-align: right;
  text-transform: none;
  font-weight: 400;
  clear: both;
  color: #fff;
  display: block;
  padding-right: 10px;
  float: none;
}

.PickupTime-Image-Custom i {
  margin-top: -2px;
}
/*# sourceMappingURL=main.49a87820.css.map*/

:root {
  --color-hyperlink-green: #44e952;
}
/* Define variables css colors*/

.Modal-Addlocation-Info {
  min-width: 410px;
}
.Modal-None-Overlay .Modal,
.Modal-None-Overlay .SelectAccountBox,
.Modal-None-Overlay .SelectSubAccountBox,
.Modal-None-Overlay .SignupBox,
.Modal-None-Overlay .Verification .VerificationBox {
  background: transparent !important;
}
.Driver-Status-Icon {
  display: block;
  width: 95px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
}
.MyDrivers .DriverBlock .Avatar.xxb {
  flex: 1;
  max-width: var(--length-xxl);
  max-height: var(--length-xxl);
  min-width: var(--length-xxl);
}
.Input .Input-Icon ~ .Place-Handler input,
.Input .Input-Icon + .Place-Handler input {
  padding-left: 0;
}
.Reset-Width-Popup-Booking .Popup-Booking-Custom {
  min-width: auto;
}
.Reset-Width-Popup-Booking .Box-Content {
  min-height: auto;
}
.DetailBooking-Price label {
  text-transform: none;
}
.Closure-List-Info .Closure-List-Button {
  margin: 0 0px 5px;
}
.Share-Location .Share-Location-Submit span {
  color: var(--color-white) !important;
}
.BookingWizard .Vehicles.block .block-item label {
  margin-left: 0px;
}
.full-day-beta {
  color: #444 !important;
}
.Services.block .block-item label,
.PickupTime.block .PickupTime-Name {
  font-size: 15px;
}
.MultipleBookingLayout .step-2.CodPod .Actions {
  position: absolute !important;
}
.MultipleBookingLayout .step-2:before {
  opacity: 0;
}
.BatchUploadLayout > div {
  width: 100%;
}
.Box-Sizing.Border-Box {
  box-sizing: border-box;
}
.Box-Sizing.Content-Box {
  box-sizing: content-box;
}
.Rotate.minus-15 {
  transform: rotate(-15deg);
}
.Rotate.plus-15 {
  transform: rotate(15deg);
}
.Rotate-plus-180 {
  transform: rotate(180deg);
}
.Left-text {
  text-align: left !important;
}
.Right-text {
  text-align: right !important;
}
.Font-normal-text,
#root .Font-normal-text {
  font-weight: normal;
}
.White-text,
#root .White-text-hover:hover {
  color: var(--color-white);
}
.White-text-important {
  color: var(--color-white) !important;
}
.White-icon .Icon {
  color: var(--color-white);
}
.White-icon-important .Icon {
  color: var(--color-white) !important;
}
.Red-text,
#root .Red-text-hover:hover {
  color: var(--color-red);
}
.Yellow-text,
#root .Yellow-text-hover:hover {
  color: var(--color-yellow);
}
.Gray-text,
#root .Gray-text-hover:hover {
  color: var(--color-gray);
}
.Gray-text-xl,
#root .Gray-text-xl-hover:hover {
  color: var(--color-gray-xl);
}
.Gray-text-important {
  color: var(--color-gray) !important;
}
.Gray-icon .Icon {
  color: var(--color-gray-l);
}
.Gray-icon-important .Icon {
  color: var(--color-gray-l) !important;
}
.Orange-text,
#root .Orange-text-hover:hover {
  color: #ff8328;
}
.Line-bg,
#root .Line-bg-hover:hover {
  background-color: #d8d8d8;
}
.White-bg,
#root .White-bg-hover:hover {
  background-color: var(--color-white);
}
.Green-bg,
#root .Green-bg-hover:hover {
  background-color: var(--color-green);
}
.Light-Green-bg,
#root .Light-Green-bg-hover:hover {
  background-color: #ebf7e9;
}
.Green-text,
#root .Green-text-hover:hover {
  color: var(--color-green);
}
.Green-text-important {
  color: var(--color-green) !important;
}
.Dark-Green-text,
#root .Dark-Green-text-hover:hover {
  color: var(--color-dark-green);
}
.Dark-Green-text-important {
  color: var(--color-dark-green) !important;
}
.Dark-Green-bg,
#root .Dark-Green-bg-hover:hover {
  background-color: var(--color-dark-green);
}
.Red-bg,
#root .Red-bg-hover:hover {
  background-color: var(--color-red);
}
.Error-Red-bg,
#root .Error-Red-bg-hover:hover {
  background: #fdeceb;
}
.Blue-bg,
#root .Blue-bg-hover:hover {
  background-color: var(--color-blue);
}
.Yellow-bg,
#root .Yellow-bg-hover:hover {
  background-color: var(--color-yellow);
}
.Light-Gray-bg,
#root .Light-Gray-bg-hover:hover {
  background-color: #efefef;
}
.Gray-bg,
#root .Gray-bg-hover:hover {
  background-color: var(--color-gray);
}
.Dark-Light-Gray-bg {
  background-color: #9b9b9b;
}
.Hidden-bg {
  background-color: #a0a0a0;
}
.Hidden-bg-important {
  background-color: #a0a0a0 !important;
}
.Dark-Gray-bg,
#root .Dark-Gray-bg-hover:hover {
  background-color: var(--color-gray-xxd);
}
.Black-Gray-bg,
#root .Black-Gray-bg-hover:hover {
  background-color: #444;
}
.Title-bg,
#root .Title-bg-hover:hover {
  background-color: #f1f1f1;
}
.Black-Gray-text,
#root .Black-Gray-text-hover:hover {
  color: #444;
}
.Orange-bg,
#root .Orange-bg-hover:hover {
  background-color: #ff8328;
  cursor: pointer;
}
.Batch-bg,
#root .Batch-bg-hover:hover {
  background-color: #ffc7c7;
}
.Transparent-bg,
#root .Transparent-bg {
  background-color: transparent;
}
select.White-text option {
  color: #444;
}
.Gray-Light-Color {
  color: #9b9b9b !important;
}
.Wrap-Text {
  white-space: nowrap;
}
.Wrap-Normal-Text,
.DynamicPopupWrapper .Popup .Popup-Booking-Custom p.Wrap-Normal-Text,
.DynamicPopupWrapper .Popup .Popup-Booking-Custom .Wrap-Normal-Text {
  white-space: normal !important;
}
.Dashed-Line-Default {
  border: 1px dashed #7c7c7c;
}
.Solid-Line-Default {
  border: 1px solid #7c7c7c;
}
.Border-Bottom,
#root .Border-Bottom-Imp {
  border-bottom: 1px solid transparent;
}
.Border-Top,
#root .Border-Top-Imp {
  border-top: 1px solid transparent;
}
.Border-Default {
  border: 1px solid transparent;
}
.Bordercolor-bg,
.Border-Color-White,
#root .Border-Color-White-hover:hover {
  border-color: var(--color-white);
}
.Border-Color-Red,
#root .Border-Color-Red-hover:hover {
  border-color: #f14433;
}
.Green-border,
#root .Green-border-hover:hover {
  border-color: var(--color-green);
}
.Dark-Green-border,
#root .Dark-Green-border-hover:hover {
  border-color: var(--color-dark-green);
}
.Border-Color-Silver,
#root .Border-Color-Silver-Imp,
#root .Border-Color-Silver-hover:hover {
  border-color: #e1e1e1;
}
.Transparent-bg {
  background-color: transparent;
}
.filter-white-to-gray {
  filter: brightness(0.8) invert(1);
}
.filter-gray-to-white {
  filter: brightness(0) invert(1);
}
.filter-white-to-black {
  filter: brightness(0) invert(0);
}
.fixed {
  position: fixed !important;
}
.absoute {
  position: absolute !important;
}
.fixed-width {
  left: 0;
  right: 0;
}
.fixed-height {
  top: 0;
  bottom: 0;
}
.relative {
  position: relative;
}
.relative-imp {
  position: relative !important;
}
.clear {
  clear: both;
}
.o-hidden {
  overflow: hidden;
}
.o-inherit {
  overflow: inherit;
}
#root .visible-imp {
  opacity: 1 !important;
  visibility: visible !important;
}
.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.max-width-14 {
  max-width: 14px;
}
.break-word {
  word-break: break-word;
}
.line-through-red {
  position: relative;
}
.line-through-red:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-1px);
  height: 2px;
  left: 0;
  right: 0;
  background-color: var(--color-red);
}
.Overlay-RadioBox.White-RadioBox label:before {
  border-color: var(--color-white);
}
.Overlay-RadioBox.White-RadioBox input[type="radio"]:checked + label:before {
  background-color: transparent;
  border-color: var(--color-white);
}
.Overlay-RadioBox.White-RadioBox input[type="radio"]:checked + label i {
  background-color: var(--color-white);
}
.Overlay-Checkbox.Gray-Checkbox label:before,
.Overlay-Checkbox.Green-Checkbox label:before,
.optimize-route .Overlay-Checkbox label:before {
  border-color: #7c7c7c;
}
.Overlay-Checkbox.Gray-Checkbox input[type="checkbox"]:checked + label:before,
.optimize-route .Overlay-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.Overlay-Checkbox.Gray-Checkbox input[type="checkbox"]:checked + label i,
.optimize-route .Overlay-Checkbox input[type="checkbox"]:checked + label i {
  color: white;
}
.Overlay-Checkbox input.select-error + label:before,
.Overlay-RadioBox input.select-error + label:before {
  background-color: var(--color-white);
  border-color: var(--color-red);
}
.select-error {
  border: 2px solid var(--color-red) !important;
}
.Booking-Modal {
  width: 420px;
}
.block {
  display: block;
}
.block-imp {
  display: block !important;
}
.hidden,
.Hidden-Field {
  display: none;
}
.Button.gray,
.Button.gray * {
  color: #4a4a4a;
}
.disabled,
.block-disabled,
.Disabled-Text,
.Button-Disabled,
.Link-Disabled,
.Input-Disabled,
.Input-Disabled .me-prefix,
.none-event {
  pointer-events: none;
}
.Button-Disabled {
  background-color: #a5a5a5 !important;
  color: #4a4a4a !important;
  font-weight: 400 !important;
}
.Input-Disabled,
.block-disabled,
.Input-Disabled .me-prefix {
  background-color: var(--color-gray-xl) !important;
  font-weight: 400 !important;
}
.Input-Disabled input,
.Input-Disabled .me-prefix {
  color: var(--color-gray) !important;
}
#root .Button.disabled,
#root .Link-Disabled {
  color: #aaaaaa;
  background: transparent;
  border: 1px solid #cecece;
}
#root .Button.disabled * {
  color: #aaaaaa;
}
.opacity-none {
  opacity: 0;
}
.opacity-default {
  opacity: 1;
}
.opacity-1 {
  opacity: 0.1;
}
.opacity-2 {
  opacity: 0.2;
}
.opacity-3 {
  opacity: 0.3;
}
.opacity-4 {
  opacity: 0.4;
}
.opacity-5 {
  opacity: 0.5;
}
.decoration {
  text-decoration: underline;
}
.font-weight-500 {
  font-weight: 500;
}
.default-title-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#root .default-smaller-font,
.default-smaller-font {
  font-size: 12px;
}
#root .default-font,
.default-font {
  font-size: var(--font-size);
}
#root .default-font-important,
.default-font-important {
  font-size: var(--font-size) !important;
}
#root .default-small-font,
.default-small-font {
  font-size: 14px;
}
#root .larger-font,
.larger-font {
  font-size: 17px;
}
#root .larger-xs-font,
.larger-xs-font {
  font-size: 18px;
}
#root .larger-xxs-font,
.larger-xxs-font {
  font-size: 19px;
}
#root .larger-xxs-font-important,
.larger-xxs-font-important {
  font-size: 19px !important;
}
#root .title-font,
.title-font {
  font-size: 20px;
}
#root .large-icon,
.large-icon {
  font-size: 21px;
}
#root .large-icon-important,
.large-icon-important {
  font-size: 21px !important;
}
#root .xxs-large-icon,
.xxs-large-icon {
  font-size: 30px;
}
#root .default-medium-font,
.default-medium-font {
  font-weight: 500;
}
#root .default-bold-font,
.default-bold-font {
  font-weight: bold;
}
#root .default-smaller-font-color,
.default-smaller-font-color {
  color: #a1a1a1;
}
#root .default-color,
.default-color {
  color: #444444;
}
#root .default-color-opacity-6,
.default-color-opacity-6 {
  color: rgba(68, 68, 68, 0.6);
}
#root .default-color-icon,
.default-color-icon {
  color: #7c7c7c;
}
#root .default-color-gray,
.default-color-gray {
  color: #999999;
}
#root .default-color-light-gray,
.default-color-light-gray {
  color: #aeaeae;
}
#root .default-color-icon-hover:hover i {
  color: var(--color-white);
}
#root .default-color-icon-green-hover:hover i {
  color: var(--color-green);
}
#root .default-color-active-icon,
.default-color-active-icon,
.default-color-active {
  color: var(--color-green);
}
#root .default-color-text,
.default-color-text {
  color: #444444;
}
#root .block-sub-item-flex-start {
  align-items: flex-start;
}
textarea.Resize-None,
.Resize-None {
  resize: none;
}
.cur-pointer {
  cursor: pointer;
}
.cur-default {
  cursor: default;
}
.cur-default-important {
  cursor: default !important;
}
.cur-auto {
  cursor: auto;
}
.sub-gray-text {
  font-size: var(--font-size-s);
  color: #444444;
}
.sub-gray-text.White-text {
  color: var(--color-white);
}
.Radius-zero {
  border-radius: 0 !important;
}
.Radius-two {
  border-radius: 2px;
}
.Radius-three {
  border-radius: 3px;
}
.Radius-default {
  border-radius: var(--radius);
}
.Radius-five {
  border-radius: 5px;
}
.Radius-thirty {
  border-radius: 30px;
}
.Radius-1 {
  border-radius: var(--radius);
}
.Radius-Top-Default {
  border-radius: var(--radius) var(--radius) 0 0;
}
.Radius-Bottom-Default {
  border-radius: 0 0 var(--radius) var(--radius);
}
.Custom-Input-Group {
  border-radius: var(--radius-s) 0 0 var(--radius-s);
  margin-left: -1px;
}
.Radius-Circle {
  border-radius: 50%;
}
.No-Border-Radius-Important {
  border-radius: 0 !important;
}
.Radius-Top-Right-None {
  border-top-right-radius: 0;
}
.Radius-Bottom-Right-None {
  border-bottom-right-radius: 0;
}
.Radius-Top-Left-None {
  border-top-left-radius: 0;
}
.Radius-Bottom-Left-None {
  border-bottom-left-radius: 0;
}
.No-Border-Bottom,
#root .No-Border-Bottom {
  border-bottom: 0 none;
}
.No-Border-Right,
#root .No-Border-Right {
  border-right: 0 none;
}
.No-Border {
  border: none;
}
.No-Border-Important {
  border: 0 none !important;
}
.No-Hover-Important:hover {
  background-color: transparent !important;
}
.white-pre-wrap {
  white-space: pre-wrap;
}
a,
.Outline-None,
.Pagination ul li:focus {
  outline: 0;
}
input,
select,
textarea {
  box-shadow: none;
}
.No-Scroll-Y {
  overflow-y: hidden !important;
}
.Scroll-Y {
  overflow-y: auto !important;
}
.min-w-16 {
  min-width: 16px;
}
.min-w-24 {
  min-width: 24px;
}
.min-w-48 {
  min-width: 24px;
}
.w100 {
  width: 100% !important;
}
.m-w100 {
  max-width: 100% !important;
}
.w14 {
  width: 14px !important;
}
.w77 {
  width: 77px !important;
}
.w30 {
  width: 30px !important;
}
.w320 {
  width: 320px !important;
}
.w336 {
  width: 336px !important;
}
.w340 {
  width: 340px !important;
}
.w230 {
  width: 230px;
}
.w270 {
  width: 270px;
}
.w180 {
  width: 180px;
}
.w170 {
  width: 170px;
}
.w52px {
  width: 52px !important;
}
.min-max-w-500 {
  min-width: 500px !important;
  max-width: 500px !important;
}
.min-max-w-300 {
  min-width: 300px !important;
  max-width: 300px !important;
}
.min-max-w-400 {
  min-width: 400px !important;
  max-width: 400px !important;
}
.min-max-w-1024 {
  min-width: 1024px !important;
  max-width: 1024px !important;
}
.min-max-w-560 {
  min-width: 560px !important;
  max-width: 560px !important;
}
.min-h-180 {
  min-height: 180px !important;
}
.min-h-225 {
  min-height: 225px !important;
}
.min-h-265 {
  min-height: 265px !important;
}
.min-h-655 {
  min-height: 655px !important;
}
.min-h-650 {
  min-height: 650px !important;
}
.max-h-490 {
  max-height: 490px !important;
}
.h100 {
  height: 100% !important;
}
.m-h50vh {
  max-height: 50vh !important;
}
.m-h80vh {
  max-height: 80vh !important;
}
.m-h32px {
  max-height: 32px !important;
}
.m-h34px {
  max-height: 34px !important;
}
.m-h36px {
  max-height: 36px !important;
}
.m-h205px {
  max-height: 205px !important;
}
.h-auto-imp {
  height: auto !important;
}
.h1px {
  height: 1.2px !important;
}
.h20px {
  height: 20px !important;
}
.h25px {
  height: 25px !important;
}
.h30px {
  height: 30px !important;
}
.h36px {
  height: 36px !important;
}
.h38px {
  height: 38px !important;
}
.h52px {
  height: 52px !important;
}
.h80px {
  height: 80px !important;
}
.h205px {
  height: 205px !important;
}
.min-h52px {
  min-height: 52px !important;
}
.max-h48px {
  max-height: 48px;
}
.min-h-80-percent {
  min-height: 80%;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.Closure,
.Contacts,
.Employee,
.MyDrivers {
  margin-bottom: 105px;
}
.Contacts,
.Employee,
.MyDrivers {
  padding-bottom: 30px;
}
.Hide-Border,
#root .Hide-Border-Imp {
  border-color: transparent;
}
.Hide-Border-Right,
#root .Hide-Border-Right {
  border-right: 0 none;
}
.Hide-Border-Bottom,
#root .Hide-Border-Bottom {
  border-bottom: 0 none;
}
.TextArea textarea {
  border-radius: var(--radius);
}
.Popup-Normal-Booking {
  width: 330px;
}
.Popup-Normal-Booking-Ez {
  width: 354px;
}
.Our-guarantee-applie {
  max-width: 450px;
}
.Our-guarantee-applie p {
  text-align: center;
  font-size: 15px;
  line-height: 1.38;
  color: #ddd;
  margin: -10px 0 50px 0;
  font-weight: bold;
}
.locating-animation {
  height: 266px;
  width: 266px;
  margin: auto;
  position: relative;
}
.car-animation {
  position: absolute;
  left: 108px;
  top: -25px;
  z-index: 1;
  margin: auto;
  height: 28px;
}
.locating-animation .circle-stroke {
  border: 2px dashed #ddd;
  width: 262px;
  height: 262px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
}
.locating-animation .circle-soild {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.locating-animation .Loader-Text {
  position: absolute;
  left: 0;
  right: 0;
  top: 286px;
  color: #ddd;
}
.extend-recovery-text {
  left: -50% !important;
  right: -50% !important;
  margin: 0 2px;
}
.circle-sub {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-sub > img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.circle-sub .Loader-Text {
  color: #ddd;
}
.ui-autocomplete {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-autocomplete .ui-state-active {
  margin: 0 !important;
}
.fixed-autocomplete {
  width: 288px !important;
}
.fixed-autocomplete-custom {
  width: 268px !important;
  left: 79px !important;
}
.BookingWizard .Content .LocationContacts .block-item {
  align-items: flex-start;
}
.BookingWizard .Content .LocationContacts .block-item label {
  min-height: 22px;
  display: flex;
  align-items: center;
}
.LocationContacts .block-sub-item {
  margin-bottom: 0;
}
.LocationContacts .block-sub-item.block-sub-item-button {
  margin: 0px 34px 0 27px;
  padding-left: 0;
  position: relative;
}
.LocationContacts .block-sub-item.block-sub-item-button .Button {
  border: 0 none !important;
  padding: 0;
  white-space: normal;
  align-items: flex-start;
  text-align: left;
  height: auto;
  line-height: 24px;
}
.LocationContacts .block-sub-item.block-sub-item-button .Button:hover {
  background-color: transparent;
}
.LocationContacts .block-sub-item.block-sub-item-button .Button .Icon {
  margin: 0 5px 0 0 !important;
  padding: 0 !important;
  width: 24px !important;
  font-size: 24px;
}
.LocationContacts .block-sub-item.block-sub-item-custom {
  margin-right: 30px;
}
.LocationContacts .block-sub-item.block-sub-item-custom i {
  color: #7c7c7c;
}
.LocationContacts .block-sub-item.block-sub-item-custom p {
  color: #444444;
}
.LocationContacts .block-sub-item.block-sub-item-custom .Input,
.LocationContacts .block-sub-item.block-sub-item-custom .TextArea {
  width: 100%;
  margin-left: 10px;
}
.LocationContacts .block-sub-item.block-sub-item-custom .TextArea textarea {
  font-size: var(--font-size-s);
}
.LocationContacts-dotline {
  padding-bottom: 15px;
}
.LocationContacts-dotline:last-child {
  padding-bottom: 0;
}
.LocationContacts-dotline:before {
  content: "";
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 14px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMnB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMi4wMDAwMDAsIC02ODcuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOC4wNTUzODMsIDIxLjc1MDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTI4LjA1NTM4MywgLTIxLjc1MDAwMCkgIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: repeat-y;
  width: 3px;
  background-size: 3px;
  opacity: 0.5;
}
.LocationContacts-dotline:last-child:before {
  display: none;
}
.block-sub-item-button button.Button {
  flex: 1;
  margin: 0 5px;
  height: 30px;
}
.Logo-business-badge {
  position: absolute;
  top: -5px;
  left: -46px;
  width: 120px;
  padding: 15px 0 5px;
  color: var(--color-dark-green);
  text-align: center;
  transform: rotate(-45deg);
  text-transform: uppercase;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 33;
}
.Logo-business-badge + img,
.Logo-business-badge ~ img {
  margin-left: 35px;
}
.Logo-business-badge:before {
  content: "";
  position: absolute;
  background-color: #f2cf23;
  top: 0;
  left: 0;
  bottom: 0;
  width: 62px;
  z-index: -1;
}
.BookingInfo-Actions {
  padding: 0 20px 10px 20px;
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
}

.BookingInfo-Actions .BookingInfo-Actions-Right {
  position: absolute;
  right: 20px;
  top: 10px;
}

.Overlay-Checkbox.Green-Checkbox input[type="checkbox"]:checked + label:before,
.ParkingTolls-Checkbox.Green-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--color-dark-green);
  border-color: var(--color-dark-green);
}
.Overlay-RadioBox.Green-RadioBox input[type="radio"]:checked + label:before {
  border-color: var(--color-dark-green);
}
.Overlay-RadioBox input[type="radio"]:checked + label i {
  background-color: var(--color-dark-green);
}
.toast-top-center.new-booking {
  left: 420px;
  width: auto;
}
#toast-container.new-booking.toast-bottom-center > div,
#toast-container.new-booking.toast-top-center > div {
  width: 260px;
}
#toast-container > div {
  border-radius: 6px;
}
#toast-container.custom-width.toast-top-center > div {
  width: 480px;
}
.toast-top-center.modal-toast-booking {
  left: 0;
  right: 0;
  width: 696px;
  max-width: 696px;
  margin: auto;
}
#toast-container.modal-toast-booking.toast-top-center > div {
  left: 190px;
  top: 5vh;
  margin-top: 80px;
}
.toast-Blue-bg > div {
  background-color: var(--color-blue);
}
#toast-container.toast-remove-icon .toast-message {
  padding-left: 0;
}
#toast-container.toast-remove-icon .toast-message:before {
  display: none;
}

.Status.dark-green {
  color: var(--color-dark-green);
}
.Status.violet {
  color: #7c1e29;
}
input,
textarea,
body,
button,
* {
  font-family: var(--font-family-base);
}
.Dropdown-Menu::-webkit-scrollbar {
  width: 4px;
  -webkit-appearance: none;
}
.Dropdown-Menu::-webkit-scrollbar-thumb {
  background-color: #bb9f13;
  outline: none;
  border-radius: 0;
}
.Dropdown-Menu::-webkit-scrollbar-track {
  background-color: #d8b71e;
}
.Closure-List-Info {
  min-height: 242px;
}
.Closure-List.chat.expand {
  min-height: 722px;
}
.Loader-Icon img {
  height: 68px;
}
.copyright.Box-Actions {
  padding: 8px 10px 7px 10px;
}
.line10 {
  height: 10px;
}
.LoginBox .Box-Head .Logo img,
.SignupBox .Box-Head .Logo img,
.SelectCityBox .Box-Head .Logo img,
.SelectSubAccountBox .Box-Head .Logo img,
.SelectAccountBox .Box-Head .Logo img {
  width: 75px;
}
.LoginBox .Box .Form {
  margin-bottom: 10px;
}
.SignupBox .Box-Head p.SignupBox-line {
  font-size: 17px;
}
.Login .LoginBox,
.Login .SelectAccountBox,
.Login .SelectSubAccountBox,
.RightMenu-close-pane {
  background: rgba(0, 0, 0, 0.6);
}
.Overlay.visible,
.PickupTime-DatePicker,
.Modal.visible {
  background: rgba(0, 0, 0, 0.8);
}
.Popup-Event .Overlay-Lightbox-Image img.Custom {
  left: 422px;
}
.PickupTime.block .PickupTime-Name {
  color: var(--color-gray);
  text-align: center;
}
.Menu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
}
.BookingWizard .Head {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3) !important;
}
.PickupTime-DatePicker-Custom {
  top: 60px;
}
.PickupTime-Modal-Driver {
  left: 436px;
  width: 580px;
}
.PickupTime-DatePicker .rdtPicker {
  width: 280px;
}
.PickupTime-DatePicker .DatePicker-Actions {
  width: 300px;
}
.PickupTime-DatePicker .rdtPicker th.rdtSwitch,
.ui-datepicker .ui-datepicker-title {
  font-size: 15px;
}
.PickupTime-DatePicker .rdtPicker td,
#ui-datepicker-div .ui-state-default {
  position: relative;
}
.PickupTime-DatePicker .rdtPicker td.rdtActive:before,
.PickupTime-DatePicker .rdtPicker td:before,
#ui-datepicker-div .ui-state-active:before,
#ui-datepicker-div .ui-state-default:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0 none;
  z-index: 2;
}
.PickupTime-DatePicker .DatePicker-Calendar .rdt.rdtOpen:last-child .rdtPicker td:before {
  display: none;
}
.PickupTime-DatePicker .rdtPicker td.rdtActive.rdtToday:before,
.PickupTime-DatePicker .rdtPicker td.rdtToday:hover:before,
#ui-datepicker-div .ui-state-highlight.ui-state-active:before,
#ui-datepicker-div .ui-state-highlight:hover:before {
  border: 1px solid var(--color-white) !important;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
}
.PickupTime-DatePicker .rdtPicker td:hover,
.PickupTime-DatePicker .rdtPicker td.rdtActive,
#ui-datepicker-div .ui-state-default:not(.ui-state-highlight):hover,
#ui-datepicker-div .ui-state-active:not(.ui-state-highlight) {
  background-color: transparent !important;
}
.PickupTime-DatePicker .rdtPicker td:not(.rdtToday):hover:before,
.PickupTime-DatePicker .rdtPicker td.rdtActive:before,
#ui-datepicker-div .ui-state-default:not(.ui-state-highlight):hover:before,
#ui-datepicker-div .ui-state-active:before {
  border: 1px solid var(--color-white) !important;
}
// .PickupTime-DatePicker .rdtPicker td.rdtNew:hover,
// .PickupTime-DatePicker .rdtPicker td.rdtOld:hover,
#ui-datepicker-div .ui-priority-secondary:hover {
  color: var(--color-dark-green) !important;
}
.PickupTime-DatePicker .rdtPicker td.rdtToday,
.PickupTime-DatePicker .rdtPicker td.rdtActive,
.ui-datepicker.ui-widget-content .ui-state-default.ui-state-hover,
.ui-datepicker .ui-state-highlight,
#ui-datepicker-div .ui-state-active {
  color: var(--color-white) !important;
}
.PickupTime-DatePicker .rdtPicker td.rdtToday,
.ui-datepicker .ui-state-highlight,
#ui-datepicker-div .ui-state-highlight.ui-state-active {
  background-color: var(--color-dark-green) !important;
  border-radius: 0px !important;
}
.PickupTime-DatePicker .DatePicker-Calendar .rdt.rdtOpen:last-child .rdtPicker {
  background-color: var(--color-dark-green);
  border-radius: 0 0 6px 6px;
  position: relative;
  top: 0;
  box-shadow: none;
}
.rdtPicker-border-bottom .rdtPicker {
  border-radius: 0 0 6px 6px;
}
.PickupTime-DatePicker .DatePicker-Calendar .rdt.rdtOpen input.timeInput {
  /*display: none;*/
}
.PickupTime-DatePicker .DatePicker-Calendar .rdtCounter .rdtBtn {
  position: relative;
}
.PickupTime-DatePicker .DatePicker-Calendar .rdtCounter .rdtBtn:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-dark-green);
  background-repeat: no-repeat;
  background-position: center;
}
.PickupTime-DatePicker .DatePicker-Calendar .rdtCounter .rdtBtn:first-child:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAAXNSR0IArs4c6QAAAF5JREFUGBltjtERgCAMQ7uAEzmdHyobsQB7xQTaE6q5y7VN3h2YJQHY5BSvp0ONU/6HJ4hr1xdmrOdUSKdb+wvzmKErPsP8FkkNmEvtJ1ACism8eFeNy04fUeapTswD3jmGNl6O1RgAAAAASUVORK5CYII=);
}
.PickupTime-DatePicker .DatePicker-Calendar .rdtCounter .rdtBtn:last-child:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAAXNSR0IArs4c6QAAAF5JREFUGBl1jtsNgDAMA7NAJ2I6PnhsxALsFc6lFhWUSG5S52QlMnNCc/yUdmKC50Cq/c3KqxsYgQWdzVgN89+ap12pvgZkeGGWVA/UJfTwGBrA3yRD7kQp+b7JJv0C1biGNngBKwcAAAAASUVORK5CYII=);
  top: -5px;
}
.PickupTime-DatePicker .rdtPicker thead th.dow,
.ui-datepicker .ui-datepicker-calendar thead th {
  font-weight: normal;
}
input.Overlay-Hours::-webkit-outer-spin-button,
input.Overlay-Hours::-webkit-inner-spin-button,
input.Overlay-Minutes::-webkit-outer-spin-button,
input.Overlay-Minutes::-webkit-inner-spin-button,
input.Hide-Arrow-Number::-webkit-outer-spin-button,
input.Hide-Arrow-Number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input.Overlay-Hours,
input.Overlay-Minutes {
  font-size: 15px;
  background-color: var(--color-dark-green);
  outline: 0;
  border: 0;
  border: 1px solid var(--color-white);
  box-shadow: none;
  color: var(--color-white);
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 0;
  position: absolute;
  bottom: 45px;
  z-index: 222222;
  border-radius: 1px;
  text-indent: -1px;
  -moz-appearance: textfield;
}
input.Overlay-Hours {
  left: 89px;
}
input.Overlay-Minutes {
  left: 133px;
}

input.Overlay-Hours.not-PH {
  left: 109px;
}
input.Overlay-Minutes.not-PH {
  left: 153px;
}

.rdtCounterSeparator {
  line-height: 96px;
}
.BookingWizard .Popover {
  margin-left: 16px;
}
.BookingWizard .Popover-xl {
  width: 301px;
  height: 45px;
}

.BookingWizard .FormGroup-label span {
  display: inline-block;
  padding-left: 1.5px;
}
.Step-Route .pac-container {
  left: 10px !important;
  margin-top: 1px;
  box-shadow: none;
  border: 0 none;
  width: 390px !important;
}
.Step-Route .pac-container .pac-item {
  border: 0 none;
}
.Step-LongHaul .pac-container {
  left: 796px !important;
  box-shadow: none;
  border: 0 none;
  width: 370px !important;
  margin-top: -72px !important;
}
.Step-LongHaul .pac-container .pac-item {
  border: 0 none;
}
.Input.error {
  border: 2px solid var(--color-red);
}
.address.FormGroup.error .Input {
  border: 2px solid var(--color-red);
}
.BookingWizard .Content h3.Custom {
  font-weight: normal;
  font-size: 15px;
}
.PageHead-title {
  padding: 0 60px 0 20px;
  font-size: 24px;
  font-weight: bold;
  color: #444444;
}
.Button-Default {
  min-width: 120px;
}
.Button-Default-Max {
  max-width: 120px;
}
.Button-Default.Button-With-Border,
.Button-Default.Button-Large {
  box-sizing: border-box;
  min-width: 140px;
}
.Button-Default.Button-With-Border {
  height: 36px;
}
.Button-Large {
  height: 40px;
}
.Closure-Head-Export {
  background: transparent;
  border: 1px solid var(--color-green);
  color: var(--color-green);
}
.Closure-Head-Export:hover,
.PageHead-actions .green-text.green-border.Button:hover,
.PageHead-title.Custom .back a:hover,
.Closure-Head-Export:focus {
  background: var(--color-green);
  color: var(--color-white);
}
.Filter .FormGroup label {
  text-transform: uppercase;
  margin-bottom: 0;
}
.Table td.selector {
  width: 50px;
}
.Table td.selector > div {
  margin: 5px auto;
  text-align: center;
  max-width: 20px;
}
.list-mar-10 > div {
  margin-bottom: 10px;
}
.list-mar-10 > div:last-child {
  margin-bottom: 0;
}
.m-auto {
  margin: auto;
}
.mt0 {
  margin-top: 0;
}
.mt0-ipt {
  margin-top: 0 !important;
}
.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}
.mt5 {
  margin-top: 5px;
}
.mt6 {
  margin-top: 6px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt60 {
  margin-top: 60px;
}
.mt60-imp {
  margin-top: 60px !important;
}
.mar0 {
  margin-bottom: 0;
}
.mar2 {
  margin-bottom: 2px;
}
.mar5 {
  margin-bottom: 5px;
}
.mar10 {
  margin-bottom: 10px;
}
.mar12 {
  margin-bottom: 12px;
}
.mar15 {
  margin-bottom: 15px;
}
.mar20 {
  margin-bottom: 20px;
}
.mar25 {
  margin-bottom: 25px;
}
.mar60 {
  margin-bottom: 60px;
}
.mar80 {
  margin-bottom: 80px;
}
.ml0 {
  margin-left: 0;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.mr0 {
  margin-right: 0 !important;
}
.mr2 {
  margin-right: 2px;
}
.mr5 {
  margin-right: 5px;
}
.mr6 {
  margin-right: 6px;
}
.mr9 {
  margin-right: 9px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb7 {
  margin-bottom: 7px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb17 {
  margin-bottom: 17px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m67 {
  margin-right: 67px;
}
#root .reset.mt,
.reset.mt {
  margin-top: 0;
}
#root .reset.mb,
.reset.mb {
  margin-bottom: 0;
}
#root .reset.ml,
.reset.ml {
  margin-left: 0;
}
#root .reset.mr,
.reset.mr {
  margin-right: 0;
}
#root .reset.m,
.reset.m {
  margin: 0;
}
.p-relative-1 {
  position: relative;
  top: 1px;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.pt0 {
  padding-top: 0;
}
.pt2 {
  padding-top: 2px;
}
.pt4 {
  padding-top: 4px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt16 {
  padding-top: 16px;
}
.pt20 {
  padding-top: 20px;
}
.pt25 {
  padding-top: 25px;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pb2 {
  padding-bottom: 2px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb11 {
  padding-bottom: 11px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb25 {
  padding-bottom: 25px;
}
.pl0 {
  padding-left: 0;
}
.pl5 {
  padding-left: 5px;
}
.pl8 {
  padding-left: 8px;
}
.pl10 {
  padding-left: 10px;
}
.pl12 {
  padding-left: 12px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl25 {
  padding-left: 25px;
}
.pl40 {
  padding-left: 40px;
}
.pr0 {
  padding-right: 0;
}
.pr5 {
  padding-right: 5px;
}
.pr8 {
  padding-right: 8px;
}
.pr10 {
  padding-right: 10px;
}
.pr12 {
  padding-right: 12px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr25 {
  padding-right: 25px;
}
.pr40 {
  padding-right: 40px;
}
.lh15 {
  line-height: 15px;
}
.w-fit-content {
  width: fit-content;
}
.text-center {
  text-align: center;
}
.br5 {
  border-radius: 5px;
}
#root .reset.pt,
.reset.pt {
  padding-top: 0;
}
#root .reset.pb,
.reset.pb {
  padding-bottom: 0;
}
#root .reset.pr,
.reset.pr {
  padding-right: 0;
}
#root .reset.pl,
.reset.pl {
  padding-left: 0;
}
#root .reset.p,
.reset.p {
  padding: 0;
}
#root .reset.p-imp,
.reset.p-imp {
  padding: 0 !important;
}
#root .reset.pt-imp,
.reset.pt-imp {
  padding-top: 0 !important;
}
#root .p-h-5-imp,
.p-h-5-imp {
  padding: 0 5px !important;
}
.no-overflow-x {
  overflow-x: hidden;
}
.Popover-Custom .Popover-Item .FormGroup {
  margin: 0;
}
.Custom-Mb-15 {
  margin-bottom: 15px !important;
}
.mb-10-imp {
  margin-bottom: 10px !important;
}
.mt-20-imp {
  margin-top: 20px !important;
}
.Input.Custom input,
.Select.Custom select,
.Textarea.Custom textarea {
  border-radius: var(--radius) 0 0 var(--radius);
}
.BookingWizard .Locations.block .block-item.actions .Button {
  padding-right: 9px;
  padding-left: 9px;
}
.BookingWizard .Popover-Arrow {
  width: 26px;
  height: 26px;
  left: -8px;
}
.BookingWizard .Popover-Arrow.Custom {
  left: -4px;
}
#share-eta-content label {
  padding-right: 90px;
}
#share-eta-content a div {
  width: 20px;
}
#options-content .customer-dnd-label {
  padding-right: 100px;
}
#options-content a div {
  width: 20px;
}
.Menu-Nav.left {
  padding-left: 20px;
}
.Services.block .block-item .Services-PopupEye,
.Services.block .block-item .Vehicle-Service-Type-Popup-Eye {
  width: 28px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Services.block:not(.list-view) .block-item .Vehicle-Service-Type-Popup-Eye {
  position: absolute;
  top: 5px;
  right: 5px;
}
.Services.block .block-item .Services-PopupEye i,
.Services.block .block-item .Vehicle-Service-Type-Popup-Eye i {
  font-size: 21px;
}
#root .BookingWizard .Services.block .Services-Icon {
  margin-top: 30px;
}
#add-destination {
  border: 1px solid var(--color-green);
  color: var(--color-green);
}
.Locations.block .block-item.actions .optimize-route .Overlay-Checkbox label {
  color: var(--color-gray);
}
.Avatar {
  width: 46px;
  height: 46px;
}
.locations-error {
  margin-top: 0;
}
.location__edit--error {
  position: absolute;
  bottom: -23px;
}
.Popup .Driver-Note-Item {
  margin-top: 20px;
}
.Popup .AddressItem,
.Popup .Driver-Note-Item {
  margin-top: 15px;
  border-bottom: 1px solid var(--color-white);
  padding-bottom: 15px;
}
.Popup .AddressItem:last-child,
.Popup .Driver-Note-Item:last-child {
  border: 0 none;
  padding-bottom: 0;
}
.Popup .Driver-Note-Item .Driver-Note-Pin {
  margin-right: 10px;
}
.Popup .Driver-Note-Item .Driver-Note-Info p {
  text-align: left;
}
#root .block-sub-item-custom.block-sub-item-custom {
  padding-left: 36px !important;
}
.block-sub-item-custom > label {
  margin: 0 0 0 10px !important;
}
.block-sub-item-custom .Icon {
  margin: 0 !important;
}
.BookingWizard .Content .block.PricingNote,
.BookingWizard .Content .block.PricingNote .block-item {
  padding-left: 0;
  padding-right: 0;
}
.PricingNote.block .block-item,
.PricingNote.block {
  font-size: 13px;
  padding: 0;
  line-height: 1.57;
  color: var(--color-white);
}
.CashbackNote {
  background: #ebf6e9;
  margin: 10px -10px -10px;
  padding: 11px 0px 0px 0px;
  border-radius: 0px 0px 5px 5px;
  font-size: 13px;
  display: flex;
  color: #4f4f4f;
}
.CashbackNote .cashback-icon {
  margin-top: -8px;
}
.CashbackNote .completed {
  color: #02751a;
}
.Pricing-bock-custom {
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px !important;
}
#root .Pricing-bock-custom div {
  z-index: 2;
  width: auto;
  min-width: 214px;
  border-top: 1px solid var(--color-green) !important;
  border-bottom: 1px solid var(--color-green) !important;
  background-color: var(--color-white);
  margin: auto auto 10px;
  padding: 9px 15px !important;
  display: inline-block;
  position: relative;
  max-height: 39px;
  min-height: 39px;
  box-sizing: border-box;
  white-space: nowrap;
}
#root .Pricing-bock-custom div:before,
#root .Pricing-bock-custom div:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.79 78.5'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:none;stroke:%2357ae2a;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow%3C/title%3E%3Cg id='Pu'%3E%3Cpath id='Path-6' class='cls-1' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3Cg id='Pu-2' data-name='Pu'%3E%3Cpath id='Path-6-2' data-name='Path-6' class='cls-2' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 33px 39px;
  height: 39px;
  width: 28px;
  position: absolute;
  top: -1px;
}
#root .Pricing-bock-custom .Pricing-bock-custom__line {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 15px;
  right: 15px;
  margin-top: -6px;
  height: 1px;
  background-color: var(--color-green);
}
#root .Pricing.Pricing-Custom-Width .Pricing-bock-custom .Pricing-bock-custom__line {
  left: 0;
  right: 0;
}
#root .Pricing-bock-custom div.white-pricing {
  border-top: 1px solid var(--color-white) !important;
  border-bottom: 1px solid var(--color-white) !important;
}
#root .Pricing-bock-custom div.white-pricing:before,
#root .Pricing-bock-custom div.white-pricing:after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='49px' height='78px' viewBox='0 0 29 78' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 58 (84663) - https://sketch.com --%3E%3Ctitle%3EPath 6%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Pu' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M48.8660141,1 L39.0710678,1 C37.7449854,1 36.4732158,1.5267842 35.5355339,2.46446609 L2.53553391,35.4644661 C0.582912447,37.4170876 0.582912447,40.5829124 2.53553391,42.5355339 L36.0355339,76.0355339 C36.9732158,76.9732158 38.2449854,77.5 39.5710678,77.5 L48.8660141,77.5 L48.8660141,77.5' id='Path-6' stroke='%23FFFFFF' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
#root .Pricing-bock-custom div:before {
  left: -28px;
}
#root .Pricing-bock-custom div:after {
  right: -28px;
  transform: rotate(180deg);
}
#root .Pricing-bock-custom div b {
  font-size: 15px;
  display: block;
  text-align: center;
  margin-bottom: 0;
  color: var(--color-green) !important;
  font-weight: bold;
}
.Pricing-bock-custom div i {
  color: var(--color-white);
  font-size: 17px;
  margin-right: 10px;
  height: 18px;
}
#root .Pricing-bock-custom div.white-pricing b {
  color: var(--color-white) !important;
}
.Pricing.block .block-item:not(.total) {
  border-top: 0 none;
}
.Pricing.block .block-item.total {
  border: 0 none;
  padding-top: 0 !important;
  position: relative;
  margin: 0 !important;
}
#root .Pricing.block .block-item.total * {
  color: #444444;
  font-size: 17px;
  text-transform: none;
}
.Pricing.block > .block-item:not(.total),
.Pricing.block .block-sub-item,
.Pricing.block .block-item.total {
  position: relative;
  z-index: 1;
}
.Pricing.block > .block-item:not(.total):before,
.Pricing.block .block-sub-item:before,
.Pricing.block .block-item.total:before,
.Pricing.block .block-item-sub > div:before {
  content: "";
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  z-index: -1;
  background-repeat: repeat-x;
  height: 2px;
  background-size: 5px;
  background-position: 0px 0;
}
.Pricing.block .block-sub-item:before {
  left: 20px;
}
.Pricing.block > .block-item:not(.total) label b,
.Pricing.block > .block-item:not(.total) span,
.Pricing.block > .block-item:not(.total) img,
.Pricing.block .block-item-sub > div label b,
.Pricing.block .block-item-sub > div span,
.Pricing.block .block-sub-item b,
.Pricing.block .block-sub-item span {
  background-color: white;
  color: #444444;
}
.Pricing.block .block-item b,
.Pricing.block .block-sub-item b {
  font-weight: normal;
  color: #444444;
}
.Pricing.block .block-item > span,
.Pricing.block .block-sub-item > span {
  padding-left: 5px;
}
.Pricing.block > .block-item:not(.total) label b,
.Pricing.block > .block-item:not(.total) label span,
.Pricing.block .block-sub-item label b,
.Pricing.block .block-sub-item label span {
  padding-right: 5px;
}
.Pricing-Custom.block {
  background: var(--color-white);
  margin: 20px 0;
  border-radius: var(--radius);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.block-item-sub:before {
  display: none;
}
.Pricing.block .block-item-sub {
  padding-top: 0 !important;
}
.Pricing.block .block-item-sub-custom {
  flex-direction: column;
}
.Pricing.block .block-item-sub-custom > div {
  margin-bottom: 10px;
  position: relative;
}
.Pricing.block .block-item-sub-custom > div:before {
  bottom: 3px;
}
.Pricing.block .block-item-sub-custom > div:last-child {
  margin-bottom: 0;
}
.Pricing.block .block-item-sub > div {
  padding: 0 !important;
  width: 100%;
}
#root .Pricing-Custom-Width .Pricing-bock-custom,
#root .Pricing-Custom-Width {
  width: 100%;
}
#root .Pricing-Custom-Width .PricingNote:not(.noted) p {
  font-size: 13px;
  padding-top: 5px !important;
}
.Box-Icon-Plate {
  background: var(--color-dark-green);
  color: white;
  height: auto;
  bottom: -70px;
  font-size: 13px;
  line-height: 26px;
  padding: 0 15px;
  width: auto;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border-bottom: 0 none;
  white-space: nowrap;
  left: -15px;
  right: -15px;
  min-width: 90px;
}
.modal-RatingDriver .Box-Icon-Plate {
  bottom: -40px;
}
.RatingDriver-Block .Avatar {
  margin: 50px auto auto;
}
.Popup-Booking-Normal h5 {
  color: var(--color-yellow);
  margin: 10px 0 55px;
  font-size: 22px;
  text-align: center;
  font-weight: 400;
}
.Reset-Title-Popup-Booking .Popup-Booking-Normal h5 {
  margin: 0 0 15px;
}
.PageHead-title.Custom {
  display: flex;
  align-items: center;
}
.PageHead-title.Custom .back a {
  margin-right: 10px;
}
.RightMenu-avatar-block .TitleSubtitle {
  margin: 5px 0 10px 0;
}
.RightMenu-avatar-block .actions button {
  margin: 5px 0 10px 0px;
}
.Empty-Box span {
  font-size: 17px;
  margin: 20px 0 30px 0;
  color: #7c7c7c;
}
.Closure-Head-Export {
  margin-right: 10px;
}
.PageHead-actions {
  margin-right: 20px;
}
.Menu-Nav.right {
  padding-right: 10px;
}
.Menu a,
.Menu a .Button,
.Menu .TitleSubtitle-title,
.Dropdown-Head-Item {
  font-size: 17px;
}
.Dropdown-Menu-Item .Dropdown-Menu-Item-Sub li a {
  font-size: 18px;
}
#root + #d_hotline_frame,
#root ~ #d_hotline_frame {
  right: 25px !important;
}
#root + #fc_frame,
#root ~ #fc_frame {
  &:not(.expanded-modal) {
    bottom: 62px !important;
  }
  z-index: 20010;
}
#root + #d_hotline_frame.custom,
#root ~ #d_hotline_frame.custom,
.ez-multiple-chat-hotline #root + #d_hotline_frame,
.ez-multiple-chat-hotline #root ~ #d_hotline_frame,
.ez-multiple-chat-hotline #root + #fc_frame,
.ez-multiple-chat-hotline #root ~ #fc_frame {
  bottom: 80px !important;
}
.ez-multiple-chat-hotline #root .header-bar .Menu {
  z-index: 9;
}
.custom-chat-hotline #root + #d_hotline_frame,
.custom-chat-hotline #root ~ #d_hotline_frame {
  bottom: 60px !important;
  right: 33px !important;
}
.custom-chat-hotline #root + #fc_frame,
.custom-chat-hotline #root ~ #fc_frame {
  bottom: 66px !important;
  right: 33px !important;
}
.BookingInfo-Actions .Button {
  width: auto;
  padding: 0 20px;
}
.BookingInfo-Actions .Button:hover {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
}
.PageHead {
  box-sizing: border-box;
  height: 50px;
}
.Locating .Modal-Basic .Box {
  max-width: 600px;
}
.DriverBlock-Button.DetailBooking-FormGroup-Custom-Button .Closure-List-Button.With-Icon {
  height: 40px;
}
.Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: var(--color-dark-green) !important;
  color: var(--color-white) !important;
}
.Closure-Scheduled .Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Fullday .Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: #077490 !important;
  border-radius: var(--radius) var(--radius) 0 0;
}
.Closure-Scheduled .Closure-List-Tab-Bottom i.green,
.Closure-Fullday .Closure-List-Tab-Bottom i.green {
  color: #077490 !important;
}
.Closure-List .Row {
  height: 100%;
}
.Menu-Nav.left .Dropdown-Head-Caret i,
.Menu-Nav.right .Dropdown-Head-Caret i {
  font-size: var(--font-size-xb);
}
.DetailBooking-Price.Pricing {
  padding: 0 !important;
}
.PricingNote.block .block-item.noted-text {
  color: var(--color-black);
  margin: 15px 0 10px 0 !important;
}
.PricingNote.block .block-item.noted-text-custom {
  color: var(--color-black);
  padding: 0 !important;
}
.Popover-contact-list-arrow:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-green);
  position: absolute;
  top: -4px;
  left: 70px;
  z-index: 3;
}
.Popover-contact-list {
  background-color: var(--color-white);
}
.Popover-contact-list-empty {
  padding: 15px;
  font-size: 13px;
  text-align: center;
  color: var(--color-gray-xd);
}
.Popover-contact-tabs span {
  box-sizing: border-box;
  color: #808080;
  height: 45px;
  line-height: 45px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
}
.Popover-contact-tabs span.Popover-contact-tabs-active {
  border-bottom: 3px solid var(--color-green);
  color: var(--color-green);
}
.BookingWizard .Selectbox-Contact-Customs {
  max-width: 290px;
  padding-left: 0px;
  padding-right: 0;
  margin-left: 5px;
}
.BookingWizard.card-step-3 .Selectbox-Contact-Customs {
  margin-top: -5px;
}
.BookingWizard .Selectbox-Contact-Customs .Popover-contact-list-arrow:before {
  left: 65px;
}
.BookingWizard .Selectbox-Contact-Customs .Popover-contact-list-arrow:after {
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -4px;
  left: 65px;
}
.BookingWizard #Step-03 .Selectbox-Contact-Customs .Popover-contact-list-arrow:after,
.BookingWizard.card-step-3 .Selectbox-Contact-Customs .Popover-contact-list-arrow:after,
.cpod-container .Selectbox-Contact-Customs .Popover-contact-list-arrow:after,
.BookingWizard #Step-03 .Selectbox-Contact-Customs .Popover-contact-list-arrow:before,
.BookingWizard.card-step-3 .Selectbox-Contact-Customs .Popover-contact-list-arrow:before,
.cpod-container .Selectbox-Contact-Customs .Popover-contact-list-arrow:before {
  left: auto;
  right: 65px;
}
.Step-Route .Login,
.Step-Route .Signup,
.Step-Route .LoginBox,
.Step-Route .LoginBoxCustom,
.Step-Route .SelectSubAccountBox,
.Step-Route .SelectAccountBox {
  top: 0;
}
.Login .Box,
.Signup .Box,
.LoginBox .Box,
.LoginBoxCustom .Box,
.SelectSubAccountBox .Box,
.SelectAccountBox .Box,
.Popup-Booking {
  margin: 0;
  align-self: center;
}
.Login .Box.Box-Custom,
.Signup .Box.Box-Custom,
.LoginBox .Box.Box-Custom,
.LoginBoxCustom .Box.Box-Custom,
.SelectSubAccountBox .Box.Box-Custom,
.SelectAccountBox .Box.Box-Custom {
  margin: 80px 0 40px;
  align-self: flex-start;
}
.DetailBooking-FormGroup.Popup .Pricing {
  width: 100%;
  margin: 0;
}
.DetailBooking-FormGroup table td,
.DetailBooking-FormGroup-Bg > div {
  font-size: var(--font-size-s);
}
.Menu-Nav-Item-Flag {
  padding-right: 15px;
}
.Menu-Nav-Item-Flag a {
  height: 24px;
}
.Menu-Nav-Item-Flag img {
  max-width: 24px;
}
.Menu-Nav-Item-Flag a:last-child {
  margin-left: 5px;
}
.Menu-Nav-Item-Flag a:first-child {
  margin-left: 0;
}
.Menu-Nav-Item-Flag a img {
  opacity: 0.5;
}
.Menu-Nav-Item-Flag a.active img,
.Menu-Nav-Item-Flag a img:hover {
  opacity: 1;
}
.Menu-Nav-Item-Button button.white-text:hover,
.Menu-Nav-Item-Button a.white-text:hover {
  background-color: var(--color-dark-green);
  color: var(--color-white);
  border-color: var(--color-dark-green);
}
.mfp-wrap .mfp-close,
.mfp-wrap .mfp-title {
  opacity: 0;
  pointer-events: none;
}
.Dropdown-Menu-Item-Sub {
  list-style: none;
  padding: 0 10px 0 10px;
  margin: 0;
  position: absolute;
  left: 101%;
  top: 0;
  background-color: var(--color-dark-green);
  display: none;
  font-size: 18px;
}
.Dropdown-Menu-Item-Sub::-webkit-scrollbar {
  box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
  width: 5px !important;
}
.Dropdown-Menu-Item-Sub li {
  padding: 10px 0;
  box-sizing: border-box;
  position: relative;
  display: block;
  text-decoration: none;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.Dropdown-Menu-Item-Sub li:first-child {
  border-top: 0 none;
}
.Dropdown-Menu-Item-Sub li a {
  color: var(--color-white);
}
.Dropdown-Menu-Item-Sub li a:hover {
  color: var(--color-yellow);
}
.Dropdown-Menu-Item:hover .Dropdown-Menu-Item-Sub {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
}
.Dropdown-Menu-Item.Font-Size {
  font-size: 18px;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-start {
  display: flex;
  align-items: center;
}
.flex-start-align {
  align-items: flex-start;
}
.flex-start-justify {
  justify-content: flex-start;
}
.flex-end-align {
  align-items: flex-end;
}
.flex-end-justify {
  justify-content: flex-end;
}
.flex-space-between-justify {
  justify-content: space-between;
}
.flex-space-around-justify {
  justify-content: space-around;
}
.flex-index {
  flex: 1 !important;
}
.flex-two {
  flex: 2 !important;
}
.flex-three {
  flex: 3 !important;
}
.flex-four {
  flex: 4 !important;
}
.flex-five {
  flex: 5 !important;
}
.flex-auto {
  flex: auto !important;
}
.flex-start-algin {
  align-items: flex-start;
}
.flex-start-algin-important {
  align-items: flex-start !important;
}
.flex-center-algin {
  align-items: center !important;
}
.flex-end-algin {
  align-items: flex-end;
}
.flex-center-justify {
  justify-content: center;
}
.flex-start-justify {
  justify-content: flex-start;
}
.flex-end-justify {
  justify-content: flex-end;
}
.flex-start-content {
  justify-content: flex-start;
}
.flex-center-content {
  justify-content: center;
}
.flex-end-content {
  justify-content: flex-end;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.FormGroup-Contacts select {
  width: 170px;
}
.label-required:after {
  content: "*";
  display: inline-block;
  padding-left: 2.5px;
  color: var(--color-red);
}
.label-required-no-show:after {
  display: none;
}
.label-required.white-label-required:after {
  color: var(--color-white);
}
.required-group {
  color: var(--color-red);
}
.required-group span.required {
  padding-left: 0;
  padding-right: 2.5px;
}
.download_template {
  margin-left: 10px;
}
.custon-icon-material {
  position: relative;
}
.custon-icon-material-cloud .pos {
  position: absolute;
  bottom: 8px;
  left: 0;
  margin: auto;
  right: 0;
  max-width: 32px;
  font-size: 30px;
}
.Contacts .ItemAddingBlock .Dropzone {
  margin-top: 25px;
}
.Contacts .ItemAddingBlock .FormGroup,
.Employee .ItemAddingBlock .FormGroup {
  margin-bottom: 10px;
}
.BookingWizard .Actions .Button:hover,
.Button-Dark-Green-Hover:hover {
  background-color: #40811e;
}
.center-checkbox > div {
  display: inline-block;
  margin: auto;
}
.BookingInfo-Block {
  display: flex;
  height: 120px;
  box-sizing: border-box;
}
.BookingInfo-Block > .BookingInfo-Block-Chat {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 66px;
  width: 250px;
  max-height: 120px;
}
.BookingInfo-Block > .BookingInfo-Block-Chat table {
  padding-left: 0;
  padding-right: 0;
}
.BookingInfo-Block > .BookingInfo-Block-Chat td {
  padding-right: 0;
  white-space: nowrap;
}
.BookingInfo-Block > .BookingInfo-Block-Chat button,
.BookingInfo-Block > .BookingInfo-Block-Chat a {
  margin-right: 10px;
  box-sizing: border-box;
}
.BookingInfo-Block > .BookingInfo-Block-Chat button:last-child,
.BookingInfo-Block > .BookingInfo-Block-Chat a:last-child {
  margin-right: 0;
}
.BookingInfo-Block > .BookingInfo-Block-Hotline {
  position: absolute;
  top: 20px;
  bottom: 66px;
  right: 20px;
  max-height: 128px;
}
.BookingInfo-Block-Border {
  width: 95px;
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid var(--color-white);
  border-radius: 6px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}
.BookingInfo-Block-Border span {
  font-size: 13px;
  font-weight: 900;
  padding: 5px 0 0;
  display: block;
}
.BookingInfo-Block-Border div {
}
.BookingInfo-Block-Border div img {
  max-width: 100%;
}
.BookingInfo-Block-Border div img.BookingInfo-Block-Border-Loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 46px;
  bottom: 0;
  max-height: 40%;
  max-width: 40%;
  margin: auto;
}
.BookingInfo-Block > div table {
  padding-top: 0;
}
.BookingInfo table thead td {
  border-color: var(--color-white);
  color: var(--color-white);
}
.BookingInfo table thead td.f {
  padding-right: 60px;
}
.BookingInfo table thead td.t {
  padding-right: 60px;
}
.BookingInfo table tbody td,
.BookingInfo table thead td {
  padding-left: 0;
}
.BookingInfo .ContactInfo-Box {
  padding-right: 0;
}
.BookingInfo .ContactInfo-Box .ContactInfo div {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 7px;
}
.BookingInfo .ContactInfo-Box .ContactInfo div span.yellow {
  flex: 1;
}
.BookingInfo .BookingDetail,
.BookingInfo .PickupDetail {
  padding-top: 2px;
}
.BookingInfo .ContactInfo-Box .ContactInfo .box-chat {
  border-radius: 20px;
  height: 23px;
  width: 102px;
  margin-left: 10px;
  margin-bottom: 0;
}
.BookingInfo .ContactInfo-Box .ContactInfo .box-chat-custom {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  width: 120px;
}
.BookingInfo .ContactInfo-Box .ContactInfo .box-chat-custom a {
  flex: 1;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}
.BookingInfo .ContactInfo-Box .ContactInfo .box-chat-custom a:last-child {
  border-left: 1px solid var(--color-white);
}
.BookingInfo .ContactInfo-Box .ContactInfo .box-chat-custom a:hover {
  background-color: var(--color-white);
  color: var(--color-gray);
}
.DriverBlock .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension .DriverInfo-Vehicle-Flex-Container {
  display: flex;
}
.DriverBlock
  .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension
  .DriverInfo-Vehicle-Flex-Container
  .Vehicle-Service-Type-Popup-Eye {
  margin-left: 5px;
  margin-top: -1px;
}
.DriverBlock .DriverInfo-Vehicle:not(.DriverInfo-Vehicle-Dimension) p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.DriverBlock .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension p {
  padding: 0;
  text-align: left;
  word-break: break-all;
}
.DriverBlock .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension p:not(.Dimension-Content) {
  margin: 0;
}
.DriverBlock .DriverInfo-Vehicle .DriverInfo-Vehicle-BranchName {
  display: inline-block;
}
.DriverBlock .DriverInfo-Vehicle .DriverInfo-Vehicle-Dimension,
.DriverBlock .DriverInfo-Vehicle .DriverInfo-Vehicle-Dimension-Tracking {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 1px;
}
.Vehicle-Service-Type-Popup-Eye i.Icon {
  color: var(--color-gray);
}
.Vehicle-Service-Type-Popup-Eye i.Icon:hover {
  cursor: pointer;
  color: var(--color-green);
}
.DriverBlock .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension .Vehicle-Service-Type-Popup-Eye .Icon,
.DriverBlock
  .DriverInfo-Vehicle
  .DriverInfo-Vehicle-Dimension-Tracking.Tracking-Page
  .Vehicle-Service-Type-Popup-Eye
  .Icon {
  color: unset;
}
.DriverBlock .DriverInfo-Vehicle.DriverInfo-Vehicle-Dimension .Vehicle-Service-Type-Popup-Eye .Icon:hover,
.DriverBlock .DriverInfo-Vehicle .DriverInfo-Vehicle-Dimension-Tracking .Vehicle-Service-Type-Popup-Eye .Icon:hover {
  cursor: pointer;
}
.Popup.normal-driver-found .title {
  font-size: 19px;
}
.DynamicPopupWrapper .Popup {
  width: 400px;
  margin: auto;
}
.DynamicPopupWrapper .Popup .Box-Content {
  margin: 0px;
  padding: 0 15px 15px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
}
.DynamicPopupWrapper .Popup .Box-Content .Normal {
  margin-top: 30px;
}
.Empty-Box {
  color: #7c7c7c;
}
.Empty-Box-Custom a {
  max-width: 450px;
  font-size: 17px;
}
.Empty-Box-Custom a:hover {
  color: #0e730f;
  background-color: transparent !important;
}
.DynamicPopupWrapper .Popup .Box-Content .Normal .Normal-Booking-Image {
  width: 330px;
}
.DynamicPopupWrapper .Popup .Normal-Booking-Image > div {
  width: 100%;
}
.DynamicPopupWrapper .Popup.normal-driver .Normal-Booking-Image p {
  color: var(--color-black);
  padding: 0;
  margin: 44px 0 0 70px;
  font-size: 14px;
  max-width: 124px;
  height: 92px;
  white-space: pre-line !important;
  text-align: center !important;
}
.DynamicPopupWrapper .Popup.normal-driver .Normal-Booking-Image b {
  top: 20px;
  right: 30px;
  white-space: pre-line !important;
  text-align: center !important;
}
.DynamicPopupWrapper:not(#DimensionPopupDefault):not(#DefaultPopup) .Popup .Box-Icon.Box-Icon-Popup .Logo img {
  height: 20px;
  max-width: 100%;
  width: auto;
}
.DynamicPopupWrapper .Popup .Popup-Booking-Custom {
  min-width: 360px;
  max-width: 360px;
  margin: 0 auto;
  display: block;
}
.DynamicPopupWrapper .Popup .Popup-Booking-Custom p {
  font-size: var(--font-size);
  white-space: pre-line !important;
  line-height: 24px;
  margin: 0;
}
.DynamicPopupWrapper .Popup .Popup-Booking-Normal h5 {
  margin: 15px 0 10px;
}
.DynamicPopupWrapper .Popup .Popup-Booking-Custom h5.mar5 {
  margin-top: 20px;
}
#PopupExistAccount.DynamicPopupWrapper:not(#DimensionPopupDefault) .Popup .Box-Icon.Box-Icon-Popup .Logo img {
  height: 40px;
}
#PopupExistAccount.DynamicPopupWrapper .Popup .Popup-Booking-Custom {
  padding: 10px 10px 0 10px;
  min-width: 290px;
  max-width: 290px;
  box-sizing: border-box;
}
#PopupExistAccount .Button-Back {
  background-color: #9b9b9b;
  color: white;
  border: none;
}
#PopupExistAccount .Button-Back:hover {
  background-color: var(--color-gray-xl);
  color: var(--color-black);
}
.DynamicPopupWrapper .Popup .Box-Icon.Box-Icon-Popup .Logo {
  padding: unset;
}
.Devina-Screen .Dropdown-Menu-Item {
  width: 510px;
  padding: 10px;
}
.Devina-Screen .Dropdown-Menu-Item.Custom {
  width: 470px;
}
.Dropdown-Devina-Group {
  flex: 1;
  overflow-y: hidden;
  font-size: var(--font-size);
  text-align: left;
  padding: 0 30px 0 15px;
}
.Dropdown-Devina-Group span,
.Dropdown-Devina-Group b {
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Overlay-Lightbox-Image,
.z-index-2 {
  z-index: 2;
}
.z-index-4 {
  z-index: 4 !important;
}
.z-index-2-max {
  z-index: 2 !important;
}
.z-index-3-max {
  z-index: 3 !important;
}
.z-index-22 {
  z-index: 22;
}
.z-index-21 {
  z-index: 21 !important;
}
.z-index-22-max {
  z-index: 22 !important;
}
.z-index-222 {
  z-index: 222 !important;
}
.z-index-max {
  z-index: 1000000 !important;
}
.z-index-auto {
  z-index: auto;
}
.Booking-Modal .Normal-Booking {
  width: 288px;
}
.Booking-Modal .dlvr-normal-booking {
  width: 100%;
  padding: 0 10px;
}
.Quick-Choices {
  padding: 0 10px 5px;
  margin: 0;
  list-style: none;
}

.quick-choices-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--color-white);
  border-radius: 4px;
}
.quick-choices-ul li {
  height: 60px;
}
.quick-choices-ul .qc-active {
  background-color: var(--color-gray-xxxl);
}
.quick-choices-ul li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.quick-choices-ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0 none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.quick-choices-ul li > div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 15px;
}
.quick-choices-ul li:last-child > div {
  border-bottom: none;
}

.Quick-Choices li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-white);
}
.Quick-Choices li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0 none;
}
.Quick-Choices li > div {
  display: flex;
  align-items: center;
}
.Quick-Choices .Overlay-RadioBox label {
  font-size: var(--font-size);
}
.Devina-Screen .Dropdown-Menu img {
  min-width: 40px;
  max-width: 40px;
}
.Locations-Custom .FormGroup {
  margin: 0;
}
.Driving-count li:nth-child(2) {
  word-break: break-word;
  width: 68px;
}
.BookingWizard .Modal.visible {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
}
.BookingWizard .Modal.visible .Normal-Booking {
  top: inherit;
  bottom: inherit;
  align-self: center;
}

.BookingWizard .Modal.visible .dlvr-normal-booking {
  top: inherit;
  bottom: inherit;
  align-self: center;
}

.BookingWizard .Modal.visible .Normal-Booking.No-Seft,
.BookingWizard-Popup .Normal-Booking.No-Seft,
.DynamicPopupWrapper .Popup.No-Seft {
  align-self: flex-start;
  margin: 80px auto 40px;
}
.Devina-Screen .Dropdown-Menu {
  max-height: 500px;
  overflow-y: auto;
}
.BookingWizard .Popover-contact-list.Custom,
.BookingWizard-Popup .Popover-contact-list.Custom,
.Modal-FormGroup-Note .Popover-contact-list.Custom {
  padding-top: 47px;
  max-height: 236px;
  overflow-x: hidden;
}
.BookingWizard .Selectbox-Contact-Input,
.modal-batchUploadValidate .Selectbox-Contact-Input,
.Modal-FormGroup-Note .batch-contact-filter-style,
.Modal-FormGroup-Note .Selectbox-Contact-Input {
  padding: 10px 10px 5px 10px;
  position: absolute;
  left: 1px;
  right: 1px;
  top: 5px;
  background-color: var(--color-white);
  z-index: 2;
  height: 32px;
}
.Modal-FormGroup-Note .batch-contact-filter-style,
.Modal-FormGroup-Note .Selectbox-Contact-Input {
  margin: 0;
}
.BookingWizard .Selectbox-Contact-Input.Custom,
.modal-batchUploadValidate .Selectbox-Contact-Input.Custom,
.Modal-FormGroup-Note .batch-contact-filter-style,
.Modal-FormGroup-Note .Selectbox-Contact-Input {
  border-radius: var(--radius-s) var(--radius-s) 0 0;
}
.modal-batchUploadValidate .Selectbox-Contact-Input.Custom,
.Modal-FormGroup-Note .batch-contact-filter-style,
.Modal-FormGroup-Note .Selectbox-Contact-Input {
  left: 5px;
  right: 5px;
}
.BookingWizard .Selectbox-Contact-Input.Custom {
  left: 0;
  right: 0;
}
.Grid-Cell .BookingWizard .Selectbox-Contact-Input.Custom,
.MultipleBookingLayout .BookingWizard .Selectbox-Contact-Input.Custom {
  left: 5px;
  right: 5px;
}
.modal-batchUploadValidate .Popover-contact-list,
.Modal-FormGroup-Note .Popover-contact-list {
  border-color: var(--color-white);
}
.BookingWizard .Selectbox-Contact-Input .Input,
.modal-batchUploadValidate .Selectbox-Contact-Input .Input,
.Modal-FormGroup-Note .batch-contact-filter-style .Input,
.Modal-FormGroup-Note .Selectbox-Contact-Input .Input {
  height: 30px;
  background-color: #f9f9f8;
}
.BookingWizard .Selectbox-Contact-Input .Input .Icon,
.modal-batchUploadValidate .Selectbox-Contact-Input .Input .Icon,
.Modal-FormGroup-Note .batch-contact-filter-style .Icon,
.Modal-FormGroup-Note .Selectbox-Contact-Input .Icon {
  margin: 0 0 0 10px;
}
.BookingWizard .Selectbox-Contact-Input .Input input,
.modal-batchUploadValidate .Selectbox-Contact-Input .Input input,
.Modal-FormGroup-Note .batch-contact-filter-style input,
.Modal-FormGroup-Note .Selectbox-Contact-Input input {
  padding-left: 5px;
}
.PickupTime.block .PickupTime-Image {
  padding: 5px 0;
}
.TitleSubtitle-title .step-2-eye {
  margin-left: 10px;
  margin-top: 0;
  display: flex;
  align-items: center;
}
.TitleSubtitle-title .step-2-eye i {
  font-size: 21px;
  color: var(--color-gray);
}
.BookingWizard .Actions .Button.gray:hover {
  background: var(--color-dark-green);
  border-color: var(--color-dark-green);
  color: var(--color-white);
}
.BookingWizard .Content .block.PricingNote,
.BookingWizard .Content .block.PricingNote .block-item {
  line-height: 18px;
}
.BookingInfo-Actions-Right .Button:hover {
  background-color: var(--color-green);
  border-color: var(--color-white);
  color: var(--color-white);
}
.Closure-List-Button.With-Icon .Favorite-Icon-active:hover {
  color: var(--color-green) !important;
}
.Closure-List-Button.With-Icon .Ban-Icon-active:hover {
  color: var(--color-red) !important;
}
.Closure-List-Button.With-Icon b {
  font-size: 13px;
  padding: 0 0 2px 0;
}
.a-add-to_favorite i,
.Ban-Icon i:first-child {
  font-size: 20px !important;
}
.Ban-Icon i.close {
  width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  left: 17px;
}
.Button-Save-Booking-Choose {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-top: 10px;
  height: var(--height);
  border: 1px solid var(--color-white);
  position: relative;
}
.Button-Save-Booking-Choose span {
  flex: 1;
  text-align: center;
  line-height: 34px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  white-space: nowrap;
  min-width: 80px;
  box-sizing: border-box;
  cursor: pointer;
}
.Button-Save-Booking-Choose .first {
  border-radius: 6px 0 0 6px;
}
.Button-Save-Booking-Choose .last {
  border-radius: 0 6px 6px 0;
}
.Button-Save-Booking-Choose-Line {
  position: absolute;
  top: 0;
  bottom: 0px;
  background: #f5f6f7;
  z-index: 10;
  border-radius: 4px;
  transition: 400ms ease 0s;
  cursor: pointer;
}
.Button-Save-Booking-Choose-Line:before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: #ddd;
  z-index: 10;
  border-radius: 0 0 4px 4px;
  height: 50%;
}
.Button-Save-Booking-Loading {
  position: absolute;
  top: 50%;
  left: -34px;
  margin-top: -12px;
  display: none;
}
.Button-Save-Booking-Loading img {
  height: 24px;
}
.Table-Left td,
.Table-Left th {
  text-align: left !important;
}
.PickupTime-Line {
  height: 2px;
  background-color: var(--color-white);
  width: 60%;
  margin: 5px auto;
}
.PickupTime-Update-Time p {
  margin: 0 auto;
}
.PickupTime-Update-Time span {
  display: block;
}
.PickupTime-Sumary {
  background-color: #f4f3f3;
  margin: 0 -10px;
  border-radius: var(--radius) var(--radius) 0 0;
  padding: 0 10px !important;
}
.Discount-Text {
  font-size: 21px;
  top: 5px;
}
.DetailBooking--Timestamp {
  margin: 5px 0 0 0;
}
.DetailBooking--Timestamp span {
  font-size: var(--font-size);
  padding-left: 5px;
}
.AddressContact-Attachments .AddressContact-Attachments-List > div {
  width: 72px;
  box-sizing: border-box;
}
.AddressContact-Attachments .AddressContact-Attachments-List img {
  display: block;
  max-height: 72px;
  margin: auto;
}
.AddressContact-Attachments-List-PDF {
  background-color: #09670a;
}
.AddressContact-Attachments-List-PDF img {
  max-width: 100%;
  margin: auto;
  height: 52px;
}
.AddressContact-Attachments-Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}
.DetailBooking-List .Table thead td {
  word-break: break-word;
}
.DetailBooking-List--Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DetailBooking-List--DownloadGroup {
  display: flex;
  align-items: center;
  position: relative;
}
.DetailBooking-List--DownloadGroup span {
  font-size: 14px;
}
.DetailBooking-List--DownloadButtonBlock {
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 5px;
  color: #3fae29;
  cursor: pointer;
  height: 30px;
}
.DetailBooking-List--DownloadButtonBlock span {
  border: 1px solid #3fae29;
  height: 28px;
  line-height: 30px;
  padding: 0 10px;
}
.DetailBooking-List--DownloadButtonBlock.disabled {
  background-color: #f2f2f2;
  color: #ccc;
}
.DetailBooking-List--DownloadButtonBlock.disabled span {
  border-color: #ccc;
}
.DetailBooking-List--DownloadButtonText {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none !important;
}
.DetailBooking-List--DownloadButtonText:hover {
  background-color: #3fae29;
  color: #fff;
}
.DetailBooking-List--DownloadButtonIcon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.DetailBooking-List--DownloadButtonIcon .download-caret-icon-white {
  display: none;
}
.DetailBooking-List--DownloadButtonIcon:hover {
  background-color: #3fae29;
}
.DetailBooking-List--DownloadButtonIcon:hover .download-caret-icon-white {
  display: inline;
}
.DetailBooking-List--DownloadButtonBlock.disabled .download-caret-icon-green,
.DetailBooking-List--DownloadButtonIcon:hover .download-caret-icon-green {
  display: none;
}
.DetailBooking-List--DownloadPopup {
  position: absolute;
  top: 35px;
  right: 0;
  width: 265px;
  padding: 20px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  display: none;
  z-index: 999;
}
.DetailBooking-List--DownloadPopup .download-fail-icon {
  display: block;
  margin: 0 auto;
}
.DetailBooking-List--DownloadPopup .download-middle-text {
  color: #808080;
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}
.DetailBooking-List--DownloadPopup .download-info-text {
  text-align: center;
  color: #808080;
  font-size: 12px;
  margin: 0;
  padding: 0 15px;
}
.DetailBooking-List--DownloadPopup .Button {
  width: 100%;
  margin-top: 15px;
  font-weight: 500;
  min-width: auto;
}
.DetailBooking-List--DownloadPopup .lds-spinner {
  display: block;
  margin: 0 auto;
}
.DetailBooking-List--DownloadPopup .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: -8px;
  left: 15px;
  width: 3px;
  height: 12px;
  border-radius: 20%;
  background: #3fae29;
}
.DetailBooking-List--DownloadPopup .download-failed-button-group {
  display: flex;
  justify-content: space-between;
}
.DetailBooking-List--DownloadPopup .checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.DetailBooking-List--DownloadPopup .Overlay-Checkbox {
  width: 50%;
  margin: 5px 0;
}
.DetailBooking-List--DownloadPopup .Overlay-Checkbox label {
  text-transform: uppercase;
  font-size: 12px;
}
.DetailBooking-List--DownloadPopup .Overlay-Checkbox.disabled label {
  color: #ccc;
  opacity: 0.5;
}

.DetailBooking-List--DownloadPopup .Overlay-Checkbox.disabled label::before {
  border-color: #ccc;
}
.DetailBooking-List--DownloadPopup .ReselectButton {
  border: 1px solid #a1a1a1;
  color: #a1a1a1;
}
.DetailBooking-List--DownloadGroup #download-photos-token {
  display: none;
}
.DetailBooking-List--DownloadGroup.loading .DetailBooking-List--DownloadPopup.downloading-pictures {
  display: block;
}
.DetailBooking-List--DownloadGroup.all-failed .DetailBooking-List--DownloadPopup.download-all-failed {
  display: block;
}
.DetailBooking-List--DownloadGroup.select-failed .DetailBooking-List--DownloadPopup.download-select-failed {
  display: block;
}
.DetailBooking-List--DownloadGroup.select .DetailBooking-List--DownloadPopup.download-pictures-select {
  display: block;
}
.Popup .AddressItem .AddressContact-Noted {
  font-size: var(--font-size-s);
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;
}
.Popup .AddressItem .AddressContact-Noted span {
  padding-left: 5px;
}
#root .employee-results-line::before {
  left: 1px;
}

/*
 * Syns color New Booking
 */

.BookingWizard input:not(.Overlay-Hours):not(.Overlay-Minutes),
.BookingWizard select,
.BookingWizard textarea,
.PickupTime.block .PickupTime-Name,
#Step-02 .TitleSubtitle-title,
.Locations.block .block-item.actions .optimize-route .Overlay-Checkbox label,
.Reimbursements.block .block-item label,
.Services.block .block-item label,
.LocationContacts .block-item label,
#Step-03 .Pricing.block > .block-item:not(.total) label b,
#Step-03 .Pricing.block > .block-item:not(.total) span,
#Step-03 .Pricing.block .block-item-sub > div label b,
#Step-03 .Pricing.block .block-item-sub > div span,
#Step-03 .Pricing.block .block-sub-item b,
#Step-03 .Pricing.block .block-sub-item span,
#Step-03 .Pricing.block .block-item b,
#Step-03 .Pricing.block .block-sub-item b,
.Input .geolocation-icon .Icon,
.default-color,
#root .default-color {
  color: #444444;
}

#Step-02 .TitleSubtitle-title,
.Reimbursements.block .block-item label {
  align-items: center;
}

.Input .geolocation-icon .Icon:hover {
  color: var(--color-green);
}
.geolocation-tooltip {
  position: absolute;
  top: 0;
  left: 100%;
  background: var(--color-yellow);
  border-radius: var(--radius-s);
  box-shadow: 1px 0px 2px #999;
  margin-left: 10px;
  white-space: nowrap;
  display: none;
}
.geolocation-tooltip.visible {
  display: block;
}
.geolocation-tooltip p {
  margin: 0;
  line-height: 32px;
  font-size: var(--font-size-s);
  padding: 0 10px;
}
.geolocation-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--color-yellow);
  top: 50%;
  left: -8px;
  margin-top: -10px;
}

/*
 * Default DropDown
 */

.Default-Dropdown {
  position: absolute;
  left: 0;
  background-color: var(--color-white);
  box-shadow: -1px 2px 7px #cacaca;
  border-radius: var(--radius);
  padding: 0;
  opacity: 0;
  visibility: hidden;
}
.Default-Dropdown.Show {
  opacity: 1;
  visibility: visible;
}
.Default-Dropdown:before,
.Default-Dropdown:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.Default-Dropdown.TopArrow:before {
  left: 86px;
  margin-left: -4px;
  bottom: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  z-index: 2;
}
.Default-Dropdown.TopArrow:after {
  left: 86px;
  margin-left: -4px;
  bottom: -9px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--color-gray-xl);
  z-index: 1;
}
.Default-Dropdown .Default-Dropdown-List {
  padding: 5px 10px;
  white-space: nowrap;
}
.Default-Dropdown .Default-Dropdown-List:hover {
  background-color: var(--color-gray-xxl);
}
.Default-Dropdown .Default-Dropdown-List:first-child:hover {
  border-radius: var(--radius) var(--radius) 0 0;
}
.Default-Dropdown .Default-Dropdown-List:last-child:hover,
.Bottom-Default-Radius {
  border-radius: 0 0 var(--radius) var(--radius);
}
.Default-Dropdown .Default-Dropdown-List .Default-Dropdown-Icon {
  width: 24px;
  height: 24px;
}
.Default-Dropdown .Default-Dropdown-List .Default-Dropdown-Icon.With-Border {
  border: solid 1px var(--color-gray-xl);
  border-radius: var(--radius);
}
.Default-Dropdown .Default-Dropdown-List .Default-Dropdown-Title {
  text-align: left;
}
.Default-Dropdown .Default-Dropdown-List.disabled div,
.Default-Dropdown .Default-Dropdown-List.disabled div i {
  color: #aaaaaa !important;
}
.Default-Dropdown .Default-Dropdown-List.disabled .Default-Dropdown-Icon {
  border: 1px solid #cecece;
}

/*
 * Batchs & Multiple
 */

.BatchUploadIntro h2 {
  font-size: 27px;
  line-height: 44px;
}
.BatchUploadIntro h5 {
  font-size: 23px;
}
.SplitView > p.BatchUploadIntro-Content {
  font-size: 17px;
  line-height: 26px;
  margin-top: 0;
  min-height: 105px;
}
.BatchUploadIntro-Image {
  height: 165px;
  margin: 45px 0 38px;
}
.Tabs-Head-Item.selected {
  color: var(--color-gray-xd);
  font-weight: bold;
}
.Custom-Step.BookingWizard .Step {
  background-color: var(--color-white);
}
.Custom-Step.BookingWizard .Step.selected {
  background-color: var(--color-gray-xd);
}
.Custom-Step.BookingWizard .Step:after {
  background-color: var(--color-white);
  border-top: 1px solid var(--color-gray-xd);
  border-right: 1px solid var(--color-gray-xd);
}
.Custom-Step.BookingWizard .Step.selected:after {
  background-color: var(--color-gray-xd);
}
.Custom-Step.BookingWizard .Step span {
  font-weight: normal;
  color: var(--color-gray-xd);
}
.Custom-Step.BookingWizard .Step.selected span {
  color: var(--color-white);
}

/*
 * Batchs
 */

.Batch label {
  font-size: var(--font-size);
}
.BatchUploadValidate,
.BatchUploadBook {
  background: #e6e9ed;
  min-height: calc(100vh - 60px);
}
.BatchUploadBook .Content,
.BatchUploadUpload .Content,
.BatchUploadAssign .Content,
.BatchUploadOptions .Content {
  padding-bottom: 140px;
}
.BatchUploadValidate .Empty-List {
  width: 100% !important;
  padding: 0 !important;
  margin-top: 30px;
}
.Batch .BatchUpload-Title {
  padding-top: 20px;
  height: 20px;
  justify-content: flex-start;
}
.Batch .BatchUpload-Title .Button {
  border: 0 none;
}
.Batch .BatchUpload-Title .Button:hover {
  background-color: transparent;
}
.Batch-Custom .BatchUpload-Title {
  padding-bottom: 10px;
}
.BatchUploadBook .Bookings-Table {
  margin-top: 10px;
}
.Batch .BatchUpload-Title.Custom {
  padding: 10px 20px 0 0;
  margin-bottom: -48px;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 157px;
  height: auto;
}
.BatchUploadLayout section.actions {
  height: 56px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 22;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  padding: 10px 20px;
  box-shadow: 0px -2px 8px #ececec;
}
.BatchUploadLayout section.actions.custom {
  left: 192px;
  box-shadow: 4px -2px 8px #ececec;
}
.BatchUploadValidate section.actions,
.BatchUploadBook section.actions {
  background-color: #e6e9ed;
  box-shadow: none;
}
.BatchUploadValidate .Batch-Table {
  overflow-x: auto;
  overflow-y: hidden;
}
.BatchUploadValidate .Table-Sortable {
  max-height: calc(100vh - 326px);
  height: calc(100vh - 326px);
  overflow-y: auto;
  overflow-x: hidden;
}
.BatchUploadValidate .Batch-Custom .Batch-Table-Sortable .ui-state-default {
  display: table;
  width: 100%;
}
.BatchUploadOptions-Box-Custom select {
  min-width: 60px;
  height: 20px;
}
.BatchUploadAssign .FormGroup label {
  color: #231f20;
}
.BatchUploadAssign .FormGroup label.FormGroup-label-ps {
  position: absolute;
  top: 0;
  right: -165px;
  max-width: 150px;
  width: 150px;
  padding: 0;
  text-align: left;
  bottom: 0;
  display: flex;
  align-items: center;
  margin: 0;
  font-style: italic;
}
.BatchUploadOptions .FormGroup-Option-VehiclyType .List-Item,
.BatchUploadOptions .BatchUploadOptions-Box-Custom .List-Item {
  background: transparent;
  border: 0 none;
  margin: 0;
  min-width: 60px;
  padding-top: 20px;
  text-align: center;
  justify-content: flex-start;
}
.BatchUploadOptions .List-Item-Image img {
  height: 32px;
  width: auto;
  margin: 10px auto;
  display: block;
}
.center-checkbox {
  text-align: center !important;
}
.Batch-Custom tbody td[class^="locations-"] .cell.center-checkbox {
  display: flex;
}
.BatchUploadBook .Bookings-Table .cell {
  border: 0 none;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: flex-start;
  align-items: center;
}
.Batch-Custom .Table thead td,
.BatchUploadBook .Table thead td {
  height: 35px;
  background-color: var(--color-gray-xd);
  color: var(--color-white);
  font-weight: bold;
  border-bottom: 0 none;
  word-break: break-word;
}
.Batch-Custom .Table thead td {
  padding: 5px;
}
.Batch-Custom .Table tbody td {
  padding: 0 5px;
}
.Batch-Custom td.locations-address .Pin,
.Batch-Custom td.locations_address .Pin {
  margin-right: 10px;
}
.Batch-Custom td.actions .Button {
  background: #fff;
  width: 30px;
  height: 30px;
}
.Batch-Custom td.locations-address .Button,
.Batch-Custom td.locations_address .Button {
  background: #fff;
}
.Batch-Custom td.locations-address .Button:not(.add),
.Batch-Custom td.locations_address .Button:not(.add) {
  margin-right: 5px;
}
.Batch-Custom td.locations-address span,
.Batch-Custom td.locations_address span,
.Batch-Custom td.locations-address .Input,
.Batch-Custom td.locations_address .Input {
  flex: 1;
}
.Batch-Custom td.locations-address .Input,
.Batch-Custom td.locations_address .Input {
  margin-right: 10px;
}
.Batch-Custom td.locations-coords .cell {
  justify-content: center;
}
.Batch-Custom td.locations-cod .cell,
.Batch-Custom td.locations_cod .cell {
  justify-content: center;
}
.Batch-Custom td.locations-cod .cell input,
.Batch-Custom td.locations_cod .cell input {
  margin: auto;
}
.Batch-Custom td.locations-pod .cell,
.Batch-Custom td.locations_pod .cell {
  justify-content: center;
}
.Batch-Custom tbody td[class^="locations-"] {
  position: relative;
}
.Batch-Custom tbody td[class^="locations-"] .cell {
  margin: 0 -3px;
}
.Batch-Custom td.prioritize-fav-drivers .cell label {
  text-align: center;
  display: inline-block;
  padding-top: 2px;
}
.Batch-Custom td.actions button i,
.Batch-Custom td.locations-address button i,
.Batch-Custom td.locations_address button i {
  font-size: var(--font-size-b) !important;
}
.Batch-Custom table .TextArea textarea {
  height: 26px;
  min-height: 26px;
  resize: none;
  padding: 4px 2px;
  box-sizing: border-box !important;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.Batch-Custom td.actions .cell {
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.Batch-Custom .Table td {
  max-width: 50px;
  min-width: 50px;
}
.Batch-Custom .Table tbody td {
  padding: 5px;
}
.Batch-Custom .Table tbody td {
  padding: 0 5px;
}
.Batch-Custom .Table tbody td,
.Batch-Custom .Table thead td {
  border-color: #e1e1e1;
}
.Batch-Custom table input,
.Batch-Custom table .Input,
.Batch-Custom table select,
.Batch-Custom table .Select,
.Batch-Custom table textarea,
.Batch-Custom table .TextArea {
  display: block;
  flex: inherit;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
}
.Batch-Custom table textarea,
.Batch-Custom table .TextArea {
  display: block;
  flex: inherit;
  padding: 0;
  min-width: auto !important;
  border: 0 none;
}
.Batch-Custom table .Input,
.Batch-Custom table .Select,
.Batch-Custom table select,
.Batch-Custom table .TextArea {
  min-width: 50px;
  height: 26px;
  line-height: 26px;
}
.Batch-Custom table input {
  padding: 0 5px;
  box-sizing: border-box !important;
}
.Batch-Custom table input[type="radio"],
.Batch-Custom table input[type="checkbox"] {
  width: auto;
  margin: auto;
  display: inline-block;
}
.Batch-Custom .Table tbody tr:nth-child(2n + 2) td {
  background-color: transparent;
}
.Batch-Custom .Table tbody tr.first td .cell {
  background: var(--color-gray-xxl);
}
.Batch-Custom .Table td.expanded {
  min-width: 65px;
  max-width: 65px;
  width: 65px;
  position: relative;
}
.BatchUploadValidate .Batch-Custom .Table td.expanded .Overlay-Checkbox {
  margin-left: 19px;
  display: inline-block;
}
.BatchUploadBook .Batch-Custom .Table td.expanded {
  min-width: 30px;
  max-width: 30px;
  width: 30px;
}
.BatchUploadBook .Batch-Custom .Table td.expanded.center-checkbox {
  max-width: 40px;
  min-width: 40px;
  width: 40px;
}
.Batch-Custom .Table tbody td.expanded:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 14px;
  background: #ddd;
}
.Batch-Custom .Table.Pickup-Parent tbody td.expanded:before {
  background: #4fb7e1;
}
.Batch-Custom .Table.Pickup-Child tbody td.expanded:before {
  background: #ffcc00;
}
.BatchUploadBook .Batch-Custom .Table tbody td.expanded:before {
  display: none;
}
.BatchUploadBook .Batch-Custom .Table tbody td.expanded.center-checkbox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 14px;
  background: #ddd;
  display: block;
}
.BatchUploadBook .Batch-Custom .Table.Pickup-Parent tbody td.expanded.center-checkbox:before {
  background: #4fb7e1;
}
.BatchUploadBook .Batch-Custom .Table.Pickup-Child tbody td.expanded.center-checkbox:before {
  background: #ffcc00;
}
.Batch-Custom table .shipment-type,
.Batch-Custom table .shipment_type {
  min-width: 70px;
  max-width: 70px;
}
.Batch-Custom .Batch-Custom-Right table .shipment_type {
  min-width: 60px;
  max-width: 60px;
}
.Batch-Custom table .locations-address,
.Batch-Custom table .locations_address {
  min-width: 370px !important;
  max-width: 370px !important;
}
.Batch-Custom .Batch-Custom-Right table .locations_address {
  min-width: 120px !important;
  max-width: 120px !important;
}
.Batch-Custom .Batch-Map-Left table .locations_address {
  min-width: 120px !important;
  max-width: 160px !important;
}
.Batch-Custom .Batch-Custom-Right table .locations_address span {
  padding-left: 5px;
}
.Batch-Custom td.locations-cod,
.Batch-Custom td.locations_cod,
.Batch-Custom td.locations-pod,
.Batch-Custom td.locations_pod,
.Batch-Custom td.need_pod,
.Batch-Custom td.need_cod,
.Batch-Custom td.prioritize-fav-drivers,
.Batch-Custom td.prioritize_fav_drivers,
.Batch-Custom td.need_to_save,
.Batch-Custom td.custom_checkbox,
.Batch-Custom td.cod_invoice_fees {
  max-width: 90px !important;
  min-width: 90px !important;
  text-align: center;
  white-space: normal !important;
}
.Batch-Custom .Table td.locations_price {
  max-width: 100px !important;
  min-width: 100px !important;
}
.Batch-Custom .Batch-Custom-Right .Table td.locations_price {
  max-width: 85px !important;
  min-width: 85px !important;
}
.Batch-Custom .Table td.booking-note,
.Batch-Custom .Table td.booking_note,
.Batch-Custom .Table td.note_to_driver,
.Batch-Custom .Table td.locations-note,
.Batch-Custom .Table td.locations_note,
.Batch-Custom .Table td.job_order_number,
.Batch-Custom .Table td.contact_name,
.Batch-Custom .Table td.locations_phone,
.Batch-Custom .Table td.pod_note {
  max-width: 110px !important;
  min-width: 110px !important;
}
.Batch-Custom .Table td.custom_width,
.Batch-Custom .Table td.cod_note,
.Batch-Custom .Table td.send_first_to_favorite,
.Batch-Custom td.round_trip_discount {
  max-width: 120px !important;
  min-width: 120px !important;
}
.Batch-Custom .Table td.time_type {
  max-width: 110px !important;
  min-width: 110px !important;
}
.Batch-Custom .Batch-Custom-Right .Table td.time_type {
  max-width: 60px !important;
  min-width: 60px !important;
}
.Batch-Custom .Table td.extra_requirements.custom_width,
.Batch-Custom .Table td.note_to_driver,
.Batch-Custom .Table td.locations_note,
.Batch-Custom .Table td.photos_and_files {
  max-width: 140px !important;
  min-width: 140px !important;
}
.Batch-Custom table .locations_address .Icon.mr5 {
  margin-right: 0;
}
.Batch-Custom table .Icon.mr5:hover {
  color: var(--color-red);
}
.Batch-Custom .shipment_type .cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Batch-Custom .shipment_type .cell .Pin {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin: 0 4px 0 2px;
  font-size: 9px;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default,
.Batch-Custom .Batch-Table-Sortable .ui-widget-content .ui-state-default,
.Batch-Custom .Batch-Table-Sortable .ui-widget-header .ui-state-default,
.Batch-Custom .Batch-Table-Sortable .ui-button,
.Batch-Custom .Batch-Table-Sortable html .ui-button.ui-state-disabled:hover,
.Batch-Custom .Batch-Table-Sortable html .ui-button.ui-state-disabled:active {
  border: 0 none;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default:not(.Pickup-Hide):not([class*="Pickup-Parent"]) > table {
  border-top: 11px solid #e6e9ed;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default:nth-of-type(1) > table {
  /*border-top: none !important;*/
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default:not(.Pickup-Hide):not([class*="Pickup-Parent"]) > table tr td {
  border-top: 1px solid #e1e1e1;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default:nth-of-type(1) > table tr td {
  border-top: none !important;
}
.Batch-Custom .Batch-Table-Sortable .expanded__moveHandle {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAARCAYAAAAVKGZoAAAABHNCSVQICAgIfAhkiAAAACBJREFUGJVj/P///38GBgYGRkZGRhibiYFYwDiqnQjtAM5lL/tsT3iTAAAAAElFTkSuQmCC");
  box-shadow: none;
  border: 0 none;
  width: 12px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  cursor: move;
}
.Batch-Custom .Batch-Table-Sortable .Pickup-Parent .expanded__moveHandle {
}
.Batch-Custom .Batch-Table-Sortable tbody tr:hover .expanded__moveHandle {
  opacity: 1;
}
.Batch-Custom .Batch-Table-Sortable .expanded__expandButton,
.expanded__expandButton,
.Modal-Table .expanded__expandButton {
  transform: none;
  z-index: 2;
  width: 16px;
  height: 16px;
  box-shadow: none;
  background-size: 16px !important;
  position: absolute;
  cursor: pointer;
  left: 50% !important;
  margin-left: 0px;
  top: 50%;
  margin-top: -8px;
}
.BatchUploadValidate .Batch-Custom .Batch-Table-Sortable .expanded__expandButton {
  margin-left: 13px;
}
.BatchUploadBook .Batch-Custom .Batch-Table-Sortable .expanded__expandButton {
  margin-left: -8px;
}
.Batch-Custom .Batch-Table-Sortable .expanded__expandButton,
.expanded__expandButton {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAx9JREFUSA29Vl1IFFEU/u7MztqDFWUWaA9rkbWJYoqlYK1CGr6UWWEYRO/WQ9BDWlD0g70kUU9B0EvUS6n1UJGRPyEtmf0YrllQL2lULBVF2c6ut3uuzjA7O67rujnL7J17z3e+b86de+85DAlcW2+v36Bz1GICm8F4NuMsi9w442PibxQKHmsMHQ93BF7MRMfiASo68naDT5zhHGvj4QwbYxgBU4531w7dNMbsraNg9Z2CnFBYv86BUrtDIn1B6ne7tIYH2wc/2PExgpVt+T7OwrdEVBl28Gz6Itog465dXXWve6x+UYIkNoFIp/g6mhWU/DPTFahVVlFTUE5jRO+fa2T2l6NI3apWYkyvYgDkN5vjNBpc1pYCIG5jTArSakx2gRhE8VrilitegCYjFEs/nkNKbFMaTG7qCH+eEtIZSDSVFSnyBHEAnt50Edeq7iJvaaGD1XmIsORDvk4XaSnyuHKwpqkLsDLdg9byq9iSVeWAiB7yZVVLLPmQr+MljkbVs3dZszDGbPLesU6sWrQGOeL2ZW/Db/0XAt9eOfLsWb0fR4pOQVM09H16hJNPDyPCw7FYBl3NqV/eIixuu5Ucuj/ex+K0JfAuLcDGFeVI1xai/0ufCWVgOJh/FAe8jWBiw3W8v4FzA80Ic93EWB8EPk311GdShDGCBOTi5//ci/HIHxRnliEvo1BEnSui6ILKXDhRch41njpwsdkuD7XiSuCC9LGKRD0z/GW+du+IQOVGGRw6ldk1aCpugVt1Yyj4UiLoBUKREFoGmtA1es/ByzbE8NYl8xn4jIJEGBz/irOll2SkRPUz9APH/IcwGHxmY56mK3KnQslzGnPMMBE39jTg3feAvBt79yUuRmxCa943vswWFe3eN+K7J5TVY8JOcICqge6dw+smz1JRFiTolzxsSsPMhxVt3if/K2MIEX933XAZva2ZD6kGoWSZfAjOnjIBC27DagpSRqYaBGDOx4ThMauW6cRpZHtyNQWpQ7UH1SCpiJQ47PVMjKAhSjUIzTv1k7nIlzisxZPBYy4aY8DazlshbBWl51SW+v8ADv0rUA3F9WYAAAAASUVORK5CYII=")
    no-repeat center;
}
.Batch-Custom .Batch-Table-Sortable .expanded__expandButton.expanded__collapseButton,
.expanded__expandButton.expanded__collapseButton,
.Modal-Table .expanded__expandButton.expanded__collapseButton {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1IVFEU/u69OqNpRbRLK0dLsxD6IX8KKUqFdlHRolrUOhctFELLlSItCqpFQa1cRIuKIAjxp8iBxp8osKRcaVHt3DXpON53u+fie755M45PZ/LAfe/ce875vvt/D4MPiTbUHYCyTiugHkwVMcW2UZhi6rf+/GJAGIy/LBiIfFoJTvsuL3+bas5ZUnVqj4rlvRIsk1ywGxv6Rp4ltLoqKQlnm6pDlsWeKKVqXb6+VcbYMOfqQn7f6JQ3KIlwtrHumFTyORS2ep1XVWeYEUycze+PvHPHJRASmWXJfr1WuW6nteoaPM65aHSTOoQ0jdLCWMYj8/aORspx2J5ebttpzbJORuB6aQz2IpEhpN241g1idzjdn7CJg3wM4eLWTxeTsc3mYHSolZIfM0b0AcCYOMjpBvHh67iw7TuR96DHFNJXJZqLm+vKZxSv2o/8e48hdleYQjq1+RXi4nQ3+gkQxxuQd+s+2MZNkBOfTSGd2sjmSzSXaA8Vd2vnQLqA3POXELh2HSwnBwvht4h1tGKh/zX4jhKI0l0Q9SeA2BysifF0MGBgQdFeWty2LCHnCDS3IHDxCvT9iPiLp5i/3QXIBV0k5NAgWGEhxN4qiEM1YJu3QH4Y1mdPT15KYTHRVlZ0WduS781gEMGObuQ2ntL7ysL8w7uI9zxKgpFjw1DRqCashqjcB15WDvl+yHQoyZnhu2gLFZ/RhpDXGOy8g5zao1DzMcS6bkL2vvK6OHXr6xdY01MQR+ohSsrAyyshB3sd+5LCxrm+TMNLDS6N1uTnD8y1XIXU67aSyPAbzLU2mxhaz1RCXOt+8M1r8edk9TfdI7+veqrO+2mbLBwc3WPuUkoL/ERk4mNzmBESULShJvK/XgxKOQoGRuqIx4zQKDoH0SdzhvSsisak/MbGdAjpRaYcRA85bhsz/ROWyWtcyZRDSOCUe1AOkpWRmpEl5jPEkUBok1IOQvNO9bUIxZo8xpOxEZazaVIBr1si7CXPZqr/D0WCIMh4OyI6AAAAAElFTkSuQmCC")
    no-repeat center;
}
.Batch-Custom .Batch-Table-Sortable .expanded__expandButton.expanded__collapseButtonFullday,
.expanded__expandButton.expanded__collapseButtonFullday,
.Modal-Table .expanded__expandButton.expanded__collapseButtonFullday {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAS5JREFUKBV9UqtuhEAUvTNBQAgWW9EVmCY1BN9Ukq7EF4XvZ9Sj6B+AWCzpT9Su6QegSgJBQO+Z7N0MzZZJ4N45j3nAUWSNqqqCcRzflFIvDB8u1Hld15Pnee95nv+IXElTluXTsiwfLLoTzK682LfW+rUoik/gxngxdWy6LmSbpGfzyuZnmBWON03T1387iUkqdnZd98HBnRi8eTwR2xUbwKMZPAqRZRlFUSTTawUGTgY+nubXvQBd11GSJBTHsUCmBwbOGgfHmlDf91TXNaVpSkEQGCoMQ4MNw2BLCUc92wgETdOQ7/vmQf/XBI/Dlz1x82ib53mmtm1taNPDo5EIfOINszOBFh6NGCERDKw7ekNBA63xAEESkIi9ncFJauDZRExCzviRhfKbbob8FwxyhIyLc9BTAAAAAElFTkSuQmCC")
    no-repeat center;
}
.Batch-Custom .Batch-Table-Sortable .Pickup-Child .expanded__expandButton {
  display: none !important;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default {
  background-color: var(--color-white);
}
.Batch-Custom .Batch-Table-Sortable .Pickup-Child,
.Batch-Custom .Batch-Table-Sortable .Pickup-Parent-Bg {
}
.Batch-Custom .Batch-Table-Sortable .Input,
.Batch-Custom .Batch-Table-Sortable .Select,
.Batch-Custom .Batch-Table-Sortable select,
.Batch-Custom .Batch-Table-Sortable .TextArea {
  border: 0 none;
  background-color: transparent;
}
.Batch-Custom .Batch-Table-Sortable .Input.error,
.Batch-Custom .Batch-Table-Sortable .Select.error,
.Batch-Custom .Batch-Table-Sortable .TextArea.error {
  background-color: #ffc7c7;
  border-radius: 0px;
  margin: 0px -2px;
  width: auto;
}
.Batch-Custom .Batch-Table-Sortable .locations-merge.error {
  background-color: #ffc7c7;
}
.Batch-Custom .Batch-Table-Sortable .locations_address .Input.error {
  padding-right: 2px;
}
.Batch-Custom .Batch-Table-Sortable .Pickup-Parent,
.Batch-Custom .Batch-Table-Sortable .Pickup-Child {
  border: 0 none;
}
.Batch-Custom .Batch-Table-Sortable .ui-state-default .Table {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.Pickup-Hide {
  display: none;
}
.Pickup-Hide.Show {
  display: block;
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay {
  display: flex;
  align-items: center;
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay input {
  flex: 1;
  color: #444444;
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay button {
  background: transparent;
  padding: 0;
  height: 18px;
  border: 0 none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay button.add i {
  border-color: var(--color-green);
  color: var(--color-green);
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay button i {
  font-size: 18px !important;
  color: #7c7c7c;
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay button.add-location {
  border-color: var(--color-green);
  background-color: var(--color-green);
}
.Batch-Custom .Batch-Table-Sortable table .Input.Input-Overlay button.add-location i {
  color: var(--color-white);
}
.Batch-Custom .Batch-Table-Sortable .Overlay-Checkbox,
.Batch-Custom .expanded-No-DragDrop .Overlay-Checkbox {
  height: 14px;
}
.BatchUploadBook .Batch-Custom .Batch-Table-Sortable .Overlay-Checkbox {
  /*margin-left: 13px;*/
}
.Batch-Custom .Batch-Table-Sortable .Overlay-Checkbox label {
  padding-left: 16px;
  line-height: 16px;
}
.Batch-Custom .Batch-Table-Sortable:not(.Batch-Table-Sortable-No-DragDrop) .Overlay-Checkbox label:before {
  width: 12px;
  height: 12px;
}
.Batch-Custom .Batch-Table-Sortable:not(.Batch-Table-Sortable-No-DragDrop) .Overlay-Checkbox i {
  line-height: 16px;
  width: 16px;
  font-size: 14px;
}
.Batch-Custom .freeze_column_icon {
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}
.Batch-Custom .freeze_column_icon.active #freeze_column_icon_path {
  fill: var(--color-green);
}
.Grid-Cell .freeze_column_border {
  height: 100%;
  width: 4px;
  background-color: var(--color-gray);
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  z-index: 3;
}
.Grid-Cell.Grid-Cell-Custom .freeze_column_border {
  right: 0px;
}
.Grid-Cell .freeze_column_border:before {
  content: "";
  background-color: var(--color-gray);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4px;
}
.ReactVirtualized__Grid {
  outline: 0 !important;
}
.Form-Tooltip {
  position: relative;
}
.Form-Tooltip-Overlay {
  position: absolute;
  top: 100%;
  left: -40px;
  right: -40px;
  border-radius: 4px;
  background-color: var(--color-white);
  border: 1px solid #e1e1e1;
  z-index: 22;
  padding: 10px 5px;
  display: none;
}
.Form-Tooltip-Overlay.Active {
  display: block;
}
.Form-Tooltip-Overlay:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  top: -5px;
  margin: auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--color-white);
}
.Form-Tooltip-Overlay input,
.Form-Tooltip-Overlay input:focus,
.Form-Tooltip-Overlay input:link,
.Form-Tooltip-Overlay input:visited,
.Form-Tooltip-Overlay textarea {
  border: 0 none;
  height: 32px;
  outline: 0;
  border-radius: 4px;
  background-color: var(--color-white) !important;
}
.Batch-Custom .Batch-Table-Sortable-No-DragDrop .expanded__moveHandle {
  display: none !important;
}
.Batch-Custom .Batch-Table-Sortable-No-DragDrop .Table tbody td {
  height: 26px;
}
.Batch-Custom .Batch-Custom-Right .Batch-Table-Sortable-No-DragDrop .Table tbody td {
  min-height: 35px;
  height: auto;
}
.Batch-Custom .Batch-Table-Sortable-No-DragDrop tbody td[class^="locations-"] .cell {
  padding: 0 5px;
}
.Batch-Custom .Batch-Table-Sortable-No-DragDrop .shipment_type .cell .Pin {
  margin-left: 0;
}
.cell-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  left: 0;
  background-color: var(--color-gray);
  opacity: 0.35;
  z-index: 2;
}
.cell-overlay + div,
.cell-overlay ~ div {
  /*color: #949494;*/
}
.Batch-Custom-Group {
  margin-top: 15px;
}
.Batch-Custom-Group h3,
.Batch-Custom-Group h4 {
  margin: 0;
}
.Batch-Custom-Group h2,
.Batch-Custom-Group h3,
.Batch-Custom-Group h4 {
  color: #444;
}
.Batch-Custom-Left {
  width: 190px;
  min-width: 190px;
  float: left;
  margin-left: 2px;
  background-color: #efefef;
  min-height: calc(100vh - 126px);
  position: relative;
}
.Batch-Custom-Right {
  padding-left: 19px;
  padding-right: 19px;
}
.Batch-Custom-Left > h3 {
  height: 38px;
  background-color: #7c7c7c;
  color: var(--color-white);
  font-size: 15px;
  line-height: 38px;
  padding: 0 15px;
  border-radius: 0 5px 0 0;
}
.Batch-Custom-Left .Batch-Custom-Left-Search {
  padding: 10px 15px;
}
.Batch-Custom-Left ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
}
.Batch-Custom-Left > ul {
  position: absolute;
  width: 190px;
}
.Batch-Custom-Left-Pagination {
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 190px;
  background-color: #ededed;
  z-index: 222;
  max-height: 60px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Batch-Custom-Left-Pagination .Pagination ul li a {
  width: 24px;
  height: 24px;
  font-size: 15px;
  margin: 0 2px;
}
.Batch-Custom-Left > ul li {
  border-right: solid 1px var(--color-gray-xl);
  border-bottom: solid 1px var(--color-gray-xl);
  border-left: solid 1px var(--color-gray-xl);
  padding: 0 18px 0 15px;
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Batch-Custom-Left > ul li:first-child {
  border-top: solid 1px var(--color-gray-xl);
}
.Batch-Custom-Left > ul li.selected {
  background-color: #d7edd7;
  border-right: 0 none;
  position: relative;
  border: 1px solid var(--color-green);
}
.Batch-Custom-Left > ul li.selected:before {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  bottom: 0;
  width: 8px;
  background-color: var(--color-green);
}
.Batch-Custom-Left > ul li a {
  display: block;
  width: 100%;
}
.Batch-Custom-Left > ul li h4 {
  font-size: 15px;
}
.Batch-Custom-Left > ul li h4 > div {
  display: flex;
}
.Batch-Custom-Left > ul li span {
  font-size: 13px;
  color: #7c7c7c;
  margin-top: 3px;
}
.Batch-Custom-Left > ul li span.Batch-Custom-Left-Badge {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: var(--color-gray-xd);
  border-radius: 2px;
  background-color: var(--color-gray-xl);
  display: inline-block;
  padding: 2px 5px;
  margin-left: 7px;
  white-space: nowrap;
  margin-top: 0;
}
.Status-Label {
  color: #7c7c7c;
  border: solid 1px #7c7c7c;
  background-color: transparent;
  font-size: 13px;
  line-height: 18px;
  display: inline-block;
  padding: 0 10px;
  border-radius: 5px;
}
.Batch-Custom-Left > ul li i {
  width: 18px;
  height: 18px;
  bottom: 12px;
}
.Batch-Custom-Left > ul li i.custom-list {
  position: absolute;
  right: 5px;
}
.Batch-Custom-Right .Batch-Title > h2 {
  font-size: 17px;
  margin: 0;
  float: left;
  width: 100%;
}
.Batch-Custom-Right .Batch-Title > h2 div {
  float: left;
  display: flex;
  align-items: center;
  max-height: 26px;
  color: var(--color-green);
}
.Batch-Custom-Right .Batch-Title > h2 div button {
  border: 0 none;
  padding: 0;
  margin-left: 10px;
}
.Batch-Custom-Right .Batch-Title > h2 div button:hover {
  background-color: transparent;
}
.Batch-Custom-Right .Batch-Title > span {
  font-size: 15px;
  font-weight: normal;
  padding: 0 8px;
  text-align: center;
  cursor: text;
}
.BatchUpload-Edit-Title input {
  border: 0 none;
  font-size: 20px;
  color: var(--color-green);
  font-weight: bold;
  min-width: 100px;
  width: auto;
  padding: 0;
  outline: 0;
  height: 26px;
  border-bottom: 1px solid var(--color-green);
  background-color: transparent;
  max-width: 80vw !important;
}
.BatchUpload-Edit-Title.Black-Gray-text input {
  max-width: 75vw !important;
}
.Batch-Title-Toggle > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.Batch-Title-Toggle > div h5 {
  margin: 0;
  white-space: nowrap;
  font-size: 15px;
  min-width: 70px;
  padding: 0 10px;
  color: #444444;
  font-weight: normal;
  text-align: center;
  line-height: 30px;
}
.Batch-Title-Toggle > div h5.sub:first-child {
  border-left: 0 none;
}
.Batch-Title-Toggle > div h5.active {
  background: var(--color-green);
  color: white;
}
.Batch-Title-Toggle > div h5 span {
  line-height: 10px;
}
.Batch-Title-Toggle > div a.disabled-btn {
  cursor: no-drop;
  background-color: var(--color-gray-xxl);
  border: solid 1px #dde0e3 !important;
  color: #b4b4b4;
}
.Batch-Title-Toggle > div a.disabled-btn h5 {
  color: #b4b4b4 !important;
}
.Batch-Title-Toggle > div a.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltipText {
  visibility: hidden;
  width: 159px;
  height: 34px;
  padding: 3px 5px 0 0;
  box-shadow: 0 2px 10px 0 #00000019;
  border: solid 1px #828282;
  background-color: #595959;
  text-align: center;
  font-size: 12px;
  color: var(--color-white);

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 45px;
}
.tooltip:hover .tooltipText {
  visibility: visible;
}
.Batch-Title-Toggle > div a.Map {
  border: 1px solid var(--color-green);
  border-radius: 5px 0 0 5px;
  border-right: none;
}
.Batch-Title-Toggle > div a.Grid {
  border: 1px solid var(--color-green);
}
.Batch-Title-Toggle > div a.Chat {
  border: 1px solid var(--color-green);
  border-radius: 0 5px 5px 0;
  border-left: none;
}
.Batch-Title-Toggle > div a.completedGrid {
  border-radius: 5px 0px 0px 5px;
}
.Batch-Merge-Chat-Unread {
  position: absolute;
  top: 10px;
  right: 6px;
}
.Batch-Info-Group {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}
.Batch-Info-Group.Custom {
  float: none;
  width: auto;
  display: flex;
  flex-direction: row;
}
.Batch-Info .Batch-Info-Group:last-child {
  margin-bottom: 15px;
}
.Batch-Info-Box {
  flex: 1;
  border-radius: 5px;
  border: solid 1px transparent;
  background-color: var(--color-gray-xxl);
  color: #444444;
  margin-right: 1%;
  float: left;
  margin-bottom: 5px;
  padding: 5px 15px;
  box-sizing: border-box;
  position: relative;
  height: 60px;
}
.Batch-Info-Box-To-At-Destination {
  flex: 1.2;
}
.Batch-Info-Box:first-child {
  flex: 1.5;
}
.Batch-Info-Box:last-child {
  margin-right: 0;
}
.Batch-Info-Box.confirmed,
.Batch-Info-Box.confirmed h3,
.Batch-Info-Box.in_progress,
.Batch-Info-Box.in_progress h3,
.Batch-Info-Box.driver_accept_booking,
.Batch-Info-Box.driver_accept_booking h3,
.Batch-Info-Box.delivery_in_progress,
.Batch-Info-Box.delivery_in_progress h3,
.Batch-Info-Box.to_at_pickup,
.Batch-Info-Box.to_at_pickup h3,
.Batch-Info-Box.to_at_destination,
.Batch-Info-Box.to_at_destination h3 {
  color: var(--color-green);
}
.Batch-Info-Box.unconfirmed,
.Batch-Info-Box.unconfirmed h3,
.Batch-Info-Box.locating_driver_timeout,
.Batch-Info-Box.locating_driver_timeout h3 {
  color: var(--color-red);
}
.Batch-Info-Box.arranging,
.Batch-Info-Box.arranging h3,
.Batch-Info-Box.locating_driver,
.Batch-Info-Box.locating_driver h3 {
  color: #ff8328;
}
.Batch-Info-Box.canceled,
.Batch-Info-Box.canceled h3 {
  color: #7c1e29;
}
.Batch-Info-Box.Batch-Info-Box-Custom {
  border: solid 1px var(--color-gray-xl);
  background: white;
}
.Batch-Info-Box .Overlay-Checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  width: 20px;
  height: 20px;
}
.Batch-Info-Box .Overlay-Tootip {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  background-color: var(--color-gray-xxd);
  width: 95px;
  margin-left: -43px;
  border-radius: 3px;
  text-align: center;
  display: none;
}
.Batch-Info-Box .Overlay-Tootip.UnCheckTooltip {
  width: 120px;
  margin-left: -55px;
}
.Batch-Info-Box .Overlay-Checkbox input[type="checkbox"]:hover ~ .Overlay-Tootip,
.Batch-Info-Box .Overlay-Checkbox input[type="checkbox"]:hover + .Overlay-Tootip {
  display: block;
}
.Batch-Info-Box .Overlay-Tootip:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #4a4a4a;
  z-index: 3;
}
.Batch-Info-Box .Overlay-Tootip span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 88%;
  margin: auto;
  color: white;
  font-size: 11px;
  line-height: 18px;
}
.Batch-Info-Box-Gray {
  /*background-color: #ffdb00;*/
}
.Batch-Info-Box-Custom {
  /*background-color: #7c1e29;*/
}
.Batch-Info-Box h3 {
  width: max-content;
  font-size: 24px;
  font-weight: normal;
}
.Batch-Info-Box p {
  margin: 0;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Batch-Info-Group table {
  border-spacing: 0px;
}
.Batch-Info-Group > table {
  border-top: solid 1px #979797;
  border-bottom: solid 1px #979797;
  width: 100%;
}
.Batch-Info-Group table * {
  font-size: 15px;
  padding: 0;
}
.Batch-Info-Group > table table td {
  white-space: nowrap;
  padding-right: 18px;
  padding-bottom: 2px;
  padding-top: 4px;
}
.Batch-Info-Group > table p {
  margin: 0;
  padding: 10px 15px 10px 0;
  line-height: 20px;
  font-size: 13px;
  color: #231f20;
}
.Batch-Custom-Right .Batch-Table-Sortable-No-DragDrop.ExpandPadding {
  padding-bottom: 150px;
}
.Batch-Custom .Batch-Custom-Right .Batch-Table-Sortable {
  padding-bottom: 171.25px;
}
.Batch-Custom .Batch-Custom-Right .ui-state-default .Table {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions {
  width: 1%;
  min-width: 64px;
  max-width: 64px;
  padding: 0;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions .Button:not(.Button-Modal) {
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-gray-xl);
  color: #777;
}
.Batch-Custom td.actions .cell .Actions-Button,
.DashboardAnalytics .Actions-Button {
  position: absolute;
  right: 0;
  top: 28px;
  width: 140px;
  background-color: var(--color-white);
  z-index: 9999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px var(--color-gray-xl);
  border-radius: 6px;
  display: none;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions .Button.showBg:before,
.DashboardAnalytics .showBg:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions .Button.showBg + .Batch-Merge-Chat-Unread {
  display: none;
}
.Batch-Custom td.actions .cell .Actions-Button.visible,
.DashboardAnalytics .Actions-Button.visible {
  display: block;
}
.Batch-Custom td.actions .cell .Actions-Button:before,
.DashboardAnalytics .Actions-Button:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 5px solid #fff;
  z-index: 3;
}
.Batch-Custom td.actions .cell .Actions-Button:after,
.DashboardAnalytics .Actions-Button:after {
  content: "";
  position: absolute;
  top: -6px;
  right: 28px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 5px solid var(--color-gray-xl);
  z-index: 2;
}
.Batch-Custom td.actions .cell .Actions-Button ul,
.DashboardAnalytics .Actions-Button ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.DashboardAnalytics .Actions-Button ul {
  padding: 0 10px;
}
.Batch-Custom td.actions .cell .Actions-Button ul li,
.DashboardAnalytics .Actions-Button ul li {
  padding: 5px 0;
  position: relative;
}
.Batch-Custom td.actions .cell .Actions-Button li button,
.DashboardAnalytics .Actions-Button li button {
  border: 0 none;
  background-color: transparent;
  display: flex;
  width: 100%;
  align-items: center;
}
.Batch-Custom td.actions .cell .Actions-Button li button .Button,
.DashboardAnalytics .Actions-Button li button .Button {
  width: 100% !important;
  border: 0 none !important;
  justify-content: flex-start;
  padding: 0;
  text-align: left;
  border-radius: 0;
}
.Batch-Custom td.actions .cell .Actions-Button li button i:first-child,
.Batch-Custom td.actions .cell .Actions-Button li button .re-assign-icon,
.button-save-cancel i {
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 15px !important;
  border-radius: var(--radius);
  border: 1px solid var(--color-gray-xl);
  line-height: 22px;
  margin-right: 10px;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions .button-save-cancel .Button:not(.Button-Modal) {
  width: auto;
  height: auto;
  border: 0 none;
  padding: 0;
  justify-content: flex-start;
  display: block;
  margin: 0;
  min-width: auto;
  line-height: 24px;
}
.Batch-Custom .Batch-Custom-Right .Table td.actions .button-save-cancel .Button:not(.Button-Modal):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Batch-Custom td.actions .cell .Actions-Button li button .re-assign-icon img {
  width: 14px;
}
.Batch-Custom td.actions .cell .Actions-Button ul li .Batch-Merge-Chat-Unread {
  right: auto;
  left: 20px;
  top: 15px;
}
.Actions-Sub-Button {
  position: absolute;
  top: 5px;
  left: -115px;
  width: 100px;
  background-color: var(--color-white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px var(--color-gray-xl);
  border-radius: 6px;
  display: none;
}
.Actions-Sub-Button.Show {
  display: block;
}
.Actions-Sub-Button:before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  right: -5px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 5px solid #fff;
  z-index: 3;
}
.Actions-Sub-Button:after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 5px solid var(--color-gray-xl);
  z-index: 2;
}
.Actions-Sub-Button button {
  padding: 5px;
  color: #777;
}
.Actions-Sub-Button button:hover {
  color: var(--color-green);
}
.Batch-Custom-Right .Table td.toggle_td {
  min-width: 80px;
  max-width: 80px;
}
.Batch-Custom-Right .Table td.toggle_td.toggle_td_custom {
  white-space: inherit !important;
}
.Batch-Custom-Right .Table td.toggle_td span {
  margin-left: 3px;
}
.Batch-Custom-Right .Table td.vehicle_type,
.Batch-Custom .Table td.booking_name {
  max-width: 88px;
  min-width: 88px;
}
.Batch-Custom-Right .Table td.sub_account_tag,
.Batch-Custom .Table td.booking_name {
  max-width: 80px;
  min-width: 80px;
}
.Batch-Custom-Right .Table td.booking_name {
  max-width: 78px;
  min-width: 78px;
}
.Batch-Custom .Table td.locations_status,
.Batch-Custom-Right .Table td.locations_status {
  min-width: 107px;
  max-width: 107px;
  width: 107px;
}
.Batch-Custom-Right .Batch-Table td.expanded {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
}
.Batch-Custom-Right .Batch-Table thead td.expanded-custom {
  border-color: transparent;
}
.Batch-Custom-Right .Batch-Table td.expanded-custom {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}
.Batch-Custom-Right .Batch-Table td.expanded-custom .expanded__expandButton {
  margin-left: -8px;
}
.Batch-Custom-Right .Batch-Table td.expanded-fullday-custom .Overlay-Tootip {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -190px;
  min-width: 170px;
  width: 170px;
  background-color: var(--color-gray-xxd);
  border-radius: 3px;
  color: var(--color-white);
  text-align: center;
  padding: 5px 10px;
  z-index: 1;
}
.Batch-Custom-Right .Batch-Table td.expanded-fullday-custom .Overlay-Tootip:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -6px;
  margin-top: -6px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid var(--color-gray-xxd);
  z-index: 3;
}
.Batch-Custom-Right .Batch-Table td.expanded-fullday-custom .expanded__expandButton:hover + .Overlay-Tootip,
.Batch-Custom-Right .Batch-Table td.expanded-fullday-custom .expanded__expandButton:hover ~ .Overlay-Tootip {
  display: block;
}
.Batch-Custom-Right .Batch-Table td.expanded .Overlay-Checkbox {
  margin-left: 17px;
  height: 20px;
}
.Batch-Custom .Table td.pickup_date_time,
.Batch-Custom .Table td.badges {
  min-width: 150px;
  max-width: 150px;
}
.Batch-Custom-Right .Table td.pickup_date_time {
  min-width: 115px;
  max-width: 115px;
}
.Batch-Custom .pickup_date_time.overwrite {
  width: 360px !important;
}
.Batch-Custom .cash_payor.overwrite {
  width: 100px !important;
  left: 1408px !important;
}
.Batch-Custom-Right .Table td.pickup_date_time .cell {
  padding: 0 !important;
}
.Batch-Custom-Right .Table td.pickup_date_time .cell .Input {
  height: 35px;
}
.Batch-Custom-Right .Table td.locations_status {
  text-align: center;
}
.BatchUploadOptions .FormGroup-Title h3 {
  font-weight: normal;
}
.BatchUploadOptions-Box .FormGroup-Title-Actions {
  display: flex;
  align-items: center;
  width: 63%;
}
.BatchUploadOptions-Box .FormGroup-Title-Actions > div {
  margin-left: 25px;
}
.BatchUploadOptions-Box .FormGroup-Title-Actions > div label {
  color: var(--color-black);
}
.BatchUploadIntro {
  position: absolute;
  top: 60px;
  bottom: 0;
  align-items: center;
}
.Box-Head {
  min-height: 50px;
}
.modal-batchUploadValidate .Box-Head,
.modal-customLayout .Box-Head,
.Box-Head-Custom-Line {
  border-bottom: 1px solid var(--color-dark-green);
}
.BatchUploadOptions .Box-Actions {
  border-top: 1px solid var(--color-dark-green);
}
.modal-batchUploadValidate .Box {
  max-width: 1176px;
}
.modal-batchUploadValidate .Box-Content .Locations {
  background: var(--color-green);
  overflow-y: scroll;
  width: 480px;
}
.modal-batchUploadValidate .Box-Content .Locations h5 {
  color: var(--color-white);
}
.modal-batchUploadValidate .Box-Content .Locations .location-sortable {
  border-radius: var(--radius) var(--radius) 0 0;
  padding: 5px;
  background: var(--color-white);
}
.modal-batchUploadValidate .Box-Content .Locations .location-sortable-modal {
  border-radius: 0;
  padding-top: 0;
}
.modal-batchUploadValidate .Box-Content .Locations .location-sortable-custom {
  padding-bottom: 0;
}
.modal-batchUploadValidate .Box-Content .Locations .block-item {
  padding: 5px;
}
.modal-batchUploadValidate .Box-Content .Locations .block-item.actions {
  padding: 5px 10px 10px 10px;
  border-radius: 0 0 var(--radius) var(--radius);
  margin-bottom: 5px;
}
.modal-batchUploadValidate .block-item.actions .Button.with-right-icon {
  border: 1px solid var(--color-gray-xl);
  color: #4c4c4c;
  padding-right: 9px;
  padding-left: 9px;
}
.modal-batchUploadValidate .block-item .Input .Icon {
  color: #7c7c7c;
}
.modal-batchUploadValidate .Box-Content,
.BatchUploadOptions .Box-Content,
.modal-multipleSavedBookings .Box-Content {
  padding-bottom: 56px;
}
.modal-batchUploadValidate .Box-Content.Box-Content-Custom-Margin,
.BatchUploadOptions .Box-Content.Box-Content-Custom-Margin,
.modal-multipleSavedBookings .Box-Content.Box-Content-Custom-Margin {
  padding-bottom: 0;
  margin-bottom: 56px;
}
.modal-multipleSavedBookings .Box-Content.Box-Draft {
  padding-bottom: 60px;
}
.modal-multipleSavedBookings .Box-Content.Box-Draft.Box-Content-Custom-Margin {
  padding-bottom: 0;
  margin-bottom: 60px;
}
.modal-batchUploadValidate .Box-Actions,
.BatchUploadOptions .Box-Actions,
.modal-multipleSavedBookings .Box-Actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 2;
  background: white;
  border-radius: 0 0 6px 6px;
  width: 100% !important;
}
.modal-batchUploadValidate .Box-Actions > div,
.BatchUploadOptions .Box-Actions > div,
.modal-multipleSavedBookings .Box-Actions > div {
  flex: 1;
}
.TextArea textarea.White-bg {
  background: var(--color-white);
}
.LocationContactPop {
  margin-left: 16px;
}
.LocationContactPop,
.LocationContactPop .Popover-Arrow {
  background-color: var(--color-green);
}
.LocationContactPop .Popover-Arrow {
  width: 26px;
  height: 26px;
  left: -8px;
}
.LocationContactPop .Popover-contact-list-arrow:before {
  border-bottom-color: var(--color-white);
}
.Batch-Guide {
  padding: 15px 19px;
}
.Batch-Guide-Gallery {
  position: relative;
}
.Batch-Guide-Gallery-Arrow {
  width: 55px;
  height: 55px;
  background-color: var(--color-green);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}
.Batch-Guide-Gallery-Arrow i {
  color: var(--color-white);
  line-height: 55px;
  font-size: 40px;
}
.Batch-Guide-Gallery-Arrow:hover {
  background-color: var(--color-dark-green);
}
.Batch-Guide-Gallery-Arrow.next {
  right: -89px;
}
.Batch-Guide-Gallery-Arrow.prev {
  left: -89px;
}
.Batch-Guide-Gallery div {
  background-color: #f8f8f8;
  border: solid 1px #d4d4d4;
  padding: 15px 21px;
}
.Batch-Guide-Gallery div img {
  max-width: 100%;
}
.Batch-Guide-Info {
  clear: both;
  padding: 15px 0 6px 0;
  display: flex;
  align-items: flex-start;
}
.Batch-Guide-Info > div {
  display: flex;
  align-items: center;
  flex: 1;
}
.Batch-Guide-Info > div div {
  width: 24px;
}
.Batch-Guide-Info > div p {
  flex: 1;
  margin: 0 0 0 10px;
  font-size: 13px;
}
.Batch-Guide-Info > div:first-child p {
  margin-right: 20px;
}
.BatchUploadLayout .List-Item {
  align-items: center;
  margin-bottom: 10px;
}
.BatchUploadOptions .FormGroup-Title-Actions .Overlay-RadioBox input {
  margin: 0;
}
.modal-batchUploadValidate .Box {
  height: 90%;
}
.modal-batchBasic .Box {
  max-width: 1076px;
  background: var(--color-white);
  max-height: 90%;
}
.modal-batchBasic .Modal-Content {
  overflow-y: inherit;
}
.BatchUploadLayout .List-Item .Pin {
  margin-top: 0;
}
.BatchUploadLayout .List-Item .Button.template-icon-download {
  border-color: var(--color-green);
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 0 13px 0 10px;
}
.BatchUploadLayout .List-Item .Button.template-icon-download b,
.BatchUploadLayout .List-Item .Button.template-icon-download span {
  margin-left: 8px;
}
.BatchUploadLayout .List-Item .Button.template-icon-download:hover {
  background-color: var(--color-dark-green);
  border-color: var(--color-dark-green);
}
.Tabs-Content-Item {
  margin-right: 382px;
}
.Tabs-Content-Item-Icon {
  margin-left: 10px;
  height: 24px;
}
.Tabs-Content-Item-Icon:not(.cur-pointer) img {
  max-width: 20px;
  margin-top: 2px;
  cursor: pointer;
}
.Tabs-Content-Right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 382px;
  z-index: 2;
  background-color: #ededed;
}
.Tabs-Content-Right .Tabs-Content-Right-Image {
  margin: 35px 0 30px 0;
  text-align: center;
}
.Tabs-Content-Right .Tabs-Content-Right-Image img {
  max-width: 66px;
}
.Tabs-Content-Right .Tabs-Content-Right-Image h3 {
  margin: 10px 0 0 0;
  font-size: 17px;
  color: #444444;
  position: relative;
}
.Tabs-Content-Right .Tabs-Content-Right-Image h3 span {
  width: 30px;
  height: 30px;
  background-color: #ffdb00;
  margin-left: 6px;
  display: inline-block;
  border-radius: 50%;
  line-height: 30px;
}
.Tabs-Content-Right .Tabs-Content-Right-List {
  padding: 0 20px;
}
.Tabs-Content-Right .Tabs-Content-Right-List > div {
  padding-left: 24px;
  position: relative;
}
.Tabs-Content-Right .Tabs-Content-Right-List > div:before {
  content: "";
  width: 5px;
  height: 5px;
  border: solid 3px #000000;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 8px;
}
.Tabs-Content-Right .Tabs-Content-Right-List span {
  font-size: var(--font-size);
  line-height: 26px;
  color: #444;
}
.batch_upload_form {
  display: flex;
  align-items: center;
}
.batch_upload_form .batch_upload_form_list {
  margin: 0 10px 10px 0;
  position: relative;
}
.batch_upload_form .batch_upload_form_list > button {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-xd);
  padding: 0;
  border-radius: 50%;
}
.batch_upload_form .batch_upload_form_list > button i {
  font-size: var(--font-size-s);
  color: var(--color-gray-xd);
}
.batch_upload_form .batch_upload_form_list > button:hover {
  background-color: var(--color-gray-xd);
}
.batch_upload_form .batch_upload_form_list > button:hover i {
  color: var(--color-white);
}
.batch_upload_form .batch_upload_form_list > div {
  width: 135px;
  height: 115px;
  text-align: center;
  border: solid 1px var(--color-gray-xd);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-white);
}
.batch_upload_form .batch_upload_form_list > div img {
  max-width: 45px;
}
.batch_upload_form .batch_upload_form_list > div span {
  font-size: 12px;
  color: var(--color-gray-xd);
  display: block;
  padding: 0 5px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
.batch_upload_form .batch_upload_form_dropzone_line {
  margin: 0 10px 8px;
  height: 115px;
}
.batch_upload_form .batch_upload_form_dropzone {
  margin: 0 0 10px 0;
  width: 135px;
  height: 115px;
  text-align: center;
}
.batch_upload_form .batch_upload_form_dropzone:first-child {
  margin-left: 0;
}
.batch_upload_form .batch_upload_form_dropzone form,
.batch_upload_form .batch_upload_form_dropzone.custom > div {
  width: 100%;
  height: 100%;
}
.batch_upload_form .batch_upload_form_dropzone.custom > div {
  border-style: solid;
  border-radius: 5px;
}
.batch_upload_form .batch_upload_form_dropzone i {
}
.batch_upload_form .batch_upload_form_dropzone p {
  font-size: 13px;
  padding: 2px 10px 5px 10px;
}
.batch_upload_form .batch_upload_form_dropzone label {
  height: 100%;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.BatchUploadLayout .FormGroup.batch_upload_dropdown {
  margin: 20px 35px 25px 0;
  max-width: 790px;
}
.batch_upload_dropdown .batch_upload_dropdown_header {
  height: 35px;
  border-radius: 6px 6px 0 0;
  background-color: var(--color-gray-xd);
  position: relative;
}
.batch_upload_dropdown .batch_upload_dropdown_header h3 {
  font-size: 15px;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-weight: normal;
  text-align: left;
  color: #ffffff;
  margin: 0;
  line-height: 36px;
  padding-left: 15px;
}
.batch_upload_dropdown .batch_upload_dropdown_header .Tabs-Content-Item-Icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.batch_upload_dropdown .batch_upload_dropdown_list {
  background: #fff;
  border-left: 1px solid var(--color-gray-xl);
  border-right: 1px solid var(--color-gray-xl);
  border-bottom: 1px solid var(--color-gray-xl);
  border-radius: 0 0 6px 6px;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div {
  border-bottom: 1px solid var(--color-gray-xl);
  padding: 0px 10px 0 15px;
  height: 35px;
  display: flex;
  align-items: center;
  position: relative;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div:last-child {
  border-bottom: 0 none;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div span {
  font-size: 13px;
  color: var(--color-gray-xd);
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div button:not(.cur-pointer) {
  border: 0 none;
  padding: 0;
  height: 30px;
  margin-left: 10px;
  cursor: default;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div button:nth-of-type(2) {
  cursor: pointer;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div button:hover {
  background-color: transparent;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div button i {
  font-size: 22px;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div > button {
  background: none;
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  cursor: pointer;
}
.batch_upload_dropdown .batch_upload_dropdown_list > div > button img {
  max-width: 20px;
  display: block;
  margin: auto;
}
.FormGroup-Upload-Batch-Name .Input,
.FormGroup-Upload-Batch-Name .TextArea {
  max-width: 500px !important;
}
.FormGroup-Upload-Batch-Name textarea {
  height: 55px;
  background-color: var(--color-white);
  resize: none;
}
.BatchUploadLayout .FormGroup {
  margin: 10px 0 20px 0;
}
.BatchUploadLayout .FormGroup.first {
  margin-bottom: 15px;
  min-height: 39px;
}
.BatchUploadLayout .FormGroup.first .List-Item {
  min-height: 36px;
}
.BatchUploadLayout .FormGroup.batch_upload_form,
.BatchUploadLayout .FormGroup.batch_upload_dropdown,
.FormGroup-Upload-Batch-Name .Input,
.FormGroup-Upload-Batch-Name .TextArea,
.BatchUploadLayout .FormGroup.batch_pickup_form {
  margin-left: 34px;
}
.BatchUploadLayout .FormGroup-Upload-Batch-Name .FormGroup {
  margin-bottom: 10px;
}
.FormGroup-Upload-Batch-Pickup .ContactInfo {
  width: 400px;
  padding: 20px 35px 20px 20px;
  background: #d8d8d8;
  border: 1px solid var(--color-gray-xxl);
  line-height: 1.5;
  border-radius: var(--radius);
  box-sizing: border-box;
  position: relative;
}
.FormGroup-Upload-Batch-Pickup .ContactInfo.White-bg {
  background: var(--color-white);
  border-color: #cecece;
}
.FormGroup-Upload-Batch-Pickup .ContactInfo .IconCheck {
  position: absolute;
  top: 23px;
  right: 15px;
}
.FormGroup-Upload-Batch-Pickup label.disabled {
  color: #ddd;
}
.BatchUploadLayout .FormGroup.batch_upload_form {
  margin-top: -10px;
  flex-wrap: wrap;
}
.BatchUploadUpload .Tabs-Head-Item {
  border-top: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  margin-right: 5px;
  color: var(--color-gray);
  font-size: 17px;
}
.BatchUploadUpload .Tabs-Head-Item.selected {
  color: var(--color-gray-xd);
}
.BatchUploadUpload .Tabs-Mapping {
  background-color: #d8d8d8;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.BatchUploadUpload .Tabs-Mapping .Tabs-Mapping-Content b {
  color: var(--color-gray-xxd);
  font-size: 15px;
}
.BatchUploadUpload .Tabs-Mapping .Tabs-Mapping-Content label {
  color: #7c7c7c;
}
.BatchUploadUpload .Tabs-Head-Item.selected,
.BatchUploadUpload .Tabs-Content {
  border-color: #d4d4d4;
}
.BatchUploadLayout .List-Item .Pin {
  font-size: 13px;
  line-height: 6px;
  text-indent: 1px;
  width: 30px;
  height: 30px;
}
.BatchUploadUpload .Dropzone {
  border-radius: 5px;
}
.Batch-Custom .Batch-Table-Sortable .Input.White-bg {
  background-color: var(--color-white);
}
.Batch-Custom .contact_name_popover {
  margin-top: -35px;
  margin-left: 8px;
}
.Batch-Custom .contact_name_popover .Selectbox-Contact .Selectbox-Contact-Input .Input {
  border: 1px solid var(--color-gray-xl);
  height: var(--height);
}
.Batch-Custom .contact_name_popover_first {
  margin-top: -18px;
}
.Batch-Custom .contact_name_popover_first .BoxCustom-Close {
  top: -6px;
}
.Batch-Custom .contact_name_popover_first .Popover-Arrow {
  top: 8px;
}

#root .Modal-Actions-Full .Modal-Actions-Left,
#root .Modal-Actions-Full .Modal-Actions-Left > div,
#root .Modal-Actions-Full .Modal-Actions-Right > div {
  align-items: center;
  height: 100%;
}
.modal-multipleSavedBookings .Pagination ul,
.Batch-Custom-Left-Pagination ul {
  display: flex;
  align-items: center;
}
.Batch-Custom-Left-Pagination ul {
  justify-content: center;
}
.Batch-Custom-Left-Pagination ul li.disabled a {
  background-color: var(--color-gray-xxl);
}
.Pagination ul li a {
  cursor: pointer;
  font-size: 17px;
}
.Pagination ul li.active a {
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: var(--color-white);
}
.Pagination ul li a.active,
.Pagination ul li a:hover {
  border-radius: var(--radius);
}
.Status-Custom-Line-Popover {
  top: 0;
  left: 0;
  display: flex;
  margin-left: -320px;
  background: var(--color-green);
  color: var(--color-white);
  cursor: text;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 400ms ease-in-out 0s;
}
.Status-Custom-Line:hover .Status-Custom-Line-Popover,
.Tooltip-Status:hover .Status-Custom-Line-Popover {
  opacity: 1;
  z-index: 2;
  visibility: visible;
}
.Status-Custom-Line-Popover .BoxCustom-Close {
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
}
.Status-Custom-Line-Popover .BoxCustom-Close i {
  width: 16px !important;
  font-size: 11px !important;
}
.Status-Custom-Line-Popover .Popover-Arrow {
  top: 50%;
  right: -7px;
  left: auto;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background: var(--color-green);
}
.Status-Custom-Line-Popover p {
  margin: 0.1em 0;
  font-size: 11px;
  text-align: center;
}

/*
 * Multiple
 */

.MultipleBookingLayout {
  background-color: var(--color-gray-xxl);
  padding: 15px 20px;
  display: flex;
  height: calc(100vh - 198px);
}
.MultipleBookingLayout .BookingWizard {
  min-width: 365px;
  width: 365px;
  margin-right: 15px;
}
.MultipleBookingLayout .BookingWizard .Head {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3) !important;
  z-index: 0;
}
.BookingWizard .Steps .Step.selected {
  font-weight: normal;
}
.MultipleBookingLayout .BookingWizard .Content h3.Custom.Custom-Title {
  margin: 0 0 6px 0;
}
.MultipleBookingLayout-Collapse {
  bottom: 56px;
  top: 60px;
  box-shadow: 0 -4px 6px rgba(49, 49, 49, 0.25);
  transform: translateX(390px);
  transition: 400ms ease-in-out 0s;
  border-radius: 0 0 0 5px;
}
.MultipleBookingLayout-Collapse.Active {
  transform: translateX(0px);
}
.MultipleBookingLayout-Collapse-Icon {
  position: fixed;
  bottom: 57px;
  top: 88px;
  right: 0;
  height: 38px;
  z-index: 3;
  background-color: white;
  border-radius: 5px 0 0 5px;
  box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-green);
  font-size: var(--font-size-s);
  width: 40px;
  cursor: pointer;
}
.MultipleBookingLayout-Collapse-Icon:hover {
  background-color: #f9f9f9;
}
.MultipleBookingLayout-Collapse-Icon span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.MultipleBookingLayout-Collapse-Icon i.Icon {
  font-size: 28px;
  font-weight: bold;
  width: 22px;
  text-align: center;
  text-indent: -3px;
  display: block;
  line-height: 22px;
}
.MultipleBookingLayout .BookingWizard .Content {
  overflow-y: auto;
}
.MultipleBookingLayout .BookingWizard .Actions {
  justify-content: flex-start;
}
.MultipleBookingLayout > .BookingWizard > .Actions {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
.MultipleBookingLayout .BookingWizard .Actions .Left {
  flex: inherit;
  width: auto;
}
.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles {
  box-shadow: 1px -1px 6px #929292;
  min-width: 156px;
  width: auto;
}
.MultipleBookingLayout .BookingWizard .Actions .Left,
.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles button,
.MultipleBookingLayout .BookingWizard-Actions button {
  font-size: 15px;
}
.MultipleBookingLayout .BookingWizard-Actions button {
  margin: 10px 0;
}
.MultipleBookingLayout .BookingWizard-Actions button img {
  max-width: 60px;
}
#root .MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles button {
  border: 0 none;
  text-align: left;
}
.MultipleBookingLayout .overlay-multiple {
  position: fixed;
  top: 106px;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  display: none;
}
.MultipleBookingLayout .BookingWizard .Locations.block .block-item.actions .Button {
  border: 1px solid var(--color-gray-xl);
  color: #444444;
}
.Custom-Scroll-Group-X {
  overflow-x: auto;
}
.MultipleBookingLayout::-webkit-scrollbar,
.ReactVirtualized__Grid::-webkit-scrollbar,
.BatchUploadValidate .Batch-Table::-webkit-scrollbar,
.Custom-Scroll-Group-X::-webkit-scrollbar,
.dlvr-scroll-bar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
}
.BookingWizard .Content::-webkit-scrollbar,
.modal-batchUploadValidate .Box-Content .Locations::-webkit-scrollbar,
.BatchUploadValidate .Table-Sortable::-webkit-scrollbar,
.BookingWizard .CodPod.Popover .Popover-List-Scroll::-webkit-scrollbar {
  width: 10px;
  -webkit-appearance: none;
}
.MultipleBookingLayout::-webkit-scrollbar-track,
.ReactVirtualized__Grid::-webkit-scrollbar-track,
.BatchUploadValidate .Batch-Table::-webkit-scrollbar-track,
.Custom-Scroll-Group-X::-webkit-scrollbar-track,
.BookingWizard .Content::-webkit-scrollbar-track,
.modal-batchUploadValidate .Box-Content .Locations::-webkit-scrollbar-track,
.BatchUploadValidate .Table-Sortable::-webkit-scrollbar-track,
.BookingWizard .CodPod.Popover .Popover-List-Scroll::-webkit-scrollbar-track,
.Dropdown-Menu-Item-Sub::-webkit-scrollbar-track,
.dlvr-scroll-bar::-webkit-scrollbar-track {
  background-color: #d7edd7;
}
.MultipleBookingLayout::-webkit-scrollbar-thumb,
.ReactVirtualized__Grid::-webkit-scrollbar-thumb,
.BatchUploadValidate .Batch-Table::-webkit-scrollbar-thumb,
.Custom-Scroll-Group-X::-webkit-scrollbar-thumb,
.BookingWizard .Content::-webkit-scrollbar-thumb,
.modal-batchUploadValidate .Box-Content .Locations::-webkit-scrollbar-thumb,
.BatchUploadValidate .Table-Sortable::-webkit-scrollbar-thumb,
.BookingWizard .CodPod.Popover .Popover-List-Scroll::-webkit-scrollbar-thumb,
.Dropdown-Menu-Item-Sub::-webkit-scrollbar-thumb,
.dlvr-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #0e730f;
}
.Booking-Multiple-Actions {
  padding: 0 20px;
  height: 56px;
  border-top: 1px solid var(--color-gray-xl);
}
.Booking-Multiple-Actions > div {
  padding: 10px 0;
}
.MultipleBookingLayout .BookingWizard .Header .Left h3 {
  margin: 8px 10px 8px 0;
  line-height: 30px;
  height: 30px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.MultipleBookingLayout .BookingWizard .Header .Left h3,
.MultipleBookingLayout .BookingWizard .Header .Left input {
  font-size: 17px;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left.Custom {
  margin-right: 140px;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Right.Custom {
  width: 140px;
  text-align: right;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Right.Custom b {
  color: var(--color-yellow);
  font-weight: normal;
  font-size: 15px;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .input-outside {
  position: relative;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .input-outside:before {
  content: "";
  position: absolute;
  top: 0;
  right: -25px;
  width: 25px;
  z-index: 1;
  bottom: 0;
  cursor: pointer;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left h3 {
  border-bottom: 1px solid transparent;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input {
  border-bottom: 1px solid transparent;
  font-weight: normal;
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input:-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input:-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Header-Custom .Left input::placeholder {
  /* Most modern browsers support this now. */
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Actions .Button:hover {
  background-color: transparent;
}
.MultipleBookingLayout .BookingWizard .Actions .Button.gray:hover {
  background-color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Actions .Button.green,
.MultipleBookingLayout .BookingWizard .Actions .Button.close-CodPod:hover {
  background-color: var(--color-dark-green);
}
.MultipleBookingLayout .BookingWizard .Actions .Button.green:hover {
  background-color: #40811e;
}
.MultipleBookingLayout .BookingWizard .Actions .Right {
  text-align: right;
}
.MultipleBookingLayout .BookingWizard .Actions .Right .Button {
  border: 1px solid var(--color-white);
  justify-content: center;
  align-items: center;
  max-height: 32px;
}
.MultipleBookingLayout .BookingWizard .Actions .Right .Left.Button:last-child {
  margin-right: 0;
}
.MultipleBookingLayout .BookingWizard .Actions .Right .Button.white {
  background-color: var(--color-dark-green);
  border-color: var(--color-dark-green);
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .Actions .Right .Button:hover {
  background-color: #40811e;
  border-color: #40811e;
}
.modal-multipleSavedBookings .Box .Table tbody td {
  font-size: var(--font-size-s);
}
.Booking-Multiple-Actions button {
  width: auto;
  min-width: 120px;
  height: var(--height);
  padding: 0 var(--length-xxs);
}
.Booking-Multiple-Actions .Right button {
  margin: 0 0 0 10px;
}
.Booking-BatchEZ .MultipleBookingLayout-Summary-Title h2,
.MultipleBookingLayout-Summary-Title h2 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: normal;
}
.Booking-BatchEZ .MultipleBookingLayout-Summary-Title,
.MultipleBookingLayout-Summary-Title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #444444;
}
.MultipleBookingLayout-Summary-BatchEZ-Dotline {
  margin: 0 10px 0 0;
  padding: 15px 10px 15px 0;
  box-sizing: border-box;
  position: relative;
}
.MultipleBookingLayout-Summary-BatchEZ-Dotline:before,
.MultipleBookingLayout-Summary-BatchEZ-Dotline:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: var(--color-gray-xxl);
  left: 20px;
  right: 20px;
}
.MultipleBookingLayout-Summary-BatchEZ-Dotline:before {
  top: 0;
}
.MultipleBookingLayout-Summary-BatchEZ-Dotline:before {
  bottom: 0;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group {
  align-items: flex-start;
  position: relative;
  padding: 0 0 10px 20px;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group:before {
  content: "";
  position: absolute;
  left: 31px;
  bottom: -5px;
  top: 5px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMnB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMi4wMDAwMDAsIC02ODcuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOC4wNTUzODMsIDIxLjc1MDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTI4LjA1NTM4MywgLTIxLjc1MDAwMCkgIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: repeat-y;
  background-position: 0px 0px;
  z-index: -1;
  width: 3px;
  background-size: 3px;
  opacity: 0.5;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group.Active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 20px;
  width: 5px;
  background-color: var(--color-green);
}
.MultipleBookingLayout-Summary-BatchEZ-Dotline
  .MultipleBookingLayout-Summary-BatchEZ:last-child
  .MultipleBookingLayout-Summary-Group:before {
  display: none;
}
.MultipleBookingLayout-Summary-BatchEZ-Docs {
  position: absolute;
  top: 80px;
  left: -111px;
  transform: rotate(-90deg);
  width: 190px;
  overflow: hidden;
}
.MultipleBookingLayout-Summary-BatchEZ-Docs div {
  padding: 0 5px;
  border-radius: 5px 5px 0 0;
  height: 32px;
  transition: 400ms ease-in-out 0ms;
  animation: 5.4s ease-out 0s 1 slideInFromLeft;
  transform: translateY(32px);
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(0);
  }
  93% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(32px);
  }
}
.MultipleBookingLayout-Collapse.Active
  .MultipleBookingLayout-Summary-BatchEZ-Docs-Hover:hover
  .MultipleBookingLayout-Summary-BatchEZ-Docs
  div {
  transform: translateY(0px);
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left {
  display: flex;
  align-items: flex-start;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Right {
  padding-left: 4px;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Right * {
  font-size: 15px;
  color: #444;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:first-child {
  min-width: 24px;
  min-height: 24px;
  border: 1px solid var(--color-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-green);
  font-weight: bold;
  background-color: var(--color-white);
  margin-top: 5px;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div.Custom-Font:first-child {
  font-size: var(--font-size-s);
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child {
  margin-left: 10px;
  position: relative;
  flex: 1;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h4,
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h5 {
  margin: 0;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h4,
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h5 {
  font-weight: normal;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h4 {
  background-color: var(--color-white);
  padding-right: 5px;
  display: inline-block;
  min-height: 19px;
  font-size: 15px;
  color: #444;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child h5 {
  font-size: 13px;
  color: #7c7c7c;
}
.MultipleBookingLayout-Summary-BatchEZ .MultipleBookingLayout-Summary-Group .Left > div:last-child:before {
  content: "";
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  z-index: -1;
  background-repeat: repeat-x;
  height: 2px;
  background-size: 5px;
  background-position: 0px 0;
}

/*
 * Preview Multiple
 */

.Booking-BatchEZ label {
  font-size: 13px;
}
.MultipleBlock-Title.w100,
.BookingWizard .Content .MultipleReviewWrapper h3.Custom {
  font-size: 15px;
}
.Booking-BatchEZ .Pricing.block .block-item b {
  font-weight: bold;
}
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Business .Left,
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Business .Right,
.MultipleBookingLayout-Summary-Group-Business .Left,
.MultipleBookingLayout-Summary-Group-Business .Right,
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Personal .Left,
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Personal .Right,
.MultipleBookingLayout-Summary-Group-Personal .Left,
.MultipleBookingLayout-Summary-Group-Personal .Right {
  font-size: 15px;
  color: #444444;
}
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Total .Left,
.Booking-BatchEZ .MultipleBookingLayout-Summary-Group-Total .Right,
.MultipleBookingLayout-Summary-Group-Total .Left,
.MultipleBookingLayout-Summary-Group-Total .Right {
  font-size: 17px;
  color: #444444;
}
.MultipleReviewWrapper h3.Custom:not(.Custom-Color-Multitple) b {
  color: #d6d6d6;
}
.MultipleBookingLayout-Noted p {
  font-size: 13px;
  color: #7c7c7c;
}
.MultipleReviewWrapper-Title-Icon {
  padding-bottom: 25px;
  margin-top: -15px;
  text-align: right;
}
.MultipleReviewWrapper-Title-Icon.Reset-Padding {
  padding-bottom: 0;
}
.MultipleReviewWrapper-Title-Icon span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d6d6d6;
  display: inline-block;
}
.MultipleReviewWrapper-Title-Icon i {
  color: #d6d6d6;
}
.MultipleReviewWrapper-Title-Icon.Rotate i {
  transform: rotate(180deg);
  margin-top: -1px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock {
  background: #d6d6d6;
  box-shadow: none;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock.Hide {
  display: none !important;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock:not(.MultipleBlockServices) .block-item {
  flex-direction: column;
  align-items: flex-start;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Title {
  margin: 0 0 10px 0;
  color: #7c7c7c;
  display: flex;
  align-items: center;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Title label {
  flex: 1;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Title span,
.Default-Expand-Location {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #7c7c7c;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Title i {
  font-size: 14px;
  line-height: 15px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Title i.white {
  color: var(--color-white);
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group {
  display: flex;
  align-items: center;
  position: relative;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group:before {
  content: "";
  position: absolute;
  top: -9px;
  height: 50%;
  left: 8px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMnB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMi4wMDAwMDAsIC02ODcuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOC4wNTUzODMsIDIxLjc1MDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTI4LjA1NTM4MywgLTIxLjc1MDAwMCkgIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: repeat-y;
  width: 3px;
  background-size: 3px;
  opacity: 0.5;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group:first-child:before {
  display: none;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group > div,
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div {
  flex: 1;
  display: flex;
  align-items: center;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group > div:first-child,
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child {
  max-width: 30px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group > div:first-child i {
  text-indent: -2px;
  font-size: 26px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Date {
  color: #7c7c7c;
  margin-bottom: 5px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Bg {
  position: absolute;
  border: 1px solid #7c7c7c;
  top: 0;
  right: 0;
  left: 30px;
  bottom: 0;
  max-width: inherit !important;
  border-radius: 6px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group {
  width: 100%;
  position: relative;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 8px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMnB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMi4wMDAwMDAsIC02ODcuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOC4wNTUzODMsIDIxLjc1MDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTI4LjA1NTM4MywgLTIxLjc1MDAwMCkgIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: repeat-y;
  width: 3px;
  background-size: 3px;
  opacity: 0.5;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group:nth-of-type(2):before {
  top: 50%;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:last-child:before {
  bottom: 50%;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group .Pin {
  max-width: 20px;
  max-height: 20px;
  font-size: 9px;
  background-color: #7c7c7c;
  color: #d6d6d6;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child {
  position: relative;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:first-child:before,
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:first-child:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:first-child:before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid #7c7c7c;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:first-child:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid #d6d6d6;
  right: -1px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child {
  width: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:last-child
  p {
  margin: 0 10px;
  padding: 10px 0;
  color: #7c7c7c;
  border-bottom: 1px solid #b1b1b1;
  flex: 1;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group:last-child
  > div:last-child
  p {
  border-bottom: 0 none;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:last-child
  div {
  display: flex;
  color: #7c7c7c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 10px;
  font-size: 13px;
  padding: 8px 0;
}
.MultipleBookingLayout
  .BookingWizard
  .MultipleBlock
  .block-item
  .MultipleBlock-Group-Location-Group
  > div:last-child
  div
  img {
  max-width: 40px;
  display: block;
  margin: auto;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item {
  border-bottom: 1px solid #b1b1b1;
  margin: 0 5px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item:last-child {
  border-bottom: 0 none;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item p {
  margin: 0;
  color: #7c7c7c;
  flex: 1;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item div {
  min-width: 14px;
  height: 14px;
  border: 1px solid #7c7c7c;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7c7c7c;
  font-size: 13px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item div.custom {
  border: 0 none;
}
.MultipleBookingLayout .BookingWizard .MultipleBlockServices .block-item i {
  min-width: 14px;
  font-size: 12px;
}
.Booking-BatchEZ .BookingWizard .Content,
.BatchUploadBook .Content {
  padding-bottom: 0;
}
.Booking-BatchEZ .MultipleBookingLayout {
  height: calc(100vh - 260px);
  background: #e6e9ed;
  border: 0 none;
  padding: 5px 20px;
}
.Booking-BatchEZ .MultipleBookingLayout-Collapse-Icon {
  top: 82px;
}
.Booking-BatchEZ .BookingWizard .Header {
  border-bottom: 1px solid var(--color-dark-green);
}

/*
 * Custom
 */

.Popup.devina-style .Box .Box-Content.Box-Content-Guarantee {
  padding-top: 37px;
}
.Modal-ParkingTolls .Popup.No-Seft {
  align-self: flex-start !important;
  margin: 80px auto 40px !important;
}
.ParkingTolls-Price-Custom {
  flex-direction: column;
  align-items: flex-end;
}
.ParkingTolls-Price-Custom > table {
  width: auto;
}
.ParkingTolls-Price-Custom > table td {
  padding-bottom: 10px;
  text-align: right;
}
.ParkingTolls-Price-Custom > table .t-right {
  text-align: right;
}
.ParkingTolls-Price-Custom > table b {
  font-size: 26px !important;
}
.ParkingTolls-Price-Custom tbody td {
  border-top: 0 none !important;
  padding: 0 0 10px 0 !important;
}
.ParkingTolls-Price-Custom tbody td span {
  font-size: var(--font-size) !important;
  margin-bottom: 0 !important;
}
.Popup .AddressItem .AddressContact-Noted {
  font-size: var(--font-size-s);
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;
}
.Box-Content-Social {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 20px 0;
}
.Box-Content-Social div {
  flex: 1;
}
.Box-Content-Social a {
  flex: 1;
  border: 0 none;
  border-radius: 4px;
  outline: 0;
  transition: 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Box-Content-Social .social-facebook {
  margin-right: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px #bbbbbb;
}
.Box-Content-Social .social-facebook:hover {
  background-color: #efefef;
  color: var(--color-black);
}
.Box-Content-Social .social-google {
  margin-left: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px #bbbbbb;
}
.Box-Content-Social .social-google:hover {
  background-color: #efefef;
  color: var(--color-black);
}
.Box-Content-Social img {
  max-height: 100%;
  margin-right: 5px;
}
.Box-Content-Social .social-google img {
  margin-right: 7px;
}
.Box-Content-Social span {
  font-size: 13px;
  text-align: center;
}
.Box-Content-Social-Line {
  padding: 0 20px;
  height: 40px;
  position: relative;
  text-align: center;
}
.Box-Content-Social-Line h5 {
  margin: auto;
  font-weight: 300;
  font-size: 13px;
  color: #444444;
  background-color: white;
  padding: 0 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}
.Box-Content-Social-Line div {
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: #a4a4a4;
  z-index: 0;
  max-width: 400px;
  margin: auto;
}
.Closure-List-Tabs-Content {
  height: 302px;
}

/*
 * Tracking chat
 */

.Modal-Tracking-Chat {
  width: 340px;
  min-height: 41px;
  border-radius: 4px 4px 0 0;
  background-color: var(--color-dark-green);
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.17);
  position: absolute;
  left: 20px;
  bottom: 56px;
  z-index: 222;
  padding: 0 5px;
}
.Modal-Tracking-Chat-Header div {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 32px 0 15px;
  cursor: pointer;
  position: relative;
}
.Modal-Tracking-Chat-Header .icon-chat {
  max-width: 18px;
  margin-right: 5px;
}
.Modal-Tracking-Chat-Header div label {
  color: var(--color-white);
  font-size: 13px;
  font-weight: 500;
  padding-left: 6px;
  cursor: pointer;
  flex: 1;
}
.Modal-Tracking-Chat-Header div span {
  width: 16px;
  height: 16px;
}
.Modal-Tracking-Chat-Header i {
  position: absolute;
  color: var(--color-white);
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}
.Modal-Tracking-Chat .Closure-List-Tabs {
  height: 70vh;
  background: white;
  border-radius: 5px;
  position: relative;
}
.Modal-Tracking-Chat .Closure-List-Tabs-Content,
.Batch-Chat .ui-tabs,
.Batch-Chat .ui-tabs-panel {
  border: 0 none;
}
.Batch-Chat .ui-tabs-panel {
  background-color: transparent;
}
.Modal-Tracking-Chat .Closure-List-Tabs-Content {
  border: 0 none;
}
.Modal-Tracking-Chat .Closure-List-Tabs-Content .Closure-List-Tab-List {
  top: 76px;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: 0 7px;
  border-top: 1px solid white;
  padding: 10px 0 0 0;
}
.Batch-Chat .Closure-List-Tabs-Content .Closure-List-Tab-List {
  top: 0;
  border-top: 0 none;
}
.Closure-List-Tab-List li.employee > div:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: -8px;
  border-top: 10px solid #e8ffe8;
}
.Closure-List-Tab-List li.employee > div {
  background-color: #e8ffe8;
  box-shadow: 0 1px 2px 0 rgba(164, 152, 135, 0.32);
}
.Closure-List-Tab-List li.client > div {
  margin: 0 8px;
}
.Closure-List-Tabs-Content .client > div {
  display: flex;
}
.Closure-List-Tabs-Content .client-image {
  width: 24px;
  position: absolute;
  top: 0;
  left: -38px;
}
.Closure-List-Tabs-Content .client-image img,
.Closure-List-Tabs-Content .client-upload .full img,
.Closure-List-Tab-List .client-upload .full img {
  max-width: 100%;
}
.Closure-List-Tab-List li > div {
  max-width: 158px;
  min-width: 158px;
}
.Closure-List-Tab-List li > div.client-upload {
  max-width: 120px;
  min-width: 120px;
}
.Closure-List-Tab-List li > div.client-upload .client-content,
.Closure-List-Tab-List li.me > div sup {
  flex: 1;
}
.Closure-List-Tab-List li > div .client-content .client-name {
  color: #999;
  font-size: smaller;
  margin: 0 0 5px 0;
}
.Closure-List-Tab-List li > div .client-content .date-time {
  margin-top: 5px;
}
.Closure-List-Tab-List li > div.client-upload .full {
  position: relative;
}
.Closure-List-Tab-List .client-upload .full.file {
  min-height: 134px;
  background-color: #cecece;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  margin-bottom: 15px;
}
.Closure-List-Tab-List .client-upload .full.file.loading {
  background-color: #8d8f8e;
}
.Closure-List-Tab-List .client-upload .full.file a {
  flex-direction: column;
  color: #949494;
  font-size: 10px;
  border-radius: 4px;
}
.Closure-List-Tab-List .client-upload .full.file p {
  width: 118px;
}
.Closure-List-Tab-List .client-upload .full.file img {
  margin-bottom: 15px;
}
.Closure-List-Tab-List .client-upload .full.file.loading img {
  max-width: 48px;
  margin: 0;
}
.Closure-List-Tab-List .client-upload .full img {
  border-radius: 4px;
}
.Closure-List-Tab-List li.me > div.client-upload .full {
  padding-bottom: 0;
  margin-bottom: 0;
}
.Closure-List-Tab-List li.me > div.client-upload .client-content > div {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.Closure-List-Tab-List li.me > div.client-upload sup,
.Closure-List-Tab-List li.me > div.client-upload i {
  position: relative;
  right: 0;
  bottom: 0;
  margin-right: 5px;
}
.Closure-List-Tab-List li.me > div.client-upload i,
.Closure-List-Tab-List li.me > div i {
  color: #3fae29;
}
.Closure-List-Tab-List li.me > div.client-upload .client-spinner {
  flex: 1;
  text-align: right;
}
.Closure-List-Tab-List li.me > div.client-upload .client-spinner img {
  width: 14px;
  max-width: 14px;
  display: block;
  margin: 0 0 0 auto;
}
.Closure-List-Tab-List li > div.client-upload .client-upload-fail a {
  overflow: hidden;
}
.Closure-List-Tab-List li > div.client-upload .client-upload-fail a img {
  filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  backdrop-filter: blur(6.5px);
}
.Closure-List-Tab-List li > div.client-upload .client-upload-fail .client-upload-fail-retry {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border-radius: 0;
}
.Modal-Tracking-Chat-Header div img,
.Closure-List-Tabs-Content .client-image img,
.Closure-List-Tab-Block .DriverBlock .Avatar,
.Closure-List-Tab-Block .DriverBlock .Avatar img {
  border-radius: 2px;
}

.Closure-List-Tab-Block .DriverBlock .Avatar {
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
}
.Closure-List-Tab-Block .DriverBlock .Avatar img {
  display: block;
  height: 100%;
  width: auto;
  border-radius: 5px;
}

.Closure-List-Tab-Block .dlvr-driver-name,
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BatchUploadIntro .SplitView:first-child:after {
  top: 0;
  bottom: 0;
  height: 424px;
  margin: auto;
}
.BatchUploadIntro-Image {
  margin: 35px 0 28px;
  height: 150px;
}
.BatchUploadIntro p {
  margin-bottom: 35px;
}
.BatchUploadAssign .FormGroup select {
  font-size: var(--font-size);
  width: 100%;
}
.BatchUploadAssign .FormGroup-explanation {
  margin-top: 0;
}
.BatchUploadLayout .Custom-Padding {
  padding-top: 14px;
}
.BatchUploadOptions .List-Item label {
  font-size: var(--font-size);
}
.Modal-Addlocation-Info-Right .Popover-contact-list-arrow:before {
  border-bottom-color: var(--color-white);
}
.Modal-Addlocation-Info-Right .Popover-contact-list-arrow:after {
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -4px;
  right: 40px;
}
.modal-batchUploadValidate .Modal.modal-outOfService {
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 56px;
  width: 480px;
}

.modal-batchUploadValidate .Modal.modal-in-popup {
  position: absolute;
  left: 0;
  width: 100%;
}

.modal-batchUploadValidate .Modal.modal-outOfService .Box {
  background-color: var(--color-green);
}
.modal-batchUploadValidate .Modal.modal-outOfService .Box .Normal-Booking-Title {
  color: var(--color-yellow);
  margin: 0 0 15px;
  font-size: 20px;
  text-transform: inherit;
}
.modal-batchUploadValidate .Modal.modal-outOfService .Box .Box-Content {
  padding-bottom: 15px;
}
.FormGroup-Option-PickupTime,
.FormGroup-Option-Select-Contact {
  padding-top: 10px;
  position: relative;
}
.BatchUploadOptions .List:before,
.FormGroup-Option-PickupTime:before,
.FormGroup-Option-Select-Contact:before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ddd;
  margin-top: 5px;
  display: block;
}
.FormGroup-Option-PickupTime:before,
.FormGroup-Option-Select-Contact:before {
  margin-bottom: 20px;
}
.BatchUploadOptions .FormGroup-Option-PickupTime .Input {
  margin: 0;
}
.BatchUploadOptions .FormGroup-Option-PickupTime .Input input {
  width: 140px;
  padding-right: 0;
}
.BatchUploadOptions .FormGroup-Option-PickupTime .Input .Input-Icon {
  width: 36px;
}
.Menu-Nav.left .Menu-Nav-Item:not(.Devina-Screen) {
  position: relative;
  padding-left: 18px;
  padding-right: 13px;
}
.Menu-Nav.left .Menu-Nav-Item:not(.Devina-Screen):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #9fd484;
  left: 0;
  height: 60%;
  margin: auto;
}
.Menu-Nav.left .Menu-Nav-Item:not(.Devina-Screen):first-child:before {
  display: none;
}
.Menu-Nav.left a.Menu-Nav-Item:not(.Devina-Screen) {
  padding-right: 19px;
}
.Menu-Nav.left .Menu-Nav-Item.Devina-Screen {
  padding-right: 0;
}
.Closure-List-Tabs-Actions a span {
  width: 16px;
  height: 16px;
  bottom: 0.5em;
  position: absolute;
  margin-left: 1px;
  top: inherit;
  padding: 0;
}
.Closure-Fullday .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Scheduled .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Fullday.Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Scheduled.Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: #077490 !important;
  border-radius: var(--radius) var(--radius) 0 0;
}
.Modal-Basic .Box.Waning-Content {
  max-width: 500px;
}
.Waning-Content .flex-start {
  align-items: flex-start;
}
.Waning-Content p {
  margin: 0 0 0 15px;
  line-height: 22px;
}
.Waning-Content p img {
  padding: 0 5px;
}
.Locating-Custom .Popup {
  align-self: center !important;
}
.PickupTime-DatePicker .DatePicker-Actions .Button.white {
  border-color: transparent;
}
.BoxCustom-Close {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--color-green);
  color: #fff;
  cursor: pointer;
}
.BoxCustom-Close i {
  display: block;
  line-height: 24px;
  font-size: 15px !important;
  color: var(--color-green);
}
.BoxCustom-Close:hover {
  background-color: var(--color-green);
}
.BoxCustom-Close:hover i {
  color: var(--color-white);
}
.w160 {
  max-width: 160px !important;
}
.w180 {
  max-width: 180px !important;
}
.m-w-80 {
  min-width: 80px;
  width: 80px;
}
.m-w-72 {
  min-width: 72px;
  width: 72px;
}
.m-h-115 {
  min-height: 115px;
}
.w485 {
  max-width: 485px !important;
}
.w340 {
  max-width: 340px !important;
}
.w400 {
  max-width: 400px !important;
}
.w600 {
  max-width: 600px !important;
}
.w620 {
  max-width: 620px !important;
}
.w700 {
  max-width: 800px !important;
}
.w-100 {
  width: 100%;
}
.m-w-auto {
  min-width: auto !important;
}
.max-w-80 {
  max-width: 80% !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.max-w-90 {
  max-width: 90% !important;
}
.w-auto {
  width: auto !important;
}
.w-660 {
  width: 660px !important;
}
.m-w180 {
  min-width: 175px !important;
}
.m-w26 {
  min-width: 26px;
}
.m-w24 {
  min-width: 24px;
}
.m-w160 {
  min-width: 160px;
}
.m-h24 {
  min-height: 24px;
}
.w72 {
  width: 72px;
}
.h72 {
  height: 72px;
}
.h40 {
  height: 40px;
}
.h56 {
  height: 56px !important;
}
.h300 {
  max-height: 300px !important;
}
.Modal-FromGroup.Modal-FromGroup-Title {
  background-color: #eeeeef;
  padding: 10px 15px 8px 15px;
  font-size: 11px;
}
.Modal-FromGroup .Modal-FromGroup-Icon {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.Modal-FromGroup .Modal-FromGroup-Icon .Services-Icon {
  height: 100%;
  margin: 0 10px 0 0 !important;
  width: 40px;
}
.Modal-FromGroup .Modal-FromGroup-Icon .Services-Icon i:not(.White-text):not(.White):not(.white) {
  color: #7c7c7c;
}
.Modal-FromGroup .Modal-FromGroup-Icon img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.Modal-Basic .Box-Actions.Box-Actions-Customs,
.Modal-Basic .Box-Actions,
.Modal-Basic .Box-Actions.w100 {
  min-height: 58px;
}
.Modal-Basic .Box-Actions.Box-Actions-Customs,
.Modal-Basic .Box-Actions.Box-Actions-Customs > div,
.Modal-Basic .Box-Actions.w100,
.Modal-Basic .Box-Actions.w100 > div {
  width: 100% !important;
}
.Modal-Basic .Box-Actions.Box-Actions-Customs {
  justify-content: flex-end;
}
.Modal-Basic .Box-Actions.Box-Actions-Customs .Button {
  width: auto;
  max-width: 100%;
}
.Input-Hover .close-icon,
.Input-Hover .Input-Hover-Icon {
  width: 0px;
  opacity: 0;
  visibility: hidden;
}
.Input-Hover:hover .close-icon,
.Input-Hover:hover .Input-Hover-Icon {
  width: 14px;
  margin-right: 9px;
  opacity: 1;
  visibility: visible;
}
.Input-Hover .close-icon ~ .Input-Icon,
.Input-Hover .Input-Hover-Icon ~ .Input-Icon,
.Input-Hover .close-icon + .Input-Icon,
.Input-Hover .Input-Hover-Icon + .Input-Icon {
  margin-left: -12px;
}
.Popover,
.MultipleBookingLayout .CodPod.Popover {
  border-radius: var(--radius) !important;
}
.Input-Icon-LongHaul {
  width: 30px;
}
.Input-Icon-LongHaul-Custom {
  margin-right: -8px;
}
.Input-Hover .close-icon ~ .Input-Icon-LongHaul-Custom {
  margin-right: 0;
}

/*
 * Long Haul Popover
 */

.BookingWizard .Actions.Popover-Item-LongHaul-Actions {
  padding: 0;
}
.Popover-Item-LongHaul-Title,
.Popover-Item-LongHaul-Title-Input {
  height: 20px;
}
.Popover-Item.Popover-Item-LongHaul {
  padding: 20px 15px;
  position: relative;
  background: var(--color-green);
  margin: -15px -10px;
  border-radius: var(--radius) !important;
}
.Popover-Item-LongHaul-Icon-Arrow {
  position: absolute;
  top: 10px;
  right: 10px;
}
.Popover-Item-LongHaul-Icon-Arrow.Active {
  opacity: 0;
}
.Popover-Item-LongHaul-Choose {
  display: flex;
  margin: 1em 0;
  background-color: var(--color-white);
  border-radius: var(--radius);
  overflow-y: auto;
}
.Popover-Item-LongHaul-Choose div {
  flex: 1;
  color: #444;
  cursor: pointer;
  position: relative;
}
.Popover-Item-LongHaul-Choose div .Popover-Item-LongHaul-Choose-Title {
  display: block;
  position: relative;
}
.Popover-Item-LongHaul-Choose div .Popover-Item-LongHaul-Choose-Title:before,
.Popover-Item-LongHaul-Group:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 1px;
  background-color: #ddd;
}
.Popover-Item-LongHaul-Choose .zones-group-0 .Popover-Item-LongHaul-Choose-Title:before,
.Popover-Item-LongHaul-Choose div:last-child .Popover-Item-LongHaul-Group:before {
  display: none;
}
.Popover-Item-LongHaul-Choose div.active {
  color: var(--color-green);
}
.Popover-Item-LongHaul-Dropdown {
  display: none;
}
.Popover-Item-LongHaul-Choose div.active .Popover-Item-LongHaul-Dropdown {
  display: block;
}
.Popover-Item-LongHaul-Choose div.active .Popover-Item-LongHaul-Icon-Arrow {
  opacity: 0;
}
.Popover-Item-LongHaul-Choose div.active .Popover-Item-LongHaul-Icon-Arrow.Active {
  opacity: 1;
}
.BookingWizard .Actions.Popover-Item-LongHaul-Actions .Button {
  margin-bottom: 0;
  border-radius: 2px;
}
.BookingWizard .Actions.Popover-Item-LongHaul-Actions .Button:first-child:last-child {
  margin-left: 0;
  margin-right: 0;
}
.BookingWizard .Actions.Popover-Item-LongHaul-Actions .Button:first-child {
  margin-left: 0;
}
.BookingWizard .Actions.Popover-Item-LongHaul-Actions .Button:last-child {
  margin-right: 0;
}
.Popover-Item-LongHaul-Input {
  border-radius: 2px;
  border-color: transparent;
  margin-bottom: 5px;
}
.Popover-Item-LongHaul-Input .Input-Icon {
  max-width: 16px;
  margin-right: 5px;
}
.Popover-Item-LongHaul-Note-Tootip {
  position: absolute;
  left: 100%;
  min-width: 240px;
  max-width: 320px;
  padding: 10px;
  border-radius: 4px;
  margin-left: 30px;
}
.Popover-Item-LongHaul-Note-Tootip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid var(--color-red);
  left: -14px;
  top: 50%;
  margin-top: -10px;
}
.Input .Popover-Item-LongHaul-Note-Error .Icon {
  cursor: default;
  color: var(--color-red);
}
.Popover-Item-LongHaul-Note-Error:hover + .Popover-Item-LongHaul-Note-Tootip,
.Popover-Item-LongHaul-Note-Error:hover ~ .Popover-Item-LongHaul-Note-Tootip {
  display: block;
}
.Popover-Item-LongHaul-Line {
  height: 1px;
  background-color: var(--color-white);
  margin-top: 1em;
}
.Popover-Item-LongHaul-Group {
  background-color: var(--color-white);
  font-size: var(--font-size);
}
.Popover-Item-LongHaul-Group .Popover-Item-LongHaul-List {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
}
.Popover-Item-LongHaul-Group .Popover-Item-LongHaul-List-Selected > div {
  color: var(--color-white);
}
.Popover-Item-LongHaul-Group .Popover-Item-LongHaul-List > div:first-child {
  flex: 1;
}
.Popover-Item-LongHaul-Group .Popover-Item-LongHaul-List > div:last-child {
  min-width: 100px;
  text-align: right;
}
.Popover-Item-LongHaul-List-None .Popover-Item-LongHaul-Choose-Title {
  padding-left: 20px;
}
.Popover-Item-LongHaul-List-None:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
}
/* Overwrite the default to keep the scrollbar always visible */
.Popover-Item-LongHaul-Choose::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px !important;
}
.Popover-Item-LongHaul-Choose::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-dark-green);
}
.Popover-Item-LongHaul-Choose::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #eee !important;
}
.Long-Haul-Legends {
  position: fixed;
  bottom: 127px;
  right: 20px;
  z-index: 11;
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  padding: 10px;
  font-size: 15px;
}
.Long-Haul-Legends .Long-Haul-Legends-List:last-child {
  margin-bottom: 0;
}
.Long-Haul-Legends .Long-Haul-Legends-Pin {
  position: relative;
  min-width: 28px;
}
.Long-Haul-Legends .Long-Haul-Legends-Pin span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 26px;
  color: var(--color-white);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.Long-Haul-Legends .Long-Haul-Legends-Pin.To span {
  color: #444;
}
.Pricing.block .block-sub-item {
  padding-left: 20px !important;
  align-items: flex-end;
}

/*
 * Long HaulPice
 */

.Pricing-LongHaul-Hide {
  display: none !important;
}
.Pricing-LongHaul-Show {
  display: block !important;
}
.Pricing-LongHaul {
  padding: 10px 0;
}
.Pricing-LongHaul.New-Pricing-LongHaul {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
}
.DetailBooking-FormGroup .Pricing-LongHaul.New-Pricing-LongHaul {
  padding-top: 0;
}
.Pricing.block .Pricing-LongHaul .block-item.Pricing-LongHaul-Group {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 7px !important;
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub {
  position: relative;
  z-index: 1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-12 {
  z-index: 12 !important;
}

.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub > div {
  min-height: 28px;
  align-items: flex-end;
  display: flex;
  /* margin-bottom: 10px; */
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub > div:before {
  bottom: 3px;
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub > div > span {
  display: flex;
  align-items: flex-end;
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub > div > span img {
  margin-right: 5px;
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul b {
  font-weight: bold;
}
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul .block-item-sub b,
.Pricing.block .Pricing-LongHaul.New-Pricing-LongHaul b {
  padding-right: 5px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List {
  padding-bottom: 10px;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:first-child {
  margin-left: 12px;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List:last-child {
  padding-bottom: 10px;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List:first-child {
  padding-top: 10px;
}
.prefix-dot-column {
  content: "";
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMnB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMi4wMDAwMDAsIC02ODcuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOC4wNTUzODMsIDIxLjc1MDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTI4LjA1NTM4MywgLTIxLjc1MDAwMCkgIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-repeat: repeat-y;
  background-size: 2px;
  left: 0;
  width: 3px;
  bottom: 0px;
  top: 0;
}
.Pricing-LongHaul.New-Pricing-LongHaul
  > div:last-child
  .Pricing-LongHaul-Group
  .Pricing-LongHaul-List:last-child
  .prefix-dot-column {
  height: 50%;
}
.prefix-dot-row {
  content: "";
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  /* background-image: url(https://s3-ap-southeast-1.amazonaws.com/deliveree-img/misc/line-ngang.svg); */
  background-repeat: repeat-x;
  background-size: 5px;
  left: 0;
  bottom: 0px;
  top: 50%;
  height: 2px;
  width: 12px;
  transform: translateY(-50%);
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:last-child {
  display: flex;
  align-items: center;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:last-child img {
  margin-right: 7px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:first-child {
  width: 24px;
  margin-right: 6px;
  z-index: 1;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:first-child {
  background-color: white;
  padding: 1px 0 2px 0;
}
.Pricing-LongHaul.New-Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List:first-child > div:first-child {
  padding: 0;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:nth-of-type(2) {
  flex: 1;
  padding-right: 10px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List > div:nth-of-type(2) span {
  max-width: 75%;
  display: block;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List span {
  color: var(--color-gray-xxd);
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-List div:last-child span {
  color: #949494;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-From button {
  background-color: #ffffff;
  border: solid 1px #949494;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0;
  border-radius: 3px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-From button i {
  color: #949494;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-From button i.Collapse-Icon {
  line-height: 20px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-From button i.Rotate {
  line-height: 18px;
  transform: rotate(180deg);
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-From div:last-child span,
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-To div:last-child span {
  color: var(--color-gray-xxd);
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-To.Collapse {
  padding-top: 21px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-To.Collapse:before {
  content: "";
  border: dashed 1px #d8d8d8;
  position: absolute;
  top: 2px;
  left: 11px;
  bottom: 26px;
  z-index: 0;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-Dropoff {
  padding: 21px 0 0 0;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-Dropoff:last-child {
  padding-bottom: 17px;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-Dropoff:before {
  content: "";
  position: absolute;
  background-color: #d8d8d8;
  width: 1px;
  top: 0;
  left: 11px;
  bottom: 0;
  z-index: 0;
}
.Pricing-LongHaul .Pricing-LongHaul-Group .Pricing-LongHaul-Dropoff > div:first-child div {
  width: 7px;
  height: 7px;
  background-color: #d8d8d8;
  border: solid 2px #ffffff;
  border-radius: 50%;
  margin-left: 6px;
}
#root .PricingNote-LongHaul.block {
  padding: 0;
  border-radius: 5px;
  background-color: #efefef !important;
  margin: 20px 10px 10px;
}
#root .Popup .PricingNote-LongHaul.block {
  margin: 20px 0 0 0;
}
#root .PricingNote-LongHaul.block div {
  color: #444;
  border-bottom: solid 1px #d8d8d8;
  padding: 6px 15px;
  display: flex;
  align-items: center;
}
#root .PricingNote-LongHaul.block div:last-child {
  border-bottom: 0 none;
}
#root .PricingNote-LongHaul.block div span {
  margin-left: 13px;
}

/*
 * dots-animation
 */

@keyframes blink {
  0% {
    opacity: 0.4;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.dots-animation {
  max-width: 25px;
  float: right;
}
.dots-animation span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  position: relative;
  max-width: 5px;
  max-height: 5px;
  overflow: hidden;
  margin-right: 5px;
  background-color: #d8d8d8;
}
.dots-animation span:last-child {
  margin-right: 0;
}
.dots-animation span:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
}
.dots-animation span:nth-child(2) {
  animation-delay: 0.2s;
}
.dots-animation span:nth-child(3) {
  animation-delay: 0.4s;
}

/*
 * End Long HaulPice
 */

/* Long Haul */
.Box-Icon img.Long-Haul-Icon {
  height: 30px;
}
.Long-Haul {
  padding: 0 10px 5px;
  margin: 0;
  list-style: none;
}
.Long-Haul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.Long-Haul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0 none;
}
.Long-Haul li > div {
  display: flex;
  align-items: center;
}
.Long-Haul .Overlay-RadioBox label {
  font-size: var(--font-size);
}
.Normal-Booking p.Long-Haul-Text {
  margin: 15px 0 5px 0;
  text-align: center;
  line-height: 18px;
}
.Normal-Booking p.Long-Haul-Sub-Text {
  margin: 5px 0 20px 0;
  text-align: center;
  line-height: 18px;
}
.Long-Haul-Bottom {
  margin: 15px -15px -15px -15px;
  border-radius: 0 0 6px 6px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Long-Haul-Bottom i.Icon.b {
  font-size: 22px;
}
.Long-Haul-Bottom p {
  margin: 0 0 0 10px;
  line-height: 18px;
}
.Long-Haul-Actions {
  margin: 0 -10px;
  width: auto;
}
.Input-Long-Haul.Child {
  margin-left: 5px;
}
.Input-Long-Haul span {
  height: 22px;
  display: inline-block;
  line-height: 22px;
  font-size: 10px;
  border-radius: 2px;
  min-width: 28px;
  text-align: center;
  cursor: pointer;
}
.Input-Long-Haul .Blue-bg {
  color: var(--color-white);
}
.PickupTime.block .PickupTime-DatePicker-Introduce {
  position: absolute;
  z-index: 12;
  left: 420px;
  max-width: 320px;
  min-width: 320px;
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 12px 15px;
  border-radius: var(--radius);
  margin-left: 16px;
  box-sizing: border-box;
  display: none;
}
.PickupTime.block .PickupTime-DatePicker-Introduce.visible {
  display: block !important;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Image {
  padding: 0;
  margin-bottom: 5px;
  position: relative;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Image i {
  margin-right: 10px;
  font-size: 34px;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Image img {
  margin-right: 10px;
  max-width: 28px !important;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Image b {
  font-size: 15px;
  font-weight: bold;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Explanation {
  color: var(--color-white);
  font-size: 13px;
  line-height: 18px;
  height: auto;
  padding: 0;
  text-align: left;
  position: relative;
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Explanation a {
  outline: 0;
  color: var(--color-yellow);
}
.PickupTime.block .PickupTime-DatePicker-Introduce .PickupTime-Explanation a:hover {
  color: var(--color-yellow);
  text-decoration: underline;
}

/*
 * Custom Scroll
 */

.Custom-Scroll-Group {
  overflow-y: auto;
}
.Custom-Scroll-Group::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -webkit-appearance: none;
}
.Custom-Scroll-Group::-webkit-scrollbar-track {
  background-color: #d7edd7;
}
.Custom-Scroll-Group::-webkit-scrollbar-thumb {
  background-color: #0e730f;
  border-radius: 100px;
}
.Custom-Scroll-Group.Default-Scroll-Services::-webkit-scrollbar-thumb,
.Custom-Scroll-Group.Default-Scroll-Services::-webkit-scrollbar-track {
  border-radius: 0 0 0 0;
}

/*
 * Map Toggle
 */

.Batch-Custom-Group > div {
  display: flex;
}
.Batch-Custom-Left {
  float: none;
}
.Batch-Custom-Left ~ div:not(.Locating-Custom) {
  flex: 1;
}
.Batch-Toggle {
  clear: both;
}
.Batch-Toggle {
  display: none;
}
.Batch-Toggle.Show {
  display: block;
}
.Batch-Toggle.Batch-Table > table {
  border-radius: 5px 0 0 0;
  border: 0 none;
  overflow: hidden;
}
.Batch-Map.Show,
.Batch-Chat.Show {
  display: flex;
}
.Batch-Map-Right {
  flex: 1;
  margin-left: 19px;
  height: calc(100vh - 270px);
  position: relative;
}
.fakeBg {
  background-color: var(--color-gray-xl);
  height: 100%;
}
.Batch-Map-Left,
.Batch-Chat-Left {
  width: 238px;
  position: relative;
}
.Batch-Map-Left .Batch-Left-Top,
.Batch-Chat-Left .Batch-Left-Top {
  display: flex;
  align-items: center;
}
.Batch-Map-Left .Batch-Left-Top .Batch-Left-Top-Title,
.Batch-Chat-Left .Batch-Left-Top .Batch-Left-Top-Title {
  flex: 1;
}
.Batch-Map-Left .Batch-Left-Top h3,
.Batch-Chat-Left .Batch-Left-Top h3,
.Batch-Chat-Right .Batch-Right-Top h3 {
  font-size: 15px;
  font-weight: bold;
}
.Batch-Map-Left .Batch-Left-Top .Batch-Left-Top-Summary span,
.Batch-Chat-Left .Batch-Left-Top .Batch-Left-Top-Summary span {
  font-size: 15px;
  color: var(--color-green);
}
.Batch-Map-Left .Batch-Left-Bottom,
.Batch-Chat-Left .Batch-Left-Bottom,
.Batch-Map-Left .Empty-List {
  margin-top: 14px;
  position: relative;
}
.Batch-Chat-Left .Batch-Left-Bottom .Batch-Left-Bottom-Search,
.Batch-Map-Left .Batch-Left-Bottom .Batch-Left-Bottom-Search {
  padding: 0 0 10px 0;
}
.Batch-Map-Left .Empty-List {
  padding: 0 15px;
  height: calc(100vh - 342px);
  min-height: auto;
}
.Batch-Map-Left .Batch-Left-Toastr,
.Batch-Chat-Left .Batch-Left-Toastr {
  position: absolute;
  left: 100%;
  width: 100%;
  z-index: 3;
  background-color: var(--color-green);
  border-radius: 6px;
  text-align: left;
  color: var(--color-white);
  font-size: 13px;
  padding: 0 20px;
  margin-left: 15px;
  box-shadow: 0px 0px 5px #999;
  display: none !important;
}
.Batch-Map-Left .Batch-Left-Toastr.Show,
.Batch-Chat-Left .Batch-Left-Toastr.Show {
  display: block !important;
}
.Batch-Map-Left .Batch-Left-Toastr:before,
.Batch-Chat-Left .Batch-Left-Toastr:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid var(--color-green);
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}
.Batch-Map-Left .Batch-Left-Toastr.error,
.Batch-Chat-Left .Batch-Left-Toastr.error {
  background-color: var(--color-red);
  top: -12px;
}
.Batch-Map-Left .Batch-Left-Toastr.error:before,
.Batch-Chat-Left .Batch-Left-Toastr.error:before {
  border-right-color: var(--color-red);
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group {
  border: 1px solid #ededed;
  padding: 0;
  margin: 0;
  list-style: none;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group {
  max-height: calc(100vh - 316px);
}
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group {
  max-height: calc(100vh - 310px);
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li {
  border-bottom: 1px solid #ededed;
  border-left: 5px solid #d8d8d8;
  padding: 7px 10px 7px 13px;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li:last-child,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li:last-child {
  border-bottom: 0 none;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info {
  display: flex;
  align-items: center;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info h5 {
  margin: 0 22px 3px 0;
  flex: 1;
  color: #444;
  font-size: 15px;
  font-weight: normal;
}
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info {
  min-height: 40px;
}
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info.Custom {
  margin-bottom: 10px;
}
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Info-Custom p {
  font-size: 13px;
  margin: 5px 22px 5px 0;
  margin-right: -40px;
  color: #444444;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li > div.Overlay-Checkbox {
  position: absolute;
  top: 7px;
  right: 10px;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Overlay-Checkbox {
  height: 20px;
  width: 20px;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-Label,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-Label {
  color: #7c7c7c;
  font-size: 13px;
  line-height: 18px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 3px;
  border: 1px solid #7c7c7c;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Pin {
  margin: 5px 0;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Pin table td {
  vertical-align: top;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Pin table span {
  color: #444444;
  font-size: 13px;
  line-height: 16px;
  display: block;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Selected .Custom-Scroll-List-Pin table span {
  color: inherit;
}
.Batch-Left-Bottom-Search {
  margin-top: 4px;
}

/*
 * Custom-Scroll-List-Pin-Custom-Tooltip
 */

.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Pin-Custom {
  margin-right: -17px;
}
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li .Custom-Scroll-List-Pin-Custom {
  margin-right: -40px;
}
.Custom-Scroll-List-Pin-Custom-Tooltip {
  margin-right: 10px;
}
.Custom-Scroll-List-Pin-Custom-Tooltip i,
.Custom-Scroll-List-Pin-Custom-Tooltip > img {
  color: #7c7c7c;
  width: 24px;
  max-width: 24px;
  font-size: 27px !important;
}
.Custom-Scroll-List-Pin-Custom-Tooltip div {
  position: absolute;
  left: 125%;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-size-s);
  white-space: nowrap;
  z-index: 22;
  padding: 2px 5px;
  border-radius: 2px;
  display: none;
}
.Custom-Scroll-List-Pin-Custom-Tooltip div:before {
  content: "";
  position: absolute;
  left: -4px;
  top: 50%;
  margin-top: -4px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid var(--color-black);
}
.Custom-Scroll-List-Pin-Custom-Tooltip i:hover + div,
.Custom-Scroll-List-Pin-Custom-Tooltip i:hover ~ div,
.Custom-Scroll-List-Pin-Custom-Tooltip img:hover + div,
.Custom-Scroll-List-Pin-Custom-Tooltip img:hover ~ div {
  display: block;
}
.Custom-Scroll-List-Pin-Custom-Tooltip .Batch-Merge-Chat-Unread {
  top: 10px;
  right: -5px;
}
.Batch-Icon-Wrapper {
  display: flex;
  flex: 1;
  justify-content: end;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Active,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Active {
  background-color: #d7edd7;
}
.Batch-Chat-Left .flex > div:not(.Custom-Scroll-Avatar) {
  flex: 1;
}
.Batch-Chat-Left .flex .Custom-Scroll-Avatar {
  width: 40px;
  max-height: 40px;
}
.Batch-Chat-Left .Custom-Scroll-Avatar img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 2px;
  display: block;
}
.Batch-Chat-Left .Custom-Scroll-Avatar a img {
  min-width: 40px;
}
.Batch-Chat-Left .flex .Custom-Scroll-Avatar .Custom-Scroll-Chat-Icon {
  position: absolute;
  bottom: -8px;
  right: 0;
  height: 16px;
}
.Batch-Chat-Center {
  flex: 1;
  margin: 0 19px;
  background-color: #f2f2f2;
  border: solid 1px #ededed;
  padding: 15px;
  position: relative;
}
.Batch-Chat-Center,
.Batch-Chat-Empty-Box {
  border-radius: 5px;
}
.Batch-Chat-Empty-Box {
  max-width: 100px;
  padding: 0 50px;
  border: solid 1px #cacaca;
}
.Batch-Chat-Center.Empty-List {
  margin-right: 0;
}
.Batch-Chat-Center .Batch-Chat-Center-Group.Hide {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.Batch-Chat-Center .Closure-List-Tab-List.vertical-scroll::-webkit-scrollbar-thumb {
  background-color: #b5b5b4;
  border-radius: 9px;
}
.Batch-Chat-Center .Closure-List-Tab-List {
  top: 15px;
  left: 7px;
  bottom: 52px;
  right: 2px;
  padding: 0 10px;
}
.Batch-Chat-Center .Closure-List-Tab-List ul {
  overflow: hidden;
}
.Batch-Chat-Center .Closure-List-Tab-List li {
  float: left;
  width: 100%;
  overflow: inherit;
}
.Batch-Chat-Center .Closure-List-Tab-List li > div {
  box-shadow: 0px 2px 2px #ddd;
}
.Batch-Chat-Center .client-image {
  width: 24px;
  position: absolute;
  top: 0;
  left: -38px;
}
.Batch-Chat-Center .client-image img {
  max-width: 100%;
  border-radius: 2px;
}
.Batch-Chat-Center .Closure-List-Tab-List li > div span {
  font-size: 13px;
}
.Batch-Chat-Center .Closure-List-Tab-Bottom {
  bottom: 15px;
  right: 15px;
  left: 15px;
}
.Batch-Chat-Center .Closure-List-Tab-Bottom textarea {
  background: white;
  border: 1px solid #ededed;
  box-shadow: 0px 2px 2px #ddd;
}
.Batch-Chat-Center .Empty-Box > span {
  font-size: 18px;
  line-height: 25px;
}
.Batch-Chat-Right {
  width: 400px;
  position: relative;
}
.Batch-Chat-Right .Custom-Scroll-Group {
  max-height: calc(100vh - 350px);
}
.Batch-Chat-Right .DetailBooking-FormGroup {
  margin: 15px 0 0 0;
  padding: 10px 0;
}
.Batch-Chat-Right .DetailBooking-FormGroup table {
  border-spacing: 0px;
  border: none;
}
.Batch-Chat-Right .DetailBooking-FormGroup table td.DetailBooking__transit-time_td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.Batch-Chat-Right .DetailBooking__transit-time {
  padding: 0px !important;
  border-radius: 0px;
}
.Batch-Chat-Right .DetailBooking__transit-time__Row {
  padding-left: 20px;
  padding-right: 10px;
}
.Batch-Chat-Right .DetailBooking__transit-time__Row:first-child {
  padding-left: 10px;
}
.Batch-Chat-Right .DetailBooking__transit-time__Row .Left {
  width: 48%;
}
.Batch-Chat-Right .DetailBooking__transit-time__Row .Right {
  width: 52%;
}
.Batch-Chat-Right .DetailBooking-FormGroup table td:first-child {
  padding-left: 10px;
}
.Batch-Chat-Right .DetailBooking-FormGroup table td:last-child {
  padding-right: 10px;
}
.Batch-Chat-Right .Batch-Chat-Right-Line {
  height: 1px;
  background-color: #d6d6d6;
  margin: 10px 10px;
}
.Batch-Chat-Right .Batch-Right-Top .Batch-Right-Top-Title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 30px;
  margin-top: 2px;
}
.Batch-Chat-Right .Batch-Right-Top .Batch-Right-Top-Title h3 {
  font-weight: normal;
}
.Batch-Chat-Right .Batch-Right-Top .Batch-Right-Top-Title div {
  text-align: right;
  margin-left: 10px;
  font-size: 15px;
}
.Batch-Chat-Right .MultipleBlock .block-item {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Title {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Title label {
  flex: 1;
  font-size: 15px;
  color: #444444;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Title span {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--color-gray-xd);
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Title i {
  font-size: 14px;
  line-height: 15px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Title i.white {
  color: var(--color-white);
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group {
  display: flex;
  align-items: center;
  position: relative;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group:before {
  content: "";
  position: absolute;
  top: -5px;
  height: 50%;
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: var(--color-gray-xd);
  left: 10px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group:first-child:before {
  display: none;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group > div,
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div {
  flex: 1;
  display: flex;
  align-items: center;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group > div label,
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child p {
  font-size: var(--font-size-s);
  color: #444444;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group > div:first-child,
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child {
  max-width: 30px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group > div:first-child i {
  text-indent: -1px;
  color: #7c7c7c;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Date {
  margin-bottom: 5px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Bg {
  position: absolute;
  border: 1px solid #d6d6d6;
  top: 0;
  right: 0;
  left: 30px;
  bottom: 0;
  max-width: inherit !important;
  border-radius: 6px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group {
  width: 100%;
  position: relative;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: var(--color-gray-xd);
  left: 10px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:nth-of-type(2):before {
  top: 50%;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:last-child:before {
  bottom: 50%;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group .Pin {
  max-width: 20px;
  max-height: 20px;
  font-size: 9px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child {
  position: relative;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child:before,
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child:before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid #d6d6d6;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:first-child:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid var(--color-white);
  right: -1px;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child {
  width: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child p {
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid #d6d6d6;
  flex: 1;
  font-size: var(--font-size-s);
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group:last-child > div:last-child p {
  border-bottom: 0 none;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-size: 13px;
  padding: 5px 0;
}
.Batch-Chat-Right .MultipleBlock .block-item .MultipleBlock-Group-Location-Group > div:last-child div img {
  max-width: 40px;
  display: block;
  margin: auto;
}

/*
 * Random Color
 */

/*.Color-Map-1*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-1,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-1 {
  border-left: 5px solid #ff877f;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-1.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-1.Selected .Custom-Scroll-List-Info h5 {
  color: #ff877f;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-1
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #ff877f;
  border-color: #ff877f;
}

/*.Color-Map-2*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-2,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-2 {
  border-left: 5px solid #7680b2;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-2.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-2.Selected .Custom-Scroll-List-Info h5 {
  color: #7680b2;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-2
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #7680b2;
  border-color: #7680b2;
}

/*.Color-Map-3*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-3,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-3 {
  border-left: 5px solid #b700ff;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-3.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-3.Selected .Custom-Scroll-List-Info h5 {
  color: #b700ff;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-3
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #b700ff;
  border-color: #b700ff;
}

/*.Color-Map-4*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-4,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-4 {
  border-left: 5px solid #a73592;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-4.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-4.Selected .Custom-Scroll-List-Info h5 {
  color: #a73592;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-4
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #a73592;
  border-color: #a73592;
}

/*.Color-Map-5*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-5 {
  border-left: 5px solid #ae8529;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-5.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-5.Selected .Custom-Scroll-List-Info h5 {
  color: #ae8529;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-5
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #ae8529;
  border-color: #ae8529;
}

/*.Color-Map-6*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-6,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-6 {
  border-left: 5px solid #24a9a2;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-6.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-6.Selected .Custom-Scroll-List-Info h5 {
  color: #24a9a2;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-6
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #24a9a2;
  border-color: #24a9a2;
}

/*.Color-Map-7*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-7,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-7 {
  border-left: 5px solid #ff5ca2;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-7.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-7.Selected .Custom-Scroll-List-Info h5 {
  color: #ff5ca2;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-7
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #ff5ca2;
  border-color: #ff5ca2;
}

/*.Color-Map-8*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-8,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-8 {
  border-left: 5px solid #f39016;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-8.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-8.Selected .Custom-Scroll-List-Info h5 {
  color: #f39016;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-8
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #f39016;
  border-color: #f39016;
}

/*.Color-Map-9*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-9,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-9 {
  border-left: 5px solid #899c6c;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-9.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-9.Selected .Custom-Scroll-List-Info h5 {
  color: #899c6c;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-9
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #899c6c;
  border-color: #899c6c;
}

/*.Color-Map-10*/
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-10,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-10 {
  border-left: 5px solid #0268bf;
}
.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Map-10.Selected .Custom-Scroll-List-Info h5,
.Batch-Chat-Left .Batch-Left-Bottom .Custom-Scroll-Group li.Color-Chat-10.Selected .Custom-Scroll-List-Info h5 {
  color: #0268bf;
}
.Batch-Map-Left
  .Batch-Left-Bottom
  .Custom-Scroll-Group
  li.Color-Map-10
  .Overlay-Checkbox.Green-Checkbox
  input[type="checkbox"]:checked
  + label:before {
  background-color: #0268bf;
  border-color: #0268bf;
}

/*
 * Toggle-Choose
 */

.line-border-bottom {
  border-bottom: solid 1px transparent;
}
.line-border-bottom.gray {
  border-color: #ddd;
}
.Toggle-Choose {
  margin: 20px auto;
}
.Toggle-Choose .Toggle-Choose-Box {
  border: 1px solid var(--color-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.Toggle-Choose.Custom .Toggle-Choose-Box {
  border-color: var(--color-yellow);
}
.Toggle-Choose h5 {
  font-size: 15px;
  text-align: center;
  min-width: 100px;
  color: #bdbdbd;
  background-color: #eae9e9;
  margin: 0;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: normal;
}
.Toggle-Choose h5.active {
  background-color: var(--color-green);
  color: var(--color-white);
}
.Toggle-Choose.Custom h5.active {
  background-color: var(--color-yellow);
  color: #373a3c;
}

/*
 * Customize
 */

.Normal-Booking-Image:before {
  border-radius: 0 0 var(--radius) var(--radius);
}
.MultipleBookingLayout .BookingWizard-Actions {
  margin-right: 10px;
}
.m-w10 {
  min-width: 10px;
}
.MultipleBookingLayout .MultipleBookingLayout-Space {
  min-width: 80px;
}
.MultipleBookingLayout.Custom-Right .MultipleBookingLayout-Space {
  min-width: 10px;
}
.MultipleBookingLayout .BookingWizard .MultipleBlock .block-item .MultipleBlock-Group-Location.Custom {
  margin-bottom: 5px;
}
.Step-AutoComplete-Multiple .pac-container,
.Step-AutoComplete-Modal .pac-container,
.Step-AutoComplete-Pickup .pac-container {
  margin-top: 1px;
  box-shadow: none;
  border: 0 none;
}
.Step-AutoComplete-Multiple .pac-container .pac-item,
.Step-AutoComplete-Modal .pac-container .pac-item,
.Step-AutoComplete-Pickup .pac-container .pac-item {
  border: 0 none;
}
.Step-AutoComplete-Multiple .pac-container {
  width: 335px !important;
  margin-left: -45px;
}
.Step-AutoComplete-Multiple.Custom-Margin .pac-container {
  margin-left: -81px;
}
.Step-AutoComplete-Modal .pac-container {
  width: 471px !important;
  margin-left: -57px;
}
.Step-AutoComplete-Pickup .pac-container {
  width: 372.5px !important;
  margin-left: -45.5px;
}
.Step-AutoComplete-Modal.Custom-Margin .pac-container {
  margin-left: -91px;
  width: 471px !important;
}
.BookingWizard .Popover.step-2:not(.Popover-Item-Drivers) {
  margin-left: 10px;
}
.BookingWizard .Popover.step-2 h3 {
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
}
.Popover-List-Scroll {
  margin: 0 -8px;
  padding: 0 8px;
}
.Overlay-Checkbox.Gray-Checkbox label:before {
  border-color: #949494;
}
.Overlay-Checkbox.Gray-Checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--color-gray-xd);
  border-color: var(--color-gray-xd);
}
.BatchUploadAssign .BatchUploadAssign-Box {
  flex-direction: column;
  position: relative;
}
.Empty-List.Light-Gray-bg .Empty-Box span {
  font-size: 18px;
}
.input-hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.Batch-Map-Left .Empty-List,
.Empty-List.Light-Gray-bg .Empty-List,
.LocationContacts .block-sub-item .Input,
.Popover-Item.actions .Button,
.LocationContacts .block-sub-item.block-sub-item-button button,
.Attachments.block .block-item .Dropzone {
  border-radius: 5px;
}
.Empty-List.Light-Gray-bg .Empty-Box span {
  font-size: 17px;
  margin-bottom: 0;
}
.ImageViewer-Delete,
.MultipleBookingLayout .Services.block .block-item .Services-PopupEye {
  z-index: 1;
}
.Status-Custom-Line {
  margin: 3px -5px 5px -5px;
}
.Status-Custom-Line label {
  width: 48px;
  height: 18px;
  display: block;
  color: var(--color-white);
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
}
.Box-List-Item.title:hover {
  background: var(--color-gray-xxl) !important;
}
.Modal-Error .Modal-Error-Icon.Red i {
  color: var(--color-red);
}
.Popover-contact-list-item:hover,
.RightMenu .Box-List-Item:hover,
.Box-List-Item-Active {
  background: #d0d0d0;
}
.PickupTime.block .block-item {
  background-color: var(--color-white);
  overflow: hidden;
}
input::placeholder,
textarea::placeholder {
  font-style: italic;
  color: var(--color-gray-xl) !important;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-style: italic;
  color: var(--color-gray-xl) !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  font-style: italic;
  color: var(--color-gray-xl) !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  font-style: italic;
  color: var(--color-gray-xl) !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-style: italic;
  color: var(--color-gray-xl) !important;
}
.Input-Disabled input::placeholder,
.Input-Disabled textarea::placeholder {
  font-style: italic;
  color: var(--color-gray-l) !important;
}
.Input-Disabled input::-webkit-input-placeholder,
.Input-Disabled textarea::-webkit-input-placeholder {
  font-style: italic;
  color: var(--color-gray-l) !important;
}
.Input-Disabled input:-moz-placeholder,
.Input-Disabled textarea:-moz-placeholder {
  font-style: italic;
  color: var(--color-gray-l) !important;
}
.Input-Disabled input::-moz-placeholder,
.Input-Disabled textarea::-moz-placeholder {
  font-style: italic;
  color: var(--color-gray-l) !important;
}
.Input-Disabled input:-ms-input-placeholder,
.Input-Disabled textarea:-ms-input-placeholder {
  font-style: italic;
  color: var(--color-gray-l) !important;
}
.BookingWizard .Content .block.Locations-Customs {
  padding: 10px 5px;
}
.BookingWizard .Content .block.Locations-Customs i.red {
  color: var(--color-red);
}
.BookingWizard .Content .BoxCustom-Close i:hover {
  color: var(--color-white);
}

/*
 * Popover-Insurance
 */

.Popover-Insurance {
}
.Popover-Insurance .Popover-Insurance-Title {
  text-align: center;
  color: white;
  margin-bottom: 15px;
}
.Popover-Insurance .Popover-Insurance-Policy {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-white);
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Policy-Link a {
  color: var(--color-yellow);
  text-decoration: underline;
}
.Popover-Insurance.Popover-Insurance-Custom .Popover-Insurance-Policy-Link a {
  color: var(--color-green);
}
.Popover-Insurance .Popover-Insurance-Policy-Link a:hover,
.Popover-Insurance .Popover-Insurance-Policy-Link a:focus,
.Popover-Insurance .Popover-Insurance-Policy-Link a:visited,
.Popover-Insurance .Popover-Insurance-Policy-Link a:link {
  color: var(--color-yellow);
}
.Popover-Insurance.Popover-Insurance-Custom .Popover-Insurance-Policy-Link a:hover,
.Popover-Insurance.Popover-Insurance-Custom .Popover-Insurance-Policy-Link a:focus,
.Popover-Insurance.Popover-Insurance-Custom .Popover-Insurance-Policy-Link a:visited,
.Popover-Insurance.Popover-Insurance-Custom .Popover-Insurance-Policy-Link a:link {
  color: var(--color-dark-green);
}
.Popover-Insurance .Popover-Insurance-Action {
  display: flex;
  margin: 0 -5px;
}
.Popover-Insurance .Popover-Insurance-Action button {
  flex: 1;
  margin: 0 5px;
}
.Popover-Insurance .Popover-Insurance-Action button.green {
  background-color: var(--color-dark-green);
}
.Popover-Insurance .Popover-Insurance-Action button.green:hover {
  background-color: #40811e;
}
.Popover-Insurance .Popover-Insurance-Group-Header {
  display: flex;
  padding: 5px 0 10px 0;
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Group-Header div {
  color: var(--color-white);
  text-align: left;
}
.Popover-Insurance .Popover-Insurance-Group {
  max-height: 225px;
  overflow-y: auto;
  background-color: var(--color-white);
  border-radius: 4px;
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Box {
  display: flex;
  align-items: center;
  padding: 10px;
}
.Popover-Insurance .Popover-Insurance-Box > div {
  color: var(--color-gray-xd);
}
.Popover-Insurance .Popover-Insurance-Box-Selected > div {
  color: var(--color-white);
}
.Popover-Insurance .Popover-Insurance-Box > div:first-child,
.Popover-Insurance .Popover-Insurance-Group-Header div:first-child {
  flex: 1;
}
.Popover-Insurance .Popover-Insurance-Box > div:last-child,
.Popover-Insurance .Popover-Insurance-Group-Header div:last-child {
  min-width: 100px;
  text-align: right;
}
/* Overwrite the default to keep the scrollbar always visible */
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px !important;
}
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-dark-green);
}
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #eee !important;
}
.SingleBookingLayout .booking_insurance_popup:before,
.SingleBookingLayout #booking_insurance_1:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 420px;
}
.Status-Custom-Line.Confirmed label {
  width: auto;
  padding: 0 6px;
  min-width: 44px;
  display: inline-block;
}

/*
 * Popover-Insurance
 */

.Popover-Insurance {
}
.Popover-Insurance .Popover-Insurance-Title {
  text-align: center;
  color: white;
  margin-bottom: 15px;
}
.Popover-Insurance .Popover-Insurance-Policy {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-white);
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Policy-Link a {
  color: var(--color-yellow);
  text-decoration: underline;
}
.Popover-Insurance .Popover-Insurance-Policy-Link a:hover,
.Popover-Insurance .Popover-Insurance-Policy-Link a:focus,
.Popover-Insurance .Popover-Insurance-Policy-Link a:visited,
.Popover-Insurance .Popover-Insurance-Policy-Link a:link {
  color: var(--color-yellow);
}
.Popover-Insurance .Popover-Insurance-Action {
  display: flex;
  margin: 0 -5px;
}
.Popover-Insurance .Popover-Insurance-Action button {
  flex: 1;
  margin: 0 5px;
}
.Popover-Insurance .Popover-Insurance-Action button.green {
  background-color: var(--color-dark-green);
}
.Popover-Insurance .Popover-Insurance-Action button.green:hover {
  background-color: #40811e;
}
.Popover-Insurance .Popover-Insurance-Group-Header {
  display: flex;
  padding: 5px 0 10px 0;
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Group-Header div {
  color: var(--color-white);
  text-align: left;
}
.Popover-Insurance .Popover-Insurance-Group {
  max-height: 225px;
  overflow-y: auto;
  background-color: var(--color-white);
  border-radius: 4px;
  font-size: var(--font-size);
}
.Popover-Insurance .Popover-Insurance-Box {
  display: flex;
  align-items: center;
  padding: 10px;
}
.Popover-Insurance .Popover-Insurance-Box > div {
  color: var(--color-gray-xd);
}
.Popover-Insurance .Popover-Insurance-Box-Selected > div {
  color: var(--color-white);
}
.Popover-Insurance .Popover-Insurance-Box > div:first-child,
.Popover-Insurance .Popover-Insurance-Group-Header div:first-child {
  flex: 1;
}
.Popover-Insurance .Popover-Insurance-Box > div:last-child,
.Popover-Insurance .Popover-Insurance-Group-Header div:last-child {
  min-width: 100px;
  text-align: right;
}
/* Overwrite the default to keep the scrollbar always visible */
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px !important;
}
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-dark-green);
}
.Popover-Insurance .Popover-Insurance-Group::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #eee !important;
}
.SingleBookingLayout .booking_insurance_popup:before,
.SingleBookingLayout #booking_insurance_1:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 420px;
}
.Status-Custom-Line label {
  width: auto;
  min-width: 44px;
  padding: 0 5px;
  height: 18px;
  display: inline-block;
  color: var(--color-white);
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  line-height: 19px;
  margin-left: 7px;
  text-transform: uppercase;
  font-weight: bold;
}

/*
 * Normal Hyperlink Custom
 */

#root .Normal-Hyperlink-Custom a:hover,
#root .Normal-Hyperlink-Custom a:focus {
  color: var(--color-yellow);
  text-decoration: underline;
}

#root a.hyperlink-default-color,
#root a.hyperlink-default-color:hover {
  color: var(--color-hyperlink-green);
  text-decoration: underline;
}

a.hyperlink-white-color,
#root a.hyperlink-white-color:hover {
  color: var(--color-hyperlink-white);
  text-decoration: underline;
}

.flash-button-parent {
  background-color: transparent !important;
  padding: 0 !important;
}
.flash-button {
  background: #ffdb00;
  padding: 0 10px 0 15px;
  border: none;
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: flash;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: flash;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/*
 * Batch Header Custom
 */

.BatchUploadValidate #Step-04 .Batch-Table,
.BatchUploadValidate #Step-05 .Batch-Table {
  position: relative;
}
.BatchUploadValidate #Step-04 .Batch-Table > .Table,
.BatchUploadValidate #Step-05 .Batch-Table > .Table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.BatchUploadValidate #Step-05 .Booking-BatchEZ .MultipleBookingLayout-Collapse-Icon {
  z-index: 24;
}
.BatchUploadValidate #Step-05 .Booking-BatchEZ .MultipleBookingLayout-Collapse {
  z-index: 23;
}

/*
 * Devina Image
 */

.Devina-Image {
  padding-right: 10px;
  padding-left: 10px;
}
.Devina-Image.Devina-Image-Max-Width {
  padding-left: 15px;
}
.Devina-Image-Max-Width {
  max-width: 120px;
}
.Devina-Image img {
  margin: auto;
}

/*
 * Custom Closure
 */

.Closure-List-Content
  .Closure-Fullday
  .Closure-List-Button
  a:not(.a-add-to_favorite):not(.a-add-to_ban):not(.button-closure-rate-driver-modal),
.Closure-List-Content .Closure-Fullday .Closure-List-Button button,
.Closure-List-Content
  .Closure-Scheduled
  .Closure-List-Button
  a:not(.a-add-to_favorite):not(.a-add-to_ban):not(.button-closure-rate-driver-modal),
.Closure-List-Content .Closure-Scheduled .Closure-List-Button button,
.Closure-List-Content
  .Closure-Longhaul
  .Closure-List-Button
  a:not(.a-add-to_favorite):not(.a-add-to_ban):not(.button-closure-rate-driver-modal),
.Closure-List-Content .Closure-Longhaul .Closure-List-Button button,
.Closure-List-Content
  .Closure-List-Button
  a:not(.a-add-to_favorite):not(.a-add-to_ban):not(.button-closure-rate-driver-modal),
.Closure-List-Content .Closure-List-Button button {
  background-color: transparent;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  box-sizing: border-box;
}
.Closure-List-Button a:hover,
.Closure-List-Button button:hover {
  background-color: var(--color-dark-green);
}
.Closure-List-Button {
  margin: 0 -2.5px 10px;
}
.Closure-List-Button .Half-Button {
  margin: 0 5px;
}
.Closure-List-Button .Full-Button,
.Closure-List-Button .Half-Button {
  word-break: break-word;
}
.Closure-List-Button .Half-Button button,
.Closure-List-Button .Full-Button button,
.Closure-List-Button.Closure-List-Dropdown .Half-Button a,
.Closure-List-Button.Closure-List-Dropdown .Full-Button a {
  padding: 0 5px;
  line-height: inherit;
  min-height: 34px;
}
.Closure-List-Button .Half-Button:first-child {
  margin-left: 0;
}
.Closure-List-Button .Half-Button:last-child {
  margin-right: 0;
}
.Closure-List-Button .Half-Button.relative {
  position: relative;
}
.Closure-List-Button span.quotes-count {
  background-color: #ffdb00;
  border-radius: 50%;
  color: black;
  font-size: 13px;
  font-family: var(--font-roboto-bold);
  height: 18px;
  margin-left: 5px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -1px;
}
.Closure-List-Dropdown {
  flex-direction: column;
  border: 1px solid var(--color-white);
  border-radius: 4px;
  padding: 5px;
  /* margin: 0 -2.5px 0; */
}
.Closure-List-Dropdown-Title h5 {
  padding-left: 5px;
}
.Closure-List-Dropdown-Title.Rotate i {
  transform: rotate(-180deg);
}
.Closure-List-Dropdown-Group {
  display: none;
  margin-top: 10px;
}
.Closure-List-Dropdown-Group.Show {
  display: block;
}
.Closure-List-Dropdown-Group > div {
  margin: 0 0 10px;
}
.Closure-List-Dropdown-Group > div:last-child {
  margin: 0 0 5px;
}
.Closure-List-Dropdown-Group > div .Full-Button {
  margin-left: 0;
}
.Closure-List-Cancel {
  position: absolute;
  top: -10px;
  right: 13px;
  transform: rotate(-90deg);
  transform-origin: right;
  border: 1px solid var(--color-white);
  border-radius: 4px 0 4px 0;
  padding: 1px 8px;
  height: 14px;
  font-size: 11px;
}
.Closure-List-Title {
  display: flex;
  align-items: center;
}
.Closure-List-TitleImage {
  margin-top: 0;
  min-width: 25px;
  margin-right: 10px;
}
.Closure-List-TitleInfo {
  display: inline-block;
  white-space: nowrap;
  padding-top: 4px;
  overflow: hidden;
}
.Closure-List-TitleInfo-Text {
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.Scroll-Effect {
  display: block;
  -moz-animation: marquee 10s linear infinite;
  -webkit-animation: marquee 10s linear infinite;
  animation: marquee 10s linear infinite;
}
@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.Closure-List-TitleSubInfo {
  font-size: 13px;
  margin-top: 5px;
}
.Closure-List-Tab-Bottom textarea {
  line-height: inherit;
  padding-top: 8px;
  padding-right: 64px;
}
.Closure-Chat-Upload {
  position: absolute;
  top: 0;
  right: 32px;
  width: 32px;
  bottom: 0;
  cursor: pointer;
}
.Closure-Chat-Upload input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.Closure-Chat-Upload input[type="file"] {
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.Closure-Chat-Upload input[type="file"]::-webkit-file-upload-button {
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.Closure-Chat-Upload .Icon {
  color: #949494;
  transform: rotate(30deg);
}
.Closure-List-Tab-Bottom i {
  top: 50%;
  margin-top: -16px;
}
.Closure-Fullday .Row {
  background-color: #7521d3;
}
.Closure-Ltl .Row {
  background-color: #e67e22;
}
.Closure-Ltl .Closure-List__Tabs > ul li.ui-state-active {
  background-color: #e67e22 !important;
}

.Closure-Ltl .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a:hover {
  background-color: #e67e22;
  color: var(--color-white) !important;
}

.Closure-Ltl .Closure-List-Button button:hover,
.Closure-Ltl .Closure-List-Button a:hover {
  background-color: #c98113;
}

.Closure-Fullday .Closure-List-Button button:hover,
.Closure-Fullday .Closure-List-Button a:hover {
  background-color: #4f3f95;
}
.Closure-Longhaul .Row {
  background-color: #726132;
}
.Closure-Longhaul .Closure-List-Button button:hover,
.Closure-Longhaul .Closure-List-Button a:hover {
  background-color: #4b3e19;
}
.Closure-Draft .Row {
  background-color: var(--color-gray);
}
.Closure-Draft .Closure-List-Button button:hover,
.Closure-Draft .Closure-List-Button a:hover {
  background-color: var(--color-gray-d);
}
.Closure-Fullday .Closure-List-Button.With-Icon a,
.Closure-Fullday .Closure-List-Button.With-Icon button,
.Closure-Longhaul .Closure-List-Button.With-Icon a,
.Closure-Longhaul .Closure-List-Button.With-Icon button,
.Closure-Draft .Closure-List-Button.With-Icon a,
.Closure-Draft .Closure-List-Button.With-Icon button {
  color: var(--color-gray-xxd);
}
.Closure-Fullday .NoDriverFound p {
  color: #4f3f95 !important;
}
.Closure-Fullday .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Fullday .Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: #4f3f95 !important;
}
.Closure-List-Devide {
  background-color: var(--color-white);
}
.NoDriverFound {
  border-color: var(--color-white);
}
.NoDriverFound.new-single {
  border-color: var(--color-black);
}
.NoDriverFound p,
.Closure-Longhaul .NoDriverFound p,
.Closure-Fullday .NoDriverFound p,
.Closure-Scheduled .NoDriverFound p,
.Closure-Draft .NoDriverFound p {
  color: var(--color-white) !important;
}
.NoDriverFound.new-single p {
  color: var(--color-black) !important;
}
.Closure-Longhaul .Closure-List-Tabs-Actions li.ui-state-active a,
.Closure-Longhaul .Closure-List.expand .Closure-List-Tabs-Actions li.ui-state-active a {
  background-color: #4b3e19 !important;
}
.Closure-Scheduled .Favorite-Icon-active b,
.Closure-Scheduled .Favorite-Icon-active i,
.Closure-Scheduled .Favorite-Icon-active {
  color: #00b9e8 !important;
}
.Closure-Scheduled .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a:hover {
  color: var(--color-white) !important;
}
.Closure-Fullday .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a,
.Closure-Fullday .Favorite-Icon-active b,
.Closure-Fullday .Favorite-Icon-active i,
.Closure-Fullday .Favorite-Icon-active {
  color: #7b5cd3 !important;
}
.Closure-Fullday .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a:hover {
  background-color: #7521d3;
  color: var(--color-white) !important;
}
.Closure-Longhaul .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a,
.Closure-Longhaul .Favorite-Icon-active b,
.Closure-Longhaul .Favorite-Icon-active i,
.Closure-Longhaul .Favorite-Icon-active {
  color: #726132 !important;
}
.Closure-Longhaul .Closure-List-Tabs .Closure-List-Button:not(.With-Icon) a:hover {
  background-color: #726132;
  color: var(--color-white) !important;
}
.Closure-Fullday .Closure-List-Button.With-Icon .Favorite-Icon-active:hover {
  color: #4f3f95 !important;
}
.Closure-Scheduled .Closure-List-Button.With-Icon .Favorite-Icon-active:hover {
  color: #00b9e8 !important;
}
.Closure-Longhaul .Closure-List-Button.With-Icon .Favorite-Icon-active:hover {
  color: #726132 !important;
}

/* New Chat UI support FPD v2 */
.Closure-List__Tabs {
  background-color: transparent;
  border: 0 none !important;
  padding: 0;
}
.Closure-List__Tabs > ul {
  background-color: transparent;
  border-top: 0 none !important;
  border-left: 0 none !important;
  border-right: 0 none !important;
  border-bottom-color: var(--color-white);
  border-radius: 0;
  padding: 0 15px !important;
}
.Closure-List__Tabs > ul li {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: calc(50% - 4px);
  background: transparent !important;
  border-radius: 6px 6px 0 0;
}
.Closure-List__Tabs > ul li.ui-state-active {
  border-top: 1px solid var(--color-white) !important;
  border-left: 1px solid var(--color-white) !important;
  border-right: 1px solid var(--color-white) !important;
}
.Closure-List__Tabs > ul li.ui-state-active {
  background-color: var(--color-green) !important;
}
.Closure-Draft .Closure-List__Tabs > ul li.ui-state-active {
  background-color: var(--color-gray) !important;
}
.Closure-Scheduled .Closure-List__Tabs > ul li.ui-state-active {
  background-color: #00b9e8 !important;
}
.Closure-Fullday .Closure-List__Tabs > ul li.ui-state-active {
  background-color: #7521d3 !important;
}
.Closure-Longhaul .Closure-List__Tabs > ul li.ui-state-active {
  background-color: #726132 !important;
}
.Closure-List__Tabs > ul li:not(.ui-state-active) {
  border-color: transparent !important;
}
.Closure-List__Tabs > ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  float: none !important;
  font-size: var(--font-size);
  color: var(--color-white) !important;
}
.Closure-List__Tabs .Closure-List__Tabs-Content {
  padding: 0 !important;
}
.Closure-List__Tabs .Closure-List__Tabs-Content .Closure-List-Info {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.Closure-List__Tabs .Closure-List__Tabs-Content .NoDriverFound {
  left: -2.5px;
  right: -2.5px;
  bottom: 2.5px;
}
.tab-chat-fpd {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.tab-chat-fpd:first-child {
  width: 50%;
  border: 1px solid var(--color-green);
  border-right: 0.5px solid var(--color-green);
  border-radius: 6px 0 0 6px;
  height: 30px;
}

.tab-chat-fpd:last-child {
  width: 50%;
  border: 1px solid var(--color-green);
  border-left: 0.5px solid var(--color-green);
  border-radius: 0 6px 6px 0;
  height: 30px;
}

.tab-chat-fpd:hover {
  color: #333333 !important;
}

.tab-chat-fpd-active, .tab-chat-fpd-active:hover {
  background-color: var(--color-green);
  color: var(--color-white) !important;
}

.Closure-List-Multiple-Chat {
  padding: 8px 12px !important;
  background-color: transparent;
  border-radius: 0;
  border: 0 none !important;
}
.Closure-List-Multiple-Chat.Batches {
  padding: 2px 0 12px 0 !important;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.Closure-List-Multiple-Chat li {
  float: left;
  margin: 0 !important;
  padding-bottom: 0 !important;
  width: calc(50% - 3px);
  background: transparent !important;
  text-align: center;
}
.Closure-List-Multiple-Chat li:first-child {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid var(--color-green);
  border-top: 1px solid var(--color-green);
  border-bottom: 1px solid var(--color-green);
}
.Closure-List-Multiple-Chat li:last-child {
  border-radius: 0 6px 6px 0;
  border: 1px solid var(--color-green);
}
.Closure-List-Multiple-Chat li:first-child:last-child {
  display: none;
}
.Closure-List-Multiple-Chat li a.ui-tabs-anchor {
  padding: 0.3em 1em !important;
  font-size: var(--font-size-s);
  color: #444;
  float: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Closure-List-Multiple-Chat.Batches li a {
  min-height: 23px;
  line-height: 23px;
  font-size: 13px;
}
.Closure-List-Multiple-Chat.Batches a {
  white-space: nowrap;
}
.Closure-List-Multiple-Chat li a.ui-tabs-anchor span {
  margin-left: 5px;
}
.Closure-List-Multiple-Chat li.ui-tabs-active {
  background-color: var(--color-green) !important;
}
.Closure-List-Multiple-Chat li.ui-tabs-active a {
  color: var(--color-white) !important;
}
.Closure-List-Multiple-Chat .Quote-Icon {
  width: 15px;
  height: 15px;
  background-color: #ffdb01;
  font-size: 10px;
  color: #444;
  display: inline-block;
  border-radius: 50%;
  line-height: 16px;
}
.Closure-List__Tabs .Closure-List-Tab-Block,
.Modal-Tracking-Chat .Closure-List-Tab-Block {
  border-bottom: solid 1px #e8e8e8;
  height: 66px;
  padding: 17px 12px;
}
.Closure-List__Tabs .Closure-List-Tab-Block .DriverInfo h3,
.Modal-Tracking-Chat .Closure-List-Tab-Block .DriverInfo h3 {
  color: #444;
}
.Closure-List__Tabs .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating,
.Modal-Tracking-Chat .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating {
  border-radius: 13.5px;
  border: solid 1px #bbbbbb;
  padding: 2px 5px;
}
#root .Closure-List__Tabs .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating i,
#root .Modal-Tracking-Chat .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating i {
  font-size: 13px;
}
#root .Closure-List__Tabs .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating span,
#root .Modal-Tracking-Chat .Closure-List-Tab-Block .DriverInfo .DriverInfo-Rating span {
  font-size: 11px;
}

.Driver-Loading > div:first-child {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #eeeeee;
}
.Driver-Loading > div:last-child > div {
  width: 80px;
  height: 13px;
  background-color: #eeeeee;
  margin-bottom: 6px;
}
.Driver-Loading > div:last-child > div:first-child {
  width: 140px;
}
.Driver-Loading > div:last-child > div:last-child {
  margin-bottom: 0;
}

/* Animation driver block */
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.Driver-Loading > div:first-child,
.Driver-Loading > div:last-child > div {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #f6f6f6),
    color-stop(18%, #f0f0f0),
    color-stop(33%, #f6f6f6)
  );
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}
/* End Animation driver block */
.bottom-70 {
  bottom: 70px !important;
}
.Closure-List__Tabs .Closure-List-Tab-List {
  bottom: 55px;
  top: 76px;
}
.Closure-List__Tabs .Closure-List-Tab-Bottom,
.Batch-Custom .Closure-List-Tab-Bottom,
.Modal-Tracking-Chat .Closure-List-Tab-Bottom {
  bottom: 12px;
  left: 10px;
  right: 10px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 0.7px #eaeaea;
}
.Closure-List__Tabs .Closure-List-Tab-Bottom .Chat-Disabled,
.Batch-Custom .Closure-List-Tab-Bottom .Chat-Disabled,
.Modal-Tracking-Chat .Closure-List-Tab-Bottom .Chat-Disabled {
  background-color: #d8d8d8;
  height: 33px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-size: 12px;
  color: #5d5d5d;
}

/* tally in my booking*/
.Closure-List__transit-time {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0px 15px;
  background-color: rgba(47, 54, 64, 0.2);
}
.Closure-List__transit-time .Closure-List-Table-Row:not(:first-child) {
  padding-left: 10px;
}
.Closure-List__transit-time .Closure-List-Table-Row:first-child {
  padding-top: 5px;
}
.Closure-List__transit-time .Closure-List-Table-Row:last-child {
  padding-bottom: 5px;
}
.Closure-List__transit-time .Closure-List-Table-Row .Right p {
  white-space: pre-wrap;
}
.transit-time-status {
  font-weight: 500;
  padding: 0 6px;
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
}
.transit-time-status.green {
  border: unset;
  background-color: white;
  color: var(--color-green);
}
.transit-time-status.red {
  border: unset;
  background-color: white;
  color: var(--color-red);
}

/*
 * Export Excel
 */

.Export-Excel-Container {
}
.Export-Excel-Divide {
  height: 2px;
  background-color: #e4e4e4;
}
.Export-Excel-Item-List > div,
.Export-Excel-Filter > div:first-child {
  min-width: 175px;
  max-width: 175px;
}
.Export-Excel-Filter .FormGroup-Filter button {
  border: 1px solid var(--color-gray-xl);
  height: 38px;
  margin-top: 2px;
}
.FormGroup-Filter {
  z-index: 99;
}

/*
 * modal-customLayout
 */

.modal-customLayout .modal-customLayout-Group .block-item {
  padding: 10px;
}
.modal-customLayout .modal-customLayout-Group .block-item .Pin {
  margin-right: 10px;
}
.modal-customLayout .modal-customLayout-Group .locations-error {
  margin-left: 45px;
  padding-bottom: 5px;
  margin-bottom: 0;
  margin-top: -5px;
}
.modal-customLayout .modal-customLayout-Line {
  border-top: 1px solid var(--color-white);
  margin: 15px 0;
}
.modal-customLayout .modal-customLayout-Button {
  margin-top: 10px;
}
.modal-customLayout .Popover-contact-list-arrow:before {
  border-bottom-color: var(--color-white);
}

/*
 * Search-Common
 */

.Search-Common {
  border: 1px solid var(--color-gray-xl);
  border-radius: 5px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 5px;
  transition: 200ms linear 0s;
  max-width: 17px;
  overflow: hidden;
  position: relative;
}
.Search-Common:not(.Show):not(.no-Click):before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  cursor: pointer;
}
.Search-Common.Show {
  max-width: 100%;
}
.Search-Common img {
  display: block;
  cursor: pointer;
}
.Search-Common.Show img {
  margin-left: 5px;
}
.Search-Common input {
  border: 0 none;
  outline: 0;
  width: 0;
  padding: 0;
  font-size: 13px;
}
.Search-Common.Show input {
  width: 100%;
}

/*
 * New UX for Time-Types Box
 */

.BookingWizard .Content .PickupTime-Show-Pickup {
  padding-bottom: 5px !important;
}
.BookingWizard .Content .PickupTime-Show {
  margin: 0 10px;
  border-radius: 0 0 var(--radius) var(--radius);
  padding: 5px 10px 10px;
  position: relative;
}
.BookingWizard .Content .PickupTime-Show:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  background: var(--color-dark-green);
  height: 5px;
}
.BookingWizard .Content .PickupTime-Show:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  border-top: 1px dotted var(--color-gray-xl);
  height: 1px;
}
.BookingWizard .Content .PickupTime-Custom .block-item {
  flex: 1;
  min-width: 160px;
  min-height: 42px;
  padding: 8px 15px !important;
  border-bottom: 1px solid var(--color-gray-xxl);
}
.MultipleBookingLayout .BookingWizard .Content .PickupTime-Custom .block-item {
  min-width: 130px;
}
.MultipleBookingLayout .BookingWizard .view-map {
  margin-top: 20px;
}
.Batch-Custom .Table thead td {
  background-color: #7c7c7c;
  white-space: nowrap !important;
}
.Batch-Custom .Table thead td,
.Batch-Custom .Table *:not(.Icon):not(.material-icons):not(.Pin):not(.small-font),
.Batch-Custom .Batch-Table-Sortable table *:not(.Icon):not(.material-icons):not(.Pin):not(.small-font) {
  font-size: 11px;
}
.Modal-FromGroup .Modal-FromGroup-Icon label span,
.Batch-Custom .Modal .Table *:not(.Icon):not(.material-icons):not(.Pin) {
  font-size: 13px;
}
.Batch-Custom .shipment_type .cell .Pin {
  font-size: 9px;
}
.BatchUploadLayout .Steps .Step,
.Modal-Basic .Modal-Head,
.Steps {
  font-size: 17px;
}
.Batch .BatchUpload-Title h3 {
  font-size: 20px;
}
.Booking-BatchEZ .BookingWizard .Content h3.Custom,
.Modal-FromGroup .Modal-FromGroup-Icon label,
.MultipleBookingLayout .BookingWizard .view-map,
.FormGroup-label,
.BookingWizard .Vehicles.block .block-item label,
.BookingWizard select {
  font-size: var(--font-size);
}
.BatchUploadLayout .Steps .Step,
.BatchUploadLayout .Steps .Step.selected,
.MultipleBookingLayout .BookingWizard .Actions .Actions-Toggles button {
  font-weight: normal;
}
.Batch .BatchUpload-Title h3,
.Modal-Basic .Modal-Head,
.BatchUploadIntro .Button {
  font-weight: bold;
}
.BatchUploadLayout .Steps .Step.selected,
.Batch-Custom tbody td[class^="locations-"] .cell,
.Modal input,
.Modal label:not(.White-text):not(.White):not(.white),
.Modal-Basic .Modal-SplitView span:not(.White-text):not(.White):not(.white),
.Modal textarea,
.Badges.block .block-item label,
.ExtraServices.block .TitleSubtitle-title,
.ExtraServices.block span,
.CodPod.block .TitleSubtitle-title,
.Options.block .TitleSubtitle-title {
  color: #444444;
}
.Batch .BatchUpload-Title,
.Batch .BatchUpload-Title + section,
.Batch .BatchUpload-Title ~ section {
  padding-top: 15px;
}
.BatchUploadAssign .FormGroup label {
  padding-right: 10px;
}
.BatchUploadLayout .FormGroup {
  margin-bottom: 15px;
}
.BatchUploadLayout .FormGroup {
  margin-top: 0;
}
.BatchUploadLayout .FormGroup.FormGroup-Upload-Batch-Pickup {
  margin: 30px 0;
}
.Batch .BatchUpload-Title .Button i {
  font-size: 20px;
}
.BatchUploadLayout .Steps .Step:after {
  box-shadow: none;
  border-top: 2px solid var(--color-dark-green);
  border-right: 2px solid var(--color-dark-green);
}
.Custom-Step .Steps .Step.selected,
.Custom-Step .Steps .Step.selected:after {
  background-color: var(--color-yellow);
}
.BatchUploadAssign .BatchUploadAssign-Box {
  background-color: #f8f8f8;
  border: solid 1px #d4d4d4;
  padding: 20px 20px 5px 20px;
}
.BatchUploadAssign .FormGroup select,
.BatchUploadAssign .FormGroup input {
  border-radius: 5px;
  outline: 0;
}
.BatchUploadAssign .FormGroup select {
  padding-left: 5px;
  color: #231f20;
}
.Batch-Custom .Table thead td {
  height: 36px;
  padding: 0 5px;
  max-height: 36px;
}
.BatchUploadValidate .Batch-Custom .Table thead .Overlay-Checkbox {
  height: 14px;
  width: 14px;
  margin-top: 2px;
}
.BatchUploadValidate .Batch-Custom .Table thead .Overlay-Checkbox label:before {
  width: 12px;
  height: 12px;
  background-color: var(--color-white);
}
.BatchUploadValidate .Batch-Custom .Table thead .Overlay-Checkbox i {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 14px;
}
.Batch-Custom .Table td.expanded > div {
  display: flex;
}
.Batch-Custom .Batch-Table-Sortable .Overlay-Checkbox {
  height: 16px;
}
.PageHead {
  border-bottom: 1px solid #d4d4d4;
}
.PageHead-tabs .item a {
  color: #7c7c7c;
  font-size: 17px;
}
.PageHead-tabs .item.selected a {
  color: var(--color-green);
}
.PageHead-tabs .item.selected {
  margin-bottom: -1px;
}
.Devina-Screen .TitleSubtitle {
  padding-left: 0;
}
.Devina-Screen .Dropdown .Dropdown-Head-Caret i,
.Devina-Screen .TitleSubtitle,
.TitleSubtitle-title:not(.White-text):not(.White),
.Box-List-Item label,
.Box-List-Item span,
.Modal-SplitView-Default-Font {
  color: #444;
  font-size: var(--font-size);
}
.Empty-Box,
.PickupTime.block .block-item .PickupTime-Image i,
.CodPod.block .block-item i {
  color: #7c7c7c;
}
.Batch-Chat-Empty-List.Empty-List,
.Batch-Map-Empty-List.Empty-List,
.Batch-Grid-Empty-List.Empty-List {
  min-height: calc(100vh - 325px);
}
.Batch-Map__Tally {
  color: #7c7c7c;
  font-size: 13px;
  padding-left: 5px;
}
.Batch-Map__Time {
  white-space: pre-line;
}
.Popover-Item.actions .Button,
.LocationContacts .block-sub-item.block-sub-item-button button,
.Modal-Addlocation-Info .Modal-FormGroup-Note .flex-index button {
  white-space: nowrap;
  margin: 0;
}
.small-text,
.small-font {
  font-size: 13px;
}
.small-font-important {
  font-size: 13px !important;
}
.Pin,
.font-bold {
  font-weight: bold;
}
.Pricing.block .block-item {
  border-color: #444444;
}
.modal-batchUploadValidate .Box-Actions > div button,
.MultipleBookingLayout .BookingWizard .Actions .Button,
.Booking-Multiple-Actions button,
.Booking-Multiple-Actions a,
.BatchUploadLayout section.actions .Button,
.Modal-Basic .Box-Actions .Button,
.Modal-Basic .Box-Actions button,
.Modal-Basic .Modal-Actions .Button,
.Modal-Basic .Modal-Actions button,
.PickupTime-DatePicker .DatePicker-Actions .Button {
  font-size: 17px;
  font-weight: bold;
}
.Attachments.block .block-item .Dropzone i {
  color: #7c7c7c;
}
.Attachments.block .block-item .Dropzone:hover {
  color: var(--color-green);
}
.DetailBooking-FormGroup .RatingStars {
  margin-left: -3px;
  margin-bottom: 5px;
  margin-top: 5px;
}

/*
 * Dashboard Analytics
 */

.DashboardAnalytics {
  background-color: #f2f2f2;
  min-height: calc(100vh - 60px);
}
.DashboardAnalytics .PageHead {
  height: 60px;
}
.DashboardAnalytics .PageHead .Button,
.DashboardAnalytics .PageHead .Input,
.DashboardAnalytics .PageHead select {
  border-color: #c3c3c3;
}
.DashboardAnalytics .PageHead .Input.error {
  border-color: var(--color-light-red);
  border: 1px solid var(--color-red);
}
.DashboardAnalytics .PageHead-title {
  padding-left: 25px;
}
.DashboardAnalytics .PageHead-Actions .Filter {
  padding-right: 25px;
}
.DashboardAnalytics .Filter .FormGroup {
  padding-right: 0;
}
.DashboardAnalytics .Actions-Button {
  top: 100%;
  right: 20px;
  margin-top: 10px;
  min-width: 160px;
  width: auto;
}
.DashboardAnalytics .Actions-Button li button img {
  padding: 5px;
  width: 20px;
  height: 20px;
}
.DashboardAnalytics .Actions-Button {
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.22);
}
.DashboardAnalytics-Export-Group {
  background-color: #f2f2f2;
}
.DashboardAnalytics .DashboardAnalytics-Box {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
}
.DashboardAnalytics .DashboardAnalytics-Box.Custom-Padding {
  padding: 15px 15px 15px 0;
}
.DashboardAnalytics .DashboardAnalytics-Box,
.DashboardAnalytics .DashboardAnalytics-Box > div.flex-center {
  min-height: 200px;
}
.DashboardAnalytics .DashboardAnalytics-Box:first-child:last-child,
.DashboardAnalytics .DashboardAnalytics-Box:last-child {
  margin-right: 0;
}
.DashboardAnalytics .DashboardAnalytics-Box .DashboardAnalytics-Box-Info-Title,
.DashboardAnalytics .DashboardAnalytics-Box .DashboardAnalytics-Box-Info-Title h3 {
  font-size: 18px;
}

.DashboardAnalytics .DashboardAnalytics-Hyperlink {
  color: #0e730f;
  font-family: var(--font-family-base);
}

/*
 * Loading common
 */

.loading-common {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/*
 * FormGroup Error
 */

.FormGroup-Error {
  position: absolute;
  top: 100%;
  background-color: var(--color-red);
  color: var(--color-white);
  font-size: 13px;
  right: 0;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
  display: none;
}
.Input.error-show-tooltip + .FormGroup-Error.invalid,
.Input.error-show-tooltip ~ .FormGroup-Error.invalid {
  display: block;
}
.Input.max-config-show-tooltip + .FormGroup-Error.maxConfig,
.Input.max-config-show-tooltip ~ .FormGroup-Error.maxConfig {
  display: block;
}
.FormGroup-Error:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -5px;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--color-red);
}
.BatchUploadOptions .Box-Content-SplitView {
  height: 260px;
  padding: 0;
}
.BatchUploadOptions .Box-Content-SplitView ul {
  border: none;
  overflow-y: inherit;
  height: auto;
}
.BatchUploadOptions .Box-Content-SplitView li.active {
  background-color: var(--color-dark-green);
}
.Input-With-Icon {
  position: relative;
}
.Input-With-Icon input {
  border: 0 none;
  background: transparent;
  min-height: 32px;
  box-sizing: border-box;
  color: var(--color-white);
}
.Input-With-Icon i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.Input-With-Icon-Line input {
  border-bottom: 1px solid var(--color-green);
  padding-right: 32px;
}
.Input-With-Icon-Line i {
}
.Input-With-Icon input.Error {
  border-bottom-color: var(--color-red);
}
.LongHaulIcon {
  height: 20px;
  /* background: url("../../images/webapp-v2/longhaul/long-haul-green-icon.png") no-repeat center; */
  background-size: contain;
}

/*
 * New Grid Style
 */

.Grid-Cell {
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-white);
  border-left: 1px solid #e1e1e1;
}
.Grid-Cell-Header-Container {
  overflow-x: hidden !important;
}
.Grid-Cell-Header {
  background-color: #7c7c7c;
}
.Grid-Cell:not(.Grid-Cell-Header):before {
  content: "";
  height: 1px;
  background-color: #e1e1e1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.Grid-Cell-Header span {
  color: var(--color-white);
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.Grid-Cell-Header.expanded .Overlay-Checkbox {
  margin-left: 18px;
}
.Grid-Cell-Header span {
  padding: 0 5px;
}
.Grid-Cell .locations-merge {
  padding: 0 5px;
  flex: 1;
}

.Grid-Cell .locations-merge .Shorted-Text {
  width: 85%;
}

.Grid-Cell .cell {
  margin: 0 -5px;
}
.Batch-Custom .Grid-Cell .shipment_type .cell .Pin {
  margin: 0 5px;
}
.Grid-Cell .locations-merge .Input,
.Grid-Cell .locations-merge .Select,
.Grid-Cell .locations-merge select,
.Grid-Cell .locations-merge .TextArea {
  min-width: 50px;
  height: 28px;
  line-height: 28px;
  border: 0 none;
  background-color: transparent;
}
.Grid-Cell:not(.Grid-Cell-Custom) .cell-overlay {
  right: 1px;
  bottom: 0;
}
.Grid-Cell .locations-merge *:not(.Icon):not(.material-icons):not(.Pin),
.Grid-Cell .locations-merge *:not(.Icon):not(.material-icons):not(.Pin) {
  font-size: 11px;
}
.Grid-Cell .locations-merge .Input.Input-Overlay button,
.Grid-Cell .locations-merge .Input.dlvr-ez-input-address button {
  background: transparent;
  padding: 0;
  height: 18px;
  max-width: 20px;
  border: 0 none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Grid-Cell .locations-merge .Input.Input-Overlay button.custom-icon,
.Grid-Cell .locations-merge .Input.dlvr-ez-input-address button.custom-icon {
  max-width: 24px;
}
.Grid-Cell .locations-merge .Input.error,
.Grid-Cell .locations-merge .Select.error,
.Grid-Cell .locations-merge .TextArea.error {
  background-color: #ffc7c7;
  border-radius: 0px;
  width: auto;
}
.Grid-Cell .locations-merge input {
  box-sizing: border-box !important;
  width: 80px;
}
.Grid-Cell .locations-merge input,
.Grid-Cell .locations-merge span {
  padding: 0 5px;
}
.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 100% !important;
}
.BottomLeftGrid_ScrollWrapper .ReactVirtualized__Grid {
  overflow: auto hidden !important;
}
.ReactVirtualized__Grid {
  will-change: auto !important;
}
.Grid-Cell .locations-merge + .BookingWizard,
.Grid-Cell .locations-merge ~ .BookingWizard {
  position: absolute;
  right: 0;
}
.Grid-Cell.Grid-Cell-Custom {
  padding-top: 11px;
  box-sizing: border-box;
}
.Grid-Cell.Grid-Cell-Custom:after {
  content: "";
  background-color: #e1e1e1;
  height: 11px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.Grid-Cell.Grid-Cell-Custom .cell-overlay {
  top: 11px;
  bottom: 0;
}
.Grid-Cell .expanded__expandButton {
  transform: none;
  z-index: 2;
  width: 16px;
  height: 16px;
  box-shadow: none;
  background-size: 16px !important;
  position: absolute;
  cursor: pointer;
  left: 50% !important;
  margin-left: 0px;
  top: 50%;
  margin-top: -8px;
}
.Grid-Cell .expanded__expandButton {
  margin-left: 4px;
  margin-top: -4px;
}
.Grid-Cell .expanded__expandButton + .Overlay-Checkbox,
.Grid-Cell .expanded__expandButton ~ .Overlay-Checkbox {
  margin-left: -25px;
}
.Grid-Cell .expanded__expandButton {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAx9JREFUSA29Vl1IFFEU/u7MztqDFWUWaA9rkbWJYoqlYK1CGr6UWWEYRO/WQ9BDWlD0g70kUU9B0EvUS6n1UJGRPyEtmf0YrllQL2lULBVF2c6ut3uuzjA7O67rujnL7J17z3e+b86de+85DAlcW2+v36Bz1GICm8F4NuMsi9w442PibxQKHmsMHQ93BF7MRMfiASo68naDT5zhHGvj4QwbYxgBU4531w7dNMbsraNg9Z2CnFBYv86BUrtDIn1B6ne7tIYH2wc/2PExgpVt+T7OwrdEVBl28Gz6Itog465dXXWve6x+UYIkNoFIp/g6mhWU/DPTFahVVlFTUE5jRO+fa2T2l6NI3apWYkyvYgDkN5vjNBpc1pYCIG5jTArSakx2gRhE8VrilitegCYjFEs/nkNKbFMaTG7qCH+eEtIZSDSVFSnyBHEAnt50Edeq7iJvaaGD1XmIsORDvk4XaSnyuHKwpqkLsDLdg9byq9iSVeWAiB7yZVVLLPmQr+MljkbVs3dZszDGbPLesU6sWrQGOeL2ZW/Db/0XAt9eOfLsWb0fR4pOQVM09H16hJNPDyPCw7FYBl3NqV/eIixuu5Ucuj/ex+K0JfAuLcDGFeVI1xai/0ufCWVgOJh/FAe8jWBiw3W8v4FzA80Ic93EWB8EPk311GdShDGCBOTi5//ci/HIHxRnliEvo1BEnSui6ILKXDhRch41njpwsdkuD7XiSuCC9LGKRD0z/GW+du+IQOVGGRw6ldk1aCpugVt1Yyj4UiLoBUKREFoGmtA1es/ByzbE8NYl8xn4jIJEGBz/irOll2SkRPUz9APH/IcwGHxmY56mK3KnQslzGnPMMBE39jTg3feAvBt79yUuRmxCa943vswWFe3eN+K7J5TVY8JOcICqge6dw+smz1JRFiTolzxsSsPMhxVt3if/K2MIEX933XAZva2ZD6kGoWSZfAjOnjIBC27DagpSRqYaBGDOx4ThMauW6cRpZHtyNQWpQ7UH1SCpiJQ47PVMjKAhSjUIzTv1k7nIlzisxZPBYy4aY8DazlshbBWl51SW+v8ADv0rUA3F9WYAAAAASUVORK5CYII=")
    no-repeat center;
}
.Grid-Cell .expanded__expandButton.expanded__collapseButton {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1IVFEU/u69OqNpRbRLK0dLsxD6IX8KKUqFdlHRolrUOhctFELLlSItCqpFQa1cRIuKIAjxp8iBxp8osKRcaVHt3DXpON53u+fie755M45PZ/LAfe/ce875vvt/D4MPiTbUHYCyTiugHkwVMcW2UZhi6rf+/GJAGIy/LBiIfFoJTvsuL3+bas5ZUnVqj4rlvRIsk1ywGxv6Rp4ltLoqKQlnm6pDlsWeKKVqXb6+VcbYMOfqQn7f6JQ3KIlwtrHumFTyORS2ep1XVWeYEUycze+PvHPHJRASmWXJfr1WuW6nteoaPM65aHSTOoQ0jdLCWMYj8/aORspx2J5ebttpzbJORuB6aQz2IpEhpN241g1idzjdn7CJg3wM4eLWTxeTsc3mYHSolZIfM0b0AcCYOMjpBvHh67iw7TuR96DHFNJXJZqLm+vKZxSv2o/8e48hdleYQjq1+RXi4nQ3+gkQxxuQd+s+2MZNkBOfTSGd2sjmSzSXaA8Vd2vnQLqA3POXELh2HSwnBwvht4h1tGKh/zX4jhKI0l0Q9SeA2BysifF0MGBgQdFeWty2LCHnCDS3IHDxCvT9iPiLp5i/3QXIBV0k5NAgWGEhxN4qiEM1YJu3QH4Y1mdPT15KYTHRVlZ0WduS781gEMGObuQ2ntL7ysL8w7uI9zxKgpFjw1DRqCashqjcB15WDvl+yHQoyZnhu2gLFZ/RhpDXGOy8g5zao1DzMcS6bkL2vvK6OHXr6xdY01MQR+ohSsrAyyshB3sd+5LCxrm+TMNLDS6N1uTnD8y1XIXU67aSyPAbzLU2mxhaz1RCXOt+8M1r8edk9TfdI7+veqrO+2mbLBwc3WPuUkoL/ERk4mNzmBESULShJvK/XgxKOQoGRuqIx4zQKDoH0SdzhvSsisak/MbGdAjpRaYcRA85bhsz/ROWyWtcyZRDSOCUe1AOkpWRmpEl5jPEkUBok1IOQvNO9bUIxZo8xpOxEZazaVIBr1si7CXPZqr/D0WCIMh4OyI6AAAAAElFTkSuQmCC")
    no-repeat center;
}
.Grid-Cell .expanded__expandButton.expanded__collapseButtonFullday {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAS5JREFUKBV9UqtuhEAUvTNBQAgWW9EVmCY1BN9Ukq7EF4XvZ9Sj6B+AWCzpT9Su6QegSgJBQO+Z7N0MzZZJ4N45j3nAUWSNqqqCcRzflFIvDB8u1Hld15Pnee95nv+IXElTluXTsiwfLLoTzK682LfW+rUoik/gxngxdWy6LmSbpGfzyuZnmBWON03T1387iUkqdnZd98HBnRi8eTwR2xUbwKMZPAqRZRlFUSTTawUGTgY+nubXvQBd11GSJBTHsUCmBwbOGgfHmlDf91TXNaVpSkEQGCoMQ4MNw2BLCUc92wgETdOQ7/vmQf/XBI/Dlz1x82ib53mmtm1taNPDo5EIfOINszOBFh6NGCERDKw7ekNBA63xAEESkIi9ncFJauDZRExCzviRhfKbbob8FwxyhIyLc9BTAAAAAElFTkSuQmCC")
    no-repeat center;
}
.Grid-Cell:not(.Grid-Cell-Custom) .expanded__expandButton {
  display: none !important;
}
.Grid-Cell .Icon.red {
  color: var(--color-red);
}
.Grid-Cell .Overlay-Checkbox,
.BatchUploadBook .Batch-Table-Sortable .Overlay-Checkbox {
  width: 16px;
  height: 16px;
}
.Grid-Cell .Overlay-Checkbox label:before,
.BatchUploadBook .Batch-Table-Sortable .Overlay-Checkbox label:before {
  width: 12px;
  height: 12px;
}
.Grid-Cell .Overlay-Checkbox i,
.BatchUploadBook .Batch-Table-Sortable .Overlay-Checkbox i {
  line-height: 16px;
  width: 16px;
  font-size: 14px;
}

/*
 * Wallet
 */

.wallet-screens-bg {
  background-color: #d2d2d2;
}
.wallet-screens-list {
  border-bottom: 1px solid #ddd;
}
.wallet-screens-devide {
  height: 1px;
  background-color: #ddd;
}
.wallet-screens-form .Dropdown-Head-Item {
  font-size: var(--font-size);
}
.wallet-screens-form .Dropdown-Menu {
  display: block;
  min-width: 180px;
  outline: none;
  background: #ffffff;
  white-space: nowrap;
  text-shadow: none;
  text-align: left;
  border-radius: 2px;
  max-height: 0;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
  z-index: 11;
  will-change: transform, opacity;
}
.Dropdown.open .Dropdown-Menu {
  max-height: 500px;
  transition: max-height 0.2s ease-in;
}
.Wallet .PageHead-tabs .item.selected {
  margin-bottom: 0;
}

.wallet-screens-settlements a {
  height: 40px;
  color: #a1a1a1;
  background-color: #e5e5e5;
}
.wallet-screens-settlements a.Active {
  color: var(--color-white);
  background-color: var(--color-green);
}
.wallet-screens-settlements a:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.wallet-screens-settlements a:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*
 * New Pickup Style
 */

.BatchUpload-Close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.BatchUploadOptions .Box-Content-SplitView ul {
  border: 0 none;
  height: 305px;
  border-radius: 5px;
  overflow-y: auto;
}
.BatchUploadOptions .Box-Content-SplitView.Custom-None-Scroll ul {
  height: auto;
  border-radius: 0;
  overflow-y: inherit;
}
.BatchUploadOptions .Box-Content-SplitView li {
  position: relative;
}
.BatchUploadOptions .Box-Content-SplitView li:after {
  border-bottom: 1px solid #cacaca;
  content: "";
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}
.BatchUploadOptions .Box-Content-SplitView li.active {
  background: var(--color-dark-green);
}
#root .BatchUploadOptions .Box-Content-SplitView li.active label {
  color: var(--color-white);
}
.BatchUploadOptions .Box-Content-SplitView li.active:after,
.BatchUploadOptions .Box-Content-SplitView li:last-child:after {
  display: none;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box {
  padding: 15px;
  width: 35% !important;
  flex: inherit;
  max-height: 360px;
  min-height: 360px;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box .Search-Common {
  margin-bottom: 15px;
  padding: 0 10px;
  border: 0 none;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box.Custom {
  width: 65% !important;
  padding: 0;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box .ContactInfo {
  border: 0 none;
  background-color: transparent;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box .ContactInfo > div {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box .ContactInfo > div:last-child {
  border-bottom: 0 none;
  margin-bottom: 0;
}
.Pickup-Location .Box-Content-SplitView.Pickup-Location-Box .ContactInfo > div p {
  margin: 0px 0 10px 0;
}

/*
 * Load Draft
 */

.Modal-Basic .Modal-Head.Box-Head-Flex {
  justify-content: flex-start;
}
.Modal-Table tbody tr:nth-child(2n + 2) td {
  background-color: transparent;
}
.Modal-Table tbody tr.Modal-Table-Pickup {
  background-color: #cecece;
  font-weight: bold;
}
.Modal-Table tbody tr.Modal-Table-Pickup td {
  border-bottom: 1px solid var(--color-white);
}
.Modal-Table tbody tr.Modal-Table-Pickup:last-child td {
  border-bottom: 0 none;
}
.Batch-Custom .Modal-Table .Table thead td {
  height: auto;
  background: transparent;
  border-bottom: 1px solid #e1e1e1;
}
.Modal-Table .location-id {
  width: 15%;
}
.Modal-Table .location-name {
  width: 15%;
}
.Modal-Table .location-vehicle {
  width: 15%;
}
.Modal-Table .location-pickup {
  width: 25%;
}
.Modal-Table .location-destination {
  width: 25%;
}
.Modal-Table .location-select {
  width: 5%;
}
.Box .Modal-Table .Table tbody td {
  padding: 8px 10px;
}
.Box .Modal-Table .Table thead td,
.Box .Modal-Table .Table tbody td {
  padding-left: 15px;
  padding-right: 15px;
}
.Box .Modal-Table .Table thead td:first-child,
.Box .Modal-Table .Table tbody td:first-child {
  padding-left: 20px;
}
/*
 * DocumentReturns
 */

.BookingWizard .Popover.step-2 .DocumentReturns h3 {
  text-align: left;
}
.DocumentReturns .DocumentReturns-Block {
  border-radius: var(--radius);
}

div.cash-payor a.cash-payor-radio.uncheck,
div.cash-payor a.cash-payor-radio,
div.cash-payor a.cash-payor-radio.check {
  border: none;
}
.Modal-Basic .Modal-SplitView.Booking-Price-Popup {
  padding: 15px;
}
.Booking-Price-Popup .MultipleBookingLayout-Summary-Group {
  padding-bottom: 15px;
  padding-left: 0;
}
.Booking-Price-Popup .BookingWizard {
  background-color: transparent;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.Booking-Price-Popup .BookingWizard label {
  font-size: var(--font-size-s);
}
.Booking-Price-Popup .BookingWizard .Content {
  overflow-y: auto;
}
.Booking-Price-Popup .BookingWizard .Content .block.Pricing {
  margin: 0;
  box-shadow: none;
}

/*
 * Preference driver
 */
.Preference-Driver-Icon img {
  max-width: 40px;
}
.Preference-Driver-Icon-Checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}
.PageHead-actions-fixed {
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: var(--color-white);
  left: 0;
  right: 0;
  text-align: right;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  box-shadow: 0px 0px 4px #999;
}

.close-modal-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.SingleBookingLayout .BookingWizard .close-modal-overlay {
  position: fixed;
}
.Attachments.block .block-item .FileView label {
  width: 85%;
  word-break: break-word;
}
.DetailBooking-ImageView .DetailBooking--ImageViewer-PDF {
  width: 100% !important;
  float: none !important;
  border-radius: 4px;
  background: var(--color-gray-xxl) !important;
  color: var(--var-gray);
  height: 100%;
  position: relative;
}
.DetailBooking--Photos-List {
  margin: auto;
}
.btn-show-more {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3fae29;
  cursor: pointer;
  float: left;
  width: 100%;
  margin-top: 5px;
}

/* tally in booking detail */
.DetailBooking-FormGroup.DetailBooking__Other table td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.DetailBooking__transit-time {
  text-align: left !important;
  border-radius: 6px;
  background-color: #eaeaea;
  color: #444444;
  padding: 0 !important;
}
.DetailBooking__transit-time__Row {
  overflow: hidden;
}
.DetailBooking__transit-time__Row .Left {
  width: 45%;
  float: left;
}
.DetailBooking__transit-time__Row .Right {
  width: 55%;
  float: left;
  text-align: right;
}
.DetailBooking__transit-time__Row.Left p {
  padding-right: 5px;
}
.DetailBooking__transit-time__Row p {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  line-height: 24px;
}
.DetailBooking__transit-time__Row .Right p {
  white-space: pre-wrap;
}
.DetailBooking__transit-time__Row {
  padding-right: 10px;
  padding-left: 20px;
}
.DetailBooking__transit-time__Row:first-child {
  padding-top: 5px;
  padding-left: 10px;
}
.DetailBooking__transit-time__Row:last-child {
  padding-bottom: 5px;
}
.DetailBooking__status {
  padding: 3px 6px 2px 6px;
  border: 1px solid #444444;
  border-radius: 5px;
  display: inline-block;
  line-height: 15px;
  font-weight: 500;
}
.DetailBooking__status.green {
  border: unset;
  background-color: white;
  color: var(--color-green);
}
.DetailBooking__status.red {
  border: unset;
  background-color: white;
  color: var(--color-red);
}

/*
 * Select-Box
 */

.Select-Box .Select-Box__list {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
}
.Select-Box .Select-Box__list.Top {
  top: 0;
}
.Select-Box .Select-Box__list.Show {
  display: block;
}

/*
 * Fit Gallery Consistency
 */

.Fit-Gallery a {
  border: 0 none;
}
.Fit-Gallery a:not(.View_Target) {
  overflow: hidden;
  position: relative;
  background: var(--color-white);
  box-sizing: border-box;
}
.Fit-Gallery a.View_Target {
  background: #09670a !important;
}
.Fit-Gallery a:not(.View_Target) span,
.Fit-Gallery-None,
.Fit-Gallery-Image {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.Fit-Gallery-None > img {
  position: relative;
  z-index: -1;
}

/*
 * Map Tracking - Booking Detail Tracking
 */

.Map-Tracking {
  position: absolute;
  right: 20px;
}
.Map-Tracking.Bottom {
  bottom: 20px;
}
.Map-Tracking.Top {
  top: 20px;
}

.Map-Tracking .dlvr-share-tracking-button {
  min-width: 178px;
  padding: 0 15px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #0f730f;
  background-color: #fff;
}

.Map-Tracking .dlvr-share-tracking-text {
  margin: 0 0 0 5px;
  font-family: var(--font-family-base);;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f730f;
  position: relative;
  top: -1px;
}

/*
 * Account Screen
 */

.SelectSubAccountBox .Box-List-Item:hover,
.SelectAccountBox .Box-List-Item:hover {
  cursor: pointer;
  background: #d0d0d0;
}

/*
 * Driver-Preferences
 */
.Modal.Modal-Driver-Custom {
  top: -15px;
  bottom: -15px;
  left: -10px;
  right: -10px;
}
.Driver-Preferences-Group ~ .DynamicPopupWrapper .Modal,
.Driver-Preferences-Group + .DynamicPopupWrapper .Modal {
  position: fixed;
}
.Popover-Item-Drivers {
  bottom: 0;
}
.Popover-Item-Drivers.Multiple {
  width: 365px;
  margin-left: 0;
  left: 0;
}
.Popover-Item-Drivers.BatchEZ {
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 10010;
  max-height: 80vh;
  padding: 0;
}
.Popover-Item-Drivers.BatchEZ > .Popover-Item-Driver {
  background-color: var(--color-green);
  padding: 15px 10px;
  border-radius: var(--radius);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
.Popover-Item-Drivers.BatchEZ:after {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.Popover-Item-Drivers.Multiple:before {
  display: none;
}
.Popover-Item-Drivers.Multiple .Modal {
  position: fixed;
}
.Popover-Item-Drivers.Multiple .PickupTime-Modal-Driver {
  left: 0;
  width: 100%;
}
.Popover-Item-Driver-Group {
  padding: 10px;
  background: #e7e7e7;
  border-radius: var(--radius);
}
.Popover-Item-Driver-Item-Avatar {
  width: 42px;
  height: 42px;
}
.Popover-Item-Driver-Item-Avatar.Big {
  width: 54px;
  height: 54px;
}
.Popover-Item-Driver-Item-Avatar span {
  display: block;
  height: 100%;
  width: 100%;
  background-size: cover;
  border-radius: var(--radius);
  overflow: hidden;
}
.Popover-Item-Driver-Name-Text {
  height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Popover-Item-Driver-Item-Icon.Hide {
  /* margin-top: 12px; */
  line-height: 4px;
}
.Popover-Item-Driver-Item-Icon.Show {
  margin-top: 3px;
}
.Popover-Item-Driver-Item-Icon-Eye {
  display: inline;
  position: relative;
  top: 5px;
}
.Popover-Item-Driver-Tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.Popover-Item-Driver-Tooltip.Top:before {
  border-bottom: 10px solid var(--color-white);
  top: -10px;
  left: 50%;
  margin-left: -5px;
}
.Popover-Item-Driver-Tooltip.Bottom {
  position: absolute;
  bottom: 55px;
  left: 15px;
  right: 15px;
}
.Popover-Item-Driver-Tooltip.Bottom:before {
  border-top: 10px solid var(--color-white);
  bottom: -10px;
  right: 115px;
}
.Driver-Item-Slider span {
  min-width: 68px;
}
.Modal-Driver-Change-Date-Time .Modal-Change-Data-Time .Modal {
  top: -15px;
  bottom: -15px;
  left: -10px;
  right: -10px;
}
.Modal-Driver-Change-Date-Time .Modal-Change-Data-Time .Popup {
  align-self: center;
}
.Modal-Driver-Change-Date-Time .Modal-Change-Data-Time .Popup-Booking-Normal h5 {
  margin: 20px 0 10px 0;
}
.Popup-Driver-Change-DateTime-Line {
  height: 2px;
  background-color: var(--color-white);
  margin: 8px 20px;
}
.Popup-Driver-Change-DateTime-Line.Active {
  background-color: var(--color-yellow);
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver-Title {
  font-size: var(--font-size);
  margin: 10px 0 !important;
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver-Group {
  padding: 10px 5px;
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver {
  padding: 5px 5px 10px 5px;
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver-Search input {
  padding: 0 5px;
}
.Popover-Item-Drivers.Multiple .Green-bg .Popover-Item-Driver-Item:not(.Modal-Driver-Change-Date-Time) {
  padding: 0;
}
.Popover-Item-Drivers.Multiple .Driver-Item-Slider span {
  min-width: 53px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  margin-right: 10px;
}
.Popover-Item-Drivers.Multiple .Driver-Item-Slider span {
  font-size: var(--font-size-s) !important;
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver-Tooltip.Bottom:before {
  right: 65px;
}
.Popover-Item-Drivers.Multiple .Popover-Item-Driver-Tooltip.Bottom {
  left: 10px;
  right: 10px;
}
.Popover-Item-Driver-Actions .Button {
  padding: 0;
}
.Recent-Drivers-Empty {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 25px;
  color: #a5a5a5;
  font-size: 16px;
}
.Recent-Drivers-List .DriverBlock {
  border-bottom: 1px solid #e4e4e4;
}
.Recent-Drivers-List .DriverBlock:last-child {
  margin-bottom: 20px;
}

/*
 * Select Area
 */

.SelectCityBox .Box-Head .Logo img {
  width: 200px;
}
.SelectCityBox .SelectCity-Flag {
  padding: 0 30px 5px 30px;
}
.SelectCityBox .SelectCity-Flag > div {
  border: 2px solid transparent;
  border-radius: var(--radius);
  min-width: 123px;
}
.SelectCityBox .SelectCity-Flag > div:hover,
.SelectCityBox .SelectCity-Flag > div.Active {
  border-color: var(--color-green);
}
.SelectCityBox .SelectCity-Country {
  padding: 0 30px;
}
#root .SelectCityBox .SelectCity-Country .Active {
  color: var(--color-dark-green);
}
#root .SelectCityBox .SelectCity-Flag .Active span,
#root .SelectCityBox .SelectCity-Flag > div:hover span {
  color: var(--color-green);
}
.SelectCityBox .SelectCity-Country .SelectCity-Country-Flex {
  flex-wrap: wrap;
  margin: 0 -8px 0 0;
  max-height: 234px;
}
.SelectCityBox .SelectCity-Country .SelectCity-Country-Flex.Custom-Scroll {
  max-height: 150px;
}
.SelectCityBox .SelectCity-Country .SelectCity-Country-4 > div,
.SelectCityBox .SelectCity-Country .SelectCity-Country-Custom > div {
  min-width: 137px;
  width: 137px;
  box-sizing: border-box;
}
.SelectCityBox .SelectCity-Country .SelectCity-Country-3 > div {
  min-width: 173px;
  width: 173px;
  box-sizing: border-box;
}
.SelectCityBox .SelectCity-Country .SelectCity-Country-2 > div {
  min-width: 265px;
  width: 265px;
  box-sizing: border-box;
}
.closure-dnd {
  position: fixed;
}
.z-index-100012 {
  z-index: 100012;
}

.closure-dnd .Popup {
  align-self: center !important;
}
.Box-List-Item .closure-dnd a,
.Box-List-Item .closure-dnd button {
  margin-left: 0;
}
.customer-dnd-label i {
  position: relative;
  top: 6px;
}
/*
 * Place-Container
 */

.Place-Container {
  background-color: #fff;
  position: fixed;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  display: none;
}
.Place-Container.Active {
  display: block;
}
.Place-Item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}
.Place-Item-Query {
  font-size: var(--font-size-s);
  padding: 0 3px 0 5px;
}
.Place-Item:hover {
  background-color: #fafafa;
}
.Place-Item.pac-item-selected,
.Place-Item.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.Place-Icon {
  width: 15px;
  height: 20px;
  margin: 6px 2px 0 5px;
  display: inline-block;
  vertical-align: top;
}
.Place-Icon-Recent {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAFV3XwhAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAADgAAAAA/ucjWAAAB7UlEQVQ4EXWTv0tbURTH8x7PdHDpKAS1k9JVSgSd1PwgToLN0tKpEPBPqA6+Jzo5KE6Cg3EpHRzMEnxJaGnHQttNFJG2EBAhTiIoecnzc673PvIwXjice77f7zn33HPfSyRYmUxmSXz/ZWWz2UoYhq8s4XO53DrBS8uyWrVazbUBFgA62EfMolgYFYKclcA2SLfb9WXv5PP5RYLxer0+IClyimdUTzzknoCu69qob/oJlgWE/E/pCQ7ejIkgvmrBSrFYTBJ/MwKqfzB75aVtBM8PhQxfehE1+/1YtgkgPKp8wc60cMNw0UAABm3b3sKPafLWiNTAyZZ5njYajddC0NsnBjdF0gEPcCjnR90j/lEqlWSiKyLGq1urV+HJ1gSUxUvNE1cfo0SC/R91nAGMLxQKL4IgOCe+x75r/A3+IpVKvS+Xy3cai1zv5RRIG+/a7fYx/Y5y0jTgHBY4jpMFe9tsNn+JJqqgN08KkTwD9xkfMoQr/BF2Q/GKYHDbeNHEVuxqfB9pkieYgcfnN6QTowT5ihnqJbjLlH/7vv/TkKojXt5B8LfT6dgU2E0mk2mepcUVRoyQeBi7hpsUjf41/kmu0uhTTugmY5KMp3iVYqG2HdEaTrzkoDkRXBEIV3sFvXuuYSOU2Yj1XXxc3gOGmdT8qe/yKAAAAABJRU5ErkJggg==);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 0px 2px;
}
.SingleBookingLayout .BookingWizard .Content {
  z-index: 10001;
}
.Newbooking-Place-Handler .Place-Container {
  margin-top: 1px;
  box-shadow: none;
  border: 0 none;
}
.Newbooking-Place-Handler:not(.Multiple):not(.BatchEZ) .Place-Container {
  width: 390px !important;
  left: 10px;
}
.Newbooking-Place-Handler.Multiple .Place-Container {
  width: 335px !important;
  margin-left: -45px;
}
.Newbooking-Place-Handler.Multiple.Map .Place-Container {
  width: 450px !important;
}
.Newbooking-Place-Handler.BatchEZ.Map .Place-Container {
  margin-top: 3px;
  width: 450px !important;
}
.modal-batchUploadValidate .Newbooking-Place-Handler.BatchEZ.Map .Place-Container,
.MultipleBookingLayout-Area .Newbooking-Place-Handler.Multiple.Map .Place-Container {
  margin-left: -45px;
}
.modal-batchUploadValidate .Locations .Input-Icon ~ .Newbooking-Place-Handler.BatchEZ.Map .Place-Container,
.MultipleBookingLayout-Area .Locations .Input-Icon ~ .Newbooking-Place-Handler.Multiple.Map .Place-Container {
  margin-left: -81px;
}
.MultipleBookingLayout-Area .Newbooking-Place-Handler.Multiple .Place-Container,
.MultipleBookingLayout-Area .Locations .Input-Icon ~ .Newbooking-Place-Handler.Multiple .Place-Container {
  margin-left: -5px;
}
.Newbooking-Place-Handler .Place-Container .Place-Item {
  border: 0 none;
}
.popup-reload-customer-dnd {
  margin-left: 10px;
}

img.show-route-button {
  object-fit: contain;
}
div#show-route {
  position: absolute !important;
  top: 10px !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  height: 40px;
}
div#show-route:not(.Active):hover {
  background-color: rgb(235, 235, 235);
}
div.show-route-area {
  display: none;
}
.Discount-Message {
  box-shadow: -1px 0 21px -18px hsla(300, 2%, 51%, 0.69);
}
.Discount-Footer-Right {
  margin-left: -14px;
}
.Discount-Info {
  position: absolute;
  background-color: var(--color-white);
  z-index: -1;
  bottom: -35px;
  left: 12px;
  right: 12px;
  padding-top: 55px;
  text-align: center;
  padding-bottom: 5px;
  border-radius: 0 0 16px 16px;
}
.Discount-Footer-Mid span {
  color: yellow;
}
.Cancel-Reasons-Penalty .Button:hover {
  background-color: var(--color-dark-green) !important;
  border-color: var(--color-white);
  color: var(--color-white) !important;
}
.Cancel-Reasons-Penalty .Button {
  white-space: normal;
  padding: 10px 20px !important;
  min-height: var(--height);
  height: auto !important;
  color: var(--color-white) !important;
  border: 1px solid var(--color-white) !important;
  margin-top: 10px !important;
  font-size: 15px !important;
}
.confirm-export:hover {
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: var(--color-white);
}
.cancel-fee-bg {
  background-color: #f1f1f1;
  padding: 10px 15px 5px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.Pricing.block .block-item {
  font-size: 14px;
}
.Pricing.block .cancel-fee-bg .block-sub-item {
  padding-left: 0 !important;
}
.Pricing.block .cancel-fee-bg .block-sub-item span {
  background-color: #f1f1f1;
}
.driver-cancel-tooltip-arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  transform: rotate(45deg);
  bottom: -20px;
}
.driver-cancel-tooltip,
.driver-cancel-tooltip-arrow {
  display: none;
}
.driver-cancel-handler.Show .driver-cancel-tooltip,
.driver-cancel-handler.Show .driver-cancel-tooltip-arrow {
  display: block;
}
button.gray-bg-white-text {
  background: var(--color-gray-xl);
  color: var(--color-white);
}
button.Try-Again-Button,
.Button-Large {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Button.white.white-hover:hover {
  background: #ccc;
  border-color: #ccc;
}

/*
 * Input-MultipleLine
 */

.Input-MultipleLine {
  height: 56px;
  align-items: flex-start;
}
.Input-MultipleLine textarea {
  display: flex;
  flex: 1;
  height: inherit;
  border: none;
  background: none;
  outline: none;
  padding: 10px;
  font-size: var(--font-size);
  border-radius: var(--radius);
  box-sizing: border-box;
  resize: none;
}

/*
 * New style for icon services-vehicle
 */

.Services.block.list-view .block-item,
.Services-Block {
  padding: 11px 15px !important;
}
.Services.block .block-item,
.Vehicles.block .block-item,
.BookingWizard .Content .PickupTime-Custom .block-item,
.PickupTime.block .block-item,
.Border-Top-Color {
  border-color: #d8d8d8;
}
.Services.block.list-view .block-item .Services-Icon,
.Services-Block .Services-Icon,
.Services-Icon-Image {
  width: 75px !important;
  max-width: 75px;
  justify-content: flex-start;
}
.Services.block.list-view .Services-Icon img,
.Services-Block .Services-Icon img {
  width: auto;
  max-width: 75px;
  max-height: 27px;
}
.Services-List-Shadow:after {
  content: "";
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
}
#root .Services-List.block {
  position: absolute;
  left: 0;
  right: 0;
  top: 52px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin: -4px 0 0 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}
#root .MultipleBookingLayout .Services-List.block {
  max-height: calc(100vh - 300px - 198px);
}
#root .Services-List-Border:before {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-gray-xxl);
}
#root .Services-List.block .block-item:not(.selected):first-child {
  border-top: 1px solid var(--color-gray-xxl);
}
#root .Services-List.block .block-item:last-child {
  overflow: unset;
}
#root .Services-List .Vehicle-Service-Type-Popup-Eye {
  visibility: hidden;
  opacity: 0;
}
#root .Services-List .block-item:hover .Vehicle-Service-Type-Popup-Eye,
#root .Services-List .block-item.selected .Vehicle-Service-Type-Popup-Eye {
  visibility: visible;
  opacity: 1;
}
#root .Services-List .block-item:not(.selected):hover,
#root .Vehicles-List .block-item:not(.selected):hover,
#root .PickupTime-List .block-item:not(.selected):hover {
  background-color: #e6f2e8;
}
.Services-Icon-Arrow {
  margin-top: 2px;
}
.Services-Icon-Arrow.Up {
  margin-top: 0;
  transform: rotate(-180deg);
}
.ExtraServices.block .block-item,
.Badges.block .block-item {
  margin: 0 15px !important;
  padding: 10px 0 !important;
  border-bottom: 1px solid #d8d8d8;
}
.new-label-more-option {
  background-color: #fedb00;
  border: 1px solid #d2d451;
  font-size: 11px;
  margin-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
}
.MoreOptions .ExtraServices.block .block-item,
.MoreOptions .Badges.block .block-item {
  margin: 0 10px !important;
}
.BookingWizard .Content .MoreOptions .block,
.BookingWizard .Content .block-reset.block {
  box-shadow: none !important;
  margin: 10px 5px 0 5px !important;
}
.BookingWizard .Content #Step-02,
.BookingWizard .Content #Step-03 {
  padding-top: 0 !important;
}
.Default-TextArea-Placeholder textarea::placeholder,
.Default-TextArea-Placeholder textarea:-ms-input-placeholder,
.Default-TextArea-Placeholder textarea:-moz-placeholder,
.Default-TextArea-Placeholder textarea::-moz-placeholder,
.Default-TextArea-Placeholder textarea::-webkit-input-placeholder {
  color: var(--color-gray-xl);
}
.Location-Menu-Icon {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: move;
  width: 6px;
}
.Location-Menu-Icon span {
  width: 9px;
  height: 2px;
  background-color: #d8d8d8;
  margin-bottom: 2px;
  display: block;
}
.block-item:active .Location-Menu-Icon span {
  background-color: var(--color-yellow);
  width: 11px;
}
.Location-Menu-Icon span:last-child {
  margin-bottom: 0;
}
.LocationContacts .block-sub-item .Input input,
.Pricing.block .block-sub-item {
  font-size: 14px;
}

/*
 * My Locations
 */

.FormGroup-Line {
  height: 1px;
  background-color: rgb(206, 206, 206);
}
.Popover-contact-list-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.Popover-contact-list {
  border: 0 none;
  border-radius: 0 0 var(--radius-s) var(--radius-s);
  box-shadow: none;
  overflow-y: unset;
  overflow-x: unset;
}
.Popover-contact-list-item:not(.reset-padding) {
  align-items: flex-start;
  padding: 0;
}
.Popover-contact-item-info {
  z-index: 1;
}
.Popover-contact-item-edit {
  position: absolute;
  top: 5px;
  right: 10px;
}
.Popover-contact-item-button {
  position: absolute;
  bottom: 8px;
  left: 10px;
  right: 10px;
}
.Copy-Contacts-Header {
  border-radius: var(--radius) var(--radius) 0 0;
}
.Copy-Contacts-List > div {
  border-bottom: 1px solid var(--color-white);
}
.Copy-Contacts-List > div:last-child {
  border-bottom: 0 none;
}
.Select-Common select option:disabled {
  color: #e4e4e4;
}
.Selectbox-Contact-Actions {
  background: #f9f9f9;
  border-radius: 3px 3px 0 0;
  padding: 5px 10px 5px 8px;
  border-bottom: 1px solid #dadada;
  min-height: 45px;
  box-sizing: border-box;
}
.Selectbox-Contact-Actions__Filter .Search-Common {
  min-width: 30px;
  width: 100%;
  height: 34px;
  box-sizing: border-box;
}
.Selectbox-Contact-Actions__Filter .Search-Common input {
  padding-left: 5px;
}
.Selectbox-Contact-Actions__Filter .Select-Common select {
  height: 34px;
  border: 1px solid var(--color-gray-xl);
  min-width: 50px;
  border-radius: 5px;
  outline: 0;
}
.Empty-List-Location {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 165px;
}
.Verification {
  background-size: cover;
  background-position: bottom;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.Verification .VerificationBox {
  background: rgba(0, 0, 0, 0.6);
}
.VerificationBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.VerificationBox .Box {
  width: 632px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  align-self: center;
  padding: 10px;
  box-sizing: border-box;
}
.VerificationEditNumber .VerificationBox .Box {
  padding: 10px 30px;
}
.VerificationBox .Box-Head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-green);
  padding: 10px;
}
.VerificationBox .Box-Content {
  text-align: center;
}
.VerificationBox .Box-Head h3 {
  margin: 0;
  text-align: center;
  padding: 0;
}
.VerificationBox .Box-Actions {
  padding: 10px 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.VerificationBox-text {
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #444444;
  padding: 10px;
}
.VerificationBox-number {
  display: inline-block;
  position: relative;
}
.VerificationBox-number-box {
  display: flex;
  justify-content: center;
  max-width: 402px;
  margin: auto;
}
.VerificationBox-number-box span {
  width: 66px;
  height: 66px;
  border-radius: 5px;
  border: solid 3px #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.VerificationBox-number-box span + span {
  margin-left: 46px;
}
.VerificationBox-number input {
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.VerificationBox .Box-Actions button {
  flex: 1;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: none;
  font-weight: 500;
}
.VerificationBox .Box-Actions button + button {
  margin-left: 20px;
}
.VerificationBox-code {
  font-size: 40px;
  color: #4a4a4a;
}

/*
 * Modal ChooseAccount
 */
.Modal-ChooseAccount {
  width: 420px;
  top: 60px !important;
}
.Modal-ChooseAccount.Full {
  top: 0px !important;
}
.Modal-ChooseAccount .SelectSubAccountBox,
.Modal-ChooseAccount .SelectAccountBox {
  position: absolute;
  max-width: 90%;
  margin: auto;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box,
.Modal-ChooseAccount .SelectAccountBox .Box {
  background-color: var(--color-green);
}
.Modal-ChooseSubAccount .Modal-ChooseSubAccount-Content,
.Modal-ChooseAccount .Modal-ChooseAccount-Content {
  max-height: 80vh;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item.title,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item.title {
  padding: 8px 15px;
  background: #f1f1f1;
  color: #444;
  text-transform: capitalize;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item {
  padding: 15px;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item:hover,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item:hover,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item.Selected {
  background-color: #f9f9f9;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item .Avatar,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item .Avatar {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: solid 1px #a6a6a6;
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item.Selected .Avatar,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item.Selected .Avatar {
  border: solid 1px var(--color-green);
}
.Modal-ChooseAccount .SelectSubAccountBox .Box-List-Item .TitleSubtitle,
.Modal-ChooseAccount .SelectAccountBox .Box-List-Item .TitleSubtitle {
  padding: 0 15px 0 10px;
}

/*
 * Modal Confirm
 */
.modal-confirm-logout .Modal.visible {
  z-index: 11111;
}
.modal-confirm-logout .Box {
  width: 400px;
  margin: auto;
}
.modal-confirm-logout .Popup {
  align-self: center;
}

.hidden-button {
  visibility: hidden;
  width: 0;
}

/* Style for Select */
.custom-select {
  position: relative;
  height: inherit;
  font-size: 15px;
  color: #444444;
}
.custom-select select {
  display: none;
}
.select-selected {
  background-color: #f1f1f1;
  display: flex;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: 1px solid #dbdbdb;
  padding: 0px 10px;
  align-items: center;
  height: inherit;
  cursor: pointer;
}
.select-selected img {
  margin-left: auto;
  margin-left: 5px;
}
.select-items div {
  padding: 5px 15px;
  cursor: pointer;
  white-space: nowrap;
}
.select-items > div:hover {
  background-color: #f1f1f1;
}
.select-items {
  position: absolute;
  top: -9px;
  left: -2px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 10px 0px;
  z-index: 11111;
}
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.loading-spinner {
  width: 36px;
  height: inherit;
  margin-right: 5px;
  display: none;
}

.loading-spinner.visible {
  width: 36px;
  height: inherit;
  margin-right: 5px;
  display: block;
}
/* lds-spinner loading */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}
.lds-spinner div {
  transform-origin: 16px 18px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 6px;
  left: 15px;
  width: 2px;
  height: 5px;
  border-radius: 20%;
  background: var(--color-green);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* pagy_nav */
.pagy_nav {
  display: block;
  padding: 10px 5px;
  text-align: center;
}
.pagy_nav span:not(.gap) {
  font-size: 17px;
  width: 32px;
  height: 32px;
  text-decoration: none;
  border: 1px solid var(--color-gray-xxl);
  margin: 0 5px;
  color: var(--color-gray);
  border-radius: var(--radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
}
.pagy_nav span.current,
.pagy_nav span:not(.gap):hover {
  background: var(--color-green);
  border: 1px solid var(--color-green);
  color: var(--color-white);
  border-radius: var(--radius);
}
.pagy_nav span > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.pagy_nav span > a:hover,
.pagy_nav span > a:focus {
  color: var(--color-white);
}

/*
 * Block Booking Shopping
 */

.Block-Shopping {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 50px;
  bottom: 20px;
  right: 100px;
  left: 420px;
  background-color: #a1a1a1;
  margin: auto;
  align-items: center;
}
.Block-Shopping button {
  min-width: 120px;
  height: 30px;
}
.Block-Shopping button:hover {
  background-color: var(--color-white);
  color: #444444;
}
.Shopping-Scroll-Detail {
  width: calc(100vw - 450px);
}

/*
 * [Countdown-Remaining, Multiple-Line]
 */

.Multiple-Line {
  height: auto;
}
.Input-Hover-Group {
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  border-radius: 0 var(--radius) var(--radius) 0;
  padding: 0 0 0 10px;
  background-color: var(--color-white);
}
.Multiple-Line.Light-Gray-bg .Input-Hover-Group {
  background-color: #efefef;
}
.Multiple-Line .Multiple-Line__Input textarea {
  transition: 350ms ease 0s;
}
.Countdown-Remaining .Countdown-Remaining__Input {
  border: 1px solid var(--color-gray-xl);
  background: var(--color-white);
}
.Countdown-Remaining .Countdown-Remaining__Input textarea,
.Multiple-Line .Multiple-Line__Input textarea {
  height: inherit;
  border: none;
  background: none;
  outline: none;
}
.Countdown-Remaining .Countdown-Remaining__Info,
.Multiple-Line .Multiple-Line__Info {
  background-color: #f1f1f1;
}

/* Require-Sinatures */
.Require-Sinatures {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
}
.Require-Sinatures .badge {
  padding: 1px 6px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 3px;
  margin-right: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffdb00;
}
.Require-Sinatures label {
  flex: 1;
  overflow: hidden;
  padding-right: 10px;
}
.Require-Sinatures label .TitleSubtitle-title {
  padding: 1px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.Signatures-Image.Not-Require-Signatures-Image-Popup {
  border: none;
  background: none;
}
.Not-Require-Signatures-Image-Popup span {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.Signatures-Image.Not-Require-Signatures-Image {
  border: none;
}

.Address-field {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  max-height: 55px;
  margin-top: -4px !important;
}

.BatchUploadOptions .Modal-Addlocation-Info .Input.Address-input {
  height: auto;
}

.BatchUploadOptions .Modal-Addlocation-Info .Input .pick-up-address {
  width: 100%;
}

.BatchUploadOptions .Modal-Addlocation-Info .Input .pick-up-address textarea {
  width: calc(100% - 20px);
}

.Step-AutoComplete-Pickup .pac-item {
  padding-left: 35px;
  position: relative;
}
.Step-AutoComplete-Pickup .pac-item > span {
  display: block;
}
.Step-AutoComplete-Pickup .pac-item .pac-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.Step-AutoComplete-Pickup .pac-item .pac-item-query {
  margin-bottom: -10px;
}
.address.FormGroup .Input {
  height: auto;
}

.address.FormGroup .Input .add-location-address textarea {
  width: 356px;
  margin-top: 9px;
}
.me-prefix {
  display: none;
  color: #444;
  margin-left: 10px;
  margin-right: 5px;
  font-size: 14px;
}
.me-prefix-container {
  display: flex;
}
.me-prefix-container .me-prefix {
  display: inline-block;
}
.me-prefix-container input {
  padding: 0;
  width: 100%;
}
.Long-Haul-Invalid-Date-Popup {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 15px;
  z-index: 99999;
  background-color: #726132;
  width: calc(100% - 30px);
}
.Long-Haul-Invalid-Date-Popup img {
  height: 25px;
}
.Long-Haul-Invalid-Date-Popup p {
  color: #ffffff;
  margin: 0 0 0 15px;
  font-weight: 500;
  word-break: break-word;
}
.Pricing-LongHaul.New-Pricing-LongHaul .fixed-price-icon {
  height: 16px;
}
.CodPod.Popover .Popover-SubItem .Input {
  margin-left: 0;
}
.button-shipping-courier {
  padding: 5px 12px;
  border-radius: 6px;
  margin-left: 15px;
  border: 1px dashed #9c9c9c;
}
.tracking-booking-top-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 450px;
  margin: auto;
  right: 0;
  display: inline-grid;
}
.tracking-booking-top-panel > .grid > .column {
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.tracking-booking-color-routes {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  /* background-color: #e3cf06; */
  background-color: #a1a1a1;
}
.saver-tracking-booking-color-routes {
  width: 456px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.saver-tracking-booking-color-routes .saver-text {
  color: #282828;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.tracking-booking-top-panel > .grid > .column p {
  font-weight: 300;
  margin: 0px;
  line-height: 30px;
  font-size: 15px;
  white-space: nowrap;
}
.tracking-booking-color-routes p {
  color: var(--color-yellow);
  padding: 10px;
}
.top-up-popup {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-white);
  border-radius: var(--radius);
  padding: 15px;
  box-shadow: 0px 0px 4px #777;
  margin-top: 20px;
}
.top-up-popup.visible {
  display: block;
}
.top-up-popup:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: -8px;
  right: 60px;
}
.top-up-popup p {
  margin: 0 0 15px 0;
}
.Popup-Event .Overlay-Lightbox-Image img {
  max-width: 640px;
}

.docs-cod-modal-content {
  overflow-y: auto;
}

.docs-cod-modal-content ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -webkit-appearance: none;
  border-radius: 0 !important;
}

.docs-cod-modal-content ::-webkit-scrollbar-track {
  background-color: #d7edd7;
  border-radius: 0px;
}

.docs-cod-modal-content .Custom-Scroll-Group::-webkit-scrollbar-track {
  border-radius: 0 0 5px 0;
}

.docs-cod-modal-content ::-webkit-scrollbar-thumb {
  background-color: #0e730f;
  border-radius: 0px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.docs-cod-modal-content .not-requested-content {
  text-align: center;
  margin: 180px 0;
  font-size: 18px;
  font-style: italic;
  opacity: 0.8;
}

.docs-cod-modal-content .Popup-Booking-Normal .title {
  margin-bottom: 35px;
}

.docs-cod-modal-content .Box.w620 {
  width: 620px !important;
  height: 580px;
}

.docs-cod-modal-content .Box.w620 .Box-Content {
  margin: 0;
}

.docs-cod-modal-content .Box-Icon-Plate {
  background: none !important;
  font-size: 16px;
  color: #ffdb00;
  bottom: -60px;
}

.docs-cod-modal-content .PageHead-tabs {
  justify-content: center;
}

.docs-cod-modal-content .PageHead-tabs .item {
  width: 180px;
  border-radius: 6px 6px 0 0;
  height: 20px;
  background: #fff;
  text-align: center;
  padding: 10px;
}

.docs-cod-modal-content .PageHead-tabs .item a {
  font-size: 16px;
  text-align: center;
  color: #0e730f;
}

.docs-cod-modal-content .PageHead-tabs .item.selected {
  background: #0e730f;
  color: #fff;
  height: 20px;
  border-radius: 6px 6px 0 0;
  font-size: 16px;
  width: 180px;
  padding: 15px;
}

.docs-cod-modal-content .PageHead-tabs .item.selected a {
  color: #fff;
}

.docs-cod-modal-content .Custom-Scroll-Group {
  background: #0e730f;
}

.docs-cod-modal-content .Box-Content {
  padding: 0;
}

.pod-content.tab-content {
  padding: 20px;
}

.pod-content.tab-content .AddressItem {
  padding: 15px;
  min-height: auto;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

.pod-content.tab-content .Dark-Green-bg {
  height: 53px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 16px !important;
  font-style: italic;
  padding: 0 15px;
}

.tab-content .AddressItem .AddressContact-Address {
  font-size: 16px !important;
}

.pod-content.tab-content .AddressItem .AddressContact-Address .none-content {
  font-size: 14px !important;
  font-style: italic;
  opacity: 0.8;
}

.pod-content.tab-content .default-font.White-text {
  font-size: 14px !important;
  font-style: italic;
  opacity: 0.8;
}

.pod-content.tab-content .AddressItem .AddressContact {
  width: 100%;
}

.pod-content.tab-content .AddressItem .AddressContact hr {
  height: 1px;
  background-color: #0e730f;
  border: 0 !important;
}

.cod-content.tab-content {
  padding: 20px;
}

.cod-content.tab-content .AddressItem {
  padding: 15px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

.tab-content .AddressItem {
  position: relative;
}

.tab-content .AddressItem:first-child {
  margin-top: 0;
}

.tab-content .AddressItem::after {
  content: "";
  height: 15px;
  width: 2px;
  position: absolute;
  bottom: -15px;
  left: 28px;
  background-color: rgba(0, 0, 0, 0.15);
}

.tab-content .AddressItem:last-child::after {
  display: none;
}

.cod-content.tab-content .AddressItem .AddressContact {
  width: 100%;
}

.cod-content.tab-content .AddressItem .AddressContact hr {
  height: 1px;
  background-color: #0e730f;
  border: 0 !important;
}

.docs-cod-modal-content .Custom-Scroll-Group {
  height: 435px;
  border-radius: 0 0 5px 5px;
}

.tab-content .AddressContact-Address .note-content {
  font-size: 14px;
  font-style: normal;
  max-height: 82px;
  overflow-y: auto;
}

.cod-content.tab-content .AddressContact-Address .none-content {
  font-size: 14px;
  opacity: 0.8;
  font-style: italic;
}

.pod-content.tab-content .flex-index {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
}

.pod-content.tab-content .flex-index .flex-1 {
  display: flex;
  flex: 1;
  align-items: center;
}

.pod-content.tab-content .flex-index .flex-1 .waiting {
  opacity: 0.8;
  font-style: italic;
}

.pod-content.tab-content .flex-index .flex-2 {
  font-size: 12px;
  max-width: 150px;
  float: right;
}

.pod-content.tab-content .flex-index .button-shipping-courier {
  right: 0px;
  align-items: flex-end;
  display: flex;
  font-style: normal;
  padding: 5px 10px;
  opacity: 0.8;
}

.pod-content.tab-content .flex-index .image {
  width: 28px;
  height: 23px;
  margin-right: 10px;
}

.pod-content.tab-content .flex-index .default-font {
  margin-right: 4px;
  font-style: normal;
  font-size: 16px !important;
  opacity: 1;
}

.docs-cod-modal-content .Popup {
  align-self: center !important;
}

.docs-cod-modal-content .b.Pin .uppercase {
  font-size: 14px;
}

.docs-cod-modal-content .b.Pin {
  width: 28px;
  height: 28px;
}

.booking-edit-mode__container {
  width: 100%;
  overflow: hidden;
}

.booking-edit-mode_block {
  display: flex;
  width: 840px;
  transform: translateX(0);
  transition: transform 1s;
}
.booking-edit-mode_block.edit-mode__animation {
  transform: translateX(-420px);
}
.booking-edit-mode {
  width: 420px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  color: #282828;
  background-color: #ffdb00;
  box-sizing: border-box;
}

.booking-edit-mode a {
  text-decoration: underline;
}

.booking-edit-mode.error {
  background-color: #f04433;
  color: #ffffff;
}

.Ceb-disable-field.Hidden-bg,
.Ceb-disable-field .Services-Icon.Services-Icon-Image,
.Vehicles.block.Ceb-disable-field .block-item,
.Vehicles.block.Ceb-disable-field .block-item:hover,
.Vehicles.block.Ceb-disable-field .block-item label:hover {
  cursor: default !important;
}

.Vehicles.block.Ceb-disable-field .block-item:not(.selected):hover {
  background-color: transparent !important;
}

.Vehicles.block.Ceb-disable-field .Hidden-bg.block-item:not(.selected):hover {
  background-color: #a0a0a0 !important;
}

.Ceb-disable-field.Disable-checkbox-overlay {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  z-index: 100;
}

#CebChangeConfirmedPopup .Button.gray,
.Ceb-Reconfirm-Popup .Button.gray {
  background: var(--color-gray);
  color: white;
}

#CebChangeConfirmedPopup .Normal-Booking .Box,
.Ceb-Reconfirm-Popup.Normal-Booking .Box {
  border-radius: 16px;
}
span.message-error.icon-error {
  display: flex;
  align-items: center;
}
span.message-error.icon-error:before {
  content: "error";
  font-size: 20px;
  font-family: "Material Icons", sans-serif;
  padding-right: 10px;
}
.Input.Input-Overlay.error:after {
  content: "warning";
  font-size: 20px;
  font-family: "Material Icons", sans-serif;
  color: red;
  padding-right: 10px;
}
.Input.ml5.mr5.Multiple-Line.Default-TextArea-Placeholder.error:after {
  font-family: "Material Icons", sans-serif;
  content: "error";
  font-size: 20px;
  color: red;
  padding-right: 10px;
}
.tooltip__content {
  position: absolute;
  bottom: calc(50% - 29px);
  left: 100%;
}
.tooltip__text {
  position: relative;
  background-color: #282828;
  color: white;
  width: 250px;
  padding: 10px;
  border-radius: 2px;
  margin-left: 2px;
}
.tooltip__text:after {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  right: 100%;
  display: block;
  border: 6px solid transparent;
  border-right-color: #282828;
}

.Modal.company-approved-modal-content.visible {
  align-items: center;
}

.company-approved-modal-content .Popup-Booking-Normal h5 {
  margin: 10px 0 5px;
}

.mark-company-approved {
  width: 5px;
  height: 5px;
  border: solid 0.8px #cdb000;
  border-radius: 3px;
  background-color: #ffdb00;
}

.company-approved-modal-content .Box-Footer {
  margin-top: 5px;
}

.Box-Icon .Logo img.bp-icon {
  border-radius: 25px;
  width: auto;
  height: 60px;
}

.color-banner-multi__blue {
  padding: 0 20px;
  height: 56px;
  width: 325px;
  background-color: #00adf5;
  display: flex;
  align-items: center;
  top: 0;
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100002;
}
.color-banner-multi__green {
  padding: 0 20px;
  height: 56px;
  width: 325px;
  background-color: #3fae29;
  display: flex;
  align-items: center;
  top: 0;
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100002;
}
.color-banner-multi__purple {
  padding: 0 20px;
  height: 56px;
  width: 325px;
  background-color: #7521d3;
  display: flex;
  align-items: center;
  top: 0;
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100002;
}
.color-banner-multi__brown {
  padding: 0 20px;
  height: 56px;
  width: 325px;
  background-color: #726132;
  display: flex;
  align-items: center;
  top: 0;
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100002;
}
.default-timetype-text {
  height: 36px;
  font-family: var(--font-family-base);
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  word-break: break-all;
  white-space: pre-line;
}
.color-banner__blue {
  padding: 0 20px;
  height: 56px;
  top: 0px;
  z-index: 100001;
  background-color: #00adf5;
  display: flex;
  align-items: center;
}
.color-banner__green {
  padding: 0 20px;
  height: 56px;
  top: 0px;
  z-index: 100001;
  background-color: #3fae29;
  display: flex;
  align-items: center;
}
.color-banner__purple {
  padding: 0 20px;
  height: 56px;
  top: 0px;
  z-index: 100001;
  background-color: #7521d3;
  display: flex;
  align-items: center;
}
.color-banner__brown {
  padding: 0 20px;
  height: 56px;
  top: 0px;
  z-index: 100001;
  background-color: #726132;
  display: flex;
  align-items: center;
}
.icon-image {
  width: 22px;
  padding-right: 10px;
}
.icon-image img {
  width: 100%;
}
.BookingWizard .card-step-1 {
  position: relative;
}
.MultipleBookingLayout-Area .BookingWizard .card-step-1 {
  position: relative;
}
.line-clamp-3 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-2 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* popup other reimbursement */
.other-reimbursement .Modal-FromGroup {
  align-items: unset;
}
.other-reimbursement__left {
  flex: 1;
}
.other-reimbursement__input.Modal-FromGroup {
  display: block;
}
.other-reimbursement__input.Modal-FromGroup span.message-error {
  color: var(--color-red) !important;
  font-size: var(--font-size-s);
}

.DetailBooking-Price-Noted {
  display: block;
}

.DetailBooking-button {
  display: flex;
  justify-content: space-between;
}
.Warning-Text {
  color: var(--color-red);
}
.Green-Text {
  color: #02751a;
}
.LowerCase {
  line-height: 1.75rem;
  text-transform: lowercase;
}
.badge-status {
  font-size: 14px;
  color: var(--color-white);
  margin-left: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 400;
}
.badge-status--completed {
  background: var(--color-green);
}
.badge-status--canceled {
  background: var(--color-red);
}
.button-custom-reimbursement {
  display: inline-flex;
}
.Button.active,
.Button.Selected:hover {
  background: transparent;
  opacity: 0.9;
}
.slot-time.Dark-Green-bg {
  align-items: center;
  display: flex;
}
.slot-time.Dark-Green-bg:before {
  content: "check_circle_outline";
  font-family: "Material Icons", sans-serif;
  padding-right: 5px;
}
.available-pickup-slot {
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 10px;
  max-width: max-content;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.7);
}
.p-static {
  position: static;
}
.color-inherit {
  color: inherit;
}
.border-bottom-driver-selected {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 10px;
  padding-right: 0;
  margin-top: 10px;
}

.mr10px {
  margin-right: 10px;
}
.mb10px {
  margin-bottom: 20px;
}
.mb20px {
  margin-bottom: 20px;
}
.btn {
  display: inline-block;
  height: 38px;
  background-color: white;
  color: #282828;
  border: 1px solid #cecece;
  border-radius: 5px;
  font-size: 18px;
}
.w120px {
  width: 120px;
}
.form-control {
  display: block;
  width: 100px;
  height: 35.5px;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 0 10px;
}
.label__add {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.Block-MiniTooltip {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 50px;
  bottom: 20px;
  background-color: #a1a1a1;
  margin: auto;
  align-items: center;
  z-index: 11;
  left: calc(50% + 210px);
  transform: translateX(-50%);
  min-width: 360px;
}
.input-group {
  display: flex;
}
.input-group .form-control {
  flex: 1;
}
.form-control::-webkit-input-placeholder {
  color: #a1a1a1 !important;
}
.form-control:-moz-placeholder {
  color: #a1a1a1 !important;
}
.form-control:-moz-placeholder {
  color: #a1a1a1 !important;
}
.form-control:-ms-input-placeholder {
  color: #a1a1a1 !important;
}
.form-group .control-label {
  width: 27px;
  height: 18px;
  margin: 0 433px 5px 0;
  font-family: var(--font-family-base);
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
}
.form__content {
  padding: 20px;
  box-sizing: border-box;
}
.form__actions {
  padding: 10px 20px;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.schedules-title {
  display: block;
  margin-bottom: 16px;
  font-weight: 500;
}
.schedules {
  display: flex;
}
.schedule {
  display: flex;
  margin-right: 25px;
}
.schedule > .schedule__radio {
  display: none;
}
.schedule > .schedule__radio + .schedule__box {
  display: grid;
  place-items: center;
  height: 20px;
  width: 20px;
  border: 1px solid #cecece;
  border-radius: 50%;
}
.schedule > .schedule__radio + .schedule__box:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3fae29;
  opacity: 0;
}
.schedule > .schedule__radio:checked + .schedule__box {
  border-color: #3fae29;
}
.schedule > .schedule__radio:checked + .schedule__box:after {
  opacity: 1;
}
.schedule > .schedule__radio + .schedule__box + .schedule__label {
  margin-left: 10px;
}
.schedule > .schedule__radio + .schedule__box + .schedule__label .schedule__name {
  color: #282828;
}
.schedule .schedule__help {
  color: #a1a1a1;
  font-size: 12px;
  font-style: italic;
}
.schedule .schedule__help p {
  margin: 0;
}

.schedule .schedule__input {
  margin-left: 10px;
  position: relative;
}
.schedule__customday {
  width: 125px;
}
.schedule__input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.schedule__input input[type="number"] {
  -moz-appearance: textfield;
}
.button-export {
  justify-content: space-between;
}
.analytics-select {
  position: relative;
}
.analytics-select select {
  cursor: pointer;
  padding-right: 34px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.analytics-select img {
  pointer-events: none;
  position: absolute;
  right: 9px;
}
.schedule .schedule__input input {
  display: block;
  width: 100px;
  height: 38px;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding-right: 50px;
  box-sizing: border-box;
}
#calcTextWidthDiv {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
}
.schedule__placeholder {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  display: flex;
  align-items: center;
  color: #a1a1a1;
  font-style: italic;
}

.emails-container {
  background-color: #f6f8fa;
}
.emails__header-prefix {
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 0;
  margin: 0 20px;
}
.emails__header {
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 20px;
}
.emails__body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  padding: 0 20px;
}
.emails__body::-webkit-scrollbar {
  width: 5px;
}
.emails__body::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #0e730f;
}
.emails__body::-webkit-scrollbar-track {
  background-color: #d7edd7;
}
.emails__title {
  font-weight: 500;
}
.email {
  display: flex;
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 0;
  height: 40.75px;
  width: 540px;
  align-items: center;
}
.emails__body .email:last-child {
  border: none;
}
.email__content {
  flex: 1;
}
.email__name {
  margin-bottom: 7px;
  color: #444;
}
.email__desc {
  font-size: 12px;
  font-style: italic;
  color: #a1a1a1;
}
.toolbar {
  position: relative;
}
.toolbar__body {
  position: absolute;
  top: 100%;
  right: 0;
}
.toolbar__content {
  display: none;
  align-items: center;
  background-color: white;
  border: 1px solid #cecece;
  padding: 8px;
  border-radius: 2px;
  font-weight: 500;
}
.icon__remove {
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  object-fit: contain;
}
.label__remove {
  width: 55px;
  height: 18px;
  margin: 0 0 0 5px;
  font-family: var(--font-family-base);
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
}
.toolbar:hover .toolbar__header {
  cursor: pointer;
}
.toolbar:hover .toolbar__content {
  display: flex;
}
.email:hover .toolbar__header {
  color: #00b200;
}
.email:hover .email__toolbar {
  color: #00b200;
}
.toolbar__header:hover img {
  filter: invert(38%) sepia(73%) saturate(1530%) hue-rotate(91deg) brightness(101%) contrast(103%);
}
.Modal-Basic.Modal-Automatic-Report .Box {
  max-height: 88%;
}

.BookingWizard .Content .location-dragging {
  margin-left: -5px;
  margin-right: -5px;
  padding-right: 5px;
  padding-left: 5px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}

.modal-batchUploadValidate .Box-Content .Locations .location-dragging {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
}

.Popup-Booking-Custom .ws-pre {
  white-space: pre-line;
}

.DetailBooking-FormGroup p {
  white-space: pre-line;
}

.Input-Icon .active-eye-icon {
  color: var(--color-green);
}

.Button.Button-Default.Button-With-Border.DetailBooking-DownloadButton {
  width: 165px;
}
.DetailBooking-List--BtnShowOptions {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.DetailBooking-List--DisplayOptions {
  position: absolute;
  top: 40px;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 4px 9px 0 rgb(0 0 0 / 30%);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  z-index: 999;
  display: none;
}
.DetailBooking-List--DisplayOptions a {
  display: inline-flex;
  cursor: pointer;
  font-size: var(--font-size);
  font-family: var(--font-family-base);
}

.DetailBooking-List--DisplayOptions .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: -8px;
  left: 15px;
  width: 3px;
  height: 12px;
  border-radius: 20%;
  background: #3fae29;
}
.DetailBooking-List--DisplayOptions .download-middle-text {
  color: #808080;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}
.DetailBooking-List--DisplayOptions .download-info-text {
  text-align: center;
  color: #808080;
  font-size: 10px;
  margin: 0;
  padding: 0 15px;
}
.DetailBooking-List--DisplayOptions > div:first-child {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 10px 0 20px;
}
.DetailBooking-List--BtnShowOptions.options .DetailBooking-List--DisplayOptions.select-option {
  display: block;
  width: 180px;
  padding: 10px;
}
.DetailBooking-List--BtnShowOptions.loading .DetailBooking-List--DisplayOptions.downloading-excel {
  display: block;
  padding: 33px 0;
}
.DetailBooking-List--BtnShowOptions.select-failed .DetailBooking-List--DisplayOptions.download-select-failed {
  display: block;
  padding: 24px 0 10px;
}
.DetailBooking-List--DisplayOptions .download-failed-button-group {
  margin-top: 24px;
}

.download-failed-button-group {
  width: 100%;
}

.Button.Button-Default.Button-With-Border.cancelBtn {
  width: 117px;
  height: 30px;
  border: solid 1px #a1a1a1;
  color: #a1a1a1;
  font-family: var(--font-family-base);
  font-size: 13px;
  font-weight: 500;
  min-width: 117px;
}

.Button.Button-Default.Button-With-Border.green-text.green-border.tryAgainBtn {
  width: 118px;
  height: 30px;
  background-color: #3fae29;
  color: #ffffff;
  font-family: var(--font-family-base);
  font-size: 13px;
  font-weight: 500;
  min-width: 118px;
}

.asset-xls {
  width: 13px;
  height: 18px;
  margin: 0 5px 0 0;
}

.dlvr-division {
  border-right: 1px solid var(--color-white);
  height: calc(100% - 25px);
  position: absolute;
  top: 24px;
  right: 11px;
}

.dlvr-left-side {
  position: relative;
}

.dlvr-right-side {
  padding-left: 15px;
}

.dlvr-right-side .AddressContact-Address {
  padding-top: 5px;
}

.dlvr-vehicle-info {
  padding-top: 15px;
  display: flex;
}

.dlvr-vehicle-image {
  display: flex;
  margin-right: 15px;
  background: var(--color-white);
  justify-content: center;
  align-items: center;
}

.dlvr-vehicle-image .vehicle-image-container {
  margin: 10px;
}

.dlvr-vehicle-image .vehicle-image-container .vehicle-image {
  height: auto;
  width: 50px;
}

.dlvr-driver-info .dlvr-driver-image .driver-image {
  position: relative;
  height: 20px;
  width: 20px;
  top: 3px;
  border-radius: 10px;
}

.dlvr-driver-info .dlvr-driver-image .driver-name {
  padding-left: 10px;
}

.dlvr-driver-info .dlvr-vehicle-make-model {
  padding-top: 5px;
}

.dlvr-driver-info .dlvr-driver-image {
  padding-top: 5px;
}

.dlvr-click-dismiss {
  text-align: center;
  color: var(--color-gray-xxl);
  font-size: 13px;
}

.Overlay-RadioBox .qc-name-text {
  color: #282828;
  font-weight: 500;
}

.Overlay-RadioBox .qc-name-active {
  color: var(--color-dark-green);
  font-weight: 500;
}

.dlvr-overlay-radio-box label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #a1a1a1;
  border-color: #a1a1a1;
  border-radius: 50%;
  left: unset;
  transform: translateY(-50%);
}

.dlvr-overlay-radio-box.White-RadioBox input[type="radio"]:checked + label:before {
  border-color: #02751a;
  background-color: red;
}

.dlvr-overlay-radio-box input[type="radio"]:checked + label i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  left: unset;
  line-height: 12px;
  text-align: center;
  width: 12px;
  font-size: 0 !important;
  border-radius: 50%;
  background-color: var(--color-green);
}

.dlvr-overlay-radio-box input[type="radio"]:checked + label:before {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-color: #02751a;
}

.empl-actions .actions-icon-hover {
  display: none;
}

.empl-actions:hover .actions-icon-hover {
  display: inline;
}

.empl-actions:hover .actions-icon {
  display: none;
}

.employ-actions {
  text-align: center !important;
}

.pending-font-color {
  color: #a1a1a1;
}

.dlvr-visible {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100011;
}

.optional-notes {
  display: block !important;
  height: auto;
}

.optional-notes input {
  height: 36px;
  width: calc(100% - 20px);
}

.Input.disabled.Poiter {
  pointer-events: all;
}

.dlvr-scroll-bar {
  overflow-x: hidden;
  overflow-y: auto;
}

.change-position-chatbox {
  right: 395px !important;
}

.dlvr-no-signal {
  position: absolute;
  width: 35px;
  height: auto;
  left: 12px;
  top: 14px;
}

.dlvr-error-text {
  color: var(--color-red) !important;
  display: block;
  margin-top: 5px;
  font-size: var(--font-size-s);
}

.box-merge {
  box-sizing: border-box;
  align-self: start !important;
}

.MergeBox {
  max-height: 100vh;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  right: unset !important;
  bottom: unset !important;
}
.custom-remarks {
  display: flex;
}
.custom-remarks span {
  font-size: 14px;
  color: #444444;
}
.custom-remarks img {
  width: 15px;
  margin-right: 5px;
}
.dlvr-area-error-text {
  color: var(--color-red) !important;
  display: block;
  margin-top: 5px;
  font-size: var(--font-size-s);
  padding-left: 103px;
}

.dlvr-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dlvr-address-input {
  width: 450px;
}

.dlvr-error-input-border {
  border: 2px solid var(--color-red) !important;
  background-color: #feedeb;
}
.dlvr-error-input-border .Input-Hover-Group {
  background-color: #feedeb;
}

.dlvr-hyper-link {
  text-decoration: revert;
  color: var(--color-hyperlink-green);
  font-size: 13px !important;
  cursor: pointer;
}

.dlvr-hyper-link:hover {
  color: var(--color-white) !important;
}

.dlvr-ic-err-code {
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.sub-account {
  margin: 0 15px;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.align-items-center {
  align-items: center;
}

.sub-account-tag {
  margin: 15px 0px 0px 0px;
  display: flex;
  position: relative;
}

.sub-account-tag label {
  flex: 1;
  overflow: hidden;
}
.borderb {
  border-bottom: 1px solid #d8d8d8;
}

.sub-account-filter {
  padding: 4px 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #d8d8d8;
}

.sub-account-filter-ezsheet {
  padding: 3px 15px 4px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}

.search-icon {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #a9a9a9;
  background-color: #fff;
}
.search-icon i {
  /* vertical-align:middle; */
  font-size: 14px;
}

.sub-account-tag-select {
  height: 30px;
  padding: 0px 8px;
  background: var(--color-white);
  border: 1px solid #a9a9a9;
  outline: none;
}

.sub-account-tag-select > option:hover {
  padding: 5px;
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

.sub-account-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--color-white);
  overflow: auto;
  max-height: 300px;
}
.sub-account-ul li {
  min-height: 45px;
}
.sub-account-ul .active-cell {
  background-color: var(--color-gray-xxxl);
}
.sub-account-ul li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.sub-account-ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0 none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.sub-account-ul li > div {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.sub-account-ul li {
  border-bottom: 1px solid #e8e8e8;
}

.sub-account-ul li:last-child > div {
  border-bottom: none;
}

.Overlay-RadioBox .sa-name-text {
  color: #282828;
  font-weight: 500;
  font-size: 15px;
}

.sub-account-ul::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-green);
}

.ez-sheet-tooltip {
  position: relative !important;
}

.sub-account-tooltip-content {
  position: absolute;
  background-color: #282828;
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  padding: 12px 14px;
  border-radius: 6px;
  width: 240px;
  z-index: 10;
}

.sub-account-tooltip-content:before {
  content: "";
  position: absolute;
}

.sub-account-tooltip-content.bottom {
  top: 30px;
  /* left: 40.5%; */
}

.sub-account-tooltip-content.bottom .multi-modal {
  left: 50%;
}

.sub-account-tooltip-content.bottom:before {
  bottom: 100%;
  left: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.ez-sheet-width-icon {
  height: var(--font-size);
}

.sub-account-tooltip .sub-account-tooltip-content {
  width: 180px;
}

.popup-price {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-price-thailand .sub-account-tooltip-content.bottom {
  left: 35%;
}

.popup-price-thailand .sub-account-tooltip-content.bottom:before {
  left: 40% !important;
}

.flex-shrink0 {
  flex-shrink: 0;
}
.dlvr-err-background {
  background: rgba(244, 67, 54, 0.5);
}

.dlvr-fd-megazone-title {
  font-size: 16px !important;
  font-weight: bold;
  padding-bottom: 10px;
  color: #fff;
}

.dlvr-osa-text-tooltip {
  font-size: 13px !important;
  font-weight: 500;
}

.dlvr-osa-link-tooltip {
  font-size: 13px !important;
}

.sub-account-select select {
  width: 130px;
  font-size: var(--font-size);
  font-weight: 400;
  -webkit-appearance: none;
  background-position: right;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAOBAMAAAARcxsoAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAbUExURUdwTEREREVFRURERE5OTkREREpKSkVFRURERA7Rh6EAAAAJdFJOUwD/X+cNiiaee+VSiL8AAAAySURBVAjXY2DgYAACRQUGBhZDEwYGVkFRBoZEQcEEhkJBwQIGBABzwRJgJWDFYG0gAwDBuwWHOQ4T3QAAAABJRU5ErkJggg==);
}

.min-height41 {
  min-height: 41px;
  height: unset !important;
}
.min-height45 {
  min-height: 45px;
  height: unset !important;
}

.dlvr-osa-text {
  margin-top: 0;
  margin-left: 38px;
  margin-bottom: 5px;
  color: var(--color-red);
  display: block;
  font-size: var(--font-size);
}

.modal-sub-account .visible {
  width: 100% !important;
}

.modal-sub-account .Booking-Modal .dlvr-normal-booking {
  padding: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.modal-sub-account button {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #a9a9a9 !important;
  background-color: #fff !important;
}

.modal-sub-account button:hover {
  background-color: #fff !important;
}
.dlvr-float-right {
  display: flex;
  justify-content: flex-end;
}
.dlvr-lh-gray-icon {
  opacity: 0.5;
}
#calcTextWidthInput:focus-visible {
  outline: none;
}
.input-automatic-report {
  outline: none;
}
.wrap-sub-account-card-booking .TitleSubtitle-title {
  font-size: 12px !important;
}

.wrap-sub-account-booking-detail .TitleSubtitle-title {
  font-size: 13px !important;
}
.Batch-Custom td.actions .cell .Actions-Button {
  z-index: 9999999999;
}
.input-automatic-report-err {
  background-color: rgba(240, 68, 51, 0.1);
}
#toast-container.toast-social-login.toast-top-center > div {
  width: 480px;
}
#toast-container.step3-social-login.toast-top-center > div {
  width: 480px;
  left: 0;
  right: 0;
  top: 0;
}
.Locations .tooltip-error-distance,
.dlvr-tooltip-error-distance {
  width: 321px !important;
  height: 91px !important;
  padding: 18px 18px 18px 15px !important;
  border-radius: 6px !important;
  background-color: #41ad29 !important;
  box-sizing: border-box;
}

.dlvr-tooltip-error-fd-megazone {
  width: 326px !important;
  height: 121px !important;
  border-radius: 6px !important;
  padding: 18px 18px 18px 15px !important;
  background-color: #41ad29 !important;
  box-sizing: border-box;
}

.dlvr-tooltip-error-osa {
  width: 321px !important;
  height: 75px !important;
  border-radius: 6px !important;
  padding: 15px 10px !important;
  background-color: #3fae29 !important;
  box-sizing: border-box;
}

.tooltip-error-distance .Popover-Item,
.dlvr-tooltip-error-distance .Popover-Item,
.dlvr-tooltip-error-fd-megazone .Popover-Item {
  line-height: 1.4;
  padding: 0 !important;
}

.multiple-select-wrapper {
  position: relative;
  display: flex;
  height: 38px;
  flex-wrap: wrap;
  width: 180px;
}

.multiple-select-wrapper .multiple-select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border-color: #ccc;
  border-radius: var(--radius);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  cursor: pointer;
  width: 150px;
  height: 36px;
  padding: 0 10px;
}

.multiple-select-wrapper .multiple-select-header__title--bold {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.active-selected {
  background: #e4f0e4 !important;
}

.multiple-select-list {
  position: absolute;
  top: 30px;
  left: 0;
  padding: 0;

  height: 150px;
  max-width: 100%;
  min-width: 170px;
  z-index: 99;

  border: 1px solid #ccc;
  border-radius: var(--radius);
}

.multiple-select-list li {
  list-style-type: none;
}

.multiple-select-list li:hover,
.multiple-select-list li:focus {
  cursor: pointer;
  background-color: #f2f2f2 !important;
}

.multiple-select-list-item {
  background: white;
  padding: 10px;
}

.multiple-select-wrapper .vertical-scroll::-webkit-scrollbar {
  width: 8px !important;
}

.multiple-select-wrapper .vertical-scroll::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-green) !important;
}

.multiple-select-wrapper .vertical-scroll::-webkit-scrollbar-track {
  background-color: #ccc !important;
}

.custom-select-wrapper {
  position: relative;
  display: flex;
  height: 28px;
  flex-wrap: wrap;
  margin-right: 10px;
}

.custom-select-wrapper .custom-select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border: 1px solid #a9a9a9;
  border-radius: var(--radius);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  cursor: pointer;
  width: 120px;
  height: 28px;
  padding: 0 10px;
}

.custom-select-wrapper .custom-select-header__title--bold {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.custom-select-list {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0;

  max-width: 100%;
  min-width: 140px;
  z-index: 99;

  border: 1px solid #ccc;
  color: #000000;
}

.custom-select-list li {
  list-style-type: none;
}

.custom-select-list li:hover,
.custom-select-list li:focus,
.active-selected-row {
  cursor: pointer;
  background-color: #d7d7d7 !important;
}

.custom-select-list-item {
  background: white;
  padding: 5px 10px;
}

.custom-select-list-item__name {
  font-size: 15px;
  font-weight: normal;
}

.retry-icon img {
  height: 30px;
}

.full-day-megazone-notice-container {
  width: 497px;
  height: 50px;
  border-radius: 4px;
  background-color: #ffdb00;
  position: absolute;
  top: 19px;
  left: 0;
  z-index: 10;
  margin: auto;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}

.full-day-megazone-notice .full-day-megazone-notice-icon {
  width: 28px;
  height: 30px;
  margin: 0 5px 0 0;
}

.full-day-megazone-notice p {
  margin: 0;
  color: #282828;
  font-size: 13px;
  width: 311px;
}

.full-day-megazone-notice {
  display: flex;
  align-items: center;
  height: 100%;
}

.full-day-megazone-notice-button {
  width: 81px;
  height: 24px;
  margin: 3px 20px 3px 10px;
  padding: 5px 10px;
  border-radius: 3px;
  border: solid 1px #a1a1a1;
  background-color: #f9f9f9;
  cursor: pointer;
}

.full-day-megazone-notice-button p {
  color: #282828;
  font-size: 12px;
  text-align: center;
  opacity: 0.8;
  width: 61px;
  margin: 0;
}

.full-day-megazone-button {
  display: flex;
}

.full-day-megazone-button img {
  width: 12px;
  height: 12px;
  border-radius: 0.5px;
  color: #a1a1a1;
  margin: 9px 0;
  cursor: pointer;
}

.close-notice-megazone {
  top: 66px !important;
}
.dlvr-note {
  padding: 0 5px;
}

.hyperlink-color-tooltip {
  color: #41ad29 !important;
}

.Grid-Cell .dlvr-tool {
  cursor: pointer;
  font-size: 20px;
  position: relative;
  display: inline-block;
}

.Grid-Cell .dlvr-tool .dlvr-tooltip-ez,
.Grid-Cell .dlvr-tool .dlvr-tooltip-osa,
.Grid-Cell .dlvr-tool .tooltip-error-distance {
  cursor: default;
  display: none;
  color: #fff;
  width: 120px;
  padding: 5px 0;
  background: #444;
  position: absolute;
  text-align: left;
  border-radius: 6px;
  left: 370px;
  z-index: 2;
}
.Grid-Cell .dlvr-tool .dlvr-tooltip-ez:after,
.Grid-Cell .dlvr-tool .dlvr-tooltip-osa:after,
.Grid-Cell .dlvr-tool .tooltip-error-distance:after {
  content: "";
  border-width: 12px;
  position: absolute;
  border-style: solid;
}

.Grid-Cell .dlvr-tool:hover .dlvr-tooltip-ez {
  display: block;
  width: 326px;
  height: 121px;
  border-radius: 6px;
  padding: 18px 18px 18px 15px;
  background-color: #41ad29;
  box-sizing: border-box;
  line-height: 1.4;
}

.Grid-Cell .dlvr-tool:hover .dlvr-tooltip-osa {
  display: block;
  width: 321px;
  height: 75px;
  border-radius: 6px;
  padding: 20px 15px;
  background-color: var(--color-green);
  box-sizing: border-box;
  line-height: 1.4;
}

.Grid-Cell .dlvr-tool:hover .tooltip-error-distance {
  display: block;
  box-sizing: border-box;
  line-height: 1.4;
  width: 321px !important;
  height: 91px !important;
  padding: 18px 18px 18px 15px !important;
  border-radius: 6px !important;
  background-color: #41ad29 !important;
}

.Grid-Cell .dlvr-tool .dlvr-tooltip-content-ez {
  top: -27px;
}

.Grid-Cell .dlvr-tool .dlvr-tooltip-content-osa {
  top: -10px;
}

.Grid-Cell .dlvr-tool .tooltip-error-content-distance {
  top: -10px;
}

.Grid-Cell .dlvr-tool .dlvr-tooltip-content-ez:after {
  top: 25%;
  right: 100%;
  border-color: transparent #41ad29 transparent transparent;
}

.Grid-Cell .dlvr-tool .dlvr-tooltip-content-osa:after {
  top: 15%;
  right: 100%;
  border-color: transparent #41ad29 transparent transparent;
}

.Grid-Cell .dlvr-tool .tooltip-error-content-distance:after {
  top: 15%;
  right: 100%;
  border-color: transparent #41ad29 transparent transparent;
}

.Grid-Cell .dlvr-tool .dlvr-error-prefix {
  top: 0 !important;
}

.Grid-Cell .dlvr-tool .dlvr-error-prefix:after {
  top: 7% !important;
  right: 100%;
  border-color: transparent #41ad29 transparent transparent;
}

.dlvr-btn-close {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 15px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--color-green);
  color: #fff;
  cursor: pointer;
}

.dlvr-btn-close:hover {
  background-color: var(--color-green);
}

.dlvr-btn-close i {
  display: block;
  line-height: 24px;
  font-size: 15px !important;
  color: var(--color-green);
}

.dlvr-btn-close i:hover {
  color: var(--color-white);
}

.dlvr-error_ez_sheet_address-text {
  font-size: 18px;
  color: var(--color-white);
}

.dlvr-popup-change-time-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  margin: auto;
  box-sizing: border-box;
}

.dlvr-popup-change-time-type .dlvr-popup-content {
  width: 100%;
}

.dlvr-popup-change-time-type .dlvr-box-time-type {
  height: 182px;
  background-color: var(--color-green);
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
}

.dlvr-popup-change-time-type .dlvr-popup-title {
  color: var(--color-yellow);
  font-size: 22px;
  padding: 27px 0 10px 0;
  font-weight: 500;
  text-align: center;
}

.dlvr-popup-change-time-type .dlvr-box-icon {
  width: 64px;
  position: absolute;
  background: var(--color-white);
  height: 64px;
  border-radius: 64px;
  justify-content: center;
  align-items: center;
  left: 50%;
  margin: -50px 0 0 -37px;
  display: flex;
  flex-direction: column;
}

.dlvr-popup-change-time-type .dlvr-box-icon img {
  width: auto;
  height: 40px;
}

.dlvr-popup-change-time-type .dlvr-popup-info {
  text-align: center;
  font-size: 18px;
  color: var(--color-white);
}

.dlvr-popup-change-time-type .dlvr-change-address-button {
  width: 288px;
  margin-top: 10px;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  background-color: var(--color-gray-button);
  color: var(--color-white);
  font-weight: 500;
  border: none;
}

.dlvr-popup-change-time-type .dlvr-change-schedule-button {
  width: 288px;
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  background-color: var(--color-white);
  color: var(--color-green);
  font-weight: 500;
  border: none;
}

.separately-from-batch {
  top: 20px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
}

.hidle-content-message {
  display: none !important;
}

.separately-from-batch .content {
  align-self: center;
  display: flex;
  align-items: center;
  width: 497px;
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  background-color: #ffdb00;
  font-size: 13px;
}

.Change-pickup-location {
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 420px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
}

.Change-pickup-location .content {
  z-index: 1;
  background: var(--color-green);
  border-radius: var(--radius);
  padding-top: 25px;
  position: relative;
}

.Change-pickup-location .content:before {
  content: "";
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  top: -32px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.Change-pickup-location .content .Text-content {
  width: 260px;
  padding: 20px;
  border-radius: 16px;
  background-color: #3fae29;
  text-align: center;
}

.Title-content {
  font-size: 22px;
  color: #ffdb00;
  font-weight: 500;
  padding-bottom: 10px;
}

.Description {
  font-size: 18px;
  color: #fff;
}

.Distance-calculator-img {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.Distance-calculator-img > div {
  padding: 0;
}

.Change-pickup-location .Change-pickup-location-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 310px;
}

.Change-pickup-location .Change-pickup-location-actions .Button {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 5px;
  font-size: 17px;
  font-weight: bold;
}

.Change-pickup-location .Change-pickup-location-actions .Button.white {
  border: 1px solid var(--color-green);
  color: var(--color-green);
  background: var(--color-white);
}

.PickupTime.block .block-item:hover {
  cursor: pointer;
  background: var(--color-gray-xxl);
}

.Change-pickup-location .Change-pickup-location-actions .Button.gray:hover {
  background-color: #a4a4a4;
  color: inherit;
}

.Change-pickup-location .Change-pickup-location-actions .Button.white:hover {
  background: #ccc;
  border-color: #ccc;
}

.dlvr-error-export-color {
  color: var(--color-light-red);
}

.dark-gray {
  color: #444444;
}

.dark-filter {
  filter: invert(52%) sepia(2%) saturate(0%) hue-rotate(106deg) brightness(96%) contrast(92%);
}

.Status-UI-pending-group {
  color: #282828;
  border-radius: 12px;
  border: solid 1px rgb(64, 174, 41);
  background-color: rgba(64, 174, 41, 0.1);
}

.Status-UI-completed-group {
  color: var(--color-white);
  border-radius: 12px;
  border: solid 1px rgb(64, 174, 41);
  background-color: rgb(64, 174, 41);
}

.Status-UI-canceled-group {
  color: var(--color-white);
  border-radius: 12px;
  border: solid 1px rgb(240, 68, 51);
  background-color: rgb(240, 68, 51);
}

.tracking-notice {
  pointer-events: none;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 6px;
  right: 5px;
  background-color: #ffdb01;
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.m0 {
  margin: 0 !important;
}
.align-self-center {
  align-self: center;
}

.dlvr-summary {
  border-right: none;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.dlvr-price {
  border-radius: unset;
}

.dlvr-location {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-left: none;
}

.dlvr-tracking-tab-tittle {
  width: 696px;
  background-color: #3fae29;
  padding: 20px 20px 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
}

.dlvr-tracking-status-container {
  width: 178px;
  font-size: 20px;
  color: #fff;
}

.dlvr-tracking-status {
  display: inline-block;
  height: 19px;
  padding: 2px 5px;
  border-radius: 3.3px;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  color: #3fae29;
}

.dlvr-tracking-status span {
  border-radius: unset !important;
  padding: unset !important;
  border: unset !important;
  color: #3fae29;
}

.icon-close {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: var(--color-white);
  cursor: pointer;
}

.dlvr-tracking-tab-button {
  cursor: pointer;
  width: 163px;
  height: 36px;
  margin: 0 1px 0 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #0e730f;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  line-height: 36px;
}

.dlvr-tab-container {
  background-color: #f6f7f7;
  width: 696px;
  height: 520px;
  padding: 0 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dlvr-tab-container-price {
  background-color: #f6f7f7;
  width: 696px;
  height: 470px;
  padding: 0 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.dlvr-tab-container::-webkit-scrollbar,
.dlvr-tab-container-price::-webkit-scrollbar {
  width: 6px;
  -webkit-appearance: none;
}

.dlvr-tab-container::-webkit-scrollbar-thumb,
.dlvr-tab-container-price::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #0e730f;
  outline: none;
}

.dlvr-tab-container::-webkit-scrollbar-track,
.dlvr-tab-container-price::-webkit-scrollbar-track {
  background-color: #d8f0d1;
}

.dlvr-tracking-tab {
  width: 656px;
  box-sizing: border-box;
}

.dlvr-tracking-tab-block {
  border: solid 1px #dbdbdb;
  border-radius: 6px;
  padding: 15px 15px 14px;
  margin: 20px 0 20px 0;
  background-color: #fff;
}

.dlvr-tab-container p,
.dlvr-tab-container-price p {
  margin: 0;
  color: #282828;
}

.dlvr-tracking-tab-block p {
  font-size: 14px;
}

.font-18-imp {
  font-size: 18px !important;
}

.ml19 {
  margin-left: 19px;
}

.mt7 {
  margin-top: 7px;
}

.font-14 {
  font-size: 14px;
}

.dlvr-estimate {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.dlvr-tracking-tab-attachment {
  padding: 0;
}

.dlvr-tracking-tab-attachment > p {
  background-color: #f1f1f1;
  margin: 0 0 15px;
  padding: 10px 0 9px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.w-space-nor {
  white-space: normal !important;
}

.dlvr-attach-item {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 0;
  padding: 5px;
  border-radius: 8px;
  border: solid 1px #a1a1a1;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.dlvr-price-tab {
  background: var(--color-white);
  margin-top: 20px;
  margin-right: 20px;
  border-radius: var(--radius);
  border: solid 1px #dbdbdb;
  padding: 15px;
  .Pricing-LongHaul{
    .dlvr-price-item,.bottom-price-tab {
      height: auto !important;
    }
    .dlvr-price-item.Pricing-LongHaul-Group{
      display: block !important;
    }
  }
}

.dlvr-price-tab span {
  color: #282828;
}

.dlvr-price-item,
.bottom-price-tab {
  display: flex !important;
  height: 23px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  color: #282828 !important;
  position: relative !important;
  justify-content: space-between !important;
  z-index: 1;
}

.dlvr-price-item:not(.Pricing-LongHaul-Group)::after {
  content: "" !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzQiIGhlaWdodD0iMSIgdmlld0JveD0iMCAwIDQzNCAxIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWRhc2hhcnJheT0iMCA2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1vcGFjaXR5PSIuMyI+CiAgICAgICAgPGcgc3Ryb2tlPSIjMDAwIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTM3LjUgMTQuNUw1NzEuNSAxNC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTQ0LjAwMDAwMCwgLTMwNC4wMDAwMDApIHRyYW5zbGF0ZSgzNzIuMDAwMDAwLCAxMjYuMDAwMDAwKSB0cmFuc2xhdGUoMC4wMDAwMDAsIDEyOS4wMDAwMDApIHRyYW5zbGF0ZSgyMC4wMDAwMDAsIDIwLjAwMDAwMCkgdHJhbnNsYXRlKDE1LjAwMDAwMCwgMTUuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K") !important;
  position: absolute !important;
  bottom: 7px !important;
  left: 0 !important;
  right: 0 !important;
  z-index: -1 !important;
  background-repeat: repeat-x !important;
  height: 2px !important;
  background-position: 0px 0 !important;
}

.dlvr-price-item span,
.dlvr-price-item label {
  background-color: #fff;
}

.dlvr-tracking-paid {
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  color: #41ad29;
  text-align: center;
  text-transform: uppercase;
}

.oval-location {
  background-color: #00b9e6;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

.oval-location-text {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 24px;
}

.backgound-loacation-to {
  background-color: #ffdd00;
}

.color-gray {
  color: #a1a1a1;
}

.dlvr-locatitab-active {
  color: #0c7310;
}

.dlvr-tab-container .dlvr-green-text,
.dlvr-tab-container-price .dlvr-green-text {
  color: #3aaa36;
}

.dlvr-location-container-icon {
  width: 24px;
  height: 24px;
}

.tracking-location-from {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
}

.dlvr-location-to {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 15px;
}

.popup-location-background {
  background-color: #f6f7f7;
}

.dlvr-note-info {
  color: #7b7c7c !important;
  font-size: 12px;
}

.dlvr-vihicle-image {
  width: auto;
  max-width: 100px;
  height: 30px;
}

.dlvr-attach-item a,
.dlvr-attach-item span,
.dlvr-attach-item img {
  display: block;
  width: 100%;
  height: 100%;
}

.dlvr-attach-item span {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dlvr-tracking-timetype::first-letter {
  text-transform: uppercase;
}

.font-15 {
  font-size: 15px;
}

.font-20 {
  font-size: 20px;
}

.ml34 {
  margin-left: 34px !important;
}

.m16 {
  margin: 16px 0;
}

.dlvr-green-from {
  background-color: #02751a !important;
}

.dlvr-tab-container .dlvr-location-note {
  font-size: 14px;
  height: 68px;
  width: 590px;
  padding: 9px;
  box-sizing: border-box;
  border: solid 1px #dbdbdb;
  margin-top: 15px;
  border-radius: 6px;
  overflow-y: auto;
}

.dlvr-tab-container .dlvr-driver-note {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  margin: 10px 0 15px 0;
}

.dlvr-go-cod-pod {
  cursor: pointer;
  margin: 15px 0;
}

.dlvr-pointer {
  cursor: pointer;
}

.dlvr-gray-1 {
  color: var(--color-gray-1) !important;
}

.dlvr-save-location {
  font-size: 12px;
  padding: 4px 9.8px 4px 10px;
  border: 1px solid #a1a1a1;
  margin: 6px 0 10px 34px;
  color: #282828;
  border-radius: 3px;
}

.dlvr-live-tracking-note {
  margin: 15px 17px 15px 34px;
  width: 590px;
  padding: 9px;
  box-sizing: border-box;
  height: 36px;
  border: solid 1px #dbdbdb;
}

.dlvr-live-tracking-note::-webkit-input-placeholder {
  font-size: 14px;
}

.oval-marker {
  width: 25.35px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.oval-color-marker-fr {
  background-color: var(--color-blue);
  color: #ffffff;
}

.oval-color-marker-to {
  background-color: #ffdd00;
  color: #282828;
}

.oval-color-marker-complete {
  background-color: #02751a;
  color: #ffffff;
}

.tracking-location-from .dlvr-green-text {
  color: #3aaa36;
}

.tracking-location-from .dlvr-location-description {
  margin: 15px 0 0 34px;
  color: #444444;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.dlvr-locatitab-active {
  color: #0c7310;
}

.dlvr-payment {
  width: 10px;
  height: 10px;
  background-color: #3aaa36;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 6px;
  bottom: 2px;
}

.dlvr-payment-icon {
  font-size: 8px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #fff;
}

.dlvr-live-tracking-note::-moz-placeholder {
  font-size: 14px;
}

.dlvr-live-tracking-note:-ms-input-placeholder {
  font-size: 14px;
}

.dlvr-live-tracking-note:-moz-placeholder {
  font-size: 14px;
}

.dlvr-live-tracking-note:focus-visible {
  outline: none;
}

.dlvr-line-gray {
  height: 1px;
  background-color: #d8d8d8;
}

.height16 {
  height: 16px;
}

.dlvr-tab-active {
  background-color: #f6f7f7;
  color: #0c7310;
}

.dlvr-pod-small-image {
  border-radius: 2px;
  border: solid 0.5px #dbdbdb;
  height: 16px;
  margin-right: 5px;
  width: 16px;
  box-sizing: border-box;
}

.dlvr-pod {
  margin-right: 25px;
  margin-bottom: 2px;
}

.dlvr-pod-image {
  border-radius: 5px;
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.dlvr-handle-button {
  color: #4a4a4a;
  text-decoration: none;
  margin: 0 !important;
}

.dlvr-btn-more {
  color: #3aaa36;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.dlvr-tracking-note {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mr34 {
  margin-left: 34px;
}

.dlvr-cod-pod-style {
  display: flex;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  width: 638.5px;
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  z-index: 1;
}

.cod-pod-container {
  width: 100%;
  padding-left: 10px;
}

.cod-pod-container .cod-pod-info {
  padding-top: 5px;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}

.cod-pod-container .cod-pod-info .Overlay-Checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 14.02px;
  height: 14.02px;
  border-color: #949494;
  border-radius: 3px;
}

.cod-pod-container .cod-pod-info-no-content {
  padding-top: 5px;
  width: 100%;
}

.cod-pod-container .cod-pod-info .cod-pod-location,
.cod-pod-container .cod-pod-info-no-content .cod-pod-location {
  margin: 0;
  color: #282828;
  padding-right: 15px;
}

.cod-pod-container .cod-pod-info .pod-style {
  margin: 10px 0;
}

.cod-pod-container .cod-pod-info .cod-pod-fees {
  display: flex;
  width: 180px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background-color: #dbdbdb;
  margin-top: 10px;
  color: #282828;
  font-size: 14px;
}

.cod-pod-container .cod-pod-info .cod-pod-fees .cod-pod-currency,
.cod-pod-container .cod-pod-info .cod-pod-fees .cod-pod-invoce-fees {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.cod-pod-container .cod-pod-info .cod-pod-fees .cod-pod-currency {
  width: 29px;
  border-right: 1px solid #a1a1a1;
}

.cod-pod-container .cod-pod-info .cod-pod-fees .cod-pod-invoce-fees {
  padding-left: 10px;
}

.cod-pod-container .cod-pod-info .cod-pod-notes-container {
  position: relative;
  width: 592px;
}

.cod-pod-container .cod-pod-info .cod-pod-notes {
  width: 592px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  margin-top: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cod-pod-container .cod-pod-info .cod-pod-notes-normal {
  width: 592px;
  white-space: normal;
}

.dlvr-background-gray {
  background-color: #f1f1f1;
}

.dlvr-background-white {
  background-color: #ffffff;
}

.cod-pod-container .cod-pod-info .checkbox-style {
  display: flex;
  align-items: center;
}

.cod-pod-container .cod-pod-info .checkedbox-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828 !important;
  padding-left: 10px;
  position: relative;
  top: 1px;
}

.cod-pod-container .cod-pod-info .question-icon {
  padding-left: 5px;
  position: relative;
}

.cod-pod-container .cod-pod-info .question-icon-un-check {
  padding-left: 5px;
  position: relative;
  top: 1px;
}

.cod-pod-container .cod-pod-info .question-icon .sub-account-tooltip i,
.cod-pod-container .cod-pod-upload-info .question-icon .sub-account-tooltip i {
  font-size: 14px;
  background-color: transparent;
}

.cod-pod-container .cod-pod-info .question-icon .sub-account-tooltip .Icon.gray,
.cod-pod-container .cod-pod-upload-info .question-icon .sub-account-tooltip .Icon.gray {
  color: #a1a1a1;
}

.cod-pod-container .cod-pod-upload-info .question-icon {
  padding-left: 3px;
  position: relative;
  top: -1px;
}

.cod-pod-container .cod-pod-info .checkbox-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828 !important;
  padding-left: 28px;
  position: relative;
  top: 2px;
}

.cod-pod-container .cod-pod-submission {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b7c7c;
  padding-top: 15px;
}

.cod-pod-container .cod-pod-upload-info .pod-upload-info {
  padding-top: 15px;
}

.cod-pod-container .cod-pod-upload-info .cod-pod-upload-title {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
  display: flex;
}

.cod-pod-container .cod-pod-upload-info .pod-cod-photos {
  width: 35px;
  height: 35px;
  margin-right: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.cod-pod-container .cod-pod-upload-info .cod-upload-info .cod-invoice-fees {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  padding-bottom: 10px;
}

.cod-pod-container .cod-pod-upload-info .cod-pod-completed-time {
  padding-top: 15px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b7c7c;
}

.dlvr-location-trackingtab-container .dlvr-item-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
}

.divider {
  height: 95%;
  border-left: 2px solid #dbdbdb;
  position: absolute;
  left: 26px;
  z-index: 0;
}
.dlvr-avatar-tracking {
  width: 50px;
  height: 50px;
  border-radius: 6.3px;
  margin-right: 10px;
}

.dlvr-rating-block {
  width: 45px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 7px 2px 8px;
  background-color: #fff;
  border-radius: 13.5px;
  border: 0.8px solid #bbb;
  font-size: 10.5px;
  color: #444;
  margin-left: 5px;
  box-sizing: border-box;
}

.dlvr-rating-block i {
  color: #f5a623;
  font-size: 11px;
  line-height: 13px;
  margin-right: 4px;
}

.BookingInfo-Block-Left {
  margin-right: 183px;
}

.dlvr-location-container-icon-person {
  width: 24px;
  text-align: center;
}

.dlvr-tracking-price-noted {
  background: transparent !important;
  box-shadow: none !important;
  z-index: 0;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
}
.dlvr-tracking-noted {
  z-index: 2;
  min-width: 204px;
  border-top: 1px solid var(--color-green) !important;
  border-bottom: 1px solid var(--color-green) !important;
  background-color: var(--color-white);
  position: relative;
  height: 38px;
  box-sizing: border-box;
  white-space: nowrap;
  background-repeat: no-repeat;
  margin: auto auto 15px;
  padding: 9px 15px !important;
  display: inline-block;
}

.dlvr-tracking-noted::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.79 78.5'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:none;stroke:%233fae29;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow%3C/title%3E%3Cg id='Pu'%3E%3Cpath id='Path-6' class='cls-1' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3Cg id='Pu-2' data-name='Pu'%3E%3Cpath id='Path-6-2' data-name='Path-6' class='cls-2' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 33px 38px;
  height: 38px;
  width: 28px;
  position: absolute;
  left: -28px;
  top: -1px;
}

.dlvr-tracking-noted::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.79 78.5'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:none;stroke:%233fae29;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow%3C/title%3E%3Cg id='Pu'%3E%3Cpath id='Path-6' class='cls-1' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3Cg id='Pu-2' data-name='Pu'%3E%3Cpath id='Path-6-2' data-name='Path-6' class='cls-2' d='M58.87,1h-9.8a5,5,0,0,0-3.53,1.46l-33,33a5,5,0,0,0,0,7.08L46,76a5,5,0,0,0,3.53,1.46h9.3' transform='translate(-10.07 0)'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 33px 38px;
  height: 38px;
  width: 28px;
  position: absolute;
  transform: rotate(180deg);
  right: -28px;
  top: -1px;
}

.dlvr-tracking-custome-line {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 0;
  right: 0;
  margin-top: -6px;
  height: 1px;
  background-color: var(--color-green);
}

.dlvr-note-for-custommer {
  width: 303px;
  margin: 0 auto !important;
  font-size: 12px;
}

.dlvr-note-for-custommer a {
  color: #3aaa36;
  text-decoration: underline;
  cursor: pointer;
}

.dlvr-modal-image-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 100012;
  cursor: zoom-out;
}

.dlvr-modal-image {
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  align-self: center;
  cursor: pointer;
}

.dlvr-modal-image img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  max-height: calc(100vh - 80px);
  line-height: 0;
  box-sizing: border-box;
}

.dlvr-prev,
.dlvr-next {
  position: absolute;
  color: #fff;
  top: 50%;
  cursor: pointer;
}

.numbertext {
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: -22px;
}

.dlvr-next {
  right: 31px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 20px solid #fff;
  opacity: 0.7;
  cursor: pointer;
}

.dlvr-prev {
  left: 31px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 20px solid #fff;
  opacity: 0.7;
  cursor: pointer;
}

.dlvr-done-icon {
  font-size: 20px;
  font-weight: 700;
  margin-right: 5px;
  color: #02751a;
  margin-bottom: -3px;
}

.dlvr-rating-oval {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px 2px 6px;
  background-color: #fff;
  border-radius: 13.5px;
  border: 0.8px solid #bbb;
  font-size: 10.5px;
  color: #444;
}

.dlvr-rating-oval i {
  color: #f5a623;
  font-size: 14px;
}

.dlvr-tracking-driver-block {
  position: relative;
  top: -12px;
}

.dlvr-tracking-vehicle-info {
  display: flex;
  align-items: center;
}

.oval-location-from {
  background-color: #00b9e6;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

.dlvr-location-tab-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dlvr-complete-driver-info .dlvr-divider-horizontal {
  margin-top: 15px;
  width: 606px;
  border-top: 1px solid #d8d8d8;
}

.dlvr-count-number {
  position: relative;
  top: 1px;
  height: 16px;
  width: 16px;
}

.dlvr-location-to .address-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: normal;
}

.dlvr-location-to .address-info .address-info-index {
  color: var(--color-gray-1);
  background-color: #ffdd00;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

.dlvr-location-to .address-info .address-info-name {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 10px;
}

.tracking-location-from .address-info {
  display: flex;
  align-items: center;
  position: relative;
}

.tracking-location-from .address-info .address-info-name {
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: var(--color-gray-1);
  position: relative;
  top: 1px;
  margin-left: 10px;
  margin-right: 20px;
}

.tracking-location-from .address-info .toggle-location {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.tracking-location-info .arrow_right,
.dlvr-location-to .arrow_right {
  color: #3aaa36;
  font-size: 15px;
  margin-right: 2px;
  position: relative;
  top: 1px;
}

.popup-price__version {
  background-color: var(--color-background-yellow);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 10px 15px;
  color: #444444;
  font-size: 13px;
  width: 696px;
  box-sizing: border-box;
  height: 50px;
}

.dlvr-live-tracking-fr-note {
  margin: 15px 17px 15px 34px;
  width: 590px;
  padding: 9px;
  box-sizing: border-box;
  height: 36px;
  border: solid 1px #dbdbdb;
}

.dlvr-live-tracking-fr-note:focus-visible {
  outline: none;
}

.tracking-doc-cod-return {
  width: fit-content;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 34px;
  display: flex;
}

.tracking-location-note-box {
  width: 590px;
  margin: 15px 0px 0px 34px;
}

.break-word-container span {
  word-break: break-all;
}

.reset-margin {
  margin: 0px !important;
}

.pdr-60 {
  padding-right: 60px !important;
}

.attention-text {
  bottom: 50px;
  left: 10px;
  right: 10px;
  position: absolute;
  word-wrap: break-word;
  padding: 5px 0;
  font-size: 14px;
}

.chat-not-available {
  font-style: italic;
  font-size: 12px;
  color: #7f7f7f;
  white-space: pre-wrap;
}
.text-left {
  text-align: left !important;
}

.confirm-reimburse-link {
  padding: 0 10px;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-gray {
  background-color: #f2f2f2;
}

.selected-bacth-name {
  font-family: var(--font-family-base);
  font-size: 24px;
  color: #444;
  margin-right: 6px;
}
.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.PresetDateRangePicker_button:active {
  outline: 0;
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699;
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput__block {
  display: block;
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489;
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489;
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left;
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #444444;
  background: #fff;
  width: 40px !important;
  height: 39px !important;
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit;
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}
.CalendarDay__outside:hover {
  border: 0;
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #077306;
  color: #ffffff;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848;
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #e7f1e7;
  border: 1px double #e5e7e7;
  color: #444444;
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #444444;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #f5f5f5;
  border: 1px solid #e5e7e7;
  color: #a1a1a1;
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee;
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7;
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth:first-child {
  padding-right: 10px !important;
}
.CalendarMonth:last-child {
  padding-left: 30px !important;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}
.CalendarMonth_caption {
  color: #444444;
  font-size: 19px;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;
  caption-side: initial;
  font-weight: bold;
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 0px;
}
.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__horizontal {
  height: 0;
}
.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1;
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}
.DayPickerNavigation__bottom {
  height: auto;
}
.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2;
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2;
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0;
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 30px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
}
.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 29px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 29px;
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block;
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2;
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}
.DayPicker__horizontal {
  background: #fff;
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  width: 100% !important;
  height: 100% !important;
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 72px;
  z-index: 2;
  text-align: left;
}
.DayPicker_weekHeader:first-child {
  padding-left: 21px !important;
  padding-right: 0 !important ;
}
.DayPicker_weekHeader:last-child {
  padding-left: 19px !important;
  padding-right: 0 !important ;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
  width: 40px !important;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: auto !important;
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}
.DateInput_fangShape {
  fill: #fff;
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}
.DateRangePickerInput__disabled {
  background: #f2f2f2;
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.DateRangePicker {
  position: relative;
  display: inline-block;
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
}
.DateRangePicker_picker__directionRight {
  right: 0;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.search-create-booking {
  width: 200px;
  height: 44px;
  font-size: 18px;
  font-weight: 500;
  line-height: 44px !important;
  max-width: 200px !important;
}

.search-type {
  min-width: 99px;
  height: 18px;
  border-right: 1px solid #dbdbdb;
  cursor: pointer;
  justify-content: space-between;
}

.search-time .search-type {
  padding: 11px 0;
  min-width: 111px;
}

.manage-search .search-type {
  padding: 10px 0 10px 11px;
}

.search-type span {
  height: 100%;
  border: none;
  background: none;
  font-size: 15px;
}

.manage-search {
  position: relative;
  height: 40px;
  width: 380px;
  box-sizing: border-box;
  margin-right: 8px;
}
.search-type img {
  margin-right: 12px;
  width: 10px;
  pointer-events: none;
  margin-left: 4px;
}

.filter-group .DateRangePickerInput__withBorder .DateInput_1:first-child input {
  padding-right: 15px;
  text-align: center;
}

.filter-group .DateRangePickerInput__withBorder .DateInput_1:last-child input {
  padding-left: 15px;
  padding-right: unset;
}

.DateInput .DateInput_input {
  color: #444444;
  font-size: 15px;
  padding-left: 0;
}

.search-type-dropdown {
  position: absolute;
  list-style: none;
  padding: 0;
  min-width: 128px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e5e7e7;
  top: 32px;
  z-index: 1;
}
.search-time-dropdown {
  position: absolute;
  list-style: none;
  padding: 0;
  min-width: 111px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e5e7e7;
  top: 32px;
  z-index: 1;
}
.have-search-day {
  width: calc(100% - 10px);
}

.search-type-dropdown li,
.search-time-dropdown li {
  cursor: pointer;
  padding: 12px 11px 10px 11px;
  color: #444444;
  font-size: 14px;
  height: 38px;
  box-sizing: border-box;
  white-space: nowrap;
}
.search-type-dropdown li:hover,
.search-time-dropdown li:hover,
.search-type-dropdown-sub li:hover {
  background-color: #f2f2f2;
}

.search-type-dropdown li img {
  height: 10px;
  pointer-events: none;
  margin-left: 14px;
}

.search-type-dropdown-sub {
  display: none;
  list-style: none;
  padding: 0;
  min-width: 128px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e5e7e7;
}

.search-type-dropdown-sub li {
  cursor: pointer;
  padding-top: 12px;
  padding-left: 11px;
  padding-bottom: 10px;
  color: #444444;
  font-size: 14px;
  height: 38px;
  box-sizing: border-box;
}
.search-time .search-type {
  height: 40px;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
}
.search-time {
  position: relative;
}
.search-time .search-type-dropdown {
  top: 33px;
}
.CalendarDay__selected {
  background-color: #077306 !important;
  border: 1px double #077306 !important;
}
.search-picker-container {
  width: 660px !important;
  position: absolute;
  height: 319px !important;
  right: 9px;
  top: 50px;
  z-index: 1;
}
.search-message-warning {
  position: absolute;
  bottom: 30px;
  border: 1px solid #f00;
  padding: 10px 0;
  width: 599px;
  text-align: center;
  left: 30px;
  border-radius: 4px;
  background-color: #fdecea;
}
.search-picker-container .DayPicker > div > div:first-child {
  width: 660px !important;
}
.search-close {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.datepicker-trigger-from-date {
  color: var(--color-gray);
  line-height: 17px;
  width: 12px;
  height: 13.3px;
  display: block;
  margin: 0 11px;
}
.search-booking-container {
  display: flex;
  justify-content: space-between;
}
.form-search-booking {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  position: relative;
}
.booking-count {
  align-self: center;
  margin-left: 20px;
}
.search-picker-container.search-warning {
  height: 375px !important;
}
.remove-search-button {
  width: auto;
  height: 38px;
  margin: 30px 0 0;
  padding: 9px 24px 8px !important;
  border-radius: 5px;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #282828;
  max-width: unset !important;
  line-height: unset !important;
}
.spinner-container {
  width: 100%;
  height: calc(100vh - 110px * 0.8);
  display: flex;
  overflow: hidden;
  background-color: #ffffff;
  opacity: 1;
  z-index: 4;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes Spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.spinner-container-inner {
  width: 100px;
  height: 100px;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.spinner-item {
  left: 48.5px;
  top: 24px;
  position: absolute;
  animation: Spinner linear 1s infinite;
  background: #3fae29;
  width: 3px;
  height: 10px;
  border-radius: 1.5px / 1.56px;
  transform-origin: 1.5px 24px;
}
.spinner-container-inner .spinner-item:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
}
.spinner-container-inner .spinner-item:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
}
.spinner-container-inner .spinner-item:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}
.spinner-container-inner .spinner-item:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
}
.spinner-container-inner .spinner-item:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
}
.spinner-container-inner .spinner-item:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}
.spinner-container-inner .spinner-item:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
}
.spinner-container-inner .spinner-item:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
}
.spinner-container-inner .spinner-item:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}
.spinner-container-inner .spinner-item:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
}
.spinner-container-inner .spinner-item:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
}
.spinner-container-inner .spinner-item:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
.booking-manage-container {
  height: 100%;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
}

.long-haul-gray-icon-class {
  margin-right: 10px;
  margin-left: 5px;
}

.long-haul-gray-icon-class img {
  height: 23px;
}
.penalty-button-n,
.penalty-button-y {
  width: 49%;
}
.penalty-button-n {
  margin-right: 2%;
}
.dropdown-active {
  min-width: 116px;
}
.dropdown-time-active,
.dropdown-active {
  background-color: #e6f1e6;
  border-radius: 6px 0 0 6px;
  border-right: solid 1px #40ae29;
}
.manage-search-border {
  border: solid 1px #40ae29;
  min-width: 116px;
}
.bolder-green {
  border: solid 1px #40ae29 !important;
}
#closure_items {
  height: calc(100vh - 110px * 0.8);
}
.h100 {
  height: 100%;
}
.show-sub-dropdown:hover .search-type-dropdown-sub {
  display: block;
}
.show-sub-dropdown {
  position: relative;
}
.dropdown-sub-container {
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 10px;
}
.pd-0 {
  padding: 0 !important;
}
.height-40 {
  height: 40px !important;
}
.height-0 {
  height: 0;
}
.height-auto {
  height: auto;
}
.width-400 {
  width: 400px !important;
}
.min-height-159 {
  min-height: 159px !important;
}
.height-219 {
  height: 219px !important;
}
.padding-horizontal-10 {
  padding: 0 10px !important;
}
.requirement-not-met-icon {
  width: 15px;
  vertical-align: bottom;
}
.enter-amount-reimbursement::placeholder {
  color: var(--color-black) !important;
}

.disabled-button {
  background-color: #f9f9f9;
  border: solid 1px #dbdbdb;
  color: #dbdbdb;
  pointer-events: none;
}
.temporarily-unavailable-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f04433;
  margin-top: 5px;
}
.dot-separate::before {
  content: "•";
  color: white;
  margin: 0 5px;
}

.main-view {
  position: fixed;
  top: var(--menu-height);
  right: 0;
  bottom: 0;
  left: 0;
}

.maplibregl-ctrl-attrib.maplibregl-compact {
  margin: 10px 0 0 10px
}
.maplibregl-marker {
  will-change: unset;
  cursor: pointer;
}

.label-marker-style {
  font-size: 15.5px !important;
  font-weight: 500;
}

.lb-container .lb-header {
  margin-top: 60px;
}

.mobile-controls {
  bottom: 110px;
}
.table-apidashboard{
  th {
    font-size: 12px;
  }
  .cell-stripped {
    background-color: #efefef;
  }
}

.id-popup-price {
  color: #ffdb00!important;
  background: transparent;
  font-size: 16px;
  padding: 0;
}

.declined-red-dot::after {
  content: "\A";
  width: 4px;
  height: 4px;
  margin-left: 4px;
  margin-bottom: 2px;
  border-radius: 50%;
  border: solid 1px #fff;
  background-color: #f04433;
  display: inline-block;
}

.declined-red-dot.Hide::after {
  display: none;
}
.declined-bg {
  background-color: #f04433 !important;
}