.BatchUploadLayout {
  @media (min-width: 1024px) and (max-width: 1366px) {
    padding-top: 50px;
  }
  .PageHead {
    .cashback-bar-wrapper {
      margin: 0;
    }

    .cashback-item {
      justify-content: end;
      margin-right: 10px;
    }

    &.step-5 .cashback-item {
      margin-right: 52px;
    }

    .cashback-item {
      justify-content: end;
      margin-right: 10px;
    }

    &.step-5 .cashback-item {
      margin-right: 52px;
    }
  }

  .Modal-Basic {
    .Modal-Head {
      justify-content: flex-start;
    }

    .Modal-Content {
      font-size: 20px;
    }

    .Modal-Error {
      align-items: flex-start;
    }

    .button-wrapper {
      align-self: flex-end;
      margin: 0 20px 20px 0;

      button:first-child {
        margin-right: 10px;
        padding: 0 17px;
      }

      button {
        font-size: 18px;
        font-weight: 500;
        padding: 0 28px;
      }

      .black-button {
        color: #282828;
        border: 1px solid var(--color-border-gray);
      }
    }
  }
}