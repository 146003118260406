// table
.DetailBooking-Table {
  overflow-x: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px !important;
    height: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0e730f !important;
  }
  &::-webkit-scrollbar-track {
    background-color: #dcf4d0 !important;
  }
}
.DetailBooking-Section {
  padding-bottom: 150px;
}
.DetailBooking-Section .DetailBooking--Description,
.DetailBooking-Section .DetailBooking--DriverNote,
.DetailBooking-Section .DetailBooking--Parking,
.DetailBooking-Section .DetailBooking--Tolls,
.DetailBooking-Section .DetailBooking--Photos,
.DetailBooking-Section .DetailBooking--Signatures {
width: 7.5%;
min-width: 7.5%;
max-width: 7.5%;
}
.DetailBooking-Section .DetailBooking--DriverInfo {
width: 15%;
min-width: 15%;
max-width: 15%;
}
.DetailBooking-Section .DetailBooking--Locations {
width: 25%;
min-width: 25%;
max-width: 25%;
> div > .Pin {
    flex: 0 0 24px;
  }
}

.AddressContact-Error .location-error-box{
  background: #0e730f;
  border-radius: 5px;
  padding: 15px 10px;
  font-size: 14px;
  font-style: normal;
  max-height: 82px;
  overflow-y: auto;
}

.DetailBooking-background-block {
  display: block !important;
  background-color: #efefef;
  margin: 5px 0 0 35px;
  border-radius: 5px;
  padding: 7px 7px;
}

.Table-Custom-Even-Tr tbody tr:nth-child(2n+2) td {
  background: #f9f9f9 !important;
}

.DetailBooking--CustomReimbursement {
width: 140px;
min-width: 140px;
max-width: 140px;
box-sizing: border-box;
}

.DetailBooking--Description {
&--text-16 {
  & .small-font {
    font-size: 16px !important;
  }
}
}

.modal-zIndex {
z-index: 10010 !important;
}

.popup-wrapper.booking-detail-modal {
z-index: 10010;

.popup-inner {
  min-width: 440px;
  min-height: 283px;

  .modal-layout {
    width: 725px;
    border-radius: 8px;
    padding: 10px;
    background-color: #51af2b;

    h3 {
      color: var(--color-white);
      font-size: 17px;
      font-weight: bold;
      padding: 10px 10px 20px;
    }

    .modal-inner {
      padding: 30px 15px;
      border-radius: 4px;
      background-color: var(--color-white);
    }
  }
}

p, h3 {
  margin: 0;
}
}

.booking-assigned {
width: 440px;
height: 100%;
color: var(--color-white);

.inner {
  padding: 20px;
  min-height: 233px;
  border-radius: 6px;
  background-color: #00b82c;
  box-sizing: border-box;

  h3 {
    font-size: 19px;
    font-weight: bold;
    color: var(--color-yellow);
    margin: 0 0 10px 0;
  }

  .divide {
    width: 100%;
    height: 1px;
    background-color: var(--color-white);
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .fr-icon {
    position: relative;

    .icon {
      width: 18px;
      height: 18px;
      background-color: var(--color-blue);
      border-radius: 50%;
      font-size: 9px;
      text-align: center;
      line-height: 19px;
    }

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 85%;
      background-color: #80db95;
      position: absolute;
      left: 50%;
      top: 18px;
    }
  }

  .right-section {
    padding-left: 10px;

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
    }

    .pickup-time {
      margin-bottom: 5px;
    }

    .pickup-address {
      margin-bottom: 19px;
      font-weight: normal;
    }

    .vehicle-info {
      display: flex;

      .vehicle-img {
        display: flex;
        background: var(--color-white);
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        width: 93px;
        height: 60px;
      }

      img {
        margin: 0;
        display: inline;
        width: 80%;
        max-height: 80%;
      }
    }

    .vehicle-name {
      padding-left: 10px;
    }

    .driver-info {
      margin-top: 5px;
      font-weight: normal;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
        vertical-align: middle;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  margin-top: 5px;

  .btn {
    width: 215px;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: var(--color-background-gray);
    border: none;
    color: var(--color-white);
    text-align: center;
    line-height: 40px;
  }

  .livemap-btn {
    color: var(--color-green);
    background-color: var(--color-white);
  }
}
}

.popup-wrapper .modal-layout {
box-sizing: border-box;

.vehicle-info {
  display: flex;
  border-radius: 4px;
  background-color: var(--color-gray-xxxl);
  padding: 2px;
  height: 88px;
  box-sizing: border-box;
}

.vehicle-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: 132px;

  img {
    max-width: 80%;
    height: 60%;
  }
}

.booking-info {
  flex: 5;
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .pickup-time,
  .driver-name {
    font-weight: normal;
  }

  .pickup-time {
    margin-top: 5px;
  }
}

.modal-sub-text,
.modal-text {
  font-size: 18px;
  font-weight: normal;
  color: #444444;
  text-align: center;
}

.modal-text {
  padding-top: 30px;
}

.modal-sub-text {
  margin-top: 20px;
}

.btn-wrapper {
  text-align: center;
  margin: 0;
  justify-content: center;
  margin-top: 30px;
}

button {
  font-size: 20px;
  color: var(--color-white);
  font-weight: 500;
  border: none;
  background-color: transparent;
  transition: background-color 0.1s ease-in;
}

.cancel-btn {
  min-width: 160px;
  padding: 0 24px;
  height: 48px;
  border: 1px solid var(--color-border-red);
  border-radius: 4px;
  color: var(--color-border-red);
  margin-right: 20px;
  transition: all 0.1s ease-in;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-border-red)
  }
}

.back-btn {
  min-width: 160px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--color-border-gray);
  color: var(--color-gray-1);
  padding: 0 10px;
  margin-right: 20px;
}

.group-btn {
  display: flex;
  justify-content: space-between;
  min-width: 400px;

  button {
    flex: 1;
    background-color: #51af2b;
    padding: 0 24px;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
      background-color: var(--color-border-green);
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      margin-right: 2px;

      &:before {
        content: attr(data-divide-text);
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(12px, -50%);
        width: 20px;
        height: 20px;
        background-color: var(--color-white);
        border-radius: 50%;
        color: #444444;
        font-size: 12px;
        line-height: 19px;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .retry-btn {
    flex: 1;
    padding-left: 24px;
  }

  .cancel-btn {
    background-color: var(--color-border-red);
    color: var(--color-white);
    margin-right: 2px;

    &:hover {
      background-color: var(--color-dark-red);
    }
  }
}

.suggestion-text {
  margin-top: 20px;
  font-size: 12px;
  font-style: italic;
  color: #282828;
  text-align: center;
}

.sub-btn,
.main-btn {
  min-width: 180px;
  height: 48px;
  border-radius: 4px;
  padding: 0 24px;
}

.sub-btn {
  border: 1px solid #51af2b;
  margin-right: 20px;
  color: #51af2b;
}

.main-btn {
  background-color: #51af2b;

  &:hover {
    background-color: var(--color-border-green);
  }
}

.pay-fee-btn {
  width: 322px;
  height: 48px;
  background-color: #51af2b;
  border-radius: 4px;

  &:disabled {
    color: var(--color-black);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: var(--color-border-green);
  }
}
}

.c-tooltip {
position: relative;

&--visible {
  .c-tooltip__content {
    visibility: visible;
  }
}

&--uncontrolled:hover {
  .c-tooltip__content {
    visibility: visible;
  }
}

&__content {
  position: absolute;
  padding: 7px 14px;
  visibility: hidden;

  border-radius: 6px !important;
  border: solid 1px #dbdbdb;
  background-color: #fff !important;
  color: var(--color-gray-1) !important;
  font-weight: 500;
  min-width: 30px;
  max-width: 250px;
  width: max-content;
  word-break: break-word;
  line-height: 1.4;
  z-index: 999;
  font-size: 13px;

  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    z-index: 1000;
  }
}

&--bottom {
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  &::before {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }
}

&--top {
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  &::before {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(225deg);
  }
}

&--left {
  right: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);

  &::before {
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

&--right {
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);

  &::before {
    right: 100%;
    top: 50%;
    transform: translate(50%, -50%) rotate(315deg);
  }
}
}

.location-note {
.popup-wrapper .popup-inner {
  width: 100%;
}

.Modal-Actions.Box-Actions {
  width: auto !important;
}

.pointer {
  cursor: pointer;
}

&__content {
  display: flex;
  justify-content: space-between;

  img {
    margin-left: 5px;
  }
}
}

.locating-stage {
position: absolute;
bottom: 0;
}

.devina-stage {
position: absolute;
bottom: 0;
color: var(--color-white);

p {
  margin: 0;
}

  
.devina-photo {
  position: absolute;
  bottom: 0;
  height: 146px;
}

.devina-content {
  padding: 20px;
  padding-left: 104px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .linear-background {
    border-radius: 4px;
  }
}

.tooltip {
  margin-left: 10px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 2;
}

[data-tooltip]:before {
  width: max-content;
  max-width: 210px;
  max-height: 70px;
}

.explain {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.note {
  margin-top: 10px;
  font-size: 15px;
  font-style: italic;
}

.countdown {
  width: 178px;
  height: 38px;
}
}

.c-count-down {
padding: 7px 10px;
border-radius: 4px;
border: solid 1px rgba(0, 0, 0, 0.2);
background-color: rgba(0, 0, 0, 0.06);
font-size: 12px;
color: #fff;
display: flex;
align-items: center;
width: max-content;
height: 38px;
box-sizing: border-box;

&__label {
  margin-right: 8px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

&__big-text {
  font-size: 22px;
  font-weight: bold;
  margin-right: 1px;
}

&__time {
  margin-right: 2px;
  display: flex;
  align-items: center;
  font-weight: 300;

  &:last-child {
    margin-right: 0px;
  }
}
}

.popup-gallery .placeholder {
width: 100%;
height: 400px;
background-color: var(--color-background-light-gray);
}

.DetailBooking-Box .booking-detail-loading {
position: fixed;
z-index: 10010;

.Loader-Icon {
  width: 150px;
  height: 150px;

  &:after {
    width: 120px;
    height: 120px;
    top: 14px;
    left: 14px;
  }

  img {
    height: 68px;
  }
}
}

.c-confirmation {
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 99999;

&__inner {
  margin-top: 80px;
  padding: 17px 40px;
  border-radius: 6px;
  background-color: #ffdb01;
  color: #282828;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: max-content;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  min-width: 100px;

  > i {
    margin-right: 10px;
    cursor: pointer;
    font-size: 22px !important;
  }
}
}

.hyperlink-green-color {
color: var(--color-border-green);
}

.submit-edits {
.modal-text {
  padding-top: 0 !important;
  b {
    text-transform: lowercase;
  }
}
}

.driver-loading {
padding: 10px;
border: 1px solid var(--color-gray-xl);
border-radius: var(--radius);
margin: 10px 0 20px;
display: flex;
align-items: center;
gap: 10px;

.linear-background {
  margin-top: 0;
}

&__avatar {
  height: var(--length-xl);
  width: var(--length-xl);
  border-radius: var(--radius);
  overflow: hidden;
}

&__content {
  .linear-background:not(:first-child) {
    margin-top: 10px;
  }
}
}

.driver-not-vailable .modal-layout h3 {
text-transform: lowercase;

&:first-letter {
  text-transform: uppercase;
}
}


.DetailBooking-List .scroll-table {
  overflow-x: auto;
  overflow-y: hidden;
  table {
    thead tr td {
      min-width: 150px;
    }
  }
}

.DetailBooking-FormGroup-Bg p {
  margin: 0;
}